import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ChartShape } from 'src/utils/types'
import AddArea, { AreaBase } from 'src/components/molecules/AddArea'
import { ChartListItem } from 'src/components/organisms/ChartList'
import { Menu, Typography } from '@material-ui/core'
import { compareName } from 'src/utils/sorting'

const StyledMenu = styled(Menu)`
  & .MuiMenu-paper {
    min-width: 300px;
  }
  .MuiMenu-list > * {
    margin: ${({ theme }) => theme.spacing(1, 0, 1, 0)};
  }
`

export const ChartSelector = ({ name, value, charts = [], onChange }) => {
  const [open, setOpen] = useState(false)
  const [selected, setSelected] = useState(value)
  const anchorRef = useRef()

  const handleClick = (chart) => {
    setSelected(chart)
    if (onChange) {
      onChange(name, chart)
    }
    setOpen(false)
  }
  const handleSelectClick = () => setOpen(true)
  const handleMenuClose = () => setOpen(false)
  const handleClearSelection = () => setSelected(null)

  const sortedCharts = charts.sort(compareName)

  return (
    <>
      <div ref={anchorRef}>
        {!selected && (
          <AddArea title="Select chart" onClick={handleSelectClick} />
        )}
        {selected && (
          <ChartListItem
            showRemoveButton={false}
            onClick={handleSelectClick}
            {...selected}
          />
        )}
      </div>
      {open && (
        <StyledMenu
          open={open}
          anchorEl={anchorRef.current}
          onClose={handleMenuClose}
        >
          {selected && (
            <AreaBase onClick={handleClearSelection}>
              <Typography variant="h4" color="textPrimary" noWrap>
                Clear selection
              </Typography>
            </AreaBase>
          )}
          {sortedCharts.map((chart) => (
            <ChartListItem
              key={chart.uuid}
              onClick={handleClick}
              showRemoveButton={false}
              uuid={chart && chart.uuid}
            />
          ))}
        </StyledMenu>
      )}
    </>
  )
}

ChartSelector.propTypes = {
  charts: PropTypes.arrayOf(PropTypes.shape(ChartShape)),
  name: PropTypes.string,
  value: PropTypes.shape(ChartShape),
  onChange: PropTypes.func,
}

export default React.memo(ChartSelector)
