import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import useForm from 'src/hooks/useForm'
import styled, { css } from 'styled-components'
import fileSize from 'filesize'
import { useDispatch } from 'react-redux'

import { Checkbox, Typography, Box, Divider } from '@material-ui/core'

import { TextInput } from 'src/components/atoms/TextInput'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import { validateValues } from 'src/utils/validation'
import { TideStationShape } from 'src/utils/types'
import { formatTideDataRange } from 'src/utils/formatters'
import FileIcon from 'src/components/atoms/Icons/FileIcon'
import { StyledLabel as InputLabel } from 'src/components/atoms/Input'
import { TIDE_TYPE, TIDES_MAX_FILE_SIZE } from 'src/constants/settings'
import { addErrorToast } from 'src/store/toast/actions'
import { CheckboxInactiveTickIcon } from 'src/components/atoms/Icons/Checkbox'
import { CheckboxTickIcon } from './../../atoms/Icons/Checkbox';

const NoTideDataMessageWrapper = styled.div(
  ({ theme }) => css`
    background: rgba(216, 216, 216, 0.15);
    border-radius: ${theme.spacing(0.75)}px;
    color: #3c495c;
    font-size: ${theme.typography.pxToRem(14)};
    font-weight: 500;
    margin-top: ${theme.spacing(3)}px;
    padding: ${theme.spacing(0.75, 1.75, 0.75, 1.75)};
  `
)

const StyledLabel = styled(InputLabel)(
  ({ theme }) => css`
    margin: 0;
    text-transform: none;
    line-height: 120%;
    font-size: ${theme.typography.pxToRem(14)};
  `
)

const TideDataRange = styled.div(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(16)};
    margin-left: ${theme.spacing(2)}px;
  `
)

const checkBoxStyle = { padding: 0 }
const typographyStyle = { paddingLeft: 15 }

const validateForm = (values) => {
  return validateValues(values, {
    name: ['required', 'name', 'minLength'],
  })
}

export const TideStationForm = ({
  onChange,
  disabled,
  tideStation,
  allDataFile,
  extremesDataFile,
  onFileSelect,
}) => {
  const dispatch = useDispatch()

  const { fields } = useForm({
    initialValues: { ...tideStation },
    validationHandler: validateForm,
    changeHandler: onChange,
  })

  const [ isAllDataDst, setIsAllDataDst ] = useState(false)
  const [ isExtremesDataDst, setIsExtremesDataDst ] = useState(false)

  const isCreated = !!tideStation.uuid

  const { dateRange = [] } = tideStation
  const dateRangeAll = dateRange.find(({ type }) => type === TIDE_TYPE.ALL)
  const dateRangeExtremes = dateRange.find(
    ({ type }) => type === TIDE_TYPE.EXTREMES
  )

  const { start: allStartDate, end: allEndDate } = dateRangeAll || {}
  const { start: extremesStartDate, end: extremesEndDate } =
    dateRangeExtremes || {}

  const onAllFileSelect = useCallback(
    (_, value) => onFileSelect(tideStation, TIDE_TYPE.ALL, value, isAllDataDst),
    [tideStation, onFileSelect, isAllDataDst]
  )

  const onExtremesFileSelect = useCallback(
    (_, value) => onFileSelect(tideStation, TIDE_TYPE.EXTREMES, value, isExtremesDataDst),
    [tideStation, onFileSelect, isExtremesDataDst]
  )

  const onAllFileReject = useCallback(
    (files) => {
      const [file] = files
      if (file && file.size > TIDES_MAX_FILE_SIZE) {
        dispatch(
          addErrorToast({
            message: `The file size limit is ${fileSize(TIDES_MAX_FILE_SIZE, {
              base: 10,
            })}`,
          })
        )
      } else {
        onFileSelect(tideStation, TIDE_TYPE.ALL, null)
      }
    },
    [onFileSelect, tideStation, dispatch]
  )

  const onExtremesFileReject = useCallback(
    () => onFileSelect(tideStation, TIDE_TYPE.EXTREMES, null),
    [tideStation, onFileSelect]
  )

  const onAllDataDstChanges = (event) => {
    setIsAllDataDst(event.target.checked)
  }

  const onExtremesDataDstChanges = (event) => {
    setIsExtremesDataDst(event.target.checked)
  }

  return (
    <>
      <Box pb={1}>
        <TextInput
          fullWidth
          label="Station Name"
          primaryLabel
          name="name"
          disabled={disabled}
          {...fields.name}
        />
      </Box>
      {isCreated && (
        <>
          <Divider component="hr" />
          <Box py={3}>
            <Box
              display="flex"
              alignItems="flex-start"
            >
              <Box flex={1} pr={4}>
                <Typography variant="h4" gutterBottom>
                  Predicted Tide Data
                </Typography>
                <StyledLabel>
                  {`Please upload the predicted tide data for this tide station,
                  formatted according to the tide CSV template. ${fileSize(
                    TIDES_MAX_FILE_SIZE,
                    { base: 10, round: 0, spacer: '' }
                  )} maximum
                  file size.`}
                </StyledLabel>
                {tideStation &&
                  tideStation.uuid &&
                  (!allStartDate || !allEndDate) && (
                    <NoTideDataMessageWrapper>
                      Tide data is not available. Please upload a CSV file.
                    </NoTideDataMessageWrapper>
                  )}
                {allStartDate && allEndDate && (
                  <Box mt={3} display="flex" alignItems="center">
                    <FileIcon ext="csv" size={25} />
                    <TideDataRange>
                      {formatTideDataRange(allStartDate, allEndDate)}
                    </TideDataRange>
                  </Box>
                )}
              </Box>
              <Box flex={0}>
                <FileInput
                  label="Add file"
                  forwardLabel
                  name="all"
                  accept=".csv"
                  extensionsLabel=".csv"
                  disabled={disabled}
                  fileSelection={allDataFile ? [allDataFile] : []}
                  onChange={onAllFileSelect}
                  onDropRejected={onAllFileReject}
                  maxSize={TIDES_MAX_FILE_SIZE}
                />
              </Box>
            </Box>
            <Box mt={2} display="flex">
              <Checkbox
                checked={isAllDataDst}
                onChange={onAllDataDstChanges}
                style={checkBoxStyle}
                checkedIcon={<CheckboxTickIcon/>}
                icon={<CheckboxInactiveTickIcon/>}
              /><Typography style={typographyStyle}>Tick this box prior to uploading a file if the Dataset within the file accounts for Daylight saving time.</Typography>
            </Box>
          </Box>
          <Divider component="hr" />
          <Box pt={3}>
            <Box
              display="flex"
              alignItems="flex-start"
            >
              <Box flex={1} pr={4}>
                <Typography variant="h4" gutterBottom>
                  Predicted High/Low Tides
                </Typography>
                <StyledLabel>
                  Please upload your predicted high/low tide data for this tide
                  station, formatted according to the associated high/low tide
                  TXT template.
                </StyledLabel>
                {tideStation &&
                  tideStation.uuid &&
                  (!extremesStartDate || !extremesEndDate) && (
                    <NoTideDataMessageWrapper>
                      High/low tide data is not available. Please upload a TXT
                      file.
                    </NoTideDataMessageWrapper>
                  )}
                {extremesStartDate && extremesEndDate && (
                  <Box mt={3} display="flex" alignItems="center">
                    <FileIcon ext="txt" size={25} />
                    <TideDataRange>
                      {formatTideDataRange(extremesStartDate, extremesEndDate)}
                    </TideDataRange>
                  </Box>
                )}
              </Box>
              <Box flex={0}>
                <FileInput
                  label="Add file"
                  forwardLabel
                  name="extremes"
                  fileSelection={extremesDataFile ? [extremesDataFile] : []}
                  accept=".txt"
                  extensionsLabel=".txt"
                  disabled={disabled}
                  onChange={onExtremesFileSelect}
                  onDropRejected={onExtremesFileReject}
                />
              </Box>
            </Box>
            <Box mt={2} display="flex" pb={4}>
              <Checkbox
                checked={isExtremesDataDst}
                onChange={onExtremesDataDstChanges}
                style={checkBoxStyle}
                checkedIcon={<CheckboxTickIcon/>}
                icon={<CheckboxInactiveTickIcon/>}
              /><Typography style={typographyStyle}>Tick this box prior to uploading a file if the Dataset within the file accounts for Daylight saving time.</Typography>
            </Box>
          </Box>
          <Divider component="hr" />
        </>
      )}
    </>
  )
}

TideStationForm.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  tideStation: PropTypes.shape(TideStationShape),
  allDataFile: PropTypes.object,
  extremesDataFile: PropTypes.object,
  onFileSelect: PropTypes.func,
}

export default TideStationForm
