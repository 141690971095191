import React, { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { PilotInvitation } from 'src/models/invitations'
import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import { TextInput } from 'src/components/atoms/TextInput'
import { getListFilteredBySelectedPort } from 'src/store/invitation/selectors'
import Autocomplete from '@material-ui/lab/Autocomplete'

export interface InvitePilotFormProps {
  onChange?: Function
  onSubmit?: Function
  disabled?: boolean
  submitting?: boolean
  invitation: PilotInvitation // Incoming invitation is not the same as IValidationToken dto, it's a subset and flattened
  advisory: (query: { email: string }) => 
    ({ 
      message: string, 
      firstName?: string, 
      lastName?: string,
      externalId?: string
      error?: string
    })
  emailOptions: string[]
}

export const InvitePilotForm: React.FC<InvitePilotFormProps> = ({
  invitation,
  onChange,
  onSubmit,
  disabled,
  submitting,
  advisory,
  emailOptions
}) => {

  const invites = useSelector(getListFilteredBySelectedPort)

  const validateInvitationForm = (invites: PilotInvitation[]) => (values: {
    [k: string]: any
  }): {
    [k: string]: null | string
  } =>
    validateValues(values, {
      firstName: ['required', 'humanName'],
      lastName: ['required', 'humanName'],
      email: ['required', 'email', () => advisoryResult.error],
      externalId: (
        value: string,
        field: any,
        values: { uuid: string }
      ): string | null => {
        if (!value) {
          return null
        }
        if (value.length >= 50) {
          return 'Maximum 50 characters'
        }
        if (
          invites &&
          invites.find(
            (invite) => invite.externalId === value && values.uuid !== invite.uuid
          )
        ) {
          return 'This pilot ID already exists'
        }
        return null
      },
    })

  const {
    fields,
  }: { fields: { [k: string]: any }; isValid: boolean } = useForm({
    initialValues: invitation,
    validationHandler: validateInvitationForm(invites),
    changeHandler: onChange,
    submitHandler: onSubmit,
  })

  const advisoryResult = useMemo(() =>
    advisory ? advisory({ email: fields.email.value }) : { message: '' },
    [fields.email.value]
  )

  useEffect(() => {
    if (advisoryResult.message) {
      fields.firstName.onChange('firstName', advisoryResult.firstName || '')
      fields.lastName.onChange('lastName', advisoryResult.lastName || '')
      fields.externalId.onChange('externalId', advisoryResult.externalId || '')
    } else {
      fields.firstName.onChange('firstName', invitation.firstName)
      fields.lastName.onChange('lastName', invitation.lastName)
      fields.externalId.onChange('externalId', invitation.externalId)
    }
  }, [advisoryResult])

  return (
    <Grid container spacing={1}>

      <Grid item xs={12}>
        <Autocomplete
          id="email-autocomplete"
          freeSolo
          inputValue={fields.email.value}
          onInputChange={(event, newValue) => {
            fields.email.onChange('email', newValue)
          }}
          options={emailOptions || []}
          getOptionLabel={(email: string) => email}
          renderInput={(params: any) => {
            return (
              <TextInput
                fullWidth
                label="Email"
                name="email"
                disabled={disabled || submitting || invitation.uuid}
                {...params}
                error={fields.email.error}
              />
            )
          }}
        />
      </Grid>
      {
        advisoryResult.message &&
        <Grid item xs={12}>{advisoryResult.message}</Grid>
      }

      <Grid item xs={12} sm={6}>
        <TextInput
          fullWidth
          name="firstName"
          label="First name"
          {...fields.firstName}
          disabled={disabled || submitting || invitation.uuid || !!advisoryResult.firstName}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput
          fullWidth
          name="lastName"
          label="Last name"
          {...fields.lastName}
          disabled={disabled || submitting || invitation.uuid || !!advisoryResult.lastName}
        />
      </Grid>
      
      <Grid item xs={12} sm={6}>
        <TextInput
          fullWidth
          name="externalId"
          label="Pilot ID"
          {...fields.externalId}
          infoTooltip="Please enter the exact same identifier used for Pilot in the Harbour Planning System at the port"
          disabled={disabled || submitting || !!advisoryResult.externalId}
        />
      </Grid>
    </Grid>
  )
}
