import React from 'react'
import styled from 'styled-components'
import useOnlineStatus from 'src/hooks/useOnlineStatus'
import { palette } from 'src/styles/theme'

const ComponentWrapper = styled.div`
  position: fixed;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  background: ${palette.error.main};
  color: #fff;
  text-align: center;
  padding: 10px;
`

const OfflineWarning = () => {
  const { online } = useOnlineStatus()

  return online ? <></> : <ComponentWrapper>You are offline</ComponentWrapper>
}

export default OfflineWarning
