import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  Dialog,
  DialogActions,
  Box,
  DialogContent,
  Typography,
  Divider,
  Switch,
  Tooltip,
  LinearProgress,
  FormControlLabel,
  RadioGroup,
  Radio
} from '@material-ui/core'
import { fade } from '@material-ui/core/styles/colorManipulator'

import { RouteShape, PortShape } from 'src/utils/types'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { saveRoute } from 'src/store/route/actions'
import { TextInput } from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import StageForm from 'src/components/organisms/StageForm'
import { RemoveButton } from 'src/components/atoms/RemoveButton'
import RouteConstraintList from 'src/components/organisms/RouteConstraintList'
import useChartsData from 'src/hooks/useCharts'
import DialogHeader from 'src/components/molecules/DialogHeader/DialogHeader'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryDialogButton from 'src/components/atoms/PrimaryDialogButton'
import Fieldset from 'src/components/organisms/Fieldset'
import InfoIcon from 'src/components/atoms/Icons/InfoIcon'
import { getFilteredListByPort } from 'src/store/route/selectors'
import NarrowButton from 'src/components/organisms/NarrowButton'
import SaveProgress from 'src/components/atoms/SaveProgress'
import { SIDE_TO, SIDE_TO_OPTIONS } from 'src/constants/route'

const AddButton = styled(NarrowButton)`
  && {
    margin: ${({ theme }) => theme.spacing(2, 0, 2, 0)};
    padding-left: 0;
    padding-right: 0;
    :hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
      .MuiButton-label {
        color: #4daaef;
        border-top-color: #4daaef;
      }
    }
    .MuiButton-label {
      border-top: 1px solid ${fade('#4daaef', 0.3)};
      color: ${fade('#4daaef', 0.3)};
      height: 0;
      justify-content: flex-start;
      transition: color 250ms, border-color 250ms;
    }
  }
`

const AddStageLabel = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  padding: ${({ theme }) => theme.spacing(0.5, 0.5, 0.5, 0)};
`

const RemoveStageButton = styled(RemoveButton)`
  && {
    padding: 0;
    position: absolute;
    right: -15px;
    top: -8px;
    z-index: 1;
  }
`

const StyledInfoIcon = styled(InfoIcon)`
  && {
    display: block;
  }
`

const validateStage = (values) =>
  validateValues(values, {
    name: ['required', 'name'],
    chart: [
      'required',
      (value) =>
        value && value.name && value.uuid && value.imageUrl
          ? null
          : 'Please select a chart',
    ],
  })

const validateRouteForm = (port, routes, name) => (values) =>
  validateValues(values, {
    name: [
      'required',
      'name',
      (value) => {
        if (
          routes &&
          routes.find((route) => route.name === value && route.port.uuid === port.uuid) &&
          value !== name
        ) {
          return 'This name already exists, please choose a different name.'
        }
        return null
      },
    ],
    stages: [
      'required',
      (stages) => {
        const errors = stages.map(validateStage)
        const hasError = errors.some((error) => error.name || error.chart)
        return stages && stages.length >= 1
          ? hasError
            ? errors
            : null
          : 'Please add at least a starting and a destination point.'
      },
    ],
  })

export const RouteFormDialog = ({ port, open, route, onCloseRequest }) => {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const existingRoutes = useSelector(getFilteredListByPort())

  const { uuid, name } = route
  const isCopy = !uuid && name
  route.stages = route.stages
    ? route.stages.sort((r1, r2) => r1.order - r2.order)
    : []
  const [stages, setStages] = useState(route.stages || [])
  const [routeConstraints, setRouteConstraints] = useState(
    route.routeConstraints || []
  )
  // EMPX-484: if isCopy, we pass in '' as the name for validation.
  // Since for a copy, the entity is not yet persisted, so we do not use
  // it in the validation logic.
  const { fields, dirty, isValid } = useForm({
    initialValues: {
      sideToRestrictions: [],
      ...route
    },
    validationHandler: validateRouteForm(port, existingRoutes, isCopy ? '' : name),
    isCopy: isCopy,
  })

  const handleCloseRequest = () => {
    if (dirty) {
      setConfirmOpen(true)
    } else {
      onCloseRequest()
    }
  }

  const handleFormSubmit = async () => {
    if (dirty) {
      setSubmitting(true)
      try {
        const values = Object.keys(fields).reduce(
          (values, key) => ({
            ...values,
            [key]: fields[key].value,
          }),
          {}
        )
        values.stages = values.stages.map((stage, index) => ({
          ...stage,
          firstOrLast: index === 0 || index === values.stages.length - 1,
          order: index,
        }))
        // Form uses uuids for the constraint field, so reinstate the objects
        // before saving
        values.routeConstraints = values.routeConstraints.map((rc, index) => ({
          ...rc,
          constraint: { uuid: rc.constraint },
          order: index,
        }))

        const result = await dispatch(
          saveRoute({
            ...values,
            port,
          })
        )
        if (!result.error) {
          onCloseRequest()
        }
      } catch (error) {
        console.warn(error)
      }
      setSubmitting(false)
    }
  }
  const handleLeaveUnsavedRejected = () => setConfirmOpen(false)
  const handleLeaveUnsavedConfirmed = () => {
    setConfirmOpen(false)
    onCloseRequest()
  }

  const { data: charts } = useChartsData()

  const handleAddNewStageClick = (event) => {
    const index = Number(event.currentTarget.dataset.index)
    let stagesList = [...stages]
    stagesList.splice(index + 1, 0, {
      name: '',
      chart: null,
      key: `${Date.now()}${Math.random()}`,
    })
    stagesList = stagesList.map((stage, index, list) => ({
      ...stage,
      order: index,
      firstOrLast: !index || index === list.length - 1,
    }))
    setStages(stagesList)
  }

  const handleRemoveStageClick = (event) => {
    const index = Number(event.currentTarget.dataset.index)
    let stagesList = [...stages]
    stagesList.splice(index, 1)
    stagesList = stagesList.map((stage, index, list) => ({
      ...stage,
      order: index,
      firstOrLast: !index || index === list.length - 1,
    }))
    setStages(stagesList)
  }
  const handleStageFormChange = (stage) => {
    const stagesList = stages.map((item) =>
      (item.uuid
      ? item.uuid === stage.uuid
      : item.key === stage.key)
        ? stage
        : item
    )
    setStages(stagesList)
  }

  useEffect(() => {
    fields.stages.onChange('stages', stages)
    fields.stages.value = [...stages]
  }, [fields.stages, stages])

  const handleRoutConstraintsListChange = useCallback((routeConstraints) => {
    setRouteConstraints(routeConstraints)
  }, [])

  const handleShowBerthingDrawingsChange = useCallback(
    (evt) => {
      fields.showBerthingStages.onChange(
        'showBerthingStages',
        evt.target.checked
      )
    },
    [fields.showBerthingStages]
  )

  useEffect(() => {
    fields.routeConstraints.onChange('routeConstraints', routeConstraints)
  }, [fields.routeConstraints, routeConstraints])

  /*
    sideToRestrictions are stored as a blacklist, but on the UI
    we show it as a whitelist. therefore we need to invert it on read and write
  */
  const handleSideToChange = (event) => {
    const value = event.target.value
    fields.sideToRestrictions.onChange('sideToRestrictions',
      value === SIDE_TO.PORT ? [SIDE_TO.STARBOARD] :
      value === SIDE_TO.STARBOARD ? [SIDE_TO.PORT] :
      value === SIDE_TO_OPTIONS.EITHER ? [] :
      value === SIDE_TO_OPTIONS.NEITHER ? [SIDE_TO.PORT, SIDE_TO.STARBOARD] :
      []
    )
  }

  const sideToRestrictions = fields.sideToRestrictions.value

  const sideToValue = !Array.isArray(sideToRestrictions) || sideToRestrictions.length === 0 ?
    SIDE_TO_OPTIONS.EITHER :
    sideToRestrictions.length === 2 ?
    SIDE_TO_OPTIONS.NEITHER :
    sideToRestrictions.indexOf(SIDE_TO.PORT) > -1 ? SIDE_TO.STARBOARD :
    sideToRestrictions.indexOf(SIDE_TO.STARBOARD) > -1 ? SIDE_TO.PORT :
    SIDE_TO_OPTIONS.EITHER

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseRequest}
        onEscapeKeyDown={handleCloseRequest}
      >
        {submitting && <SaveProgress />}
        <DialogHeader>{uuid ? 'Update' : 'Create'} route</DialogHeader>
        <DialogContent>
          {submitting && <LinearProgress variant="indeterminate" />}
          <Box mb={4}>
            <TextInput
              fullWidth
              label="Route name"
              name="name"
              {...fields.name}
            />
          </Box>

          <Divider component="hr" />

          <Fieldset title="Applies for Side to at" fullWidth>
            <Box mb={4}>
              <RadioGroup row value={sideToValue} onChange={handleSideToChange}>
                <FormControlLabel value={SIDE_TO.PORT} control={<Radio />} label={SIDE_TO.PORT} />
                <FormControlLabel value={SIDE_TO.STARBOARD} control={<Radio />} label={SIDE_TO.STARBOARD} />
                <FormControlLabel value={SIDE_TO_OPTIONS.EITHER} control={<Radio />} label={SIDE_TO_OPTIONS.EITHER} />
              </RadioGroup>
            </Box>
          </Fieldset>

          <Divider component="hr" />

          <Fieldset title="Chart Sequence" fullWidth>
            <Box display="flex" alignItems="center" mb={4}>
              <Box flex={1} display="flex" alignItems="end" pr={4}>
                <Typography variant="body1" component="div">
                  Add a default berthing drawing to the Route
                </Typography>
                <Tooltip title="A berthing drawing is a chart that is added to a route, on which a sketch can be made showing details for the berthing or mooring.">
                  <Box ml={1}>
                    <StyledInfoIcon />
                  </Box>
                </Tooltip>
              </Box>
              <Box>
                <Switch
                  name="showBerthingStages"
                  color="primary"
                  checked={fields.showBerthingStages.value}
                  onChange={handleShowBerthingDrawingsChange}
                />
              </Box>
            </Box>
          </Fieldset>
          <Divider component="hr" />
          <Box mt={4}>
            {stages.map((stage, index) => (
              <Box key={stage.uuid || stage.key} position="relative">
                <StageForm
                  value={stage}
                  onChange={handleStageFormChange}
                  charts={charts}
                />
                {index && index < stages.length ? (
                  <RemoveStageButton
                    onClick={handleRemoveStageClick}
                    data-index={index}
                  />
                ) : null}

                <AddButton
                  disableRipple
                  fullWidth
                  data-index={index}
                  onClick={handleAddNewStageClick}
                >
                  <AddStageLabel>+ Add stage</AddStageLabel>
                </AddButton>
              </Box>
            ))}
          </Box>
          <Divider component="hr" />
          <RouteConstraintList
            routeConstraints={routeConstraints}
            onChange={handleRoutConstraintsListChange}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleCloseRequest} disabled={submitting}>
            Cancel
          </SecondaryButton>
          <PrimaryDialogButton
            onClick={handleFormSubmit}
            disabled={submitting || !dirty || !isValid}
          >
            Done
          </PrimaryDialogButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmOpen}
        onCancel={handleLeaveUnsavedRejected}
        onConfirm={handleLeaveUnsavedConfirmed}
        title="Unsaved changes"
      >
        You have unsaved changes. Are you sure you want to close?
      </ConfirmDialog>
    </>
  )
}
RouteFormDialog.propTypes = {
  open: PropTypes.bool,
  route: PropTypes.shape(RouteShape),
  port: PropTypes.shape(PortShape),
  onCloseRequest: PropTypes.func,
}

export default RouteFormDialog
