import React from 'react'
import PropTypes from 'prop-types'

export const UserIcon = ({ size = 16 }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1212.000000, -70.000000)" fill="#354052">
        <g transform="translate(-15.000000, -9.000000)">
          <g transform="translate(1227.000000, 76.000000)">
            <path d="M8,10.9999908 C6.22679719,10.9999908 4.78476372,9.56478913 4.78476372,7.79998709 C4.78476372,6.03518506 6.22679719,4.59998341 8,4.59998341 C9.77320281,4.59998341 11.2152363,6.03518506 11.2152363,7.79998709 C11.2152363,9.56478913 9.77320281,10.9999908 8,10.9999908 M11.0207145,11.5383914 C12.2963595,10.5167902 13.0398829,8.86478832 12.7665878,7.05598624 C12.4490832,4.95758382 10.6951718,3.27838188 8.57793872,3.0335816 C5.65609275,2.69518121 3.17714557,4.95918382 3.17714557,7.79998709 C3.17714557,9.31198884 3.88128232,10.6591904 4.97928551,11.5383914 C2.28170227,12.5471926 0.312370045,14.9159953 0.00370736165,18.112799 C-0.0413059463,18.5855995 0.329250035,19 0.806712624,19 C1.21585144,19 1.56470458,18.6927996 1.60087599,18.2871992 C1.92320342,14.7167951 4.66981902,12.5999926 8,12.5999926 C11.330181,12.5999926 14.0767966,14.7167951 14.399124,18.2871992 C14.4352954,18.6927996 14.7841486,19 15.1932874,19 C15.67075,19 16.0413059,18.5855995 15.9962926,18.112799 C15.68763,14.9159953 13.7182977,12.5471926 11.0207145,11.5383914" />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

UserIcon.propTypes = {
  size: PropTypes.number,
}

export default React.memo(UserIcon)
