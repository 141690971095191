import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import store from 'src/store/stations'
import SelectInput from 'src/components/atoms/SelectInput'
import { PortShape } from 'src/utils/types'
import { BERTH_STATION_TYPE } from 'src/constants/settings'

const sortByName = (bs1, bs2) => {
  const bs1name = bs1.name.toLowerCase()
  const bs2name = bs2.name.toLowerCase()
  return bs1name > bs2name ? 1 : bs1name === bs2name ? 0 : -1
}

export const BerthStationSelect = ({
  onChange,
  value: selected,
  required,
  type,
  port,
  ...props
}) => {
  const { selectors } = store
  const berthStations = useSelector(
    type ? selectors.getListByType(type) : selectors.list
  )
  const dispatch = useDispatch()
  const [uuid, setUuid] = useState(selected && selected.uuid)
  const [options, setOptions] = useState([])

  useEffect(() => {
    let opts = berthStations || []
    if (!type) {
      opts = [
        ...opts
          .filter((bs) => bs.berthStationType === BERTH_STATION_TYPE.BERTH)
          .sort(sortByName),
        ...opts
          .filter((bs) => bs.berthStationType === BERTH_STATION_TYPE.STATION)
          .sort(sortByName),
      ]
    } else {
      opts.sort(sortByName)
    }
    opts = berthStations
      ? opts.map((item) => ({
          label: `${item.name} ${!type ? `(${item.berthStationType})` : ''}`,
          value: item.uuid,
        }))
      : []
    if (opts && opts.length && !required) {
      opts.unshift({
        label: ' ',
        value: null,
      })
    }
    setOptions(opts)
  }, [berthStations, required, type])
  if (berthStations === null) {
    dispatch(store.actions.getList())
  }
  const handleChange = useCallback(
    (event) => {
      const {
        target: { value: uuid },
      } = event
      const berthStation = berthStations.find((item) => item.uuid === uuid)
      onChange(props.name, berthStation)
      setUuid(berthStation && berthStation.uuid)
    },
    [berthStations, onChange, props.name]
  )
  return (
    <SelectInput
      {...props}
      value={uuid || ''}
      onChange={handleChange}
      disabled={options === null || props.disabled}
      options={options || []}
    />
  )
}

BerthStationSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  port: PropTypes.shape(PortShape),
  type: PropTypes.oneOf(Object.values(BERTH_STATION_TYPE)),
}

export default BerthStationSelect
