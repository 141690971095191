import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Box } from '@material-ui/core/'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import AddButton from 'src/components/atoms/AddButton'
import InfoIcon from 'src/components/atoms/Icons/InfoIcon'
import UploadButton from 'src/components/atoms/UploadButton'
import { ATTACHMENT_SIZE_LIMIT } from 'src/constants/settings';
import RestartButton from 'src/components/atoms/RestartButton'

const StyledInfoIcon = styled(InfoIcon)`
  && {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`

const Title = styled(Typography).attrs({ variant: 'h2' })`
  && {
    border-left: 4px solid
      ${({ dirty, theme: { palette } }) =>
        palette[dirty ? 'warning' : 'text'].main};
    transition: border-color 250ms;
    padding-left: ${({ theme }) => theme.spacing(2)}px;
    margin: ${({ theme }) => theme.spacing(0, 0, 3, 0)};
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(3)}px;
`

const Subtitle = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`

const FileSelectField = styled(UploadButton)`
  && {
    width: 300px;
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }
`

const SectionHeader = ({
  title,
  children,
  infoTooltip,
  dirty,
  className,
  clickHandler,
  buttonContents,
  hasButton,
  isButtonReset,
  subtitle,
  buttonDisabled,
  selectHandler,
  routeAttachmentSelectHandler,
  routeAttachmentRejectHandler,
  ...wrapperProps
}) => {
  return (
    <SectionWrapper>
      <Grid
        container
        alignContent="center"
        className={className}
        {...wrapperProps}
      >
        <Grid item xs={12} md={12}>
          <Box display="flex">
            {title && (
              <Title variant="h5" color="textPrimary">
                {title}
              </Title>
            )}
            {infoTooltip && (
              <Tooltip title={infoTooltip}>
                <div>
                  <StyledInfoIcon />
                </div>
              </Tooltip>
            )}
          </Box>
        </Grid>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </Grid>
      {hasButton && !isButtonReset && (
        <AddButton disabled={buttonDisabled} handleClick={clickHandler}>
          {buttonContents}
        </AddButton>
      )}
      {hasButton && isButtonReset && (
        <RestartButton disabled={buttonDisabled} handleClick={clickHandler}>
          {buttonContents}
        </RestartButton>
      )}
      {selectHandler && (
        <FileSelectField
          label="Add Chart"
          extensionsLabel="jpg, png"
          accept="image/jpg,image/jpeg,image/png"
          rightAlign
          onSelect={selectHandler}
        />
      )}
      {routeAttachmentSelectHandler && (
        <FileSelectField
          label="Add Attachment"
          extensionsLabel="jpg, png, pdf"
          accept="image/jpg,image/jpeg,image/png,application/pdf"
          rightAlign
          onSelect={routeAttachmentSelectHandler}
          onDropRejected={routeAttachmentRejectHandler}
          maxSize={ATTACHMENT_SIZE_LIMIT}
        />
      )}
    </SectionWrapper>
  )
}

SectionHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  dirty: PropTypes.bool,
  infoTooltip: PropTypes.string,
  clickHandler: PropTypes.func,
  buttonContents: PropTypes.node,
  hasButton: PropTypes.bool,
  isButtonReset: PropTypes.bool,
  subtitle: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  ButtonComponent: PropTypes.object,
  selectHandler: PropTypes.func,
  routeAttachmentSelectHandler: PropTypes.func,
  routeAttachmentRejectHandler: PropTypes.func,
}

SectionHeader.defaultProps = {
  placeholder: 'Search',
}

export default SectionHeader
