import { createSelector } from 'reselect'
import {
  CHART_LIST_GET_IN_PROGRESS,
  CHART_LIST_GET_ERROR,
  CHART_LIST_GET_SUCCESS,
  CHART_LIST_GET_REQUEST,
  CHART_GET_IN_PROGRESS,
} from 'src/store/chart/actions'
import { createGetListFilteredBySelectedPortSelector } from 'src/utils/store'

import { imageTokenSelector } from 'src/store/auth/selectors'
import { defaultBaseUrl } from 'src/utils/sauce/api'

export const chartStateSelector = (state) => state.chart

export const chartListUpdatedAtSelector = createSelector(
  chartStateSelector,
  (state) => state && state.updatedAt
)

export const chartDataSelector = createSelector(
  chartStateSelector,
  (state) => state && state.data
)
export const chartsByIdSelector = createSelector(
  chartStateSelector,
  (state) => state && state.byId
)
export const chartListSelector = createSelector(
  chartDataSelector,
  chartsByIdSelector,
  (data, byId) => data && byId && data.map((uuid) => byId[uuid])
)

export const getListFilteredByPort = (portUuid) =>
  createSelector(
    chartListSelector,
    (list) =>
      list
        ? list.filter(
            (item) => item && item.port && item.port.uuid === portUuid
          )
        : []
  )

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  chartListSelector
)

export const chartListStatusSelector = createSelector(
  chartStateSelector,
  (state) => state.status
)

export const isChartListLoadingSelector = createSelector(
  chartStateSelector,
  (state) =>
    state.status === CHART_LIST_GET_REQUEST ||
    state.status === CHART_LIST_GET_IN_PROGRESS
)

export const isChartListLoadedSelector = createSelector(
  chartStateSelector,
  (state) => state.status === CHART_LIST_GET_SUCCESS
)

export const chartDataHasErrorSelector = createSelector(
  chartStateSelector,
  (state) => state.status === CHART_LIST_GET_ERROR
)

export const chartByIndexSelector = (index) =>
  createSelector(
    chartStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const chartByIdSelector = (uuid) =>
  createSelector(
    chartStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalChartSelector = createSelector(
  chartStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    chartStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    chartStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
export const chartIsLoadingSelector = (uuid) =>
  createSelector(
    statusByIdSelector(uuid),
    (status) =>
      [CHART_GET_IN_PROGRESS, CHART_LIST_GET_IN_PROGRESS].includes(status)
  )

// Used to generate imageApiUrl from new api
export const chartImageApiUrlSelector = (uuid, thumbnail) =>
  createSelector(
    chartByIdSelector(uuid),
    imageTokenSelector,
    (chart, imageToken) =>
      chart &&
      `${defaultBaseUrl}/charts/${uuid}/image?imageToken=${imageToken}${
        thumbnail ? '&type=thumbnail' : ''
      }`
  )
