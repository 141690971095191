import { createSelector } from 'reselect'
import {
  createGetListFilteredByPortSelector,
  createGetListFilteredBySelectedPortSelector,
} from 'src/utils/store'

export const weatherLocationStateSelector = (state) => state.weatherLocations

export const weatherLocationListSelector = createSelector(
  weatherLocationStateSelector,
  (state) => state.data && state.data.map((uuid) => state.byId[uuid])
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  weatherLocationListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  weatherLocationListSelector
)

export const weatherLocationByIndexSelector = (index) =>
  createSelector(
    weatherLocationStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const weatherLocationByIdSelector = (uuid) =>
  createSelector(
    weatherLocationStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalWeatherLocationSelector = createSelector(
  weatherLocationStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    weatherLocationStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    weatherLocationStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
