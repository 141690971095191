import React, { useEffect, useCallback } from 'react'
import { useRoute } from 'react-router5'
import { useSelector, useDispatch } from 'react-redux'
import { actions as routerActions } from 'redux-router5'

import PageNotFound from 'src/components/molecules/PageNotFound'
import {
  SETTINGS_PORT,
  SETTINGS_PILOTAGE,
  HOME,
  SETTINGS_SCOPED,
  SETTINGS_CREATE,
  SETTINGS,
  SETTINGS_PORT_IDENTIFICATION,
  SETTINGS_PILOTAGES,
  SETTINGS_VESSELS,
  SETTINGS_LOCATIONS,
  SETTINGS_ENVIRONMENT,
  SETTINGS_FLOW,
  SETTINGS_PASSAGE_PLANNER,
  SETTINGS_CHECKLISTS,
  SETTINGS_GUIDELINES_TERMS,
  SETTINGS_INTEGRATIONS,
} from 'src/router/routes'
import {
  portListSelector,
  selectedPortSelector,
  getSelectedPortSelector,
} from 'src/store/port/selectors'
import { selectPort } from 'src/store/port/actions'
import ErrorMessage from 'src/components/atoms/ErrorMessage'
import ViewportLoader from 'src/components/atoms/ViewportLoader'
import Environment from 'src/containers/Environment'
import Flow from 'src/containers/Flow'
import Checklists from 'src/containers/Checklists'
import GuidelinesAndTerms from 'src/containers/GuidelinesAndTerms'
import Locations from 'src/containers/Locations'
import PassagePlanner from 'src/containers/PassagePlanner'
import PortIdentification from 'src/containers/PortIdentification'
import Pilotages from 'src/containers/Pilotages'
import Vessels from 'src/containers/Vessels'
import Integrations from 'src/containers/Integrations'
import SettingsWrapper from 'src/components/molecules/SettingsWrapper'
import useFeatureFlags from 'src/hooks/useFeatureFlags'

export const Settings = () => {
  const dispatch = useDispatch()
  const routeInfo = useRoute()
  const { route: { name: routeName, params } = {} } = routeInfo
  const ports = useSelector(portListSelector)
  const selectedPort = useSelector(getSelectedPortSelector)
  const selectedPortUuid = useSelector(selectedPortSelector)
  const featureFlags = useFeatureFlags()

  // load feature flags when selected port changes
  const loadFeatureFlags = useCallback(featureFlags.load, [selectedPortUuid])

  useEffect(() => {
    loadFeatureFlags()
  }, [loadFeatureFlags])

  useEffect(() => {
    if (params.portUuid) {
      if (selectedPort && params.portUuid !== selectedPortUuid) {
        dispatch(
          routerActions.navigateTo(routeName, { portUuid: selectedPortUuid })
        )
      } else if (!selectedPort) {
        dispatch(selectPort(params.portUuid))
      }
    } else if (
      (routeName === SETTINGS || routeName === HOME) &&
      !params.portUuid
    ) {
      if (selectedPort) {
        dispatch(
          routerActions.navigateTo(
            SETTINGS_SCOPED,
            {
              portUuid: selectedPort.uuid,
            },
            { replace: true }
          )
        )
      } else if (ports && ports.length > 0) {
        dispatch(selectPort(ports[0].uuid))
      }
    } else if (routeName === SETTINGS_CREATE && ports && ports.length) {
      // Right now we dont allow to create multiple ports within an org.
      dispatch(
        routerActions.navigateTo(SETTINGS_PORT, {
          portUuid: selectedPort ? selectedPort.uuid : ports[0].uuid,
        })
      )
    }
  }, [dispatch, params.portUuid, ports, routeName, selectedPort])

  if (routeName !== SETTINGS_CREATE) {
    if (!params.portUuid && !selectedPort) {
      return ports && ports.length ? (
        <ViewportLoader />
      ) : (
        <ErrorMessage>No ports available</ErrorMessage>
      )
    }
    if (routeName === HOME) {
      return <ViewportLoader />
    }
  } else if (ports && ports.length) {
    return <ViewportLoader />
  }

  let Component

  switch (routeName) {
    case SETTINGS_CREATE:
    case SETTINGS_PORT:
    case HOME:
    case SETTINGS_SCOPED:
    case SETTINGS_PILOTAGE:
    case SETTINGS_PORT_IDENTIFICATION:
      Component = PortIdentification
      break
    case SETTINGS_PILOTAGES:
      Component = Pilotages
      break
    case SETTINGS_VESSELS:
      Component = Vessels
      break
    case SETTINGS_LOCATIONS:
      Component = Locations
      break
    case SETTINGS_ENVIRONMENT:
      Component = Environment
      break
    case SETTINGS_FLOW:
      Component = Flow
      break
    case SETTINGS_PASSAGE_PLANNER:
      Component = PassagePlanner
      break
    case SETTINGS_CHECKLISTS:
      Component = Checklists
      break
    case SETTINGS_GUIDELINES_TERMS:
      Component = GuidelinesAndTerms
      break
    case SETTINGS_INTEGRATIONS:
      Component = Integrations
      break
    default:
      return <PageNotFound />
  }
  return (
    <SettingsWrapper port={selectedPort}>
      <Component port={selectedPort} />
    </SettingsWrapper>
  )
}

export default Settings
