import React, { useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'
import { Box, TextField, Tooltip, IconButton, InputAdornment } from '@material-ui/core'

import InfoIcon from 'src/components/atoms/Icons/InfoIcon'
import { CopyIcon } from './../Icons/CopyIcon'

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: ${({ regularCase }) => (regularCase ? 'none' : 'capitalize')};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  color: ${({ primary, theme }) => (primary ? theme.palette.text.primary : '')};
  margin-bottom: 5px;
`

export const LabelNarrow = styled(Label)`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  text-transform: ${({ regularCase }) => (regularCase ? 'none' : 'capitalize')};
  color: ${({ theme }) => theme.palette.text.secondary};
`

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 0.85em;
  position: absolute;
  right: 0;
`

export const StyledTextField = styled(TextField)``

export const StyledTextFieldNarrow = styled(StyledTextField)`
  && {
    .MuiOutlinedInput-input {
      padding: ${({ theme }) => theme.spacing(1, 1)};
    }
  }
`

export const FieldWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  padding-bottom: ${({ theme }) => theme.spacing(1)}px;
  position: relative;
`

const StyledInfoIcon = styled(InfoIcon)`
  && {
    display: block;
  }
`

const StyledIconButton = styled(IconButton)`
  && {
    margin-left: auto;
  }
`

export const TextInput = ({
  id = null,
  label = null,
  error = null,
  disabled = false,
  pristine = false,
  touched = false,
  dirty = false,
  className = null,
  narrow = false,
  infoTooltip = null,
  regularCase = false,
  copyEnabled = false,
  onCopy = null,
  primaryLabel = '',
  unit = '',
  ...inputProps
}) => {
  const inputRef = useRef()
  const inputId = useMemo(() => id || `input-${Date.now()}`, [id])
  const clsName = classNames(className, { dirty, touched, pristine })
  const Input = narrow ? StyledTextFieldNarrow : StyledTextField
  const FieldLabel = narrow ? LabelNarrow : Label
  return (
    <FieldWrapper className={clsName}>
      {label && (
        <FieldLabel
          primary={primaryLabel}
          regularCase={regularCase}
          disabled={disabled}
          htmlFor={inputId}
        >
          <Box pr={0.7}>{label}</Box>
          {infoTooltip && (
            <Tooltip title={infoTooltip}>
              <div>
                <StyledInfoIcon />
              </div>
            </Tooltip>
          )}
          {copyEnabled && (
            <>
            <StyledIconButton onClick={onCopy}>
              <CopyIcon />
            </StyledIconButton>
            </>
          )}
        </FieldLabel>
      )}
      <Input
        className={className}
        variant="outlined"
        disabled={disabled}
        error={!!error}
        id={inputId}
        ref={inputRef}
        InputProps={{
          endAdornment: unit && (
            <InputAdornment position="end">{unit}</InputAdornment>
          )
        }}
        {...inputProps}
      />
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </FieldWrapper>
  )
}

TextInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  pristine: PropTypes.bool,
  touched: PropTypes.bool,
  dirty: PropTypes.bool,
  narrow: PropTypes.bool,
  onChange: PropTypes.func,
  infoTooltip: PropTypes.string,
  regularCase: PropTypes.bool,
  copyEnabled: PropTypes.bool,
  onCopy: PropTypes.func,
  primaryLabel: PropTypes.bool,
  unit: PropTypes.string
}

export default React.memo(TextInput)
