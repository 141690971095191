import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Typography } from '@material-ui/core'

import BlockIcon from '@material-ui/icons/Block'

const NoImageWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-height: 120px;
  width: 100%;
`
const StyledUploadIcon = styled(BlockIcon).attrs({ color: 'error' })`
  opacity: 0.5;
`
export const NoImage = ({ label, className }) => (
  <NoImageWrapper className={className}>
    <StyledUploadIcon />
    <Typography variant="caption" color="textSecondary">
      {label || 'No image'}
    </Typography>
  </NoImageWrapper>
)

NoImage.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
}

export default NoImage
