import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom'
import { RouterProvider } from 'react-router5'

import theme from 'src/styles/theme'

import configureRouter from 'src/router/configureRouter'
import configureStore from 'src/store/configureStore'
import RootLayout from 'src/RootLayout'
import * as serviceWorker from 'src/serviceWorker'

// Disable perf debugger on production
if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)

  const Enzyme = require('enzyme')
  const Adapter = require('enzyme-adapter-react-16')

  Enzyme.configure({ adapter: new Adapter() })
}

const store = configureStore()
const router = configureRouter(store)

router.start(() => {
  ReactDOM.render(
    <Provider store={store}>
      <RouterProvider router={router}>
        <RootLayout theme={theme} />
      </RouterProvider>
    </Provider>,
    document.getElementById('root')
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
