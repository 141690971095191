import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Typography, Box } from '@material-ui/core'
import DirectionsBoat from '@material-ui/icons/DirectionsBoat'

const CenterWrapper = styled.div`
  align-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 600px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
`

export const PageNotFound = ({ children }) => (
  <CenterWrapper>
    <Box>
      <Typography component="h2" variant="h1">
        <DirectionsBoat color="secondary" /> 404
      </Typography>
      {children || (
        <Typography variant="body2">We can&apos;t find this ship...</Typography>
      )}
    </Box>
  </CenterWrapper>
)
PageNotFound.propTypes = {
  children: PropTypes.node,
}
export default PageNotFound
