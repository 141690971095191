import React from 'react'
import styled from 'styled-components'
import { ButtonBase, Typography } from '@material-ui/core/'
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded'

const AreaBase = styled(ButtonBase)`
  && {
    display: flex;
    min-width: 180px;
    margin-left: auto;
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(2, 2.5, 2, 2)};
    border-radius: 8px;
    border: 1px dashed ${({ theme }) => theme.palette.secondary.main};
  }
`

const StyledIcon = styled(AddCircleOutlineRoundedIcon)`
  color: ${({ theme }) => theme.palette.text.main};
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`

export const AddButton = (props) => {
  return (
    <AreaBase disableRipple {...props}>
      <StyledIcon />
      <Typography variant="h4" color="textPrimary" noWrap>
        Add Item
      </Typography>
    </AreaBase>
  )
}
