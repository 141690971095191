import React from 'react'
import PropTypes from 'prop-types'

export const ReportsIcon = ({ size = 16 }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-921.000000, -251.000000)" fill="#515151">
        <g transform="translate(843.000000, 48.000000)">
          <g transform="translate(35.000000, 93.000000)">
            <g transform="translate(0.000000, 110.000000)">
              <path d="M49,10 L57,10 L57,8 L49,8 L49,10 Z M49,14 L57,14 L57,12 L49,12 L49,14 Z M50,4 L56,4 L56,0 L50,0 L50,4 Z M63,0 L63,20 L43,20 L43,0 L48,0 L48,2 L45,2 L45,18 L61,18 L61,2 L58,2 L58,0 L63,0 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

ReportsIcon.propTypes = {
  size: PropTypes.number,
}

export default React.memo(ReportsIcon)
