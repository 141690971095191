import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import ButtonBase from '@material-ui/core/ButtonBase'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/Add'

export const AreaBase = styled(({ dense, ...props }) => (
  <ButtonBase {...props} />
))`
  && {
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: left;
    width: 100%;
    min-width: 210px;
    padding: ${({ theme, dense }) =>
      dense ? theme.spacing(2, 2.5) : theme.spacing(4, 5)};
    border-radius: 8px;
    border: 1px dashed ${({ theme }) => theme.palette.secondary.main};
  }
`

const StyledIcon = styled(AddIcon)`
  color: ${({ theme }) => theme.palette.text.main};
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`

const AddArea = ({ title, subtitle, onClick, dense, ...props }) => {
  return (
    <AreaBase disableRipple onClick={onClick} dense={dense} {...props}>
      <StyledIcon />
      <div>
        <Typography variant="h4" color="textPrimary" noWrap>
          {title}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          {subtitle}
        </Typography>
      </div>
    </AreaBase>
  )
}

AddArea.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  dense: PropTypes.bool,
}

export default AddArea
