import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import {
  Box,
  Tooltip,
} from '@material-ui/core'
import { PortShape } from 'src/utils/types'
import Fieldset from 'src/components/organisms/Fieldset'
import { getWeatherLocationList, saveWeatherLocation, deleteWeatherLocation } from 'src/store/weatherLocations/actions'
import { getListFilteredBySelectedPort } from 'src/store/weatherLocations/selectors'
import DataTable from 'src/components/molecules/DataTable'
import { compareName } from 'src/utils/sorting'
import { RemoveIconButton } from 'src/components/atoms/RemoveButton'
import EditButton from 'src/components/atoms/EditButton'
import WeatherLocationForm from 'src/components/organisms/WeatherLocationForm'
import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import ConfirmDialog from '../ConfirmDialog'
import { addToast } from 'src/store/toast/actions'
import { TOAST_VARIANT_ERROR, TOAST_VARIANT_SUCCESS } from 'src/containers/Toast'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import { formatLatOrLong } from 'src/utils/formatters'

const ActionWrapper = styled.div`
  && {
    align-items: center;
    display: flex;
    > button {
      padding: ${({ theme }) => theme.spacing(0.5)}px;
    }
  }
`

const getWeatherLocationColumnDefinitions = ({ onRemove, onEdit }) => [
  {
    field: 'name',
    title: 'Name',
  },
  {
    field: 'location',
    title: 'Latitude',
    labelFunction: (location) => formatLatOrLong(location.latitude)
  },
  {
    field: 'location',
    title: 'Longitude',
    labelFunction: (location) => formatLatOrLong(location.longitude)
  },
  {
    field: 'weatherLocationConstraints',
    title: 'Constraints',
    labelFunction: (weatherLocationConstraints = []) => (
      <>
        {
        weatherLocationConstraints
          .sort((a, b) => {
            const A = a.constraint.name.toLowerCase()
            const B = b.constraint.name.toLowerCase()
            return A < B ? -1 : A > B ? 1 : 0
          })
          .map(c => 
            <div 
              key={c.uuid} 
              style={{
                fontSize: weatherLocationConstraints.length > 1 ? 12 : undefined, 
                whiteSpace: 'nowrap'
              }}
            >{c.constraint.name}</div>  
          )
        }
      </>
    ),
  },
  {
    field: 'uuid',
    title: '',
    sortable: false,
    align: 'right',
    justify: 'flex-end',
    width: 40,
    // eslint-disable-next-line react/display-name
    labelFunction: (uuid, weatherLocation) => (
      <ActionWrapper display="flex" alignItems="center">
        <Tooltip title="Edit location">
          <EditButton onClick={() => onEdit(weatherLocation)} />
        </Tooltip>
        <Tooltip title="Remove location">
          <RemoveIconButton onClick={() => onRemove(weatherLocation)} />
        </Tooltip>
      </ActionWrapper>
    ),
  }
]

export const WeatherLocations = ({ port, disabled }) => {

  const [submitting, setSubmitting] = useState(false)
  const dispatch = useDispatch()
  const weatherLocations = useSelector(getListFilteredBySelectedPort)

  const [selectedWeatherLocation, setSelectedWeatherLocation] = useState(null)
  const [selectedWeatherLocationToRemove, setSelectedWeatherLocationToRemove] = useState(
    null
  )

  useEffect(() => {
    if (port) {
      dispatch(getWeatherLocationList(port.uuid))
    }
  }, [dispatch, port])

  const handleAddLocationClick = () => {
    setSelectedWeatherLocation({
      name: '',
      port,
      location: {
        latitude: '',
        longitude: ''
      },
      weatherLocationWeatherLocations: []
    })
  }

  const handleSaveLocation = async (values) => {
    setSubmitting(true)
    await dispatch(saveWeatherLocation(values))
    setSubmitting(false)
    setSelectedWeatherLocation(null)
  }

  const handleConfirmRemove = () => {
    dispatch(deleteWeatherLocation(selectedWeatherLocationToRemove.uuid)).then(
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_SUCCESS,
            message: getToastMessage(
              TOAST_MESSAGES.WEATHER_LOCATION_DELETE_SUCCESS,
              selectedWeatherLocationToRemove
            ),
          })
        )
        setSelectedWeatherLocationToRemove(null)
      },
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_ERROR,
            message: getToastMessage(
              TOAST_MESSAGES.WEATHER_LOCATION_DELETE_ERROR,
              selectedWeatherLocationToRemove
            ),
          })
        )
        setSelectedWeatherLocationToRemove(null)
      }
    )
  }

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Weather Locations"
        subtitle="Please define the locations of interest for weather. The pilots will see predicted weather for these locations."
        description="Manage the locations of interest of your weather data"
        hasButton
        buttonContents="Add Location"
        clickHandler={handleAddLocationClick}
      >
        {
          weatherLocations && weatherLocations.length > 0 &&
          <Box mb={4}>
            <DataTable
              columns={getWeatherLocationColumnDefinitions({
                onRemove: setSelectedWeatherLocationToRemove,
                onEdit: setSelectedWeatherLocation
              })}
              dataProvider={weatherLocations ? weatherLocations.sort(compareName) : []}
            />
          </Box>
        }
      </Fieldset>

      {selectedWeatherLocation && (
        <FormDialog
          open={!!selectedWeatherLocation}
          title="Create weather location"
          FormComponent={WeatherLocationForm}
          onCancel={() => setSelectedWeatherLocation(null)}
          onSave={handleSaveLocation}
          formEntityProp="weatherLocation"
          entity={selectedWeatherLocation}
          submitting={submitting}
        />
      )}

      {selectedWeatherLocationToRemove && (
        <ConfirmDialog
          open={!!selectedWeatherLocationToRemove}
          onCancel={() => setSelectedWeatherLocationToRemove(null)}
          onConfirm={handleConfirmRemove}
          title={`Remove weather location: ${selectedWeatherLocationToRemove.name}`}
        >
          Are you sure you want to remove this weather location?
          <br />
          {selectedWeatherLocationToRemove.name}
        </ConfirmDialog>
      )}
    </>
  )
}

WeatherLocations.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool
}

export default WeatherLocations
