import { createSelector } from 'reselect'
import idx from 'idx'
import {
  INVITATION_LIST_GET_IN_PROGRESS,
  INVITATION_LIST_GET_SUCCESS,
  INVITATION_LIST_GET_ERROR,
} from 'src/store/invitation/actions'
import { usersByIdSelector } from 'src/store/user/selectors'
import {
  createGetListFilteredBySelectedPortSelector,
  createGetListFilteredBySelectedPortSelector2,
  createGetListFilteredByPortSelector,
  createGetListFilteredByPortSelector2,
} from 'src/utils/store'

export const invitationStateSelector = (state) => state.invitation

export const invitationDataSelector = createSelector(
  invitationStateSelector,
  (state) => state.data
)

export const invitationUuidMapSelector = createSelector(
  invitationStateSelector,
  (state) => state.byId
)

export const invitationListSelector = createSelector(
  invitationDataSelector,
  invitationUuidMapSelector,
  (data, byId) => (data ? data.map((uuid) => byId[uuid]) : [])
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  invitationListSelector
)

export const getListFilteredByPort2 = createGetListFilteredByPortSelector2(
  invitationListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  invitationListSelector
)

export const getListFilteredBySelectedPort2 = createGetListFilteredBySelectedPortSelector2(
  invitationListSelector
)

export const invitationListWithUsersSelector = createSelector(
  [invitationListSelector, usersByIdSelector],
  (list, users) => {
    return list && list.map((invite) => ({
      ...invite,
      user: invite.user
        ? {
            ...invite.user,
            ...((users && users[invite.user.uuid]) || {}),
          }
        : invite.user,
    }))
  }
)

export const getListFilteredByPortWithUsers = createGetListFilteredByPortSelector(
  invitationListWithUsersSelector
)

export const getListFilteredByPortWithUsers2 = createGetListFilteredByPortSelector2(
  invitationListWithUsersSelector
)

export const getListFilteredBySelectedPortWithUsers = createGetListFilteredBySelectedPortSelector(
  invitationListWithUsersSelector
)

export const getListFilteredBySelectedPortWithUsers2 = createGetListFilteredBySelectedPortSelector2(
  invitationListWithUsersSelector
)

export const invitationListByOrgUuidSelector = (orgUuid, mergeUsers) =>
  createSelector(
    mergeUsers ? invitationListWithUsersSelector : invitationListSelector,
    (list) =>
      (list || []).filter(
        (item) => idx(item, (_) => _.organisation.uuid) === orgUuid
      )
  )

export const adminInvitationListByOrgUuidSelector = (orgUuid, mergeUsers) =>
  createSelector(
    invitationListByOrgUuidSelector(orgUuid, mergeUsers),
    (list) => (list || []).filter((item) => item.isAdmin)
  )

export const invitationListStatusSelector = createSelector(
  invitationStateSelector,
  (state) => state.status
)

export const isInvitationListLoadingSelector = createSelector(
  invitationListStatusSelector,
  (status) => status === INVITATION_LIST_GET_IN_PROGRESS
)

export const isInvitationListLoadedSelector = createSelector(
  invitationListStatusSelector,
  (status) => status === INVITATION_LIST_GET_SUCCESS
)

export const isInvitationListLoadingFailedSelector = createSelector(
  invitationListStatusSelector,
  (status) => status === INVITATION_LIST_GET_ERROR
)

export const invitationByIndexSelector = (index) =>
  createSelector(
    invitationStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const invitationByIdSelector = (uuid) =>
  createSelector(
    invitationStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalInvitationSelector = createSelector(
  invitationStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    invitationStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    invitationStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
