import { createMuiTheme } from '@material-ui/core/styles'

const fontFamily = "'ProximaNova', sans-serif"

const fontWeightSemiBold = 600
const fontWeightBold = 700
const fontWeightMedium = 500
const fontWeightRegular = 400
const fontWeightLight = 300

const spacingUnit = 8
export const spacing = {
  unit: spacingUnit,
  xxl: spacingUnit * 10, // 80
  xl: spacingUnit * 6, // 48
  lg: spacingUnit * 4, // 32
  md: spacingUnit * 2, // 16
  sm: spacingUnit, // 8
  xs: spacingUnit / 2, // 4
}

export const palette = {
  primary: {
    main: '#4B87FF',
    light: '#6d9eff',
    xlight: '#f2f6ff',
    dark: '#4B87FF',
    contrastText: '#fff',
  },
  secondary: {
    main: '#c5d0de',
    light: '#6d9eff',
    dark: '#c9dbff',
    contrastText: '#fff',
  },
  tertiary: {
    accent: '#E4E4EA',
    light: '#eff2f9',
    main: '#828797',
    heavy: '#656977',
    dark: '#201F20',
  },
  background: {
    light: '#F5F7FA',
    grey: '#EFF2F5',
    default: '#fff',
    dark: '#c5d0de',
    paper: '#fdfdfd',
  },
  disabled: {
    main: '#949494',
    light: '#f4f4f4',
  },
  success: {
    main: '#79e35d',
    light: '#CFFFD2',
    xlight: '#F5FDF7',
  },
  warning: {
    main: '#f7981c',
    xlight: '#fffaea',
  },
  error: {
    main: '#FF2151',
    light: '#FBE3EE',
    xlight: '#fcedf4',
  },
  text: {
    primary: '#354052',
    secondary: '#a0a0a0',
    contrast: '#FFFFFF',
  },
  divider: '#ECECEC',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    main: '#4B87FF',
    error: '#D2003C',
    success: '#84D584',
  },
  type: 'light',
  action: {
    main: '#a8aab7',
  },
  status: {
    caution: '#ffab4f',
    success: '#05C985',
    complete: '#4B87FF',
  },
}

const transition =
  '250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'

const theme = createMuiTheme({
  palette,
  spacing: spacingUnit,
  typography: {
    useNextVariants: true,
    fontFamily: fontFamily,
    fontFamilySecondary: fontFamily,
  },
  weights: {
    fontWeightSemiBold,
    fontWeightBold,
    fontWeightMedium,
    fontWeightRegular,
    fontWeightLight,
  },
  status: {
    danger: 'orange',
  },
  props: {
    MuiWithWidth: {
      initialWidth: 'xs',
    },
  },
  transition,
})

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontFamily: fontFamily,
  fontFamilySecondary: fontFamily,
  h1: {
    ...theme.typography.h1,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(54),
  },
  h2: {
    ...theme.typography.h2,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(43),
  },
  h3: {
    ...theme.typography.h3,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
  },
  h4: {
    ...theme.typography.h4,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
  },
  h5: {
    ...theme.typography.h5,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
    textTransform: 'uppercase',
  },
  h6: {
    ...theme.typography.h6,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
  },
  subtitle1: {
    ...theme.typography.subtitle1,
  },
  subtitle2: {
    ...theme.typography.subtitle2,
  },
  body1: {
    ...theme.typography.body1,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  body2: {
    ...theme.typography.body2,
  },
  button: {
    ...theme.typography.button,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  caption: {
    ...theme.typography.caption,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(11),
    lineHeight: theme.typography.pxToRem(16),
  },
  overline: {
    ...theme.typography.overline,
  },
}

// Overrides are assigned here so we can use theme.breakpoints
// Override cake ui components here based off classes api
theme.overrides = {
  MuiCard: {
    root: {
      borderRadius: 0,
      border: `1px solid ${palette.divider}`,
      boxShadow: 'none',
    },
  },
  MuiChip: {
    root: {
      marginRight: spacing.sm,
      color: palette.primary.main,
      height: 28,
      backgroundColor: 'transparent',
      border: `1px solid ${palette.primary.main}`,
    },
  },
  MuiRadio: {
    colorSecondary: {
      '&$checked': {
        color: theme.palette.primary.main
      }
    }
  },
  MuiSwitch: {
    root: {
      width: 36,
      height: 20,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$disabled + $track': {
        backgroundColor: `${theme.palette.common.white} !important`,
        borderColor: `${theme.palette.grey[500]} !important`,
      },
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
        label: {
          color: 'red',
        },
      },
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 20 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
    },
    colorPrimary: {
      '&$checked': {
        color: theme.palette.common.white,
      },
    },
  },
  MuiCollapse: {
    entered: {
      height: 'auto',
      overflow: 'visible',
    },
  },
  MuiButton: {
    root: {
      textTransform: 'none',
      fontFamily,
      fontSize: theme.typography.pxToRem(18),
      fontWeight: fontWeightMedium,
      letterSpacing: theme.typography.pxToRem(1.25),
      minHeight: 65,
    },
    contained: {
      boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14)',
      minWidth: 210,
    },
    containedSecondary: {
      '&:hover': {
        backgroundColor: palette.secondary.main,
      },
    },
    outlined: {
      borderColor: palette.secondary.dark,
      color: palette.secondary.dark,
    },
  },
  MuiIconButton: {
    root: {
      fontSize: theme.typography.pxToRem(18),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    colorPrimary: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  MuiInput: {
    root: {
      padding: theme.spacing(1, 1),
    },
  },
  MuiDialog: {
    paper: {
      borderRadius: 10,
      minWidth: 460,
      padding: `${spacing.lg}px ${spacing.xl}px ${spacing.xl}px ${spacing.xl}px`,
    },
    paperWidthSm: {
      maxWidth: 700,
    },
    paperScrollPaper: {
      flexGrow: 1,
    },
  },
  MuiDialogContent: {
    root: {
      padding: 0,
      paddingRight: 20,
      overflowY: 'auto',
      overflowX: 'hidden',
    },
  },
  MuiDialogActions: {
    root: {
      marginTop: spacing.lg,
      padding: 0,
    },
  },
  MuiSelect: {
    icon: {
      color: 'inherit',
    },
    select: {
      color: theme.palette.primary.main,
    },
    disabled: {
      color: theme.palette.disabled.main,
    },
  },
  MuiMenuItem: {
    root: {
      fontSize: theme.typography.pxToRem(14),
      textTransform: 'none',
      fontFamily,
      color: theme.palette.text.main,
      letterSpacing: theme.typography.pxToRem(0.88),
      padding: '6px 24px',
      minWidth: 100,
      // Hide empty label value
      '&[data-value=""]': {
        display: 'none',
      },
    },
  },
  MuiTab: {
    root: {
      textTransform: 'none',
      minHeight: 56,
      [theme.breakpoints.up('xs')]: {
        minWidth: 0,
        marginLeft: 25,
        marginRight: 25,
      },
      '&:first-of-type': {
        marginLeft: 20,
      },
    },
    label: {
      fontSize: theme.typography.pxToRem(18),
      color: palette.primary.contrastText,
      fontWeight: fontWeightRegular,
      lineHeight: theme.typography.pxToRem(36),
    },
    textColorPrimary: {
      fontWeight: fontWeightBold,
      color: palette.primary.contrastText,
    },
    disabled: {
      opacity: 0.3,
    },
    labelContainer: {
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      marginLeft: 0,
    },
  },
  MuiTable: {
    root: {
      borderRadius: 10,
      border: `1px solid ${palette.background.grey}`,
      borderCollapse: 'separate',
    },
  },
  MuiTableRow: {
    head: {
      background: palette.background.grey,
      color: 'rgb(81, 81, 81)',
    },
    root: {
      border: `1px solid ${palette.background.grey}`,
    },
  },
  MuiTableCell: {
    head: {
      color: palette.text.primary,
      fontSize: theme.typography.pxToRem(16),
      fontWeight: fontWeightMedium,
      padding: 10,
    },
    body: {
      color: palette.text.primary,
      fontSize: theme.typography.pxToRem(15),
      fontWeight: fontWeightRegular,
      padding: 10,
      position: 'relative',
    },
  },
  MuiPrivateTextarea: {
    textarea: {
      width: 'auto',
    },
  },
  MuiTooltip: {
    tooltip: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
}

export default theme
