import React from 'react'
import PropTypes from 'prop-types'

const InfoIcon = ({ size = 17, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 16C12.6421 16 16 12.6421 16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16Z"
      stroke="#1F8EFA"
    />
    <path
      d="M8.49358 6.28C9.02158 6.28 9.45358 5.848 9.45358 5.332C9.45358 4.816 9.02158 4.372 8.49358 4.372C7.97758 4.372 7.54558 4.816 7.54558 5.332C7.54558 5.848 7.97758 6.28 8.49358 6.28ZM7.72558 13H9.27358V7H7.72558V13Z"
      fill="#1F8EFA"
    />
  </svg>
)

InfoIcon.propTypes = {
  size: PropTypes.number,
}

export default InfoIcon
