import { useState, useEffect } from 'react'

export default function useOnlineStatus() {
  const [online, setOnline] = useState(Boolean(navigator.onLine))

  useEffect(() => {
    const updateOnlineStatus = () => setOnline(Boolean(navigator.onLine))

    window.addEventListener('online', updateOnlineStatus)
    window.addEventListener('offline', updateOnlineStatus)

    return () => {
      window.removeEventListener('online', updateOnlineStatus)
      window.removeEventListener('offline', updateOnlineStatus)
    }
  }, [])

  return { online }
}
