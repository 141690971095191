import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const CircleFill = styled.circle(
  ({ theme }) => css`
    fill: ${theme.palette.status.caution};
  `
)

export const CautionIcon = React.memo(({ size, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 28 28" {...props}>
    <CircleFill cx="14" cy="14" r="14" />
    <g fill="#fff">
      <circle cx="14" cy="20.5" r="1.5" />
      <rect x="12.5" y="6" width="3" height="11" rx="1.5" />
    </g>
  </svg>
))

CautionIcon.displayName = 'CautionIcon'

CautionIcon.propTypes = {
  size: PropTypes.number,
}

CautionIcon.defaultProps = {
  size: 26,
}

export default CautionIcon
