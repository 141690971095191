import {
  TIDE_DATA_CREATE_IN_PROGRESS,
  TIDE_DATA_CREATE_SUCCESS,
  TIDE_DATA_CREATE_ERROR,
} from 'src/store/tideData/actions'

export const reducer = (state = {}, action) => {
  const { type, payload, error } = action
  switch (type) {
    case TIDE_DATA_CREATE_IN_PROGRESS:
    case TIDE_DATA_CREATE_SUCCESS: {
      const { tideStation: { uuid } = {} } = payload
      return {
        ...state,
        [uuid]: type,
      }
    }
    case TIDE_DATA_CREATE_ERROR: {
      const { payload: { tideStation: { uuid } = {} } = {} } = error
      return uuid
        ? {
            ...state,
            [uuid]: type,
          }
        : state
    }
    default:
      return state
  }
}

export default reducer
