import { useDispatch, useSelector } from 'react-redux'
import {
  constraintListSelector,
  getListFilteredBySelectedPort,
  getListFilteredByPort,
  constraintListStatusSelector,
} from 'src/store/constraint/selectors'
import {
  CONSTRAINT_LIST_GET_SUCCESS,
  CONSTRAINT_LIST_GET_ERROR,
  getConstraintList,
  CONSTRAINT_LIST_GET_IN_PROGRESS,
} from 'src/store/constraint/actions'

const useConstraintsData = (filterByPort = true, portUuid) => {
  const dispatch = useDispatch()
  const data = useSelector(
    filterByPort
      ? portUuid
        ? getListFilteredByPort(portUuid)
        : getListFilteredBySelectedPort
      : constraintListSelector
  )
  const status = useSelector(constraintListStatusSelector)
  if (status === null) {
    dispatch(getConstraintList())
  }
  return {
    data,
    isLoading: CONSTRAINT_LIST_GET_IN_PROGRESS === status,
    isLoaded: [CONSTRAINT_LIST_GET_SUCCESS, CONSTRAINT_LIST_GET_ERROR].includes(
      status
    ),
    hasError: CONSTRAINT_LIST_GET_ERROR === status,
  }
}

export default useConstraintsData
