import React from 'react'
import propTypes from 'prop-types'
import styled from 'styled-components'
import { ButtonBase, Typography } from '@material-ui/core/'
import RestartAltRoundedIcon from '@material-ui/icons/RefreshRounded'

const AreaBase = styled(ButtonBase)`
  && {
    height: 60px;
    display: flex;
    margin-left: auto;
    margin-bottom: ${({ theme }) => theme.spacing(3)}px;
    padding: ${({ theme }) => theme.spacing(2, 2.5, 2, 2)};
    border-radius: 8px;
    border: 1px dashed ${({ theme }) => theme.palette.secondary.main};
  }
`

const StyledIcon = styled(RestartAltRoundedIcon)`
  color: ${({ theme }) => theme.palette.text.main};
  margin-right: ${({ theme }) => theme.spacing(3)}px;
`
const ResetButton = ({ children, handleClick }) => {
  return (
    <AreaBase onClick={handleClick} disableRipple>
      <StyledIcon />
      <Typography variant="h4" color="textPrimary" noWrap>
        {children}
      </Typography>
    </AreaBase>
  )
}

ResetButton.propTypes = {
  children: propTypes.node,
  handleClick: propTypes.func,
}

export default ResetButton
