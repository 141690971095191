import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import Fieldset from 'src/components/organisms/Fieldset'
import { TextInput } from 'src/components/atoms/TextInput'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { Switch, Grid } from '@material-ui/core'
import useFeatureFlags from 'src/hooks/useFeatureFlags'
import deepEqual from 'dequal'
import { addToast } from 'src/store/toast/actions'
import {
  TOAST_VARIANT_SUCCESS,
} from 'src/containers/Toast'
import { ExcludeOmcPdfToggle } from './../RouteAttachmentList/ExcludeOmcPdfToggle'

const ActionsBox = styled.div`
  display: flex;
  & button {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
  }
`

const R = <sub style={{fontSize: 8, bottom: '0.75em'}}>®</sub>

export interface PortShape {
  uuid: string
  name: string
  locationName: string
}

interface OmcConfigProps {
  disabled: boolean
}

interface OmcConfigMetaData {
  system_name: string
  show_graph_in_master_view?: boolean
  show_table_in_master_view?: boolean
  exclude_omc_pdf_attachment_enabled?: boolean
}

const blankConfig = (): OmcConfigMetaData => ({
  system_name: '',
  show_graph_in_master_view: false,
  show_table_in_master_view: false,
  exclude_omc_pdf_attachment_enabled: false,
})

const flagName = 'omc_config'

export const OmcConfig: React.FC<OmcConfigProps> = ({ disabled }) => {
  const { getFlag, setFlag, error, loading, updating } = useFeatureFlags()
  const dispatch = useDispatch()

  const handleSubmit = (config: OmcConfigMetaData) => {
    setFlag(flagName, config).then(() => {
      dispatch(
        addToast({
          variant: TOAST_VARIANT_SUCCESS,
          message: `OMC configuration saved`,
        })
      )
    })
  }

  return (
    <Fieldset
      title={<>OMC DUKC{R}</>}
      subtitle={
        <>Enter the OMC DUKC{R} system name for this port. You can obtain this from the OMC DUKC{R} portal.</>
      }
      disabled={disabled}
      clickHandler={() => null}
      hasButton={false}
      buttonContents=""
    >
      {loading ? (
        '...'
      ) : (
        <OmcConfigForm
          config={(getFlag(flagName) as OmcConfigMetaData) || blankConfig()}
          updating={updating}
          disabled={disabled}
          error={error}
          onSubmit={handleSubmit}
        />
      )}
    </Fieldset>
  )
}

interface OmcConfigFormProps {
  config: OmcConfigMetaData
  updating: boolean
  disabled: boolean
  error?: Error
  onSubmit(config: OmcConfigMetaData): void
}

const OmcConfigForm: React.FC<OmcConfigFormProps> = ({
  config,
  updating,
  disabled,
  error,
  onSubmit,
}) => {
  const [editing, setEditing] = useState<OmcConfigMetaData>({ ...config })

  const dirty = !deepEqual(editing, config)
  const isValid = true

  const handleSubmit = () => onSubmit({ ...editing })

  return (
    <>
      <TextInput
        label="System Name"
        name="systemName"
        fullWidth
        value={editing ? editing.system_name : ''}
        onChange={(event: KeyboardEvent) =>
          editing &&
          setEditing({
            ...editing,
            system_name: (event.target as HTMLInputElement).value,
          })
        }
        />
      {
        editing && editing.system_name &&
        <Toggle
          checked={editing ? !!editing.exclude_omc_pdf_attachment_enabled : false}
          label="Exclude OMC PDF attachment in email?"
          onChange={(checked: boolean) =>
            setEditing({
              ...editing,
              exclude_omc_pdf_attachment_enabled: checked,
            })
          }
          />
      }
      {
        editing && editing.system_name &&
        <Toggle
          checked={editing ? !!editing.show_graph_in_master_view : false}
          label="Export UKC graph in the PDF output?"
          onChange={(checked: boolean) =>
            setEditing({
              ...editing,
              show_graph_in_master_view: checked,
            })
          }
        />
      }

      {
        false && // not implemented yet
        <Toggle
          checked={editing ? !!editing.show_table_in_master_view : false}
          label="Export UKC table in the PDF output?"
          onChange={(checked: boolean) =>
            setEditing({
              ...editing,
              show_table_in_master_view: checked,
            })
          }
        />
      }

      <ActionsBox>
        {dirty && (
          <PrimaryButton
            disabled={updating || !isValid || !dirty || disabled}
            onClick={handleSubmit}
          >
            Save
          </PrimaryButton>
        )}
      </ActionsBox>

      {error && error.message}
    </>
  )
}

interface ToggleProps {
  checked: boolean
  label: string
  onChange(checked: boolean): void
}

const Toggle: React.FC<ToggleProps> = ({ checked, label, onChange }) => (
  <>
    <h5>{label}</h5>
    <Grid component="label" container alignItems="center" spacing={1}>
      <Grid item>
        <Switch
          name="toggle"
          color="primary"
          checked={checked}
          onChange={() => onChange(!checked)}
        />
      </Grid>
      <Grid item>
        <ToggleText>{checked ? 'Enabled' : 'Disabled'}</ToggleText>
      </Grid>
    </Grid>
  </>
)

export default React.memo(OmcConfig)
