import { createSelector } from 'reselect'
import {
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGOUT_IN_PROGRESS,
  AUTH_PASSWORD_RESET_IN_PROGRESS,
  AUTH_PASSWORD_UPDATE_IN_PROGRESS,
  AUTH_PASSWORD_REQUEST_NEW_IN_PROGRESS,
  AUTH_SESSION_REFRESH_IN_PROGRESS,
} from 'src/store/auth/actions'

export const authStateSelector = (state) => state.auth

export const authTokenSelector = createSelector(
  authStateSelector,
  (authState) => authState && authState.token
)

export const refreshTokenSelector = createSelector(
  authStateSelector,
  (authState) => authState && authState.refreshToken
)

export const imageTokenSelector = createSelector(
  authStateSelector,
  (authState) => authState && authState.imageToken
)

export const authErrorSelector = createSelector(
  authStateSelector,
  (authState) => authState && authState.error
)

export const authStatusSelector = createSelector(
  authStateSelector,
  (authState) => authState && authState.status
)
export const authUserSelector = createSelector(
  authStateSelector,
  (authState) => authState && authState.user
)

export const isLoginInProgressSelector = createSelector(
  authStatusSelector,
  (status) => status === AUTH_LOGIN_IN_PROGRESS
)

export const isLogoutInProgressSelector = createSelector(
  authStateSelector,
  (status) => status === AUTH_LOGOUT_IN_PROGRESS
)

export const isRefreshSessionInProgressSelector = createSelector(
  authStatusSelector,
  (status) => status === AUTH_SESSION_REFRESH_IN_PROGRESS
)

export const isRequestNewPasswordInProgressSelector = createSelector(
  authStatusSelector,
  (status) => status === AUTH_PASSWORD_REQUEST_NEW_IN_PROGRESS
)

export const isPasswordResetInProgressSelector = createSelector(
  authStatusSelector,
  (status) => status === AUTH_PASSWORD_RESET_IN_PROGRESS
)

export const isPasswordUpdateInProgressSelector = createSelector(
  authStatusSelector,
  (status) => status === AUTH_PASSWORD_UPDATE_IN_PROGRESS
)

export const isLoggedInSelector = createSelector(
  authTokenSelector,
  (token) => !!token
)
