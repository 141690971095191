export class ApiError extends Error {
  static createError(axiosError) {
    const status =
      (axiosError.response &&
        axiosError.response.data &&
        axiosError.response.data.status) ||
      (axiosError.response && axiosError.response.status) ||
      axiosError.status

    switch (status) {
      case 400:
        return new BadRequestError(axiosError)
      case 401:
        return new AuthorizationError(axiosError)
      case 403:
        return new ForbiddenError(axiosError)
      case 404:
        return new NotFoundError(axiosError)
      case 405:
        return new MethodNotAllowedError(axiosError)
      case 418:
        return new IAmATeaPotError(axiosError)
      case 500:
        return new InternalServerError(axiosError)
      case 501:
        return new NotImplementedError(axiosError)
      case 502:
        return new BadGatewayError(axiosError)
      case 503:
        return new ServiceUnavailableError(axiosError)
      case 504:
        return new GatewayTimeoutError(axiosError)
      default:
        return new ApiError(axiosError)
    }
  }
  constructor({ response, config, request }) {
    let { data: { message: { error: message, status } = {} } = {} } =
      response || {}
    super(message || response.error)
    this.status = status || response.status
    this.url = config.url

    try {
      this.data =
        request.responseType === 'json' ? JSON.parse(config.data) : config.data
    } catch (error) {}

    this.response = response
    this.request = request
    this.config = config
  }
}

export class AuthorizationError extends ApiError {}
export class NotFoundError extends ApiError {}
export class BadRequestError extends ApiError {}
export class ForbiddenError extends ApiError {}
export class MethodNotAllowedError extends ApiError {}
export class IAmATeaPotError extends ApiError {
  constructor(axiosError) {
    super(axiosError)
    console.info(
      atob(
        'CiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgIC8KICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAvCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICB4eFgjIyN4eCAgICAgICAvCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgOjpYWFggICAgICAgIC8KICAgICAgICAgICAgICAgICAgICAgICAgIHh4WFg6Ojo6OjojIyNYWFhYWHgvIyMjIyMKICAgICAgICAgICAgICAgICAgICA6OjpYWFhYWDo6Ojo6OlhYWFhYWFhYWC8gICAgIyMjIwogICAgICAgICB4WFhYLy86Ojo6Ojo6Ly8vLy8vLy8vOjo6Ojo6Ojo6OjovIyMjIyMgICAgIyAgICAgICAgICMjIyMjIyMjIyMKICAgICAgWFhYWFhYLy86Ojo6OjovLy8vL3hYWFhYWFhYWFhYWFhYWFgvIyAgICAjIyMjIyMjICAgICAgIyMjICAgIyMjCiAgICAgWFhYWCAgICAgICAgOjovLy8vL1hYWFhYWFhYWCMjIyMjI1gvIyMjIyMjIyAgICAgICMgICAjIyMgICAgIwogICAgIFhYWFggICAgICAgIDo6Ly8vL1hYWFhYWFhYWCMjIyMjIyMvICMgICAgICMgICAgICAjIyMjICAgIyAgIwogICAgICBYWFhYLzogICAgIDo6Ly8vL1hYWFhYWFhYWFgjIyMjIy8gICMgICAgICMjIyMjIyMjIyAgICAgICMjCiAgICAgICAiIlhYLy86Ojo6OjovLy8vWFhYWFhYWFhYWFhYWFgvIyMjIyMjIyMjIyMgICAgICMgICAgICAgIwogICAgICAgICAgICI6Ojo6Ojo6Oi8vLy9YWFhYWFhYWFhYWFgvICAgICMgICAgICMgICAgICMgICAgICAjIwogICAgICAgICAgICAgICAgIDo6OjovLy8vWFhYWFhYWFhYWC8jIyMjIyMjIyMjIyMjIyMjIyMgICAjIyMKICAgICAgICAgICAgICAgICAgICAgOjo6OjovL1hYWFhYWC8jICAgICMgICAgICMgICAjIyMjIyMjCiAgICAgICAgICAgICAgICAgICAgICAgICA6Ojo6Ojo6Oi8jIyMjIyMjIyMjIyMjIyMjCiAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgLwogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgLwogICAgICAgICAgICAgICAgICAgICAgICAgICAgICAvCiAgICA='
      )
    )
  }
  isTeaTimeNow() {
    return true
  }
}
export class InternalServerError extends ApiError {}
export class NotImplementedError extends ApiError {}
export class BadGatewayError extends ApiError {}
export class ServiceUnavailableError extends ApiError {}
export class GatewayTimeoutError extends ApiError {}
export class OfflineError extends ApiError {}

export default ApiError
