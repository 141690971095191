import React from 'react'
import PropTypes from 'prop-types'
import Port from 'src/components/organisms/Port/Port'
import TugList from 'src/components/organisms/TugList'
import ExternalLinkConfig from 'src/components/organisms/ExternalLinkConfig'
import Divider from 'src/components/atoms/Divider'
import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import { EmptyTugsPdfExportToggle } from './../../components/organisms/TugList/EmptyTugsPdfExportToggle'

export const PortIdentification = ({ port }) => {
  const portUuid = port && port.uuid
  return (
    <SettingsTemplate title="Port Identification &amp; Tugs" port={port}>
      <Port port={port} />
      <Divider />
      <TugList port={port} disabled={!portUuid} />
      <EmptyTugsPdfExportToggle/>
      <Divider />
      <ExternalLinkConfig port={port} index={1} disabled={!portUuid} />
      <Divider />
      <ExternalLinkConfig port={port} index={2} disabled={!portUuid} />
      <Divider />
      <ExternalLinkConfig port={port} index={3} disabled={!portUuid} />
    </SettingsTemplate>
  )
}

PortIdentification.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default PortIdentification
