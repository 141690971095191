import React from 'react'

const PortIdentificationIcon = () => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.30421 0V1.3857H8.7133V1.94577H8.0144V5.8104C6.68545 5.05892 5.17049 4.56582 5.17049 4.56582C5.17049 4.56582 3.64114 5.0637 2.30772 5.82125H2.19412V1.94577H1.49512V1.3857H4.95612V0H5.30421ZM2.68188 3.82259H3.99607V2.18193H2.68188V3.82259ZM5.78721 3.82259H4.47311V2.18193H5.78721V3.82259ZM6.26434 3.82259H7.57853V2.18193H6.26434V3.82259Z"
      fill="#B0B0B1"
    />
    <path
      d="M1.4935 7.00139L1.57483 6.82649C1.69753 6.56267 1.58596 6.24797 1.3257 6.12361C1.06513 5.99911 0.754787 6.1123 0.632142 6.37606L0.0498599 7.62797C-0.0728452 7.89179 0.0387296 8.20646 0.29899 8.33085C0.370823 8.36518 0.446406 8.38136 0.520809 8.38136C0.716254 8.38136 0.903714 8.26939 0.992515 8.07834L1.04284 7.97023C1.06746 8.22504 1.23889 8.67203 1.44453 9.13086C1.88729 9.2374 2.17498 9.19927 2.72264 9.01518C3.39792 8.78946 3.62286 8.78946 4.29814 9.01518C4.54552 9.09837 4.73918 9.15127 4.91832 9.17518V5.11536C4.23941 5.35885 2.36326 6.08898 1.4935 7.00139Z"
      fill="#B0B0B1"
    />
    <path
      d="M10.1573 7.62726L9.57503 6.37536C9.45239 6.11166 9.14213 5.9984 8.88148 6.12291C8.63652 6.23997 8.52343 6.52561 8.61326 6.77873C7.63604 5.93624 5.90253 5.28369 5.34668 5.08789V9.17374C5.52585 9.14961 5.72109 9.09692 5.97079 9.01444C6.63592 8.79632 6.83677 8.78112 7.54648 9.01444C8.13263 9.20877 8.42244 9.24059 8.90437 9.1109C9.06216 8.75736 9.1986 8.4125 9.26241 8.15858C9.36138 8.29946 9.52062 8.38062 9.68639 8.38062C9.7608 8.38062 9.83638 8.36444 9.90821 8.33011C10.1685 8.20575 10.28 7.89108 10.1573 7.62726Z"
      fill="#B0B0B1"
    />
  </svg>
)

export default PortIdentificationIcon
