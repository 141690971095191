import api, {
    getPayloadFromResponse,
    getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getTideRateStations = (config) =>
    api
        .get(`/tide_rate_stations`, config)
        .then(
            (response) => getPayloadFromResponse(response),
            (error) => Promise.reject(error)
        )

export const getTideRateStation = (uuid, config) =>
    api
        .get(`/tide_rate_stations/${uuid}`, config)
        .then(
            (response) => getSinglePayloadFromResponse(response),
            (error) => Promise.reject(error)
        )

export const createTideRateStation = (tideStation, config) => {
    const { file, ...body } = tideStation
    return api.post('/tide_rate_stations', body, config).then(
        // POST the tide_rate_station data
        getSinglePayloadFromResponse,
        (error) => Promise.reject(error)
    )
}

export const updateTideRateStation = (tideStation, config) => {
    const { file, ...body } = tideStation
    return api.put(`/tide_rate_stations/${tideStation.uuid}`, body, config).then(
        // PUT the tide_rate_station data
        getSinglePayloadFromResponse,
        (error) => Promise.reject(error)
    )
}

export const deleteTideRateStation = (uuid, config) =>
    api
        .delete(`/tide_rate_stations/${uuid}`, config)
        .then(
            (response) => getSinglePayloadFromResponse(response),
            (error) => Promise.reject(error)
        )

export default {
    create: createTideRateStation,
    update: updateTideRateStation,
    delete: deleteTideRateStation,
}
