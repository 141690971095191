import {
  CHART_LIST_GET_SUCCESS,
  CHART_GET_SUCCESS,
  CHART_CREATE_SUCCESS,
  CHART_UPDATE_SUCCESS,
  CHART_UPDATE_ERROR,
  CHART_DELETE_SUCCESS,
  CHART_DELETE_ERROR,
  CHART_LIST_GET_ERROR,
  CHART_LIST_GET_IN_PROGRESS,
  CHART_LIST_GET_REQUEST,
} from 'src/store/chart/actions'
import { getIdsFromList, getByIdFromList } from 'src/utils/store'

export const initialState = {
  status: null,
  data: null,
  error: null,
  byId: null,
  statusById: null,
  errorById: null,
}

export const reducer = (state = initialState, action) => {
  const { type, payload, error } = action
  const { uuid } = typeof payload === 'string' ? payload : payload || {}

  switch (type) {
    case CHART_LIST_GET_REQUEST:
    case CHART_LIST_GET_IN_PROGRESS:
      return {
        ...state,
        status: type,
      }
    case CHART_LIST_GET_ERROR:
      return {
        ...state,
        error: error || payload,
        status: type,
      }
    case CHART_LIST_GET_SUCCESS:
      const { data } = payload
      return {
        ...state,
        status: type,
        updatedAt: Date.now(),
        data: getIdsFromList(data),
        byId: getByIdFromList(data),
        statusById: getIdsFromList(data).reduce(
          (status, id) => ({
            ...status,
            [id]: type,
          }),
          {}
        ),
        errorById: {},
      }
    case CHART_GET_SUCCESS:
    case CHART_CREATE_SUCCESS:
    case CHART_UPDATE_SUCCESS: {
      const { byId, statusById, data, ...restState } = state
      const { data: chart } = payload
      const { uuid } = chart
      return {
        ...restState,
        data: data.indexOf(uuid) > -1 ? data : [...data, uuid],
        byId: {
          ...byId,
          [uuid]: chart,
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case CHART_UPDATE_ERROR: {
      const { statusById, errorById, ...restState } = state
      return {
        ...restState,
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        errorById: {
          ...errorById,
          [uuid]: payload,
        },
      }
    }
    case CHART_DELETE_SUCCESS: {
      const { data, byId, statusById, ...restState } = state
      delete byId[uuid]
      return {
        ...restState,
        data: data.filter((id) => id !== uuid),
        byId: { ...byId },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case CHART_DELETE_ERROR: {
      const { statusById, errorById, ...restState } = state
      return {
        ...restState,
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        errorById: {
          ...errorById,
          [uuid]: payload,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
