import { Box, Checkbox, Grid, Typography } from '@material-ui/core'
import fileSize from 'filesize'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FileInput from 'src/components/atoms/FileInput/FileInput'
import SelectInput from 'src/components/atoms/SelectInput'
import { LabelNarrow, TextInput } from 'src/components/atoms/TextInput'
import { ATTACHMENT_SIZE_LIMIT } from 'src/constants/settings'
import useForm from 'src/hooks/useForm'
import { getRouteList } from 'src/store/route/actions'
import {
  getListFilteredBySelectedPort
} from 'src/store/route/selectors'
import { getListFilteredBySelectedPort as getRouteAttachmentListFilteredBySelectedPort } from 'src/store/routeAttachment/selectors'
import { addErrorToast } from 'src/store/toast/actions'
import { RouteAttachmentShape } from 'src/utils/types'
import {
  validateValues
} from 'src/utils/validation'
import styled, { css } from 'styled-components'
import { ImageInput } from 'src/components/atoms/ImageInput/ImageInput'
import { CheckboxInactiveTickIcon } from 'src/components/atoms/Icons/Checkbox'
import { CheckboxTickIcon } from './../../atoms/Icons/Checkbox'
import { routeAttachmentApiUrlSelector } from './../../../store/routeAttachment/selectors'

const PreviewWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    color: ${theme.palette.text.secondary};
    word-break: break-all;
  `
)

const CheckboxWrapper = styled.div(
  () => css`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  `
)

const SubWrapper = styled.sub(
  () => css`
    color: ${({ theme }) => theme.palette.text.secondary};
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    `
)

const checkBoxStyle = { padding: 0, marginRight: 10 }

const validateForm = (values, routeAttachmentList) => {
  return validateValues(values, {
    name: [
      'required',
      'attachmentName',
      (value) =>
        routeAttachmentList.find(
          ({ name, uuid }) => name === value && uuid !== values.uuid
        )
          ? 'Attachment name already exists.'
          : null,
    ],
  })
}

export const RouteAttachmentForm = ({
  onChange,
  onSubmit,
  disabled,
  routeAttachment,
}) => {

  const fileUrlPreview = useSelector(routeAttachmentApiUrlSelector(routeAttachment.uuid))
  const routeAttachmentList = useSelector(getRouteAttachmentListFilteredBySelectedPort)
  const dispatch = useDispatch()
  const [file, setFile] = useState(routeAttachment.fileUrl || fileUrlPreview)

  const validate = useCallback((values) => validateForm(values, routeAttachmentList), [routeAttachmentList])

  const transformSubmitValues = ({
    name,
    allRoutes,
    routeUuids,
  }) => ({
    uuid: routeAttachment.uuid,
    name,
    routes: routeUuids.map((uuid) => {
      const existing = (routeAttachment.routes || []).find(route =>
        route.uuid === uuid
      )
      return existing || { uuid }
    }),
    fileUrl: file,
    allRoutes,
  })

  const handleOnChange = (...args) => {
    if (onChange) {
      onChange(...args)
    }
  }

  const routes = useSelector(getListFilteredBySelectedPort)

  const routeOptions = (routes || [])
    .map(({ name, uuid }) => ({ label: name, value: uuid }))
    .sort((a, b) => {
      const A = a.label.toLowerCase()
      const B = b.label.toLowerCase()
      return A < B ? -1 : A > B ? 1 : 0
    })

  useEffect(() => {
    if (routes === null) {
      dispatch(getRouteList())
    }
  }, [routes, dispatch])

  const routeUuids = (routeAttachment.routes || []).map((route) => route.uuid)

  const { fields } = useForm({
    initialValues: {
      uuid: routeAttachment.uuid,
      name: routeAttachment.name,
      fileUrl: routeAttachment.fileUrl || fileUrlPreview,
      routeUuids,
      allRoutes: routeAttachment.allRoutes,
    },
    validationHandler: validate,
    changeHandler: handleOnChange,
    transformHandler: transformSubmitValues,
    submitHandler: onSubmit,
  })

  const fieldsFileUrl = {...fields.fileUrl}

  const onRouteAttachmentFileSelect = useCallback(
    (_, value) => {
      setFile(value)
      const fileUrlOnChange = fieldsFileUrl.onChange
      if (fileUrlOnChange) {
        fileUrlOnChange('fileUrl', value)
      }
    },
    [fieldsFileUrl]
  )

  const onRouteAttachmentFileReject = useCallback(
    (files) => {
      const [file] = files
      if (file && file.size > ATTACHMENT_SIZE_LIMIT) {
        dispatch(
          addErrorToast({
            message: `The file size limit is ${fileSize(ATTACHMENT_SIZE_LIMIT, {
              base: 10,
            })}`,
          })
        )
      }
    },
    [dispatch]
  )

  let showImagePreview = false
  if (file) {
    const mimeType = file.type || routeAttachment.attachment.mimeType
    if (mimeType.toLowerCase().endsWith('jpg') || mimeType.toLowerCase().endsWith('jpeg') || mimeType.toLowerCase().endsWith('png')) {
      showImagePreview = true
    }
  }

  const AttachmentNamePreview = () => {
    return (
      <Box ml={1}>
        <Typography variant="subtitle2">{file.name || routeAttachment.attachment.name}</Typography>
        <Typography variant="caption">
          {fileSize(file.size || routeAttachment.attachment.size, { base: 10 })}
        </Typography>
      </Box>
    )
  }

  // EMPX-532 since we have both Upload button in form, and ImageInput to select
  // attachment, we want to intercept event for ImageInput and pass it back to
  // button handler, so that both be handled by the same handelr.
  const fieldsFileUrlInterceptOnChange = {...fields.fileUrl}
  fieldsFileUrlInterceptOnChange.onChange = onRouteAttachmentFileSelect

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          { showImagePreview && <ImageInput name="fileUrl"
            maxSize={ATTACHMENT_SIZE_LIMIT}
            onDropRejected={onRouteAttachmentFileReject}
            {...fieldsFileUrlInterceptOnChange} /> }
          <PreviewWrapper>
            <FileInput
              label="Replace file"
              forwardLabel
              name="fileUrl"
              fileSelection={[]}
              extensionsLabel="jpg, png, pdf"
              accept="image/jpg,image/jpeg,image/png,application/pdf"
              disabled={disabled}
              onChange={onRouteAttachmentFileSelect}
              onDropRejected={onRouteAttachmentFileReject}
              maxSize={ATTACHMENT_SIZE_LIMIT}
            />
            { file && <AttachmentNamePreview/>}
          </PreviewWrapper>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            narrow
            name="name"
            label="Name"
            {...fields.name}
          />
        </Grid>
        <Grid item xs={12}>
          <LabelNarrow
            primary={''}
            regularCase={false}
            disabled={disabled}
          >
            <Box pr={0.7}>Associated Routes</Box>
          </LabelNarrow>
          <CheckboxWrapper>
            <Checkbox
              name="allRoutes"
              {...fields.allRoutes}
              checked={fields.allRoutes.value}
              style={checkBoxStyle}
              checkedIcon={<CheckboxTickIcon/>}
              icon={<CheckboxInactiveTickIcon/>}
            /><Typography>Apply to all routes</Typography>
          </CheckboxWrapper>
        </Grid>
        <Grid item xs={12}>
          <SelectInput
            fullWidth
            narrow
            name="routeUuids"
            disabled={disabled || fields.allRoutes.value}
            multiple
            {...fields.routeUuids}
            options={routeOptions}
            renderValue={(selected) => {
              return selected
                .map(key => {
                  const option = routeOptions.find(({ value }) => value === key)
                  return option ? option.label : ''
                })
                .filter(s => s)
                .join(', ')
            }}
            MenuProps={
              {
                variant: 'menu',
                getContentAnchorEl: null
              }
            }
          />
          <SubWrapper disabled={disabled || fields.allRoutes.value}>(Multiple routes may be selected)</SubWrapper>
        </Grid>
      </Grid>
    </>
  )
}

RouteAttachmentForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  routeAttachment: PropTypes.shape(RouteAttachmentShape),
  routeAttachmentFile: PropTypes.object,
}

export default RouteAttachmentForm
