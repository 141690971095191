import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { StationShape } from 'src/utils/types'
import { TextInput } from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import store from 'src/store/stations'

const createValidateForm = (stations) => (values) =>
  validateValues(values, {
    name: [
      'required',
      'nameWithSpecialCharacters',
      (value) => {
        if (stations.find((station) => station.name === value)) {
          return 'This name already exists, please choose a different name.'
        }
        return null
      },
    ],
  })

export const StationForm = ({ station, disabled, onChange, onSubmit }) => {
  const existingStations = useSelector(
    store.selectors.getListByType(station.berthStationType)
  )

  const { fields } = useForm({
    initialValues: station,
    validationHandler: createValidateForm(existingStations),
    changeHandler: onChange,
    submitHandler: onSubmit,
  })

  return (
    <TextInput
      fullWidth
      label="Name"
      name="name"
      disabled={disabled}
      {...fields.name}
    />
  )
}

StationForm.propTypes = {
  station: PropTypes.shape(StationShape),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default StationForm
