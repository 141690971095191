import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { Drawer, Typography, Box, Divider } from '@material-ui/core'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'

import { toastsSelector } from 'src/store/toast/selectors'
import { areNotificationsOpen } from 'src/store/ui/selectors'
import { closeNotifications } from 'src/store/ui/actions'
import { NotificationItem } from 'src/components/organisms/NotificationItem'

export const NOTIFICATION_TYPE_INFO = 'info'
export const NOTIFICATION_TYPE_ERROR = 'error'

const StyledDrawer = styled(Drawer)`
  align-items: flex-start;
  display: flex;
`

const DrawerHeader = styled(Typography).attrs({ variant: 'h3' })`
  && {
    padding: ${({ theme }) => theme.spacing(2)}px;
    display: flex;
    align-items: center;
  }
`

const DrawerHeaderIcon = styled(NotificationsActiveIcon)`
  margin-right: ${({ theme }) => theme.spacing(1)}px;
  color: ${({ theme }) => theme.palette.secondary.light};
`

const EmptyListMessage = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
  height: 100%;
  justify-content: center;
`

export const NotificationDrawer = () => {
  const toasts = useSelector(toastsSelector)
  const open = useSelector(areNotificationsOpen)
  const dispatch = useDispatch()
  const handleOnClose = () => dispatch(closeNotifications())
  return (
    <StyledDrawer open={open} onClose={handleOnClose}>
      <DrawerHeader variant="h3">
        <DrawerHeaderIcon /> Notifications
      </DrawerHeader>
      <Divider />
      <Box p={2} minWidth="25vw" flexGrow="1">
        {!toasts.length && (
          <EmptyListMessage>Nothing so far...</EmptyListMessage>
        )}
        {toasts.map((notification, index) => (
          <NotificationItem key={`notification-${index}`} {...notification} />
        ))}
      </Box>
    </StyledDrawer>
  )
}

export default NotificationDrawer
