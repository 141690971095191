import {
  WEATHER_LOCATION_LIST_GET_SUCCESS,
  WEATHER_LOCATION_GET_SUCCESS,
  WEATHER_LOCATION_CREATE_SUCCESS,
  WEATHER_LOCATION_UPDATE_SUCCESS,
  WEATHER_LOCATION_UPDATE_ERROR,
  WEATHER_LOCATION_DELETE_SUCCESS,
  WEATHER_LOCATION_DELETE_ERROR,
} from 'src/store/weatherLocations/actions'
import { getIdsFromList, getByIdFromList } from 'src/utils/store'

export const getInitialState = {
  status: null,
  data: null,
  error: null,
  byId: null,
  statusById: null,
  errorById: null,
}

export const reducer = (state = getInitialState, action) => {
  const { type, payload } = action
  const { uuid } = typeof payload === 'string' ? payload : payload || {}

  switch (type) {
    case WEATHER_LOCATION_LIST_GET_SUCCESS:
      return {
        ...state,
        data: getIdsFromList(payload),
        byId: getByIdFromList(payload),
        statusById: getIdsFromList(payload).reduce(
          (status, id) => ({
            ...status,
            [id]: type,
          }),
          {}
        ),
        errorById: {},
      }
    
    case WEATHER_LOCATION_GET_SUCCESS: {
      const { data, byId, statusById, ...restState } = state
      return {
        ...restState,
        data: data.indexOf(uuid) > -1 ? data : [...data, uuid],
        byId: {
          ...byId,
          [uuid]: payload,
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    
    case WEATHER_LOCATION_CREATE_SUCCESS: {
      const { data, byId, statusById, ...restState } = state
      return {
        ...restState,
        data: [...data, uuid],
        byId: {
          ...byId,
          [uuid]: payload,
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case WEATHER_LOCATION_UPDATE_SUCCESS: {
      const { byId, statusById, ...restState } = state
      return {
        ...restState,
        byId: {
          ...byId,
          [uuid]: payload,
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case WEATHER_LOCATION_UPDATE_ERROR: {
      const { statusById, errorById, ...restState } = state
      return {
        ...restState,
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        errorById: {
          ...errorById,
          [uuid]: payload,
        },
      }
    }
    case WEATHER_LOCATION_DELETE_SUCCESS: {
      const { data, byId, statusById, ...restState } = state
      delete byId[uuid]
      return {
        ...restState,
        data: data.filter((id) => id !== uuid),
        byId: { ...byId },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case WEATHER_LOCATION_DELETE_ERROR: {
      const { statusById, errorById, ...restState } = state
      return {
        ...restState,
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        errorById: {
          ...errorById,
          [uuid]: payload,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
