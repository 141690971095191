import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getRoutes = (config) =>
  api
    .get(`/routes`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getRoute = (uuid, config) =>
  api
    .get(`/routes/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createRoute = (route, config) => {
  return api
    .post('/routes', route, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateRoute = (route, config) => {
  return api
    .put(`/routes/${route.uuid}`, route, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteRoute = (uuid, config) =>
  api
    .delete(`/routes/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  create: createRoute,
  update: updateRoute,
  delete: deleteRoute,
}
