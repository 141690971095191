import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { useRoute } from 'react-router5'
import { actions as routerActions } from 'redux-router5'

import {
  Box,
  Fab,
  Tooltip,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import useOrganisationsData from 'src/hooks/useOrganisations'
import Fieldset from 'src/components/organisms/Fieldset'
import useInvitationsData from 'src/hooks/useInvitations'
import {
  ORGANISATIONS,
  ORGANISATION_EDIT,
  ORGANISATION_CREATE,
  ORGANISATION_DELETE,
} from 'src/router/routes'
import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import OrganisationForm, {
  EMPTY_ORGANISATION,
} from 'src/components/organisms/OrganisationForm'
import OrganisationCard from 'src/components/organisms/OrganisationList/OrganisationCard'
import {
  saveOrganisation,
  deleteOrganisation,
} from 'src/store/organisation/actions'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import useUsersData from 'src/hooks/useUsers'
import usePortsData from 'src/hooks/usePorts'
import { USER_ROLES } from 'src/constants/user'

const StyledOrgCard = styled(OrganisationCard)`
  && {
    margin-bottom: 20px;
    width: 100%;
    background-color: #EFF2F5;
    border-radius: 3px;
  }
`

const AddButton = styled(Fab).attrs({
  color: 'primary',
})`
  && {
    bottom: ${({ theme }) => theme.spacing(3)}px;
    position: fixed;
    right: ${({ theme }) => theme.spacing(3)}px;
  }
`

export const OrganisationList = () => {

  const dispatch = useDispatch()

  const { data: organisations } = useOrganisationsData()
  const { data: ports } = usePortsData()
  const { data: users } = useUsersData()
  const { data: invitations, isLoading: invitationsLoading } = useInvitationsData(
    true,
    false // do not filter by port, we need all
  )

  const adminUsers = (users || []).filter(({ identity }) => identity.roles.indexOf(USER_ROLES.ADMIN) > -1)
  const adminInvitations = (invitations || []).filter(({ isAdmin }) => isAdmin)

  const { route } = useRoute()
  const { params, name: routeName } = route
  const { orgUuid } = params
  
  const organisation = (organisations || []).find(({ uuid }) => uuid === orgUuid)

  organisations && organisations.sort((i1, i2) => i1.name.trim().toLowerCase() < i2.name.trim().toLowerCase() ? -1
  : i1.name.trim().toLowerCase() > i2.name.trim().toLowerCase() ? 1 : 0)

  const [organisationFormSubmitting, setOrganisationFormSubmitting] = useState(
    false
  )

  const backToOrgList = () =>
    dispatch(routerActions.navigateTo(ORGANISATIONS))

  const handleAddOrganisation = () =>
    dispatch(routerActions.navigateTo(ORGANISATION_CREATE))
    
  const handleOrganisationSave = async (values) => {
    const { invitation, ...organisation } = values // why also saving invitation here???
    setOrganisationFormSubmitting(true)

    try {
      let result = await dispatch(saveOrganisation(organisation))
      if (!result.error) {
        dispatch(routerActions.navigateTo(ORGANISATIONS))
      }
    } catch (error) {}
    setOrganisationFormSubmitting(false)
  }

  const handleConfirmDeleteOrganisation = async () => {
    if (organisation) {
      dispatch(deleteOrganisation(organisation))
      dispatch(routerActions.navigateTo(ORGANISATIONS))
    }
  }

  const handleEditOrganisation = (organisation) =>
    dispatch(
      routerActions.navigateTo(ORGANISATION_EDIT, { orgUuid: organisation.uuid })
    )
  
  const handleDeleteOrganisation = (organisation) =>
    dispatch(
      routerActions.navigateTo(ORGANISATION_DELETE, { orgUuid: organisation.uuid })
    )

  return (
    <>
      <Fieldset
        title="Organisations"
        description="Manage organisations and their ports and admin users"
      >
        {organisations && organisations.length > 0 && (
          <Box display="flex" justifyContent="space-around" flexWrap="wrap">

            {organisations.map((org) => {
              return (
                <StyledOrgCard 
                  key={org.uuid} 
                  organisation={org} 
                  ports={ports ? ports.filter(port => org.uuid === port.organisation.uuid) : []}
                  invitations={adminInvitations || []}
                  invitationsLoading={invitationsLoading}
                  users={adminUsers || []}
                  onEditOrganisation={handleEditOrganisation}
                  onDeleteOrganisation={handleDeleteOrganisation}
                />
              )}
            )}
          </Box>
        )}
      </Fieldset>
      
      {ORGANISATION_CREATE === routeName && (
        <FormDialog
          open
          submitting={organisationFormSubmitting}
          title="Create Organisation"
          entity={{ ...EMPTY_ORGANISATION }}
          FormComponent={OrganisationForm}
          formEntityProp="organisation"
          onCancel={backToOrgList}
          onSave={handleOrganisationSave}
        />
      )}

      {ORGANISATION_EDIT === routeName && organisation && (
        <FormDialog
          open
          submitting={organisationFormSubmitting}
          title="Update Organisation"
          entity={organisation}
          FormComponent={OrganisationForm}
          formEntityProp="organisation"
          onCancel={backToOrgList}
          onSave={handleOrganisationSave}
        />
      )}

      {ORGANISATION_DELETE === routeName && organisation && (
        <ConfirmDialog
          open
          title={`Delete organisation: ${organisation.name}`}
          onCancel={backToOrgList}
          onConfirm={handleConfirmDeleteOrganisation}
        >
          Are you sure you want to remove this organisation?
        </ConfirmDialog>
      )}    

      <Tooltip title="Add new organisation" placement="left">
        <AddButton onClick={handleAddOrganisation}>
          <AddIcon />
        </AddButton>
      </Tooltip>
    </>
  )
}

export default OrganisationList
