import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fade } from '@material-ui/core/styles/colorManipulator'
import Preloader from 'src/components/atoms/Preloader'
import { Typography } from '@material-ui/core'

const Overlay = styled.div`
  align-items: center;
  background: ${({ theme }) => fade(theme.palette.background.default, 0.75)};
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 100;
`

export const ViewportLoader = ({ message, children }) => (
  <Overlay>
    <Preloader />
    <Typography variant="h5">{message}</Typography>
    {children}
  </Overlay>
)

ViewportLoader.propTypes = {
  message: PropTypes.string,
  children: PropTypes.node,
}

export default ViewportLoader
