import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Box } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'

import {
  chartIsLoadingSelector,
  chartImageApiUrlSelector,
} from 'src/store/chart/selectors'
import Preloader from 'src/components/atoms/Preloader'

const ChartItemPreview = styled(({ src, children, ...props }) => (
  <Box {...props}>{children}</Box>
))(
  ({ src }) => `
  align-items: center;
  background: url(${src}) center center no-repeat;
  background-size: cover;
  display: flex;
  height: 85px;
  justify-content: center;
`
)

export const ChartImage = ({ uuid, imageUrl, ...props }) => {
  const isLoading = useSelector(chartIsLoadingSelector(uuid))
  const [imageIsAvailable, setImageIsAvailable] = useState(true)
  const [imageUrlOverride, setImageUrlOverride] = useState('')
  const thumbnailUrl = useSelector(chartImageApiUrlSelector(uuid, true))
  const imageUrlRef = useRef(imageUrl)

  useEffect(() => {
    if (imageUrl !== imageUrlRef.current) {
      const url = new URL(thumbnailUrl)
      url.searchParams.set('t', Date.now())
      setImageUrlOverride(url.toString())
    }
  }, [imageUrl, imageUrlRef, thumbnailUrl])

  useEffect(() => {
    const img = new Image()
    const handleError = () => setImageIsAvailable(false)
    const handleLoad = () => setImageIsAvailable(true)
    img.addEventListener('error', handleError)
    img.addEventListener('load', handleLoad)
    img.src = thumbnailUrl
    return () => {
      img.removeEventListener('error', handleError)
      img.removeEventListener('load', handleLoad)
    }
  }, [thumbnailUrl])

  return (
    <ChartItemPreview src={imageUrlOverride || thumbnailUrl} {...props}>
      {!imageIsAvailable && <BlockIcon color="error" />}
      {isLoading && <Preloader />}
    </ChartItemPreview>
  )
}

ChartImage.propTypes = {
  uuid: PropTypes.string,
  imageUrl: PropTypes.string,
}

export default ChartImage
