import { createSelector } from 'reselect'
import { USER_ROLES } from 'src/constants/user'

export const userStateSelector = (state) => state.user

export const currentUserSelector = createSelector(
  userStateSelector,
  (state) => state.current
)

export const userOrganisationSelector = createSelector(
  currentUserSelector,
  (current) => current && current.organisation
)

export const userNameSelector = createSelector(
  currentUserSelector,
  (current) => {
    const { firstName = '', lastName = '' } = current || {}
    return `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName ||
      ''}`
  }
)

export const userEmailSelector = createSelector(
  currentUserSelector,
  (current) => current && current.email
)

export const userOrganisationUUIDSelector = createSelector(
  userOrganisationSelector,
  (organisation) => organisation && organisation.uuid
)

export const userOrganisationNameSelector = createSelector(
  userOrganisationSelector,
  (organisation) => organisation && organisation.name
)

export const roleSelector = createSelector(
  currentUserSelector,
  (current) => current && current.roles
)

export const isSuperAdminSelector = createSelector(
  roleSelector,
  (roles) => roles && roles.includes(USER_ROLES.SUPER_ADMIN)
)

export const isAdminSelector = createSelector(
  roleSelector,
  (roles) => roles && roles.includes(USER_ROLES.ADMIN)
)

export const isPilotSelector = createSelector(
  roleSelector,
  (roles) => roles && roles.includes(USER_ROLES.PILOT)
)

export const isMasterSelector = createSelector(
  roleSelector,
  (roles) => roles && roles.includes(USER_ROLES.MASTER)
)

// LIST

export const userStatusSelector = createSelector(
  userStateSelector,
  (state) => state && state.status
)
export const userListSelector = createSelector(
  userStateSelector,
  (state) => state.data && state.data.map((uuid) => state.byId[uuid])
)

export const userListStatusSelector = createSelector(
  userStateSelector,
  (state) => state.status
)

export const userByIndexSelector = (index) =>
  createSelector(
    userStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const usersByIdSelector = createSelector(
  userStateSelector,
  (state) => state && state.byId
)

export const getUserByIdSelector = (uuid) =>
  createSelector(
    usersByIdSelector,
    (byId) => byId && byId[uuid]
  )

export const totalOrganisationSelector = createSelector(
  userStateSelector,
  (state) => state.data && state.data.length
)
