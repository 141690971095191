import {
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_ERROR_STATE_RESET,
  AUTH_SESSION_REFRESH_SUCCESS,
  AUTH_SESSION_REFRESH_ERROR,
  AUTH_LOGOUT_ERROR,
  ACTION_BASE,
  AUTH_PASSWORD_REQUEST_NEW_ERROR,
  AUTH_PASSWORD_UPDATE_ERROR,
  AUTH_PASSWORD_RESET_ERROR,
} from 'src/store/auth/actions'

const initialState = {}

export const reducer = (state = initialState, action) => {
  const { type } = action
  const statusState = {
    status: type,
  }
  switch (type) {
    case AUTH_LOGIN_REQUEST:
      return { ...statusState }
    case AUTH_LOGIN_IN_PROGRESS:
      return {
        ...statusState,
      }
    case AUTH_LOGIN_SUCCESS:
    case AUTH_SESSION_REFRESH_SUCCESS:
      const { token, refreshToken, imageToken } = action.payload
      return {
        ...statusState,
        token,
        refreshToken,
        imageToken,
      }
    case AUTH_LOGIN_ERROR:
      return {
        ...statusState,
        error: action.error,
      }
    case AUTH_SESSION_REFRESH_ERROR:
      return {
        ...statusState,
        error: action.error,
        refreshToken: null, // TODO make difference between server error and auth error
      }
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...statusState,
      }
    case AUTH_LOGOUT_ERROR:
    case AUTH_PASSWORD_REQUEST_NEW_ERROR:
    case AUTH_PASSWORD_UPDATE_ERROR:
    case AUTH_PASSWORD_RESET_ERROR:
      return {
        ...state,
        ...statusState,
        error: action.error,
      }
    case AUTH_ERROR_STATE_RESET:
      const { error, ...resetedState } = state
      const { status } = resetedState
      if (status === AUTH_LOGIN_ERROR) {
        resetedState.status = undefined
      }
      return resetedState
    default:
      if (type.indexOf(ACTION_BASE) === 0) {
        return {
          ...state,
          ...statusState,
        }
      }
      return state
  }
}

export default reducer
