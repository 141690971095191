import { useEffect, useState, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuidv1 from 'uuid'

import {
  extraListStatusSelector,
  extraByNameSelector,
  extraValueByNameSelector,
} from 'src/store/extra/selectors'
import { EXTRA_NAME } from 'src/constants/settings'
import {
  EXTRA_CREATE_ERROR,
  EXTRA_UPDATE_ERROR,
  getExtraList,
  saveExtra,
} from 'src/store/extra/actions'
import useForm from 'src/hooks/useForm'

const validateCheckListForm = () => ({
  checklist: null,
})

const useChecklist = ({ extraType, defaultItems, onChange, port }) => {
  const extraDataStatus = useSelector(extraListStatusSelector)
  const checklistExtra = useSelector(
    // the second parameter is filterByPort
    // the checklist is always filtered
    extraByNameSelector(EXTRA_NAME[extraType], true, port && port.uuid)
  )
  const checklist = useSelector(
    // the second parameter is filterByPort
    // the checklist is always filtered
    extraValueByNameSelector(EXTRA_NAME[extraType], true, port && port.uuid)
  )
  const [dirty, setDirty] = useState(false)
  const [loaded, setLoaded] = useState(extraDataStatus !== null)
  const dispatch = useDispatch()

  const { name, uuid } = checklistExtra || {
    name: EXTRA_NAME[extraType],
  }

  useEffect(() => {
    dispatch(getExtraList()).then(() => setLoaded(true))
  }, [dispatch])

  const handleChecklistChange = (change) => {
    if (change.dirty !== dirty) {
      setDirty(change.dirty)
    }
    if (onChange) {
      onChange(change)
    }
  }

  const initialValue = useMemo(() => {
    return (checklist || defaultItems).map((item) =>
      item.uuid ? item : { ...item, uuid: uuidv1() }
    )
  }, [checklist, defaultItems])

  const { fields } = useForm({
    initialValues: {
      checklist: initialValue,
    },
    changeHandler: handleChecklistChange,
    validationHandler: validateCheckListForm,
  })

  const handleSave = async (port) => {
    try {
      const result = dispatch(
        saveExtra({
          uuid,
          name,
          extraType,
          port,
          metadata: JSON.stringify({
            value: fields.checklist.value.filter(
              (item) => !!item && item.label
            ),
          }),
        })
      )
      const { type } = result
      return [EXTRA_CREATE_ERROR, EXTRA_UPDATE_ERROR].includes(type)
        ? Promise.reject(result)
        : Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    fields,
    loaded,
    dirty,
    handleSave,
  }
}

export default useChecklist
