import ReactGA from 'react-ga'
import { hotjar } from 'react-hotjar'

const useAnalytics = () => {
  const initGA = () => {
    ReactGA.initialize({
      trackingId: process.env.REACT_APP_GA_TRACKING_ID,
    })
  }
  const recordPageView = (route) => {
    ReactGA.set({ page: route })
    ReactGA.pageview(route)
  }
  const recordEvent = ({ category, action }) => {
    ReactGA.event({ category, action })
  }
  const initHJ = () => {
    if (process.env.REACT_APP_HJID && process.env.REACT_APP_HJSV) {
      hotjar.initialize(process.env.REACT_APP_HJID, process.env.REACT_APP_HJSV)
    }
  }
  return {
    initGA,
    initHJ,
    recordPageView,
    recordEvent,
  }
}

export default useAnalytics
