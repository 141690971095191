import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button/index'

const StyledButton = styled(Button)(
  ({ theme, size, minWidth = 210 }) => `
  && {
    display: block;
    margin-left: auto;
    margin-top: 14px;
    border: 0 none;
    color: ${theme.palette.primary.main};
    min-width: ${minWidth}px;
    font-weight: 500;
    font-size: ${theme.typography.pxToRem(18)};
    ${size === 'small' ? 'min-height: 42px;' : ''}
  }
  .MuiButton-label {
    display: flex;
  }
`
)

const SecondaryButton = ({ children, ...props }) => (
  <StyledButton variant="outlined" color="secondary" {...props}>
    {children}
  </StyledButton>
)

SecondaryButton.propTypes = {
  children: PropTypes.node,
}

export default React.memo(SecondaryButton)
