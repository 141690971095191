import { applyMiddleware, compose, createStore } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { router5Middleware } from 'redux-router5'

import { router } from 'src/router/configureRouter'
import reducers from 'src/store/reducers'

export const middlewares = [thunkMiddleware, router5Middleware(router)]
export default function configureStore(initialState) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  const store = createStore(
    reducers,
    { ...(initialState || {}) },
    composeEnhancers(applyMiddleware(...middlewares))
  )
  return store
}
