import { createSelector } from 'reselect'
import {
  createGetListFilteredByPortSelector,
  createGetListFilteredBySelectedPortSelector,
} from 'src/utils/store'

export const tugStateSelector = (state) => state.tug

export const tugListSelector = createSelector(
  tugStateSelector,
  (state) => state.data && state.data.map((uuid) => state.byId[uuid])
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  tugListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  tugListSelector
)

export const tugByIndexSelector = (index) =>
  createSelector(
    tugStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const tugByIdSelector = (uuid) =>
  createSelector(
    tugStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalTugSelector = createSelector(
  tugStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    tugStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    tugStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
