import { createSelector } from 'reselect'

export const uiStateSelector = (state) => state.ui || {}

export const notificationsSelector = createSelector(
  uiStateSelector,
  (state) => state.notifications || {}
)
export const areNotificationsOpen = createSelector(
  notificationsSelector,
  (notificationsState) => notificationsState.open
)

export const progressSelector = createSelector(
  uiStateSelector,
  (state) => state && state.progress
)
