import React from 'react'
import PropTypes from 'prop-types'
import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import VesselUpload from 'src/components/organisms/VesselUpload'
import VesselFieldDisplay from 'src/components/organisms/VesselFieldDisplay'
import Divider from 'src/components/atoms/Divider'

export const Vessels = ({ port }) => {
  return (
    <SettingsTemplate title="Vessels" port={port}>
      <VesselUpload port={port} />
      <Divider />
      <VesselFieldDisplay port={port} />
    </SettingsTemplate>
  )
}

Vessels.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Vessels
