import React from 'react'
import PropTypes from 'prop-types'

import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import ChartList from 'src/components/organisms/ChartList'
import Divider from 'src/components/atoms/Divider/Divider'
import Stations from 'src/containers/Stations'

export const Locations = ({ port }) => {
  const portUuid = port && port.uuid
  return (
    <SettingsTemplate title="Locations" port={port}>
      <Stations disabled={!portUuid} port={port} />
      <Divider />
      <ChartList disabled={!portUuid} port={port} />
      <Divider />
    </SettingsTemplate>
  )
}

Locations.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Locations
