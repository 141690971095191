import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InfoIcon from '@material-ui/icons/Info'
import { ReactSortable as Sortable } from 'react-sortablejs'

import {
  List,
  ListItem,
  InputAdornment,
  Box,
  Typography,
  Button,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CancelOutlined'

import { TextInput } from 'src/components/atoms/TextInput'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import ValuedIconButton from 'src/components/atoms/ValuedIconButton'
import isObject from 'src/utils/isObject'

const StyledListItem = styled(ListItem)`
  && {
    display: flex;
    padding: 0;
    margin: 0;
    > * {
      width: 85%;
      padding: 0;
      margin: 0;
    }
    .MuiOutlinedInput-adornedEnd {
      padding-right: 0;
    }
    &:hover {
      cursor: pointer;
    }

    &.ghost .MuiOutlinedInput-root {
      background-color: rgba(142, 208, 255, 0.2);
    }
  }
`

const ListHeader = styled.div`
  display: flex;
  > :first-child {
    flex-grow: 1;
  }
`
const StyledAddButton = styled(Button).attrs({
  size: 'small',
  fullWidth: true,
})`
  && {
    min-height: 0;
  }
`

const DeleteButtonContainer = styled.div`
  align-items: center;
  flex-basis: 0;
  justify-content: center;
  .MuiIconButton-root {
    padding: ${({ theme }) => theme.spacing(1)}px;
    margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const Label = styled.label`
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: capitalize;
  font-size: 0.875rem;
`

export const ListInput = ({
  label,
  Input = TextInput,
  value,
  valueProp,
  keyProp = 'uuid',
  defaultValue,
  onChange,
  error,
  InputProps,
  AddButton,
  useEndAdornmentDeleteButton = true,
  hideDeleteButton = false,
  setHeaderColor = false,
  emptyPlaceholder,
  limit,
  onSave,
  dirty = false,
  isChartDialog,
  sortable,
  children,
  ExtraControlsComponent,
  readOnly = false,
  ...inputProps
}) => {
  const onListChange = (value) => {
    // Remove the properties added by sortable off the items.
    // If we do it in an immutable way useForm will detect the form as changed
    // even if the order is left the same.
    value.forEach((item) => {
      delete item.chosen
      delete item.selected
      delete item.filtered
    })
    onChange(inputProps.name, value)
  }

  const handleChange = (index, event, inputValue) => {
    if (isNaN(index)) {
      return
    }
    const { target } = event
    const newValue = target ? target.value : inputValue
    updateValueAtIndex(newValue, index, valueProp)
  }

  const updateValueAtIndex = (newValue, index, valueProp) => {

    onChange(
      inputProps.name,
      value.map((mapValue, mapIndex) => {

        if (mapIndex !== index) {
          return mapValue
        }
        return typeof mapValue === 'string'
          ? newValue
          : valueProp
          ? {
              ...mapValue,
              [valueProp]: newValue,
            }
          : newValue
      })
    )
  }

  const replaceAtIndex = (newValue, index) => {
    onChange(
      inputProps.name,
      value.map((mapValue, mapIndex) => {
        return mapIndex !== index ? mapValue : newValue
      })
    )
  }

  const handleRemove = (event, index) => {
    onChange(inputProps.name, [
      ...value.slice(0, index),
      ...value.slice(index + 1),
    ])
  }

  const handleAddClick = () => {
    const newItem =
      defaultValue && typeof defaultValue === 'object'
        ? { ...defaultValue }
        : valueProp
        ? { [valueProp]: defaultValue }
        : defaultValue
    onChange(inputProps.name, [...value, newItem])
  }

  const AddButtonComponent = isChartDialog ? StyledAddButton : AddButton

  const hasEmpty = value.some((value) =>
    valueProp ? !value[valueProp] : !value
  )

  const hasReachedLimit = typeof limit === 'number' && value.length >= limit
  const ListComponent = sortable ? Sortable : List
  return (
    <Box width="100%">
      <ListHeader>{label && <Label>{label}</Label>}</ListHeader>
      {value.length === 0 && emptyPlaceholder && (
        <Box mb={1}>{emptyPlaceholder}</Box>
      )}
      <ListComponent
        tag={List}
        list={value}
        setList={onListChange}
        animation={150}
        ghostClass="ghost"
        disabled={!sortable}
      >
        {value.map((item, index) => {

          const extraControls = ExtraControlsComponent ?
            <ExtraControlsComponent
              showHeaderSwitch
              item={item}
              onChange={(newItem) => {
                replaceAtIndex(newItem, index)
              }}
            /> : null;

          const deleteButton = (
            <ValuedIconButton value={index} onClick={handleRemove}>
              <CloseIcon color="action" />
            </ValuedIconButton>
          )

          return (
            <StyledListItem
              disableGutters
              key={`${isObject(item) && keyProp ? item[keyProp] : item}`}
            >
              <Box mb={1} width="85%" flexGrow={1}>
                <Input
                  style={{backgroundColor: `${setHeaderColor && item && item.isHeader ? 'rgba(25,145,235,0.1)' : ''}`}}
                  {...inputProps}
                  fullWidth
                  value={(valueProp ? item[valueProp] : item) || ''}
                  error={Array.isArray(error) ? error[index] : error}
                  onChange={(...args) => handleChange(index, ...args)}
                  inputProps={{ 'data-index': index, readOnly, }}
                  // eslint-disable-next-line
                  InputProps={{
                    ...(InputProps || {}),
                    endAdornment: useEndAdornmentDeleteButton ? (
                      <>
                      {extraControls}
                      {!hideDeleteButton && (
                        <InputAdornment>{deleteButton}</InputAdornment>
                      )}
                      </>
                    ) : extraControls,
                  }}
                />
              </Box>
              {!useEndAdornmentDeleteButton && !hideDeleteButton && (
                <DeleteButtonContainer>{deleteButton}</DeleteButtonContainer>
              )}
            </StyledListItem>
          )
        })}
      </ListComponent>

      {children}

      {isChartDialog && (
        <AddButtonComponent
          disabled={hasEmpty || hasReachedLimit}
          onClick={handleAddClick}
        >
          + Add
        </AddButtonComponent>
      )}

      <Box display="flex">
        {onSave && dirty && (
          <PrimaryButton onClick={onSave} variant="contained" color="primary">
            Save
          </PrimaryButton>
        )}
      </Box>

      {hasReachedLimit && (
        <Box mt={2} display="flex" alignItems="center">
          <Box pr={2}>
            <InfoIcon />
          </Box>
          <Box>
            <Typography variant="body1" gutterTop>
              Limit of {limit} items reached
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  )
}
ListInput.propTypes = {
  label: PropTypes.string,
  useEndAdornmentDeleteButton: PropTypes.bool,
  hideDeleteButton: PropTypes.bool,
  setHeaderColor: PropTypes.bool,
  Input: PropTypes.elementType,
  value: PropTypes.arrayOf(PropTypes.any),
  valueProp: PropTypes.string,
  keyProp: PropTypes.string,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  inputProps: PropTypes.object,
  InputProps: PropTypes.object,
  AddButton: PropTypes.elementType,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  emptyPlaceholder: PropTypes.string,
  limit: PropTypes.number,
  onSave: PropTypes.func,
  dirty: PropTypes.bool,
  isChartDialog: PropTypes.bool,
  sortable: PropTypes.bool,
  readOnly: PropTypes.bool,
  children: PropTypes.any,
  ExtraControlsComponent: PropTypes.any
}

export default ListInput
