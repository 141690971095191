import React, { useCallback, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import idx from 'idx'
import { Grid, Typography, Collapse } from '@material-ui/core'
import styled from 'styled-components'

import TextInput from 'src/components/atoms/TextInput/TextInput'
import ImageInput from 'src/components/atoms/ImageInput/ImageInput'
import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import ListInput from 'src/components/organisms/ListInput/ListInput'
import NumberInput from 'src/components/atoms/NumberInput'
import { BERTH_STATION_TYPE } from 'src/constants/settings'
import { ChartShape } from 'src/utils/types'
import {
  replaceSpecialCharacters,
  removeFileExtensionFromName,
} from 'src/utils/formatters'

import BerthStationSelect from 'src/components/organisms/BerthStationSelect'
import {
  chartImageApiUrlSelector,
  getListFilteredBySelectedPort,
} from 'src/store/chart/selectors'

const validateForm = (values, chartList) => {
  return validateValues(values, {
    name: [
      'required',
      'chartName',
      (value) =>
        chartList.find(
          ({ name, uuid }) => name === value && uuid !== values.uuid
        )
          ? 'Chart name already exists.'
          : null,
    ],
    imageUrl: [
      'required',
      (value) =>
        typeof value === 'string' || value instanceof File
          ? null
          : 'Please select a valid file',
    ],
    berths: (berths) =>
      berths.map((berth, index) => {
        if (!berth || berth.berthStationType !== BERTH_STATION_TYPE.BERTH) {
          return 'Please select a berth'
        }
        if (
          berth &&
          berths.find((b, i) => b && b.uuid === berth.uuid && i !== index)
        ) {
          return 'No duplicate berths'
        }
        return null
      }),
    stations: (stations) =>
      stations.map((station, index) => {
        if (
          !station ||
          station.berthStationType !== BERTH_STATION_TYPE.STATION
        ) {
          return 'Please select a Pilot Boarding Place'
        }
        if (
          station &&
          stations.find((b, i) => b && b.uuid === station.uuid && i !== index)
        ) {
          return 'No duplicate Pilot Boarding Places'
        }
        return null
      }),
  })
}

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 0.85em;
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
`

const BottomPaddedTypography = styled(Typography)`
  && {
    padding-bottom: ${({ theme }) => theme.spacing(3)}px;
  }
`

export const ChartForm = ({ chart, onChange, onSubmit, disabled }) => {
  const chartList = useSelector(getListFilteredBySelectedPort)
  const validate = useCallback((values) => validateForm(values, chartList), [
    chartList,
  ])
  const { uuid, name, imageUrl, pixels, meters, berthsStations = [] } = chart
  const handleOnChange = useCallback(
    (change) => {
      if (onChange) {
        const { berths, stations, ...chartValues } = change.values
        onChange({
          ...change,
          values: {
            ...chartValues,
            berthsStations: [...(berths || []), ...(stations || [])],
          },
        })
      }
    },
    [onChange]
  )
  const newImageUrl = useSelector(chartImageApiUrlSelector(uuid))
  const initialValues = useRef({
    uuid,
    name,
    pixels: pixels || '',
    meters: meters || '',
    imageUrl: imageUrl instanceof File ? imageUrl : newImageUrl,
    berths: berthsStations.filter(
      (item) => item.berthStationType === BERTH_STATION_TYPE.BERTH
    ),
    stations: berthsStations.filter(
      (item) => item.berthStationType === BERTH_STATION_TYPE.STATION
    ),
  })

  const { fields } = useForm({
    initialValues: initialValues.current,
    validationHandler: validate,
    changeHandler: handleOnChange,
    submitHandler: onSubmit,
  })
  const previousFileNameRef = useRef(idx(fields.imageUrl, (_) => _.value.name))
  useEffect(() => {
    if (
      fields.imageUrl.value instanceof File &&
      (!fields.name.value || previousFileNameRef.current === fields.name.value)
    ) {
      fields.name.onChange(
        'name',
        replaceSpecialCharacters(
          removeFileExtensionFromName(fields.imageUrl.value.name)
        )
      )
      previousFileNameRef.current = idx(fields.imageUrl, (_) => _.value.name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields.imageUrl.value])

  return (
    <>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12}>
          <ImageInput name="imageUrl" {...fields.imageUrl} />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            narrow
            name="name"
            label="Chart name"
            disabled={disabled}
            {...fields.name}
          />
        </Grid>
      </Grid>
      <Grid item container alignItems="flex-end" spacing={1} xs={12}>
        <Grid item xs={3} md={3}>
          <NumberInput
            narrow
            name="pixels"
            label="Chart scale"
            disabled={disabled}
            isInteger
            infoTooltip={
              'Leave Chart Scale fields blank if vessel/chart scaling is not required.'
            }
            {...fields.pixels}
          />
        </Grid>
        <Grid item container justify="center" xs={2} md={2}>
          <BottomPaddedTypography>Pixels =</BottomPaddedTypography>
        </Grid>
        <Grid item xs={3} md={3}>
          <NumberInput
            narrow
            name="meters"
            disabled={disabled}
            decimalCount={2}
            {...fields.meters}
          />
        </Grid>
        <Grid item xs={2} md={2}>
          <BottomPaddedTypography>Meters </BottomPaddedTypography>
        </Grid>
      </Grid>
      <Collapse
        in={
          (!!fields.pixels.value && !fields.meters.value) ||
          (!fields.pixels.value && !!fields.meters.value)
        }
      >
        <StyledErrorMessage>
          Both &apos;Pixels&apos; and &apos;Meters&apos; values need to be
          provided to add scale information.
        </StyledErrorMessage>
      </Collapse>
      <Grid item container spacing={1} xs={12}>
        <Grid item xs={12} md={6}>
          <ListInput
            name="berths"
            label="Berths"
            Input={BerthStationSelect}
            type={BERTH_STATION_TYPE.BERTH}
            required
            {...fields.berths}
            disabled={disabled}
            isChartDialog
            useEndAdornmentDeleteButton={false}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <ListInput
            name="stations"
            label="Pilot Boarding Places"
            Input={BerthStationSelect}
            type={BERTH_STATION_TYPE.STATION}
            required
            {...fields.stations}
            disabled={disabled}
            isChartDialog
            useEndAdornmentDeleteButton={false}
          />
        </Grid>
      </Grid>
    </>
  )
}
ChartForm.propTypes = {
  chart: PropTypes.shape(ChartShape),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
}
export default ChartForm
