import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getPorts = (config) =>
  api
    .get(`/ports`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getPort = (uuid, config) =>
  api
    .get(`/ports/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createPort = (port, config) => {
  return api
    .post('/ports', port, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updatePort = (port, config) => {
  return api
    .put(`/ports/${port.uuid}`, port, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deletePort = (uuid, config) =>
  api
    .delete(`/ports/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  create: createPort,
  update: updatePort,
  delete: deletePort,
}
