import React from 'react'

const PilotagesIcon = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.15497 3.51115L5.42997 0.911153C5.49172 0.840953 5.58077 0.800003 5.67437 0.800003C5.76797 0.800003 5.85702 0.840953 5.91877 0.911153L8.19377 3.51115C8.27762 3.60735 8.29842 3.7432 8.24512 3.85955C8.19247 3.97525 8.07677 4.05 7.94937 4.05H6.64937V8.275C6.64937 8.4544 6.50377 8.6 6.32437 8.6H5.02437C4.84497 8.6 4.69937 8.4544 4.69937 8.275V4.05H3.39937C3.27198 4.05 3.15628 3.9759 3.10363 3.85955C3.05098 3.7432 3.07047 3.6067 3.15497 3.51115Z"
      fill="#B0B0B1"
    />
    <path
      d="M2.0998 7.95V9.9H9.2498V7.95H10.5498V10.55C10.5498 10.9094 10.2586 11.2 9.8998 11.2H1.44981C1.09035 11.2 0.799805 10.9094 0.799805 10.55V7.95H2.0998Z"
      fill="#B0B0B1"
    />
  </svg>
)

export default PilotagesIcon
