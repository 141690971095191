import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-chart'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'

// GET CHART
const ACTION_GET = 'CHART_GET'
export const [
  CHART_GET_REQUEST,
  CHART_GET_RESULT,
  CHART_GET_SUCCESS,
  CHART_GET_ERROR,
  CHART_GET_CANCEL,
  CHART_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getChart = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getChartRequest(uuid))
}
export const getChartRequest = (uuid) => async (dispatch) => {
  dispatch(getChartInProgress(uuid))
  try {
    const chartResult = await api.getChart(uuid)
    return dispatch(
      getChartSuccess({
        data: chartResult,
      })
    )
  } catch (error) {
    return dispatch(getChartError(error))
  }
}
export const getChartInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getChartSuccess = (chart) => createSuccessAction(ACTION_GET, chart)
export const getChartError = (error) => createErrorAction(ACTION_GET, error)

// GET CHART_LIST
const ACTION_GET_LIST = 'CHART_LIST_GET'
export const [
  CHART_LIST_GET_REQUEST,
  CHART_LIST_GET_RESULT,
  CHART_LIST_GET_SUCCESS,
  CHART_LIST_GET_ERROR,
  CHART_LIST_GET_CANCEL,
  CHART_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getChartList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getChartListRequest(uuid))
}
export const getChartListRequest = (uuid) => async (dispatch) => {
  dispatch(getChartListInProgress(uuid))
  try {
    const chartListResult = await api.getCharts(uuid)
    return dispatch(
      getChartListSuccess({
        data: chartListResult,
      })
    )
  } catch (error) {
    return dispatch(getChartListError(error))
  }
}
export const getChartListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getChartListSuccess = (chart) =>
  createSuccessAction(ACTION_GET_LIST, chart)
export const getChartListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE CHART
const ACTION_CREATE = 'CHART_CREATE'
export const [
  CHART_CREATE_REQUEST,
  CHART_CREATE_RESULT,
  CHART_CREATE_SUCCESS,
  CHART_CREATE_ERROR,
  CHART_CREATE_CANCEL,
  CHART_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createChart = (chart) => async (dispatch) => {
  const { pixels, meters } = chart
  const updatedChart =
    pixels === '' || meters === ''
      ? { ...chart, pixels: undefined, meters: undefined }
      : chart
  dispatch({ type: ACTION_CREATE, payload: updatedChart })
  return dispatch(createChartRequest(updatedChart))
}
export const createChartRequest = (payload) => async (dispatch) => {
  dispatch(createChartInProgress(payload))
  try {
    const chartResult = await api.createChart(payload)
    dispatch(
      addSuccessToast({
        message: `You successfully created the chart: '${payload.name}'`,
      })
    )
    return dispatch(
      createChartSuccess({
        data: chartResult,
      })
    )
  } catch (error) {
    dispatch(
      addErrorToast({
        message: `Error adding a new chart: '${payload.name}'`,
      })
    )
    return dispatch(createChartError(error))
  }
}
export const createChartInProgress = (chart) =>
  createInProgressAction(ACTION_CREATE, chart)
export const createChartSuccess = (chart) => (dispatch) =>
  dispatch(createSuccessAction(ACTION_CREATE, chart))
export const createChartError = (error) =>
  createErrorAction(ACTION_CREATE, error)

// UPDATE chart
const ACTION_UPDATE = 'CHART_UPDATE'
export const [
  CHART_UPDATE_REQUEST,
  CHART_UPDATE_RESULT,
  CHART_UPDATE_SUCCESS,
  CHART_UPDATE_ERROR,
  CHART_UPDATE_CANCEL,
  CHART_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateChart = (chart) => (dispatch) => {
  const { pixels, meters } = chart
  const updatedChart =
    pixels === '' || meters === ''
      ? { ...chart, pixels: undefined, meters: undefined }
      : chart

  dispatch({
    type: ACTION_UPDATE,
    payload: updatedChart,
  })
  return dispatch(updateChartRequest(updatedChart))
}
export const updateChartRequest = (payload) => async (dispatch) => {
  dispatch(updateChartInProgress(payload))
  try {
    const chartResult = await api.updateChart(payload)
    dispatch(
      addSuccessToast({
        message: `You successfully updated the chart: '${payload.name}'`,
      })
    )
    return dispatch(
      updateChartSuccess({
        data: chartResult,
      })
    )
  } catch (error) {
    dispatch(
      addErrorToast({
        message: `Error updating the chart: '${payload.name}'`,
      })
    )
    return dispatch(updateChartError(error))
  }
}
export const updateChartInProgress = (chart) =>
  createInProgressAction(ACTION_UPDATE, chart)
export const updateChartSuccess = (chart) => (dispatch) =>
  dispatch(createSuccessAction(ACTION_UPDATE, chart))
export const updateChartError = (error) =>
  createErrorAction(ACTION_UPDATE, error)

// SAVE chart (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'CHART_SAVE'
export const saveChart = (chart) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: chart })
  const result = await dispatch(
    chart.uuid ? updateChart(chart) : createChart(chart)
  )
  return result
}

// DELETE chart
const ACTION_DELETE = 'CHART_DELETE'
export const [
  CHART_DELETE_REQUEST,
  CHART_DELETE_RESULT,
  CHART_DELETE_SUCCESS,
  CHART_DELETE_ERROR,
  CHART_DELETE_CANCEL,
  CHART_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteChart = (chart) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: chart })
  return dispatch(deleteChartRequest(chart))
}
export const deleteChartRequest = (chart) => async (dispatch) => {
  dispatch(deleteChartInProgress(chart))
  try {
    await api.deleteChart(chart.uuid)
    return dispatch(deleteChartSuccess(chart))
  } catch (error) {
    dispatch(deleteChartError(error))
    return error
  }
}
export const deleteChartInProgress = (chart) =>
  createInProgressAction(ACTION_DELETE, chart)
export const deleteChartSuccess = (chart) =>
  createSuccessAction(ACTION_DELETE, chart)
export const deleteChartError = (error) =>
  createErrorAction(ACTION_DELETE, error)
