import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-tide-stations'
import { TIDE_DATA_CREATE_ERROR } from 'src/store/tideData/actions'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import idx from 'idx'

const getErrorMessage = (errorPayload) => {
  const { type, error } = errorPayload
  const { status } = error || {}
  let message
  if (type === TIDE_DATA_CREATE_ERROR) {
    const responseMessage = idx(error, (_) => _.response.data.message)
    message =
      'Tide data error: ' +
      (status > 500
        ? TOAST_MESSAGES.TIDE_DATA_SERVER_ERROR
        : status === 400
        ? TOAST_MESSAGES.TIDE_DATA_CSV_ERROR
        : status === 403
        ? TOAST_MESSAGES.TIDE_DATA_FORBIDDEN_ERROR
        : status === 422
        ? responseMessage || TOAST_MESSAGES.TIDE_DATA_CSV_ERROR
        : TOAST_MESSAGES.TIDE_STATION_CREATE_ERROR)
  } else {
    message = getToastMessage(
      TOAST_MESSAGES.TIDE_STATION_CREATE_ERROR,
      errorPayload
    )
  }
  return message
}

// GET TIDE_STATION
const ACTION_GET = 'TIDE_STATION_GET'
export const [
  TIDE_STATION_GET_REQUEST,
  TIDE_STATION_GET_RESULT,
  TIDE_STATION_GET_SUCCESS,
  TIDE_STATION_GET_ERROR,
  TIDE_STATION_GET_CANCEL,
  TIDE_STATION_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getTideStation = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getTideStationRequest(uuid))
}
export const getTideStationRequest = (uuid) => async (dispatch) => {
  dispatch(getTideStationInProgress(uuid))
  try {
    const tideStationResult = await api.getTideStation(uuid)
    return dispatch(getTideStationSuccess(tideStationResult))
  } catch (error) {
    return dispatch(getTideStationError(error))
  }
}
export const getTideStationInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getTideStationSuccess = (tideStation) =>
  createSuccessAction(ACTION_GET, tideStation)
export const getTideStationError = (error) =>
  createErrorAction(ACTION_GET, error)

// GET TIDE_STATION_LIST
const ACTION_GET_LIST = 'TIDE_STATION_LIST_GET'
export const [
  TIDE_STATION_LIST_GET_REQUEST,
  TIDE_STATION_LIST_GET_RESULT,
  TIDE_STATION_LIST_GET_SUCCESS,
  TIDE_STATION_LIST_GET_ERROR,
  TIDE_STATION_LIST_GET_CANCEL,
  TIDE_STATION_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getTideStationList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getTideStationListRequest(uuid))
}
export const getTideStationListRequest = (uuid) => async (dispatch) => {
  dispatch(getTideStationListInProgress(uuid))
  try {
    const tideStationListResult = await api.getTideStations(uuid)
    return dispatch(getTideStationListSuccess(tideStationListResult))
  } catch (error) {
    return dispatch(getTideStationListError(error))
  }
}
export const getTideStationListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getTideStationListSuccess = (tideStation) =>
  createSuccessAction(ACTION_GET_LIST, tideStation)
export const getTideStationListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE TIDE_STATION
const ACTION_CREATE = 'TIDE_STATION_CREATE'
export const [
  TIDE_STATION_CREATE_REQUEST,
  TIDE_STATION_CREATE_RESULT,
  TIDE_STATION_CREATE_SUCCESS,
  TIDE_STATION_CREATE_ERROR,
  TIDE_STATION_CREATE_CANCEL,
  TIDE_STATION_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createTideStation = (tideStation) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: tideStation })
  return dispatch(createTideStationRequest(tideStation))
}
export const createTideStationRequest = (payload) => async (dispatch) => {
  dispatch(createTideStationInProgress(payload))
  try {
    const tideStationResult = await api.createTideStation(payload)
    dispatch(
      addSuccessToast({
        message: getToastMessage(
          TOAST_MESSAGES.TIDE_STATION_CREATE_SUCCESS,
          tideStationResult
        ),
      })
    )
    return dispatch(createTideStationSuccess(tideStationResult))
  } catch (error) {
    dispatch(addErrorToast({ message: getErrorMessage(error) }))
    return error.type === TIDE_DATA_CREATE_ERROR
  }
}
export const createTideStationInProgress = (tideStation) =>
  createInProgressAction(ACTION_CREATE, tideStation)
export const createTideStationSuccess = (payload) =>
  createSuccessAction(ACTION_CREATE, payload)
export const createTideStationError = (error) =>
  createErrorAction(ACTION_CREATE, error)

// UPDATE tideStation
const ACTION_UPDATE = 'TIDE_STATION_UPDATE'
export const [
  TIDE_STATION_UPDATE_REQUEST,
  TIDE_STATION_UPDATE_RESULT,
  TIDE_STATION_UPDATE_SUCCESS,
  TIDE_STATION_UPDATE_ERROR,
  TIDE_STATION_UPDATE_CANCEL,
  TIDE_STATION_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateTideStation = (tideStation) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: tideStation })
  return dispatch(updateTideStationRequest(tideStation))
}
export const updateTideStationRequest = (data) => async (dispatch) => {
  dispatch(updateTideStationInProgress(data))
  try {
    const tideStationResult = await api.updateTideStation(data)
    dispatch(
      addSuccessToast({
        message: getToastMessage(
          TOAST_MESSAGES.TIDE_STATION_UPDATE_SUCCESS,
          tideStationResult
        ),
      })
    )
    return dispatch(updateTideStationSuccess(tideStationResult))
  } catch (error) {
    dispatch(
      addErrorToast({
        message: getErrorMessage(error),
      })
    )
    return dispatch(updateTideStationError(error))
  }
}
export const updateTideStationInProgress = (tideStation) =>
  createInProgressAction(ACTION_UPDATE, tideStation)
export const updateTideStationSuccess = (tideStation) =>
  createSuccessAction(ACTION_UPDATE, tideStation)
export const updateTideStationError = (error) =>
  createErrorAction(ACTION_UPDATE, error)

// SAVE tideStation (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'TIDE_STATION_SAVE'
export const saveTideStation = (tideStation) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: tideStation })
  return dispatch(
    tideStation.uuid
      ? updateTideStation(tideStation)
      : createTideStation(tideStation)
  )
}

// DELETE tideStation
const ACTION_DELETE = 'TIDE_STATION_DELETE'
export const [
  TIDE_STATION_DELETE_REQUEST,
  TIDE_STATION_DELETE_RESULT,
  TIDE_STATION_DELETE_SUCCESS,
  TIDE_STATION_DELETE_ERROR,
  TIDE_STATION_DELETE_CANCEL,
  TIDE_STATION_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteTideStation = (tideStation) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: tideStation })
  return dispatch(deleteTideStationRequest(tideStation))
}
export const deleteTideStationRequest = (tideStation) => async (dispatch) => {
  dispatch(deleteTideStationInProgress(tideStation))
  try {
    await api.deleteTideStation(tideStation.uuid)
    return dispatch(deleteTideStationSuccess(tideStation))
  } catch (error) {
    dispatch(deleteTideStationError(error))
    return error
  }
}
export const deleteTideStationInProgress = (tideStation) =>
  createInProgressAction(ACTION_DELETE, tideStation)
export const deleteTideStationSuccess = (tideStation) =>
  createSuccessAction(ACTION_DELETE, tideStation)
export const deleteTideStationError = (error) =>
  createErrorAction(ACTION_DELETE, error)
