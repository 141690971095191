import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-route'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'

// GET ROUTE
const ACTION_GET = 'ROUTE_GET'
export const [
  ROUTE_GET_REQUEST,
  ROUTE_GET_RESULT,
  ROUTE_GET_SUCCESS,
  ROUTE_GET_ERROR,
  ROUTE_GET_CANCEL,
  ROUTE_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getRoute = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getRouteRequest(uuid))
}
export const getRouteRequest = (uuid) => async (dispatch) => {
  dispatch(getRouteInProgress(uuid))
  try {
    const routeResult = await api.getRoute(uuid)
    return dispatch(getRouteSuccess(routeResult))
  } catch (error) {
    return dispatch(getRouteError(error))
  }
}
export const getRouteInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getRouteSuccess = (route) => createSuccessAction(ACTION_GET, route)
export const getRouteError = (error) => createErrorAction(ACTION_GET, error)

// GET ROUTE_LIST
const ACTION_GET_LIST = 'ROUTE_LIST_GET'
export const [
  ROUTE_LIST_GET_REQUEST,
  ROUTE_LIST_GET_RESULT,
  ROUTE_LIST_GET_SUCCESS,
  ROUTE_LIST_GET_ERROR,
  ROUTE_LIST_GET_CANCEL,
  ROUTE_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getRouteList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getRouteListRequest(uuid))
}
export const getRouteListRequest = (uuid) => async (dispatch) => {
  dispatch(getRouteListInProgress(uuid))
  try {
    const routeListResult = await api.getRoutes(uuid)
    return dispatch(getRouteListSuccess(routeListResult))
  } catch (error) {
    return dispatch(getRouteListError(error))
  }
}
export const getRouteListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getRouteListSuccess = (route) =>
  createSuccessAction(ACTION_GET_LIST, route)
export const getRouteListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE ROUTE
const ACTION_CREATE = 'ROUTE_CREATE'
export const [
  ROUTE_CREATE_REQUEST,
  ROUTE_CREATE_RESULT,
  ROUTE_CREATE_SUCCESS,
  ROUTE_CREATE_ERROR,
  ROUTE_CREATE_CANCEL,
  ROUTE_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createRoute = (route) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: route })
  return dispatch(createRouteRequest(route))
}
export const createRouteRequest = (payload) => async (dispatch) => {
  dispatch(createRouteInProgress(payload))
  try {
    const routeResult = await api.createRoute(payload)
    return dispatch(createRouteSuccess(routeResult))
  } catch (error) {
    return dispatch(createRouteError(error))
  }
}
export const createRouteInProgress = (route) =>
  createInProgressAction(ACTION_CREATE, route)
export const createRouteSuccess = (route) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: `You successfully created the route: '${route.name}'`,
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, route))
}
export const createRouteError = (error) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: `An error occurred while we tried to create the route`,
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE route
const ACTION_UPDATE = 'ROUTE_UPDATE'
export const [
  ROUTE_UPDATE_REQUEST,
  ROUTE_UPDATE_RESULT,
  ROUTE_UPDATE_SUCCESS,
  ROUTE_UPDATE_ERROR,
  ROUTE_UPDATE_CANCEL,
  ROUTE_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateRoute = (route) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: route })
  return dispatch(updateRouteRequest(route))
}
export const updateRouteRequest = (payload) => async (dispatch) => {
  dispatch(updateRouteInProgress(payload))
  try {
    const routeResult = await api.updateRoute(payload)
    return dispatch(updateRouteSuccess(routeResult))
  } catch (error) {
    return dispatch(updateRouteError(error))
  }
}
export const updateRouteInProgress = (route) =>
  createInProgressAction(ACTION_UPDATE, route)
export const updateRouteSuccess = (route) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: `You successfully updated the route: '${route.name}'`,
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, route))
}
export const updateRouteError = (error) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: `An error occurred while we tried to update the route`,
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE route (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'ROUTE_SAVE'
export const saveRoute = (route) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: route })
  const result = await dispatch(
    route.uuid ? updateRoute(route) : createRoute(route)
  )
  return result
}

// DELETE route
const ACTION_DELETE = 'ROUTE_DELETE'
export const [
  ROUTE_DELETE_REQUEST,
  ROUTE_DELETE_RESULT,
  ROUTE_DELETE_SUCCESS,
  ROUTE_DELETE_ERROR,
  ROUTE_DELETE_CANCEL,
  ROUTE_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteRoute = (route) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: route })
  return dispatch(deleteRouteRequest(route))
}
export const deleteRouteRequest = (route) => async (dispatch) => {
  dispatch(deleteRouteInProgress(route))
  try {
    await api.deleteRoute(route.uuid)
    return dispatch(deleteRouteSuccess(route))
  } catch (error) {
    dispatch(deleteRouteError(error))
    return error
  }
}
export const deleteRouteInProgress = (route) =>
  createInProgressAction(ACTION_DELETE, route)
export const deleteRouteSuccess = (route) =>
  createSuccessAction(ACTION_DELETE, route)
export const deleteRouteError = (error) =>
  createErrorAction(ACTION_DELETE, error)
