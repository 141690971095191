export const SETTINGS_COMPLETION_STATE = {
  NOT_CONFIGURED: 'warning',
  IN_PROGRESS: 'progress',
  COMPLETE: 'complete',
}

export const CHART_UNITS = {
  METERS: 'm',
}
export const PAGINATION_ROWS_PER_PAGE_OPTIONS = [3, 6, 12, 24, 48, 96]
export const PAGINATION_DEFAULT_ROWS_PER_PAGE =
  PAGINATION_ROWS_PER_PAGE_OPTIONS[1]

export const CONSTRAINT_UNITS = {
  PERCENT: '%',
  METERS: 'm',
}

export const CONSTRAINT_TYPE = {
  UKC: 'ukc',
  OHC: 'air',
  UKC_DYNA: 'ukc_dyna',
}

export const TUG_TYPES = {
  ASD: 'Azimuth Stern Drive',
  ATD: 'Azimuth Tractor Drive',
  VOITH: 'Voith',
  ROTOR: 'Rotor',
  CONVENTIONAL: 'Conventional',
  NA: 'N/A',
}

export const BERTH_STATION_TYPE = {
  BERTH: 'berth',
  STATION: 'station',
}
export const BERTH_STATION_TYPE_LABEL = {
  [BERTH_STATION_TYPE.BERTH]: 'berth',
  [BERTH_STATION_TYPE.STATION]: 'pilot boarding place',
}

export const EXTRAS_CHECKLIST_NAME = 'Default Checklist'

export const EXTRA_TYPE = {
  CHECKLIST: 'checklist',
  PASS_FAIL_CHECKLIST: 'pass_fail_checklist',
  VALUES_CHECKLIST: 'values_checklist',
  GUIDELINES: 'guidelines',
  TERMS_CONDITIONS: 'terms',
  TIMESTAMP_CHECKLIST: 'timestamp_checklist',
  EXTERNAL_LINK: 'external_link',
  EXTERNAL_LINK_2: 'external_link_2',
  EXTERNAL_LINK_3: 'external_link_3',
  FLOWLIST: 'flowlist',
}

export const EXTRA_NAME = {
  [EXTRA_TYPE.CHECKLIST]: 'Default Checklist',
  [EXTRA_TYPE.PASS_FAIL_CHECKLIST]: 'Pass Fail Checklist',
  [EXTRA_TYPE.VALUES_CHECKLIST]: 'Values Checklist',
  [EXTRA_TYPE.TIMESTAMP_CHECKLIST]: 'Timestamp Checklist',
  [EXTRA_TYPE.EXTERNAL_LINK]: 'External link 1',
  [EXTRA_TYPE.EXTERNAL_LINK_2]: 'External link 2',
  [EXTRA_TYPE.EXTERNAL_LINK_3]: 'External link 3',
  [EXTRA_TYPE.FLOWLIST]: 'Flowlist',
}

export const DATETIME_FORMAT = 'HH:mm, D MMM YYYY'
export const DATE_FORMAT = 'D MMM YYYY'
export const PILOTAGE_EXPORT_MAX_DAYS = 14

export const WEATHER_SOURCE = {
  worldWeatherOnline: {
    id: 'worldWeatherOnline',
    apiType: 'World Weather Online',
    description: 'Your default weather source',
    url: 'https://api.worldweatheronline.com/premium/v1/marine.ashx',
  },
  stormGeo: {
    id: 'stormGeo',
    apiType: 'Storm Geo',
    description:
      'This source requires the following setup information (besides the latitude and logitude of your defined weather locations)',
  // },
  // metOcean: {
  //   id: 'metOcean',
  //   apiType: 'Met Ocean',
  //   description:
  //     'This source requires the following setup information (besides the latitude and logitude of your defined weather locations)',
  },
  metOceanV2: {
    id: 'metOceanV2',
    apiType: 'Met Ocean V2',
    description:
      'This source requires the following setup information (besides the latitude and logitude of your defined weather locations)',
  },
  weatherzone: {
    id: 'weatherzone',
    apiType: 'Weatherzone',
    description: 'This source requires weather location definitions only',
    url: 'https://ws.weatherzone.com.au'
  },
  portQuest: {
    id: 'portQuest',
    apiType: 'Port Quest',
    description:
      'This source requires the following setup information (besides the latitude and logitude of your defined weather locations)',
  },
}
export const ATTACHMENT_SIZE_LIMIT = 1e6
export const IMPORT_MAX_FILE_SIZE = 3e6
export const TIDES_MAX_FILE_SIZE = 15e6

export const TIDE_TYPE = {
  ALL: 'ALL',
  EXTREMES: 'EXTREMES',
}

export const FILE_STATUS = {
  SELECTED: 'selected',
  UPLOADING: 'uploading',
  SUCCESS: 'success',
  ERROR: 'error',
}
