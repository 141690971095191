import api, {
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getSso = (config) =>
  api
    .get(`/sso_scim`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createSso = (config) => {
  return api
    .post('/sso_scim', config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateSso = (config) => {
  return api
    .put(`/sso_scim`, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteSso = (config) =>
  api
    .delete(`/sso_scim`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  get: getSso,
  create: createSso,
  update: updateSso,
  delete: deleteSso,
}
