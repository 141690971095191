export const SIDE_TO = {
    PORT: 'Port',
    STARBOARD: 'Starboard'
}

export const SIDE_TO_OPTIONS = {
    ...SIDE_TO,
    EITHER: 'Either',
    NEITHER: 'Neither'
}
  