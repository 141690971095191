import React from 'react'
import PropTypes from 'prop-types'

export const UploadIcon = ({ className, size = 20 }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    className={className}
  >
    <path
      d="M6.55146506 4.2560867c-.48885549-.1591103-1.00075131-.2671852-1.54269972-.2671852C2.24192337 3.9889015 0 6.2284539 0 8.9923698c0 2.7629152 2.24192337 5.0034683 5.00876534 5.0034683h5.00876536v-2.0013873H5.17205109c-1.47958928 0-2.855998-.9926882-3.1184573-2.4476967-.34259955-1.8983159 1.11595292-3.5564653 2.95517155-3.5564653.37064863 0 .72226396.0760527 1.05083897.1991381l-.02103682.7655306c-.01502629.5673933.44077135 1.0367186 1.0097671 1.0367186.50688705 0 .94264963-.372258.99974956-.8756069.06010518-.5223621.15026296-1.1758151.28549962-1.839275.80841473-3.98176 8.22439273-4.84635934 9.52667163.4863372.5128976 2.1024573-.8304532 4.056812-1.8322063 5.1966021v2.358635C18.0315552 12.1985923 20 9.7909233 20 6.9909825 20-.60928585 9.52366642-2.73976264 6.55146506 4.2560867zm9.99248684 4.2709605l-1.3964438 1.4149809-1.1229651-1.1618054V20h-2.0035062V8.8602782l-1.1209617 1.0817499-1.45354367-1.4149809 3.54620587-3.537452 3.5512146 3.537452z"
      fill="#515151"
    />
  </svg>
)

UploadIcon.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
}

export default UploadIcon
