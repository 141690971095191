import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import idx from 'idx'

import Fieldset from 'src/components/organisms/Fieldset'
import { TextInput } from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import { PortShape, ExtraShape } from 'src/utils/types'
import { validateValues } from 'src/utils/validation'
import {
  saveExtra,
  EXTRA_CREATE_ERROR,
  EXTRA_UPDATE_ERROR,
} from 'src/store/extra/actions'

export const PortExtraForm = ({
  port,
  label,
  autoSave,
  extra,
  disabled,
  subtitle,
  description,
  infoTooltip,
}) => {
  const dispatch = useDispatch()
  const value = idx(extra, (_) => _.metadata.value) || ''
  const { name, extraType } = extra

  const handleSave = async (values) => {
    try {
      const result = await dispatch(
        saveExtra({
          ...(extra || {}),
          name,
          extraType,
          port: { uuid: port.uuid },
          metadata: JSON.stringify({ value: values[name] }),
        })
      )
      const { type } = result
      return [EXTRA_CREATE_ERROR, EXTRA_UPDATE_ERROR].includes(type)
        ? Promise.reject(result)
        : Promise.resolve(result)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const validateForm = (values) =>
    validateValues(values, {
      [name]: 'required',
    })
  const { fields, handleSubmit } = useForm({
    initialValues: {
      [name]: value,
    },
    validationHandler: validateForm,
    submitHandler: handleSave,
    autoSave,
  })

  return (
    <>
      <Fieldset
        title={label}
        dirty={fields[name].dirty}
        onSave={handleSubmit}
        subtitle={subtitle}
        description={description}
        infoTooltip={infoTooltip}
        disabled={disabled || !port || !port.uuid}
      >
        <TextInput
          name={name}
          multiline
          fullWidth
          rowsMax={8}
          rows={4}
          placeholder="Enter Text"
          {...fields[name]}
        />
      </Fieldset>
    </>
  )
}

PortExtraForm.propTypes = {
  port: PropTypes.shape(PortShape),
  label: PropTypes.string,
  autoSave: PropTypes.number,
  disabled: PropTypes.bool,
  subtitle: PropTypes.string,
  extra: PropTypes.shape(ExtraShape),
  description: PropTypes.string,
  infoTooltip: PropTypes.string,
}

export default React.memo(PortExtraForm)
