import React from 'react'
import { Switch, Box } from '@material-ui/core'
import Fieldset from 'src/components/organisms/Fieldset'
import Typography from '@material-ui/core/Typography'
import useFeatureFlags from 'src/hooks/useFeatureFlags'
import styled from 'styled-components'

const SubheadingText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-left: 10px;
    margin-bottom: 20px;
  }
`

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
    display: inline-block;
    padding: 1px 5px;
  }
`

export const PilotageManualOnlineToggle = () => {
  const description = `
    Here you can enable pilot override of the automatic online connection functionality.
    When enabled, pilots can check the connectivity for eMPX before syncing any offline changes.
    When disabled, eMPX will automatically check for connectivity before attempting to sync any offline changes.`
  const featureFlags = useFeatureFlags()
  const flagName = 'manual_online_enabled'
  const pilotManualOnlineEnabled = featureFlags.getFlag(flagName)

  return (
    <Fieldset
      disabled={false}
      title='Manual Online Mode'
      description={description}
    >
      <Box>
        <SubheadingText>{description}</SubheadingText>
      </Box>
      <Box display="flex">
        <Switch
          name="toggle"
          color="primary"
          checked={!!pilotManualOnlineEnabled}
          disabled={featureFlags.updating || featureFlags.loading}
          onChange={() => featureFlags.setFlag(flagName, !pilotManualOnlineEnabled)}
        />
        <ToggleText>
          { pilotManualOnlineEnabled
            ? 'Enabled'
            : 'Disabled'}
        </ToggleText>
        {featureFlags.error && <span>{featureFlags.error.message}</span>}
      </Box>
    </Fieldset>
  )
}

export default React.memo(PilotageManualOnlineToggle)
