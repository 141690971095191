import { createSelector } from 'reselect'
import {
  createGetListFilteredBySelectedPortSelector,
  createGetListFilteredByPortSelector,
} from 'src/utils/store'

export const tideRateStationStateSelector = (state) => state.tideRateStation

export const tideRateStationUpdateInProgressSelector = createSelector(
  tideRateStationStateSelector,
  (state) => state.updating
)

export const tideRateStationListSelector = createSelector(
  tideRateStationStateSelector,
  (state) => state.data && state.data.map((uuid) => state.byId[uuid])
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  tideRateStationListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  tideRateStationListSelector
)

export const tideRateStationByIndexSelector = (index) =>
  createSelector(
    tideRateStationStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const tideRateStationByIdSelector = (uuid) =>
  createSelector(
    tideRateStationStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalTideStationSelector = createSelector(
  tideRateStationStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    tideRateStationStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const errorSelector = createSelector(
  tideRateStationStateSelector,
  (state) => state.error
)

export const statusByIdSelector = (uuid) =>
  createSelector(
    tideRateStationStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
