import { Dispatch } from './types'
import { vesselFieldDisplayConfigSelector } from './selectors'
import { getSelectedPortSelector, selectedPortSelector } from '../port/selectors'
import {
  getPreferences,
  createPreferences,
  updatePreferences,
} from 'src/utils/sauce/sauce-api-preferences'
import {
  IPreferencesVesselFieldDisplayConfig,
  VesselFieldDisplayConfig,
  VESSEL_FIELD_DISPLAY_CONFIG_KEY
} from 'src/utils/sauce/sauce-api-preferences/VesselFieldDisplayConfig'
import { addSuccessToast } from '../toast/actions'


const vesselFieldDisplaySetLoading = (loading: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({ type: 'VESSEL_FIELD_DISPLAY_CONFIG_SET_LOADING', loading })
}
const vesselFieldDisplaySetUpdating = (updating: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({ type: 'VESSEL_FIELD_DISPLAY_CONFIG_SET_UPDATING', updating })
}
const vesselFieldDisplaySetError = (error?: Error) => (dispatch: Dispatch) => {
  dispatch({ type: 'VESSEL_FIELD_DISPLAY_CONFIG_SET_ERROR', error })
}
const vesselFieldDisplaySetConfig = (payload: {
  config?: VesselFieldDisplayConfig
  uuid?: string
}) => (dispatch: Dispatch) => {
  dispatch({ type: 'VESSEL_FIELD_DISPLAY_CONFIG_SET', ...payload })
}


export const vesselFieldDisplayGet = (portUuid: string) => async (dispatch: Dispatch, getState: any) => {

  const state = getState()
  const vesselFieldDisplayConfigState = vesselFieldDisplayConfigSelector(state)
  const { loading } = vesselFieldDisplayConfigState
  if (loading || !portUuid) { return }

  dispatch(vesselFieldDisplaySetConfig({ config: undefined, uuid: undefined }))
  dispatch(vesselFieldDisplaySetLoading(true))

  try {
    const allPreferences = await getPreferences(portUuid)
    // super_admin users will have preferences for all ports in the list
    // so we'd need to only get preferences for the active port


    const vesselFieldDisplayConfig = allPreferences.find(
      (preferences) =>
        preferences.port.uuid === portUuid &&
        preferences.key === VESSEL_FIELD_DISPLAY_CONFIG_KEY
    ) as IPreferencesVesselFieldDisplayConfig | undefined

    if (vesselFieldDisplayConfig) {
      const config = vesselFieldDisplayConfig.metadata
      const uuid = vesselFieldDisplayConfig.uuid
      dispatch(vesselFieldDisplaySetConfig({ config, uuid }))
    }

  } catch (error) {
    console.error(error)
    dispatch(vesselFieldDisplaySetError(error))
  }

  dispatch(vesselFieldDisplaySetLoading(false))
}

export const vesselFieldDisplaySet = (
  portUuid: string, 
  nextConfig: VesselFieldDisplayConfig
) => async (
  dispatch: Dispatch, 
  getState: any
) => {

  const state = getState()
  const vesselFieldDisplayConfigState = vesselFieldDisplayConfigSelector(state)
  const selectedPort = getSelectedPortSelector(state)
  const { uuid, updating } = vesselFieldDisplayConfigState

  if (updating) { return }

  try {
    dispatch(vesselFieldDisplaySetUpdating(true))

    const nextPreference: IPreferencesVesselFieldDisplayConfig = {
      key: VESSEL_FIELD_DISPLAY_CONFIG_KEY,
      metadata: nextConfig, // is fully overwritten rather than patched
      port: selectedPort,
    }

    const [saved] = !uuid ? 
      await createPreferences(nextPreference) as IPreferencesVesselFieldDisplayConfig[] : 
      await updatePreferences(uuid, nextPreference) as IPreferencesVesselFieldDisplayConfig[]

    // also update in redux
    if (portUuid && saved && !updating) {
      dispatch(vesselFieldDisplaySetConfig({ uuid: saved.uuid, config: saved.metadata  }))
    }
    
    dispatch(addSuccessToast({ message: 'Updated vessel field display configuration' }))

  } catch (error) {
    dispatch(vesselFieldDisplaySetError(error))
  }

  dispatch(vesselFieldDisplaySetUpdating(false))
}