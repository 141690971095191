import styled, { css } from 'styled-components'

export const FeatureHeader = styled.h2(
  ({ theme }) => css`
    font-size: ${theme.typography.pxToRem(34)};
    font-weight: 400;
    margin-top: ${theme.spacing(2.25)}px;
    margin-top: ${theme.spacing(3.625)}px;
  `
)

export default FeatureHeader
