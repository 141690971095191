import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import idx from 'idx'

import { ListItemText, List } from '@material-ui/core'

import TideRateStationForm from 'src/components/organisms/TideRateStationForm/TideRateStationForm'
import {
  getListFilteredByPort,
  getListFilteredBySelectedPort,
  tideRateStationListSelector,
  errorSelector
} from 'src/store/tideRateStations/selectors'
import {
  getListFilteredByPort as getConstraintListFilteredByPort,
  constraintListSelector,
} from 'src/store/constraint/selectors'
import {
  getTideRateStationList,
  saveTideRateStation,
  deleteTideRateStation,
  TIDE_RATE_STATION_DELETE_SUCCESS,
  TIDE_RATE_STATION_CREATE_ERROR,
} from 'src/store/tideRateStations/actions'
import { addToast, addErrorToast } from 'src/store/toast/actions'
import {
  TOAST_VARIANT_SUCCESS,
  TOAST_VARIANT_ERROR,
} from 'src/containers/Toast'
import TideRateStationList from 'src/components/organisms/TideRateStationList'
import Fieldset from 'src/components/organisms/Fieldset'
import EmptyState from 'src/components/atoms/EmptyState'
import { PortShape } from 'src/utils/types'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import Preloader from 'src/components/atoms/Preloader'

import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import { FILE_STATUS } from 'src/constants/settings'
import { createTideRateData } from 'src/store/tideRateData/actions'
import { compareName } from 'src/utils/sorting'
import ErrorMessage from 'src/components/atoms/ErrorMessage'
import TideRateConfig from '../TideRate/TideRateConfig'
import { defaultTidalRateConfig } from 'src/utils/sauce/sauce-api-preferences/TidalRateConfig'

export const TideRateStations = ({ disabled, port, disableFilterByPort, children }) => {
  const dispatch = useDispatch()

  const tideRateStationList = useSelector(
    disableFilterByPort
      ? tideRateStationListSelector
      : port
      ? getListFilteredByPort(port.uuid)
      : getListFilteredBySelectedPort
  )

  const tideRateStationError = useSelector(errorSelector)

  const constraints =
    useSelector(
      disableFilterByPort
        ? constraintListSelector
        : port
        ? getConstraintListFilteredByPort(port.uuid)
        : getListFilteredBySelectedPort
    ) || []

  const [allDataFile, setAllDataFile] = useState(null)

  useEffect(() => {
    dispatch(getTideRateStationList())
  }, [dispatch])

  const [selectedTideRateStation, setSelectedTideRateStation] = useState(null)
  const [openFileUpload, setOpenFileUpload] = useState(false)
  const [
    selectedTideRateStationToRemove,
    setSelectedTideRateStationToRemove,
  ] = useState(null)

  const [submitting, setSubmitting] = useState(false)

  // handle updated tide station to selected item
  useEffect(() => {
    if (selectedTideRateStation) {
      const uuid = selectedTideRateStation.uuid
      if (uuid) {
        const tideRateStation = tideRateStationList.find((item) => item.uuid === uuid)
        if (tideRateStation) {
          setSelectedTideRateStation(tideRateStation)
        }
      } else {
        // create mode, noop
      }
    } else {
      // auto select first one (if not in edit mode)
      if (tideRateStationList && tideRateStationList.length > 0) {
        setSelectedTideRateStation(tideRateStationList[0])
      }
    }
  },
  [selectedTideRateStation, tideRateStationList])

  const handleUploadClick = useCallback(
    (uuid) => {
      const tideRateStation = tideRateStationList.find((item) => item.uuid === uuid)
      if (tideRateStation) {
        setSelectedTideRateStation(tideRateStation)
        setOpenFileUpload(true)
      }
    },
    [tideRateStationList]
  )

  const handleClick = useCallback(
    (uuid) => {
      const tideRateStation = tideRateStationList.find((item) => item.uuid === uuid)
      if (tideRateStation) {
        setSelectedTideRateStation(tideRateStation)
        setOpenFileUpload(false)
      }
    },
    [tideRateStationList]
  )
  const handleRemove = useCallback(
    (uuid) => {
      const tideRateStation = tideRateStationList.find((item) => item.uuid === uuid)
      if (tideRateStation) {
        setSelectedTideRateStationToRemove(tideRateStation)
        setSelectedTideRateStation(tideRateStation)
      }
    },
    [tideRateStationList]
  )
  const handleRejectRemove = () => {
    setSelectedTideRateStationToRemove(null)
  }

  const handleConfirmRemove = useCallback(() => {
    dispatch(deleteTideRateStation(selectedTideRateStationToRemove)).then((action) => {
      const { type, payload } = action
      if (type === TIDE_RATE_STATION_DELETE_SUCCESS) {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_SUCCESS,
            message: `Tide rate station has been removed '${selectedTideRateStationToRemove.name}'`,
          })
        )
      } else {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_ERROR,
            message: `Error deleting tide rate station '${selectedTideRateStationToRemove.name}': ${payload}`,
          })
        )
      }
      setSelectedTideRateStationToRemove(null)
      setSelectedTideRateStation(null)
    })
  }, [dispatch, selectedTideRateStationToRemove])

  const showErrorMessage = useCallback(
    (error) => {
      let message = error
      if (typeof error === 'object') {
        // Unprocessable entry
        if (error.status === 422) {
          message = idx(error, (_) => _.response.data.message) || message
        } else {
          message = 'Error processing tide rate data'
        }
      }
      dispatch(
        addErrorToast({
          message,
        })
      )
    },
    [dispatch]
  )

  const handleCreateNewTideRateStation = () => {
    setSelectedTideRateStation({ name: '' })
    setOpenFileUpload(true)
  }

  const handleCancel = () => {
    if (!selectedTideRateStation.uuid) {
      setSelectedTideRateStation(null)
    }
    setAllDataFile(null)
    setOpenFileUpload(false)
  }

  const handleSave = useCallback(
    async (values) => {
      try {
        setSubmitting(true)
        const { location } = port
        const amendedValues = {
          location,
          port,
          name: values.name,
          uuid: values.uuid,
        }
        const action = await dispatch(saveTideRateStation(amendedValues))
        setSubmitting(false)
        const { type, error } = typeof action === 'boolean' ? {} : action
        if (error) {
          throw error
        }
        if (
          values.uuid &&
          (action === true || type !== TIDE_RATE_STATION_CREATE_ERROR)
        ) {
          // For existing tide rate stations, close the form on save
          handleCancel()
        } else if (!values.uuid) {
          // Update the selected tide station with the API response payload
          setSelectedTideRateStation(action.payload)
          setOpenFileUpload(false)
        }
      } catch (error) {
        showErrorMessage(error)
      }
    },
    [dispatch, handleCancel, port, showErrorMessage]
  )

  const setFileStatus = (file, status) => {
    setAllDataFile({ file, status })
  }

  const onFileSelect = useCallback(
    async (tideRateStation, file, isDataDst) => {
      if (file === null) {
        // rejected file
        showErrorMessage('Invalid file type selected')
        return
      }
      if (file instanceof File && tideRateStation) {
        try {
          // TODO: fails for new tide station
          setFileStatus(file, FILE_STATUS.UPLOADING)
          setSubmitting(true)
          const action = await dispatch(
            createTideRateData({
              tideRateStation: { uuid: tideRateStation.uuid },
              file,
              isDataDst,
            })
          )
          if (action.error) {
            throw action.error
          }
          setFileStatus(file, FILE_STATUS.SUCCESS)
          // Returned tide rate station has the updated date ranges
          setSelectedTideRateStation(action.payload.tideRateStation)
        } catch (error) {
          setFileStatus(file, FILE_STATUS.ERROR)
          showErrorMessage(error)
        } finally {
          setSubmitting(false)
        }
      }
    },
    [dispatch, showErrorMessage]
  )

  if (tideRateStationError) {
    return <ErrorMessage active message="Error loading Tide Rate Stations" />
  }

  if (tideRateStationList === null) {
    return <Preloader>Loading Tide Rate Stations List...</Preloader>
  }

  const constraintsLinkedToTideRateStation = constraints.filter(
    (constraint) =>
      idx(constraint.tideRateStation, (_) => _.uuid) ===
      idx(selectedTideRateStationToRemove, (_) => _.uuid)
  )

  const sortedTideRateStationList =
    tideRateStationList && tideRateStationList.sort(compareName)

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Tide Rate Stations"
        subtitle="Please create tide rate station and upload the predicted tide rate data, formatted according to the tide rate CSV template."
        description="Set your tide rate station"
        clickHandler={handleCreateNewTideRateStation}
        hasButton
        buttonContents="Add Tide Rate Station"
      >
        {children}

        <TideRateStationList
          data={sortedTideRateStationList}
          selectedTideRateStation={selectedTideRateStation}
          onRemove={handleRemove}
          onUpload={handleUploadClick}
          onClick={handleClick}
        />
        {(!sortedTideRateStationList || !sortedTideRateStationList.length) && (
          <EmptyState message="No tide rate station data yet" />
        )}
      </Fieldset>

      {selectedTideRateStation && openFileUpload && (
        <FormDialog
          open
          disableBackdropClick={submitting}
          title={`${selectedTideRateStation.uuid ? 'Edit' : 'Create'} Tide Rate Station`}
          FormComponent={TideRateStationForm}
          formComponentProps={{
            allDataFile,
            onFileSelect,
          }}
          formEntityProp="tideRateStation"
          entity={selectedTideRateStation}
          onSave={handleSave}
          onCancel={handleCancel}
          submitting={submitting}
          saveButtonLabel={'Done'}
          alwaysEnableSaveBtn
        />
      )}
      {selectedTideRateStation && selectedTideRateStation.uuid && !openFileUpload && (
        <TideRateConfig
          tideRateStation={selectedTideRateStation}
          config={selectedTideRateStation.metadata || defaultTidalRateConfig.metadata}
          disabled={disabled}>
        </TideRateConfig>
      )}
      {selectedTideRateStationToRemove && (
        <ConfirmDialog
          open={!!selectedTideRateStationToRemove}
          title={`Remove Tide Rate Station: ${selectedTideRateStationToRemove.name}`}
          onCancel={handleRejectRemove}
          onConfirm={handleConfirmRemove}
        >
          {constraintsLinkedToTideRateStation.length >= 1 ? (
            <>
              {`This Tide Rate Station is currently linked to the following
              constraint${
                constraintsLinkedToTideRateStation.length === 1 ? '' : 's'
              }:`}
              <List>
                {constraintsLinkedToTideRateStation.map((constraint) => (
                  <ListItemText inset key={constraint.name}>
                    {constraint.name}
                  </ListItemText>
                ))}
              </List>
              Are you sure you want to remove the selected Tide Rate Station?
              Constraints using this Tide Rate Station will not be able to show tide rate data anymore.
            </>
          ) : (
            'Are you sure that you remove the selected Tide Rate Station?'
          )}
        </ConfirmDialog>
      )}
    </>
  )
}

TideRateStations.propTypes = {
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
  port: PropTypes.shape(PortShape),
  children: PropTypes.any
}

export default TideRateStations
