import styled from 'styled-components'
import PrimaryButton from 'src/components/atoms/PrimaryButton'

export const PrimaryDialogButton = styled(PrimaryButton)`
  && {
    margin-left: ${({ theme }) => theme.spacing(1)}px;
  }
`

export default PrimaryDialogButton
