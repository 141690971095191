import React from 'react'
import styled from 'styled-components'
import { useRoute, useRouter, Link } from 'react-router5'
import { useDispatch, useSelector } from 'react-redux'

import { Paper, Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import LinearProgress from '@material-ui/core/LinearProgress'
import Tooltip from '@material-ui/core/Tooltip'

import { AppLogoLink } from 'src/components/atoms/AppLogo'
import {
  HOME,
  AUTH_ACCEPT_INVITATION,
  AUTH_FORGOT_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_LOGIN,
  AUTH_REFRESH_SESSION,
} from 'src/router/routes'
import {
  requestLogin,
  requestResetPassword,
  requestNewPassword,
  acceptInvitation,
} from 'src/store/auth/actions'
import {
  isLoggedInSelector,
  isLoginInProgressSelector,
  isPasswordResetInProgressSelector,
  isRequestNewPasswordInProgressSelector,
  isRefreshSessionInProgressSelector,
} from 'src/store/auth/selectors'

import SetPasswordForm from 'src/components/organisms/SetPasswordForm'
import LoginForm from 'src/components/organisms/LoginForm/LoginForm'
import ForgotPasswordForm from 'src/components/organisms/ForgotPasswordForm'
import RefreshSession from 'src/components/organisms/RefreshSession'

const AuthPaper = styled(Paper)`
  && {
    padding: ${({ theme }) => theme.spacing(1)}px;
    padding-bottom: ${({ theme, progress }) =>
      progress === 'true' ? theme.spacing(0.5) : theme.spacing(1)}px;
    position: relative;
    width: 600px;
    opacity: ${({ progress }) => (progress === 'true' ? 0.9 : 1)};
  }
`

const AuthHeader = styled.section`
  padding: ${({ theme }) => theme.spacing(9)}px;
  text-align: center;
`

const AbsoluteStyledLink = styled(Link)`
  position: absolute;
`

// eslint-disable-next-line
const BackButton = ({ routeName = AUTH_LOGIN }) => (
  <AbsoluteStyledLink routeName={routeName}>
    <Tooltip title="Back to login">
      <IconButton size="small">
        <KeyboardArrowLeftIcon />
      </IconButton>
    </Tooltip>
  </AbsoluteStyledLink>
)

const getActionForRouteName = (routeName, payload) => {
  switch (routeName) {
    case AUTH_LOGIN:
      return requestLogin(payload)
    case AUTH_FORGOT_PASSWORD:
      return requestNewPassword(payload)
    case AUTH_ACCEPT_INVITATION:
      return acceptInvitation(payload)
    case AUTH_RESET_PASSWORD:
      return requestResetPassword(payload)
    default:
      return requestLogin(payload)
  }
}

export const Auth = () => {
  const isLoggedIn = useSelector(isLoggedInSelector)
  const route = useRoute()
  const router = useRouter()
  const { route: { name: routeName, params = {} } = {} } = route
  const dispatch = useDispatch()
  let ChildComponent
  let formProps = {}
  let displayBackbutton = true
  let inProgress = false
  let inProgressSelector

  switch (routeName) {
    case AUTH_ACCEPT_INVITATION:
    case AUTH_RESET_PASSWORD:
      ChildComponent = SetPasswordForm
      formProps = {
        ...formProps,
        ...params,
      }
      inProgressSelector = isPasswordResetInProgressSelector
      break
    case AUTH_FORGOT_PASSWORD:
      ChildComponent = ForgotPasswordForm
      inProgressSelector = isRequestNewPasswordInProgressSelector
      break
    case AUTH_REFRESH_SESSION:
      ChildComponent = RefreshSession
      displayBackbutton = false
      inProgressSelector = isRefreshSessionInProgressSelector
      break
    default:
      ChildComponent = LoginForm
      displayBackbutton = false
      inProgressSelector = isLoginInProgressSelector
  }
  inProgress = useSelector(inProgressSelector)

  if (isLoggedIn) {
    router.navigate(HOME)
  }

  const handleSubmit = (payload) =>
    dispatch(getActionForRouteName(routeName, payload)).then(() => {
      router.navigateToDefault()
    })

  return (
    <>
      <AuthPaper progress={(inProgress || '').toString()}>
        <Box pl={8} pr={8} pb={8}>
          {displayBackbutton ? <BackButton /> : null}
          <AuthHeader>
            <AppLogoLink size={200} />
          </AuthHeader>
          <Box pb={1}>
            <ChildComponent
              {...formProps}
              inProgress={inProgress}
              onSubmit={handleSubmit}
            />
          </Box>
          {inProgress && <LinearProgress />}
        </Box>
      </AuthPaper>
    </>
  )
}

export default Auth
