import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { actions as routerActions } from 'redux-router5'
import { useRoute } from 'react-router5'
import { OrganisationShape, PortShape, UserShape, InvitationShape } from 'src/utils/types'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import InvitationForm, { EMPTY_INVITATION } from 'src/components/organisms/InvitationForm'
import InvitationList from 'src/components/organisms/InvitationList'
import UserForm from 'src/components/organisms/UserForm'
import FormDialog from 'src/components/molecules/FormDialog'
import { requestNewPassword } from 'src/store/auth/actions'
import {
  createInvitation,
  deleteInvitation,
  updateInvitationPorts,
  saveInvitation
} from 'src/store/invitation/actions'
import {
    deleteUser,
    updateUser,
    updateUserPorts
} from 'src/store/user/actions'
import {
    ORGANISATIONS,
    ORGANISATION_PORT_ADMIN_EDIT,
    ORGANISATION_PORT_INVITE_CREATE,
    ORGANISATION_PORT_INVITE_EDIT,
    ORGANISATION_PORT_INVITE_RESET,
    ORGANISATION_PORT_INVITE_DELETE
} from 'src/router/routes'
import {
    Tooltip,
} from '@material-ui/core'
import EditButton from 'src/components/atoms/EditButton'
import AddUserButton from 'src/components/atoms/AddUserButton'


const PortCardContainer = styled.div`
  background: #fff;
  margin-bottom: 20px;
  border-radius: 3px;
  .port-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding: 0px 20px;
  }
  .port-content {
    padding: 0px 20px;
  }
  .port-actions {
    padding-right: 15px;
  }
`

export const PortCard = ({
    organisation,
    port,
    users,
    invitations,
    invitationsLoading,
    onEditPort
}) => {

    const dispatch = useDispatch()

    const { route } = useRoute()
    const { params, name: routeName } = route
    const { orgUuid, portUuid, invitationUuid, userUuid } = params

    const isActive = organisation.uuid === orgUuid && port.uuid === portUuid

    const contextParams = {
        orgUuid: organisation.uuid,
        portUuid: port.uuid
    }

    const portInvitations = invitations.filter(({ user, portValidationTokens = [] }) => {
        if (user && user.identity && user.identity.portIdentities) {
            return user.identity.portIdentities.find(portIdentity =>
                portIdentity.port && portIdentity.port.uuid === port.uuid
            )
        }
        return portValidationTokens.find(validationToken =>
            validationToken.port && validationToken.port.uuid === port.uuid
        )
    })

    const invitation = invitations.find(({ uuid }) => uuid === invitationUuid)
    const user = users.find(({ uuid }) => uuid === userUuid)

    const [invitationFormSubmitting, setInvitationFormSubmitting] = useState(false)
    const [userFormSubmitting, setUserFormSubmitting] = useState(false)

    const backToOrgList = () =>
        dispatch(routerActions.navigateTo(ORGANISATIONS))

    const handleInvitationClick = (invitation) => {
        if (invitation.user) {
            dispatch(routerActions.navigateTo(ORGANISATION_PORT_ADMIN_EDIT, {
                ...contextParams, userUuid: invitation.user.uuid
            }))
        } else {
            dispatch(routerActions.navigateTo(ORGANISATION_PORT_INVITE_EDIT, {
                ...contextParams, invitationUuid: invitation.uuid
            }))
        }
    }

    const handleInvitationReset = (invitation) => {
        dispatch(routerActions.navigateTo(ORGANISATION_PORT_INVITE_RESET, {
            ...contextParams, invitationUuid: invitation.uuid
        }))
    }

    const handleConfirmReset = async () => {
        if (!invitation) {
            return
        }
        if (invitation.verified) {
            await dispatch(requestNewPassword({ email: invitation.email }))

        } else {
            await dispatch(deleteInvitation(invitation, false))

            const newInvite = {
                email: invitation.email,
                metadata: invitation.metadata,
                organisation: {
                    uuid: organisation.uuid,
                },
                portValidationTokens: [...invitation.portValidationTokens],
                isAdmin: true,
            }

            await dispatch(createInvitation(newInvite))
        }

        backToOrgList()
    }

    const handleInvitationDelete = (invitation) => {
        dispatch(routerActions.navigateTo(ORGANISATION_PORT_INVITE_DELETE, {
            ...contextParams, invitationUuid: invitation.uuid
        }))
    }

    // Deletes user/invite from all ports in org
    const handleConfirmDeleteInvitation = async () => {
        if (!invitation) {
            return
        }
        if (invitation.user) {

            const portIdentities = invitation.user.identity.portIdentities || []
            const nextPortIdentities = portIdentities.filter(id => id.port.uuid !== portUuid)

            if (nextPortIdentities.length === 0) { // if no ports left, delete whole user
                await dispatch(
                    deleteUser({
                        uuid: invitation.user.uuid,
                        email: invitation.email,
                        firstName: invitation.metadata.firstName,
                        lastName: invitation.metadata.lastName,
                    })
                )
            } else { // or just remove port from list
                await dispatch(
                    updateUserPorts({
                        ...invitation.user,
                        identity: {
                            ...invitation.user.identity,
                            portIdentities: nextPortIdentities
                        }
                    },
                    `Removed ${invitation.metadata.firstName} ${invitation.metadata.lastName} from ${port.name}`
                ))
            }
        } else {

            const portValidationTokens = invitation.portValidationTokens || []
            const nextPortValidationTokens = portValidationTokens.filter(token => token.port.uuid !== portUuid)

            if (portValidationTokens.length === 0) { // if no ports left, delete whole invitation
                await dispatch(deleteInvitation(invitation, false))

            } else { // or just remove port from list
                await dispatch(
                    updateInvitationPorts({
                        ...invitation,
                        portValidationTokens: nextPortValidationTokens
                    },
                    `Removed ${invitation.metadata.firstName} ${invitation.metadata.lastName} from ${port.name}`
                ))
            }
        }

        backToOrgList()
    }

    const handleInviteAdmin = () => {
        dispatch(
            routerActions.navigateTo(ORGANISATION_PORT_INVITE_CREATE, { ...contextParams })
        )
    }

    const handleUpdateUser = async (values) => {

        if (!user) { return }

        setUserFormSubmitting(true)

        const email = (
            (user.contacts || []).find(
            (contact) => contact.contactType === 'email'
            ) || {}
        ).value || ''

        try {
            await dispatch(
                updateUser({
                    uuid: user.uuid,
                    email,
                    metadata: values.metadata,
                })
            )

        } catch (error) {}

        setUserFormSubmitting(false)
        backToOrgList()
    }

    const handleUpdateInvite = async (values) => {

        setInvitationFormSubmitting(true)

        try {
            const { email, ...data } = values

            const result = await dispatch(
                saveInvitation({
                    // If we send email it means that the BE will re-send the invitation.
                    // So we just remove the email if it is the same as it was before.
                    ...(invitation && email === invitation.email ? data : values),
                    organisation: {
                        uuid: organisation.uuid,
                    },
                    isAdmin: true,
                    // we don't update the portValidationTokens in this request
                })
            )
            if (!result.error) {
                backToOrgList()
            }
        } catch (error) {
            // @todo display error
        }

        setInvitationFormSubmitting(false)
    }

    const handleCreateInvite = async (values) => {

        setInvitationFormSubmitting(true)

        const { existingInvite, existingUser } = getMatchingInvitesAndUsersByEmail(values.email)

        try {
            let result

            if (existingUser && existingUser.identity.portIdentities) {
                result = await dispatch(
                    updateUserPorts({
                        ...existingUser,
                        identity: {
                            ...existingUser.identity,
                            portIdentities: existingUser.identity.portIdentities.concat([{ port }])
                        }
                    },
                    `Added ${existingInvite.metadata.firstName} ${existingInvite.metadata.lastName} to ${port.name}`
                ))

            } else if (existingInvite && existingInvite.portValidationTokens) {
                result = await dispatch(
                    updateInvitationPorts({
                        ...existingInvite,
                        portValidationTokens: existingInvite.portValidationTokens.concat([{ port }])
                    },
                    `Invited ${existingInvite.metadata.firstName} ${existingInvite.metadata.lastName} to ${port.name}`
                ))

            } else {
                result = await dispatch(createInvitation({
                    ...values,
                    organisation: { uuid: organisation.uuid },
                    portValidationTokens: [{ port: { uuid: port.uuid } }],
                    isAdmin: true,
                }))
            }
            if (!result.error) {
                backToOrgList()
            }
        } catch (error) {
            // @todo display error
        }

        setInvitationFormSubmitting(false)
    }

    const getMatchingInvitesAndUsersByEmail = (email) => {

        const existingUser = users.find(({ contacts }) =>
            contacts.find(({ contactType, value }) => contactType === 'email' && value === email)
        )

        const existingInvite = invitations.find(({ email: e, organisation: org }) =>
            email.toLowerCase() === e.toLowerCase() && organisation.uuid === org.uuid
        )

        return { existingUser, existingInvite }
    }

    const handleCreateInviteAdvisory = (values) => {
        const { existingInvite, existingUser } = getMatchingInvitesAndUsersByEmail(values.email)

        if (existingUser) {
            if ((existingUser.identity.portIdentities || []).find(identity => identity.port.uuid === portUuid)) {
                return { message: '', error: `${existingUser.metadata.firstName} ${existingUser.metadata.lastName} has already been added as an admin` }
            }
            return {
                message: `${existingUser.metadata.firstName} ${existingUser.metadata.lastName} is already part of ${organisation.name}. Proceeding will also make them an admin of ${port.name}.`,
                ...existingUser.metadata
            }

        } else if (existingInvite) {
            if ((existingInvite.portValidationTokens || []).find(token => token.port.uuid === portUuid)) {
                return { message: '', error: `${existingInvite.metadata.firstName} ${existingInvite.metadata.lastName} has already been invited as an admin` }
            }
            return {
                message: `${existingInvite.email} has already been invited to ${organisation.name}. Proceeding will also invite them to ${port.name}.`,
                ...existingInvite.metadata
            }
        } else {
            return {
                message: ''
            }
        }
    }

  return (
    <>
        <PortCardContainer>

            <div className="port-header">
                <h3>{port.name}</h3>
                <div className="port-actions">
                    {
                        !organisation.ssoEnabled &&
                        <Tooltip title="Invite admin">
                            <AddUserButton onClick={handleInviteAdmin} />
                        </Tooltip>
                    }
                    <Tooltip title="Edit port">
                        <EditButton onClick={() => onEditPort(port)} />
                    </Tooltip>
                </div>
            </div>
            {
                portInvitations.length > 0 &&
                <div className="port-content">
                    <InvitationList
                        isLoading={invitationsLoading}
                        enableResetRemove={!organisation.ssoEnabled}
                        list={portInvitations}
                        onClick={handleInvitationClick}
                        onReset={handleInvitationReset}
                        onDelete={handleInvitationDelete}
                    />
                </div>
            }
        </PortCardContainer>

        {
            // edit admin (user)
            (!organisation.ssoEnabled) && ORGANISATION_PORT_ADMIN_EDIT === routeName && user && isActive && (
                <FormDialog
                    open
                    submitting={userFormSubmitting}
                    FormComponent={UserForm}
                    title="Update admin"
                    entity={user}
                    formEntityProp="user"
                    onCancel={backToOrgList}
                    onSave={handleUpdateUser}
                    saveButtonLabel="Update"
                />
            )
        }
        {
            // create invite
            ORGANISATION_PORT_INVITE_CREATE === routeName && isActive && (
                <FormDialog
                    open
                    submitting={invitationFormSubmitting}
                    FormComponent={InvitationForm}
                    formComponentProps={{
                        advisory: handleCreateInviteAdvisory,
                        emailOptions: invitations
                            .filter(invite => // exclude those already in port
                                portInvitations.indexOf(invite) === -1
                            )
                            .map(({ email }) => email)

                    }}
                    title='Invite admin'
                    entity={{ ...EMPTY_INVITATION }}
                    formEntityProp="invitation"
                    onCancel={backToOrgList}
                    onSave={handleCreateInvite}
                    saveButtonLabel='Invite'
                />
            )
        }
        {
            // update invite
            ORGANISATION_PORT_INVITE_EDIT === routeName && isActive && (
                <FormDialog
                    open
                    submitting={invitationFormSubmitting}
                    FormComponent={InvitationForm}
                    title='Change invitation'
                    entity={invitation}
                    formEntityProp="invitation"
                    onCancel={backToOrgList}
                    onSave={handleUpdateInvite}
                    saveButtonLabel='Update invitation'
                />
            )
        }
        {
            // delete invite
            ORGANISATION_PORT_INVITE_DELETE === routeName && invitation && isActive && (
                <ConfirmDialog
                    open
                    title={invitation.verified ? 'Delete admin' : 'Delete invitation'}
                    onConfirm={handleConfirmDeleteInvitation}
                    onCancel={backToOrgList}
                >
                    Are you sure you want to delete this{' '}
                    {invitation.verified ? 'admin' : 'invitation'}:
                    {invitation.email}?
                </ConfirmDialog>
            )
        }
        {
            // reset/resend invite
            ORGANISATION_PORT_INVITE_RESET === routeName && invitation && isActive && (
                <ConfirmDialog
                    open
                    title={invitation.verified ? 'Reset password' : 'Resend invitation'}
                    onCancel={backToOrgList}
                    onConfirm={handleConfirmReset}
                >
                    Are you sure you want to{' '}
                    {invitation.verified
                    ? 'reset password for'
                    : 'resend invitation to'}{' '}
                    {invitation.email}?
                </ConfirmDialog>
            )
        }
    </>
  )
}

PortCard.propTypes = {
  organisation: PropTypes.shape(OrganisationShape),
  port: PropTypes.shape(PortShape),
  users: PropTypes.arrayOf(PropTypes.shape(UserShape)),
  invitations: PropTypes.arrayOf(PropTypes.shape(InvitationShape)),
  invitationsLoading: PropTypes.bool,
  onEditPort: PropTypes.func
}

export default PortCard
