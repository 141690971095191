import { createSelector } from 'reselect'
import {
  createSauceStore,
  REDUCER_TYPES,
  CREATOR_STATUS_NAMES,
  ACTION_STATUSES,
} from '@weareroam/sauce-web-store'

import {
  BERTH_STATION_TYPE,
  BERTH_STATION_TYPE_LABEL,
} from 'src/constants/settings'
import { createFilterBySelectedPortSelector } from 'src/utils/store/store-selector-utils'
import actionToastMessage from 'src/utils/store/toast-middleware-creator'

export const store = createSauceStore({
  name: 'stations',
  url: '/berth_stations(/:uuid)',
  type: REDUCER_TYPES.LIST,
  middlewares: [
    'statusById',
    'errorById',
    actionToastMessage({
      transformPayload: (
        name,
        actionName,
        status,
        payload,
        dispatch,
        getState
      ) => {
        const station =
          typeof payload === 'string'
            ? store.selectors.getById(payload)(getState()) || {}
            : payload instanceof Error
            ? payload.data
            : payload
        const type =
          station && BERTH_STATION_TYPE_LABEL[station.berthStationType]
        return {
          ...station,
          type:
            status === CREATOR_STATUS_NAMES[ACTION_STATUSES.SUCCESS]
              ? type[0].toUpperCase() + type.slice(1)
              : type,
        }
      },
    }),
  ],
})

// add the port filtering to the list selector
const listSelector = store.selectors.list
store.selectors.list = createFilterBySelectedPortSelector(listSelector)

store.selectors.getListByType = (type) =>
  createSelector(
    store.selectors.list,
    (stations) =>
      stations &&
      stations.filter((station) => type === station.berthStationType)
  )

// we have to remove the charts from the payload
// because it is not valid for the backend as it provides
store.actions.update.use(({ charts, ...station }) => station)

store.selectors.berths = store.selectors.getListByType(BERTH_STATION_TYPE.BERTH)
store.selectors.stations = store.selectors.getListByType(
  BERTH_STATION_TYPE.STATION
)

export default store
