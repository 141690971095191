import React from 'react'
import styled from 'styled-components'
import { useRouter } from 'react-router5'
import { useSelector } from 'react-redux'

import { Box, IconButton, Paper, Button } from '@material-ui/core'
import AppsIcon from '@material-ui/icons/Apps'
import PermIdentityIcon from '@material-ui/icons/PermIdentity'
import GroupIcon from '@material-ui/icons/Group'
import Popover from '@material-ui/core/Popover'

import { PILOTS, SETTINGS, ORGANISATIONS } from 'src/router/routes'
import { roleSelector } from 'src/store/user/selectors'
import { USER_ROLES } from 'src/constants/user'
import { SettingsIcon } from 'src/components/atoms/Icons'

const StyledPopoverPaper = styled(Paper).attrs({ elevation: 4 })`
  padding: ${({ theme }) => theme.spacing(2)}px;
  & {
    border-radius: ${({ theme }) => theme.spacing(3)}px;
    border-top-right-radius: 0;
  }
`
const StyledButton = styled(Button).attrs({
  size: 'small',
  disableRipple: true,
})`
  margin: ${({ theme }) => theme.spacing(0.5)}px;
  width: calc(50% - ${({ theme }) => theme.spacing(0.5)}px);
  && {
    border-radius: 5px;
  }
  & > * {
    flex-direction: column;
  }
`
const MenuButtonLabel = styled.div`
  font-size: ${({ theme }) => theme.typography.body2.fontSize}px;
`
// eslint-disable-next-line
const MenuButton = ({ icon, label, onClick }) => (
  <StyledButton onClick={onClick}>
    {icon}
    <MenuButtonLabel>{label}</MenuButtonLabel>
  </StyledButton>
)

export const AppMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const router = useRouter()
  const userRoles = useSelector(roleSelector)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const createMenuButtonClickHandler = (routeName) => () => {
    router.navigate(routeName)
    handleClose()
  }

  const open = !!anchorEl
  const id = open ? 'app-menu-popover' : undefined

  const menuItems = [
    {
      label: 'Organisations',
      icon: <GroupIcon size={22} />,
      handleClick: createMenuButtonClickHandler(ORGANISATIONS),
      roles: [USER_ROLES.SUPER_ADMIN],
    },
    {
      label: 'Pilots',
      icon: <PermIdentityIcon size={22} />,
      handleClick: createMenuButtonClickHandler(PILOTS),
      roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
    },
    {
      label: 'Settings',
      icon: <SettingsIcon size={22} />,
      handleClick: createMenuButtonClickHandler(SETTINGS),
      roles: [USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN],
    },
  ].filter(
    ({ roles }) =>
      !roles ||
      !userRoles ||
      userRoles.filter((role) => roles.includes(role)).length > 0
  )

  return (
    <Box>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <AppsIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <StyledPopoverPaper>
          <Box pb={1} pt={0} textAlign="right">
            <AppsIcon color="disabled" />
          </Box>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-around"
            maxWidth="25vw"
            minWidth="200px"
          >
            {menuItems.map(({ label, handleClick, icon }, index) => (
              <MenuButton
                key={`app-menuitem-${index}`}
                label={label}
                icon={icon}
                onClick={handleClick}
              />
            ))}
          </Box>
        </StyledPopoverPaper>
      </Popover>
    </Box>
  )
}

export default AppMenu
