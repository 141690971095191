import React from 'react'
import Box from '@material-ui/core/Box'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { Switch, Grid } from '@material-ui/core'
import useFeatureFlags from 'src/hooks/useFeatureFlags'

const SubheadingText = styled.p`
    color: #000;
    font-size: 12px;
    font-weight: bold;
    padding: 20px 0px 10px 0px;
`

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
  }
`

export const BerthingConditionsToggle = () => {

  const { getFlag, setFlag, error, loading } = useFeatureFlags()

  const flagName = 'use_berthing_conditions'
  const useBerthingConditions = getFlag(flagName)

  if (loading) { return null }

  return (
    <>
      <Box>
        <SubheadingText>
        Would you like to display berthing conditions showing Max. Allowable Draft on Pilot App?
        </SubheadingText>
      </Box>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <Switch
            name="toggle"
            color="primary"
            checked={useBerthingConditions === true}
            onChange={() => setFlag(flagName, !useBerthingConditions)}
          />
        </Grid>
        <Grid item>
          <ToggleText>
            {typeof useBerthingConditions === 'undefined' || useBerthingConditions === false
              ? 'Disabled'
              : 'Enabled'}
          </ToggleText>
        </Grid>
      </Grid>
      {error && error.message}
    </>
  )
}

BerthingConditionsToggle.propTypes = {}

export default BerthingConditionsToggle
