import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import fileSize from 'filesize'

import Box from '@material-ui/core/Box'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'

import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import DialogActions from '@material-ui/core/DialogActions'
import Collapse from '@material-ui/core/Collapse'

import Fieldset from 'src/components/organisms/Fieldset'
import { PortShape } from 'src/utils/types'
import PrimaryButton from 'src/components/atoms/PrimaryButton'

import DialogHeader from 'src/components/molecules/DialogHeader/DialogHeader'

import store from 'src/store/vessels'
import UploadButton from 'src/components/atoms/UploadButton'
import { FileIcon } from 'src/components/atoms/Icons'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import { ImportResults } from './ImportResults'
import {
  addErrorToast,
  addSuccessToast,
  addToast,
} from 'src/store/toast/actions'
import { TOAST_MESSAGES, getToastMessage } from 'src/utils/toastMessages'
import { IMPORT_MAX_FILE_SIZE } from 'src/constants/settings'

const SubheadingText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-left: 10px;
    margin-bottom: 20px;
  }
`

const DialogBody = styled(DialogContent)`
  max-height: 50vh;
  overflow-y: auto;
`

const Progress = styled(LinearProgress)`
  && {
    margin: ${({ theme }) => theme.spacing(0.5, 0, 0.5, 0)};
  }
`

const UploadContainer = styled.div``

const LeftAlignedUploadButton = styled(UploadButton)`
  & {
    margin-right: auto;
  }
`

const FileName = styled.div`
  && {
    max-height: 1.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const File = ({ file }) =>
  file ? (
    <Box display="inline">
      <FileName>
        {file.name} ({fileSize(file.size, { base: 10 })})
      </FileName>
    </Box>
  ) : null
File.propTypes = {
  file: PropTypes.instanceOf(File),
}

const UploadProgressLabel = styled.div``

const CancelButton = styled(SecondaryButton)`
  && {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const VesselUpload = ({ disabled, port }) => {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [file, setFile] = useState(null)
  const [results, setResults] = useState(null)
  const uploadProgress = useSelector(store.selectors.uploadProgress)

  useEffect(() => {
    dispatch(store.actions.getList())
  }, [dispatch])

  const handleFileSelect = (files) => {
    if (files && files.length > 0) {
      setFile(files[0])
      setResults(null)
    }
  }

  const handleStartProcess = async () => {
    if (file) {
      setSubmitting(true)
      try {
        const actionResult = await dispatch(
          store.actions.import({
            file,
            port,
          })
        )
        const { payload, error } = actionResult
        if (error || !payload) {
          throw error || new Error()
        }
        setResults(payload)
        dispatch(
          addSuccessToast({
            message: getToastMessage(TOAST_MESSAGES.IMPORT_SUCCESS, {
              fileName: file.name,
            }),
          })
        )
      } catch (error) {
        dispatch(
          addErrorToast({
            message: getToastMessage(TOAST_MESSAGES.IMPORT_ERROR, {
              fileName: file.name,
            }),
          })
        )
      } finally {
        setSubmitting(false)
      }
    }
  }

  const closeProcess = () => {
    setFile(null)
    setResults(null)
  }
  const cancelProcess = () => {
    closeProcess()
  }

  const handleDropRejected = (files) => {
    const [file] = files
    if (file && file.size > IMPORT_MAX_FILE_SIZE) {
      dispatch(
        addToast({
          message: `The file size limit is ${fileSize(IMPORT_MAX_FILE_SIZE, {
            base: 10,
          })}`,
        })
      )
    }
  }

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Vessel Upload"
        description="Here you can upload new and updated vessels for your organisation. Please upload the vessels in a CSV file format, according to the template"
      >
        <Box>
          <SubheadingText>
            Here you can upload new and updated vessels for your organisation.
            Please upload the vessels in a CSV file format, according to the
            template
          </SubheadingText>
        </Box>
        <Box display="flex">
          <LeftAlignedUploadButton
            Container={UploadContainer}
            name="file"
            label="Add vessels"
            extensionsLabel="csv"
            accept=".csv"
            maxSize={IMPORT_MAX_FILE_SIZE}
            onDropRejected={handleDropRejected}
            multiple={false}
            onSelect={handleFileSelect}
          >
            <PrimaryButton>Select file</PrimaryButton>
          </LeftAlignedUploadButton>
        </Box>
      </Fieldset>
      <Dialog open={!!file || !!results}>
        <DialogHeader>
          {results && file !== null
            ? `Upload result for ${file.name}`
            : 'Upload .csv file'}
        </DialogHeader>
        <DialogBody>
          <Collapse in={!results}>
            <Box display="flex" alignItems="center">
              <Box flexShrink={1}>
                <FileIcon ext="csv" />
              </Box>
              <Box flex={1} px={1}>
                <Box display="flex" flexDirection="column">
                  <File file={file} />
                  <Progress
                    variant={
                      !submitting || (uploadProgress && uploadProgress < 100)
                        ? 'determinate'
                        : 'indeterminate'
                    }
                    value={
                      (submitting || !!results) && uploadProgress
                        ? uploadProgress
                        : 0
                    }
                  />
                  <UploadProgressLabel>
                    {submitting && uploadProgress < 100 && (
                      <>
                        {uploadProgress
                          ? (uploadProgress || 0).toFixed(2)
                          : '0'}
                        %
                      </>
                    )}
                    {submitting && uploadProgress === 100 && 'Processing...'}
                  </UploadProgressLabel>
                </Box>
              </Box>
            </Box>
          </Collapse>
          <Collapse in={results !== null && results.length > 0}>
            {results && <ImportResults results={results} />}
          </Collapse>
        </DialogBody>
        <DialogActions>
          {!submitting && !results && (
            <>
              <CancelButton
                disabled={submitting || !!results}
                onClick={cancelProcess}
              >
                Cancel
              </CancelButton>
              <PrimaryButton
                disabled={submitting || !!results}
                onClick={handleStartProcess}
              >
                Upload
              </PrimaryButton>
            </>
          )}
          {results && (
            <PrimaryButton onClick={closeProcess}>Close</PrimaryButton>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

VesselUpload.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
}

export default VesselUpload
