import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import styled from 'styled-components'
import ListInput from 'src/components/organisms/ListInput'
import Fieldset from 'src/components/organisms/Fieldset'
import { PortShape } from 'src/utils/types'
import Preloader from 'src/components/atoms/Preloader'
import MovementTypeSelector from 'src/components/molecules/MovementTypeSelector'
import { EXTRA_TYPE } from 'src/constants/settings'
import { CheckIcon } from 'src/components/atoms/Icons'
import useChecklist from 'src/hooks/useChecklist'
import ChecklistPdfExportToggle from './ChecklistPdfExportToggle'
import uuidv1 from 'uuid'

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const DEFAULT_CHECKLIST = [
  { label: 'Pilot card' },
  { label: 'Defects' },
  { label: 'Nav aids' },
  { label: 'Charted course' },
  { label: 'Harbor traffic' },
  { label: 'Bollards SWL' },
  { label: 'Anchors clear, man forward' },
  { label: 'Ladder, gangway' },
  { label: 'Whale protocol' },
  { label: 'Changed to low emission fuel(s)' },
]

const Checklist = ({ port }) => {
  const { fields, loaded, dirty, handleSave } = useChecklist({
    extraType: EXTRA_TYPE.CHECKLIST,
    defaultItems: DEFAULT_CHECKLIST,
  })

  const saveChecklist = () => handleSave(port)

  const handleAddClick = () => {
    fields.checklist.onChange('checklist', [
      ...fields.checklist.value,
      { label: undefined, uuid: uuidv1() },
    ])
  }

  const limit = 100
  const hasReachedLimit =
    typeof limit === 'number' && fields.checklist.value.length >= limit

  return (
    <Fieldset
      title="Checklist"
      description="Checklist for the pilot app"
      hasButton
      buttonContents="Add Item"
      clickHandler={handleAddClick}
      buttonDisabled={hasReachedLimit}
    >
      {!loaded && <Preloader />}
      {loaded && (
        <ListInput
          sortable
          name="checklist"
          fullWidth
          onSave={saveChecklist}
          valueProp="label"
          limit={limit}
          setHeaderColor
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <StyledCheckIcon isSuccessColor size={22} />
              </InputAdornment>
            ),
          }}
          ExtraControlsComponent={MovementTypeSelector}
          {...fields.checklist}
          dirty={dirty}
        >
          <ChecklistPdfExportToggle flagName="show_checklist_in_master_view" />
        </ListInput>
      )}
    </Fieldset>
  )
}

Checklist.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default React.memo(Checklist)
