import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getTugs = (config) =>
  api
    .get(`/tugs`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getTug = (uuid, config) =>
  api
    .get(`/tugs/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createTug = (tug, config) => {
  const { file, ...body } = tug
  return api
    .post('/tugs', body, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateTug = (tug, config) => {
  const { file, ...body } = tug
  return api
    .put(`/tugs/${tug.uuid}`, body, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteTug = (uuid, config) =>
  api
    .delete(`/tugs/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  create: createTug,
  update: updateTug,
  delete: deleteTug,
}
