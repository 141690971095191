import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { AppLogoLink } from 'src/components/atoms/AppLogo'
import UserMenu from 'src/components/organisms/UserMenu'
import AppMenu from 'src/components/organisms/AppMenu'
import VerticalDivider from 'src/components/atoms/VerticalDivider'
import { progressSelector } from 'src/store/ui/selectors'
import { LinearProgress } from '@material-ui/core'
import MainContainer from 'src/components/layouts/DefaultLayout/MainContainer'
import PortSelector from 'src/components/organisms/PortSelector'

const StyledAppLogoLink = styled(AppLogoLink)`
  margin-right: auto;
  align-self: center;
`

const StyledToolBar = styled.div`
  align-items: stretch;
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(4, 0)};
`

export const DefaultLayoutHeader = () => {
  const progress = useSelector(progressSelector)
  return (
    <>
      <MainContainer>
        <StyledToolBar>
          <StyledAppLogoLink />
          <PortSelector />
          <AppMenu />
          <VerticalDivider />
          <UserMenu />
        </StyledToolBar>
      </MainContainer>
      {progress !== null && progress !== undefined && (
        <LinearProgress variant="determinate" value={progress} />
      )}
    </>
  )
}

export default DefaultLayoutHeader
