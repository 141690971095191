import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const createTideData = ({ tideStation, file, tidesType, isDataDst }, config) => {
  const data = new FormData()
  data.append('file', file)
  data.append('tideStation', JSON.stringify(tideStation))
  data.append('tidesType', tidesType)
  data.append('fileInfo', JSON.stringify({ isDataDst }))
  return api
    .post(`/tide_data`, data, {
      ...(config || {}),
      headers: {
        ...((config || {}).headers || {}),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )
}

export const updateTideData = ({ tideStation, file }, config) => {
  const data = new FormData()
  data.append('file', file)
  data.append('tideStation', JSON.stringify(tideStation))
  return api
    .put(`/tide_data`, data, {
      ...(config || {}),
      headers: {
        ...((config || {}).headers || {}),
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )
}

export const deleteTideData = (uuid, config) =>
  api
    .delete(`/tide_data/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )
