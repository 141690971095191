import Typography from '@material-ui/core/Typography'
import copy from 'copy-to-clipboard'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { TextInput } from 'src/components/atoms/TextInput'
import Fieldset from 'src/components/organisms/Fieldset'
import { SSO_CREATE_IN_PROGRESS, SSO_GET_IN_PROGRESS, SSO_GET_SUCCESS, SSO_UPDATE_IN_PROGRESS } from 'src/store/sso/actions'
import { ssoDataSelector, ssoStatusSelector } from 'src/store/sso/selectors'
import { SSO_ENABLE_CONSOLE_LOG } from 'src/utils/ssoConstants'
import { TOAST_MESSAGES } from 'src/utils/toastMessages'
import { validateValues } from 'src/utils/validation'
import styled from 'styled-components'
import useForm from './../../../hooks/useForm'
import { createSso, getSso, updateSso } from './../../../store/sso/actions'
import { addSuccessToast } from './../../../store/toast/actions'
import { isSuperAdminSelector } from 'src/store/user/selectors'

const ActionsBox = styled.div`
  display: flex;
  & button {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
  }
`

const R = <sub style={{fontSize: 8, bottom: '0.75em'}}>®</sub>

export interface PortShape {
  uuid: string
  name: string
  locationName: string
  organisation: FkShape
}
export interface FkShape {
  uuid: string
}
interface SsoConfigProps {
  disabled: boolean,
  port: PortShape
}

interface SsoConfigMetaData {
  subdomain: string
  scimHostname: string
  ssoPilotUuid: string | undefined
  ssoPilotRedirectUrl: string
  ssoAdminUuid: string | undefined
  ssoAdminRedirectUrl: string
  scimUuid: string | undefined
  tenantUrl: string
  tenantId: string
  clientId: string
  port: PortShape
  organisation: FkShape
}

const blankConfig = (port: PortShape): SsoConfigMetaData => ({
  subdomain: '',
  scimHostname: '',
  ssoPilotUuid: undefined,
  ssoPilotRedirectUrl: '',
  ssoAdminUuid: undefined,
  ssoAdminRedirectUrl: '',
  scimUuid: undefined,
  tenantUrl: '',
  tenantId: '',
  clientId: '',
  port,
  organisation: port.organisation,
})

export const SsoConfig: React.FC<SsoConfigProps> = ({ disabled, port }) => {
  const dispatch = useDispatch()
  const data: SsoConfigMetaData = useSelector(ssoDataSelector)
  const portUuid: string = port.uuid
  const status = useSelector(ssoStatusSelector)
  const isLoading: boolean = status === SSO_GET_IN_PROGRESS || status === SSO_UPDATE_IN_PROGRESS || status === SSO_CREATE_IN_PROGRESS
  const isLoaded: boolean = status === SSO_GET_SUCCESS
  const [barrierDisabled, setBarrierDisabled] = useState(false)
  const isSuperAdmin = useSelector(isSuperAdminSelector)

  const disabledFinal: boolean = disabled || isLoading || !barrierDisabled

  if (SSO_ENABLE_CONSOLE_LOG) {
    console.log(`status [${status}] isLoading [${isLoading}] isLoaded [${isLoaded}] data [${JSON.stringify(data)}]`)
  }
  useEffect(() => {
    if (isLoaded) {
      if (!barrierDisabled) {
        setBarrierDisabled(true)
      }
    }
  }, [dispatch, isLoaded, barrierDisabled])

  const loadSso = async () => {
    if (status === SSO_GET_IN_PROGRESS) {
      return
    }
    dispatch(getSso({ params: { portUuid } }))
  }

  useEffect(() => {
    if (portUuid) {
      loadSso()
    }
  }, [dispatch, portUuid])

  const handleSubmit = async (dataToCreateUpdate: SsoConfigMetaData) => {
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`dataToCreateUpdate [${JSON.stringify(dataToCreateUpdate)}]`)
    }
    const scimHostname = getApiDomain(dataToCreateUpdate.subdomain, false)
    dataToCreateUpdate.scimHostname = scimHostname
    dataToCreateUpdate.port = port
    dataToCreateUpdate.organisation = port.organisation
    if (dataToCreateUpdate.ssoPilotUuid) {
      await dispatch(updateSso(dataToCreateUpdate))
    } else {
      await dispatch(createSso(dataToCreateUpdate))
    }
    return true
  }

  const onCopy = (value: string) => {
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`onCopy [${value}]`)
    }
    copy(value)
    dispatch(
      addSuccessToast({
        message: TOAST_MESSAGES.COPIED_TO_CLIPBOARD
      })
    )
  }

  return (
    <Fieldset
      title={<>Single sign-on (SSO) with Microsoft Azure Active Directory (AD)</>}
      subtitle={
        <>Create and enter the Microsoft Azure Active Directory (AD) configuration details for the port to enable Single Sign-On (SSO) using SCIM auto provisioning for Admin and Pilot users.</>
      }
      disabled={disabledFinal}
      clickHandler={() => null}
      hasButton={false}
      buttonContents=""
    >
      {/* {!barrierDisabled || status === SSO_GET_IN_PROGRESS ? ( */}
      {!barrierDisabled ? (
        '...'
      ) : (
        <SsoConfigForm
          config={data || blankConfig(port)}
          updating={false}
          disabled={disabledFinal}
          error={undefined}
          port={port}
          onSubmit={handleSubmit}
          onCopy={onCopy}
          isSuperAdmin={isSuperAdmin}
        />
      )}
    </Fieldset>
  )
}

interface SsoConfigFormProps {
  config: SsoConfigMetaData
  updating: boolean
  disabled: boolean
  port: PortShape
  error?: Error
  onSubmit(config: SsoConfigMetaData): void
  onCopy: any
  isSuperAdmin: boolean
}

const getPilotDomainGeneric = (subdomain: string, appType: string, includeProtocol: boolean) => {
  let domain;
  if (process.env.REACT_APP_ENV !== 'prod') {
    domain = `${includeProtocol? 'https://':''}${subdomain}.${process.env.REACT_APP_ENV}.${appType}empx.app`
  } else {
    domain = `${includeProtocol? 'https://':''}${subdomain}.${appType}empx.app`
  }
  return domain
}

const getPilotDomain = (subdomain: string, includeProtocol: boolean) => {
  return getPilotDomainGeneric(subdomain, '', includeProtocol)
}

const getAdminDomain = (subdomain: string, includeProtocol: boolean) => {
  return getPilotDomainGeneric(subdomain, 'admin.', includeProtocol)
}

const getApiDomain = (subdomain: string, includeProtocol: boolean) => {
  return getPilotDomainGeneric(subdomain, 'api.', includeProtocol)
}

const validateForm = (values: any) =>
validateValues(values, {
  subdomain: 'required',
  tenantId: 'required',
  clientId: 'required'
})

const SsoConfigForm: React.FC<SsoConfigFormProps> = ({
  config,
  updating,
  disabled,
  error,
  onSubmit,
  onCopy,
  isSuperAdmin,
}) => {
  const formValues = useRef(config)
  // This method gets called for each prop's change. not efficient as we only need to
  // track hostname changes. Need to look at useForm code and optimise later.
  const onChange = (change: any) => {
    const subdomain = change.values.subdomain
    let derivedFieldsUpdate = {...derivedFields}
    if (!derivedFieldsUpdate) {
      derivedFieldsUpdate = {}
    }
    if (subdomain) {
      derivedFieldsUpdate.tenantUrl = `${getApiDomain(subdomain, true)}/scim`
      derivedFieldsUpdate.ssoAdminRedirectUrl = `${getAdminDomain(subdomain, true)}/sso`
      derivedFieldsUpdate.ssoPilotRedirectUrl = `${getPilotDomain(subdomain, true)}/sso`
    } else {
      derivedFieldsUpdate.tenantUrl = ''
      derivedFieldsUpdate.ssoAdminRedirectUrl = ''
      derivedFieldsUpdate.ssoPilotRedirectUrl = ''
    }
    setDerivedFields(derivedFieldsUpdate)
    formValues.current = change.values
  }

  const { dirty, isValid, fields } = useForm({
    initialValues: config as any,
    validationHandler: validateForm as any,
    submitHandler: () => null,
    changeHandler: onChange,
  })
  const fieldsAny = fields as any
  const [derivedFields, setDerivedFields] = useState<any>(null)
  const handleSubmit = () => onSubmit({...formValues.current, ...derivedFields})

  if (SSO_ENABLE_CONSOLE_LOG) {
    console.log(`isValid [${isValid}] dirty [${dirty}] config [${JSON.stringify(config)}] derivedFields [${JSON.stringify(derivedFields)}]`)
  }

  const getFieldValues = (field: any) => {
    let fieldObj: any = {}
    if (!isSuperAdmin) {
      if (field && field.value) {
        fieldObj = {value: field.value}
      } else {
        fieldObj = {value: ''}
      }
    } else {
      fieldObj = {...field}
    }
    return fieldObj
  }

  let subdomainObj: any = getFieldValues(fieldsAny.subdomain)
  let tenantIdObj: any = getFieldValues(fieldsAny.tenantId)
  let clientIdObj: any = getFieldValues(fieldsAny.clientId)
  return (
    <>
      <TextInput
        label="Hostname"
        name="subdomain"
        fullWidth
        readOnly={!isSuperAdmin}
        disabled={disabled}
        infoTooltip={"This hostname is used as a sub-domain in eMPX and helps generate Tenant URL and Redirect URL to be used in Azure Portal."}
        {...subdomainObj}
      />

      <TextInput
        label="Tenant URL"
        name="tenantUrl"
        disabled={disabled}
        copyEnabled
        onCopy={() => onCopy(derivedFields ? derivedFields.tenantUrl : '')}
        infoTooltip={"Tenant URL is created automatically based on the host name provided. Copy this URL and enter this under SCIM provisioning section in Azure Portal."}
        readOnly
        fullWidth
        value={derivedFields ? derivedFields.tenantUrl : ''}
      />

      <TextInput
        label="Admin App Redirect URI"
        name="ssoAdminRedirectUrl"
        disabled={disabled}
        copyEnabled
        onCopy={() => onCopy(derivedFields ? derivedFields.ssoAdminRedirectUrl : '')}
        infoTooltip={"Redirect URI is created automatically based on the host name provided. Copy this URL and enter this under App Registrations section in Azure Portal."}
        readOnly
        fullWidth
        value={derivedFields ? derivedFields.ssoAdminRedirectUrl : ''}
      />

      <TextInput
        label="Pilot App Redirect URI"
        name="ssoPilotRedirectUrl"
        disabled={disabled}
        copyEnabled
        onCopy={() => onCopy(derivedFields ? derivedFields.ssoPilotRedirectUrl : '')}
        infoTooltip={"Redirect URI is created automatically based on the host name provided. Copy this URL and enter this under App Registrations section in Azure Portal."}
        readOnly
        fullWidth
        value={derivedFields ? derivedFields.ssoPilotRedirectUrl : ''}
      />

      <TextInput
        label="Tenant Id"
        name="tenantId"
        disabled={disabled}
        infoTooltip={"Tenant Id is the unique identifier of Azure and can be found under Tenant Properties in Azure Portal."}
        fullWidth
        readOnly={!isSuperAdmin}
        {...tenantIdObj}
      />

      <TextInput
        label="Application Id"
        name="clientId"
        disabled={disabled}
        infoTooltip={"Application (Client) Id is the unique identifier of eMPX enterprise application and can be found under App Registrations in Azure Portal."}
        fullWidth
        readOnly={!isSuperAdmin}
        {...clientIdObj}
      />

      {isSuperAdmin && <ActionsBox>
        <PrimaryButton
          disabled={updating || !isValid || !dirty || disabled}
          onClick={handleSubmit}
        >
        { config.ssoPilotUuid ? 'Save' : 'Create' }
        </PrimaryButton>
      </ActionsBox>}

      {error && error.message}
    </>
  )
}

export default React.memo(SsoConfig)
