import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'

import Preloader from 'src/components/atoms/Preloader'
import SelectableRow from 'src/components/atoms/SelectableRow'
import TableSortLabel from 'src/components/atoms/TableSortLabel'
import { columnsShape } from 'src/utils/types'

const HeaderCells = ({ columns, sortDirection, activeField, onSortClick }) =>
  columns.map(
    ({ width, align: textAlign, justify, field, sortable, title }, index) => (
      <TableCell key={`table-head-${index}`} style={{ width, textAlign }}>
        <TableSortLabel
          active={field === activeField}
          direction={sortDirection}
          sortable={sortable}
          value={field}
          justify={justify}
          onClick={onSortClick}
        >
          {title}
        </TableSortLabel>
      </TableCell>
    )
  )

const BodyCells = ({ columns, item }) => {

  return columns.map(
    ({ labelFunction, width, align: textAlign, field }, cellIndex) => {
      if (labelFunction) {
        return (
          <TableCell
            key={`body-cell-${cellIndex}`}
            style={{ width, textAlign }}
          >
            {labelFunction(item[field], item, cellIndex)}
          </TableCell>
        )
      }
      return (
        <TableCell key={`body-cell-${cellIndex}`} style={{ width }}>
          {item[field]}
        </TableCell>
      )
    }
  )
}

const BodyRows = ({
  dataProvider,
  selectable,
  selectedPropertyName,
  onRowClick,
  columns,
}) =>
  dataProvider.map((item, rowIndex) => {
    if (selectable) {
      return (
        <SelectableRow
          key={`body-row-select-${rowIndex}`}
          value={item}
          onClick={onRowClick}
          hover={selectable}
          selected={item[selectedPropertyName]}
        >
          <BodyCells columns={columns} item={item} />
        </SelectableRow>
      )
    }
    return (
      <TableRow key={`body-row-${rowIndex}`} onClick={onRowClick}>
        <BodyCells columns={columns} item={item} />
      </TableRow>
    )
  })

const DataTable = ({
  columns,
  dataProvider,
  emptyMessage,
  hideHeader,
  selectable,
  disabledPropertyName,
  selectedPropertyName,
  onRowClick,
  onSortClick,
  inProgress,
}) => {
  const [activeField, setActiveField] = useState(null)
  const [sortDirection, setSortDirection] = useState('DESC')

  const handleSortClick = (newActiveField) => {
    const newSortDirection =
      sortDirection === 'DESC' && activeField === newActiveField
        ? 'ASC'
        : 'DESC'
    if (onSortClick) {
      onSortClick(newActiveField, newSortDirection)
    }
    setActiveField(newActiveField)
    setSortDirection(newSortDirection)
  }

  if (!columns || !dataProvider || (dataProvider && !dataProvider.length)) {
    return <div>{emptyMessage}</div>
  }

  return (
    <React.Fragment>
      <Table>
        {!hideHeader && (
          <TableHead>
            <TableRow>
              <HeaderCells
                columns={columns}
                activeField={activeField}
                sortDirection={sortDirection}
                onSortClick={handleSortClick}
              />
            </TableRow>
          </TableHead>
        )}
        {!inProgress && (
          <TableBody>
            <BodyRows
              columns={columns}
              dataProvider={dataProvider}
              selectable={selectable}
              onRowClick={onRowClick}
              selectedPropertyName={selectedPropertyName}
              disabledPropertyName={disabledPropertyName}
            />
          </TableBody>
        )}
      </Table>
      {inProgress && <Preloader />}
    </React.Fragment>
  )
}

DataTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape(columnsShape)),
  dataProvider: PropTypes.array,
  onSortClick: PropTypes.func,
  onRowClick: PropTypes.func,
  emptyMessage: PropTypes.node,
  disabledPropertyName: PropTypes.string,
  width: PropTypes.number,
  hideHeader: PropTypes.bool,
  selectable: PropTypes.bool,
  hideLastBorder: PropTypes.bool,
  selectedPropertyName: PropTypes.string,
  inProgress: PropTypes.bool,
}

DataTable.defaultProps = {
  emptyMessage: 'No data',
}

export default React.memo(DataTable)
