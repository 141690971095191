import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import validateEmail from 'src/utils/validateEmail'

import PrimaryButton from 'src/components/atoms/PrimaryButton'
import TextInput from 'src/components/atoms/TextInput/TextInput'

const SubmitButton = styled(PrimaryButton)`
  && {
    margin-top: 16px;
    width: 100%;
  }
`

const StyledForm = styled.form`
  width: 100%;
`

export const ForgotPasswordForm = ({ inProgress, onSubmit }) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState()

  const isValidEmail = (value) => !value || validateEmail(value)
  const isFormValid = () => email && isValidEmail(email)

  const handleEmailChange = (event) => setEmail(event.target.value)
  const handleEmailBlur = () => {
    setEmailError(
      isValidEmail(email) ? '' : 'Please enter a valid email address'
    )
  }
  const handleEmailFocus = () => {
    setEmailError('')
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (isFormValid()) {
      onSubmit({ email })
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit} disabled={inProgress}>
      <TextInput
        disabled={inProgress}
        error={emailError}
        fullWidth
        id="email"
        label="Email"
        name="email"
        onBlur={handleEmailBlur}
        onChange={handleEmailChange}
        onFocus={handleEmailFocus}
        placeholder="Your email"
        type="email"
      />
      <SubmitButton type="submit" disabled={!isFormValid() || inProgress}>
        Send the instructions
      </SubmitButton>
    </StyledForm>
  )
}

ForgotPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
}

export default React.memo(ForgotPasswordForm)
