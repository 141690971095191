import api, { ApiResponse } from 'src/utils/sauce/api'
import { IPreferencesFeatureFlags, defaultFeatureFlags, FEATURE_FLAG_KEY } from './FeatureFlags'
import { IPreferencesTidalRateConfig, defaultTidalRateConfig, TIDAL_RATE_CONFIG_KEY } from './TidalRateConfig'
import { IPreferencesVesselFieldDisplayConfig, defaultVesselFieldDisplayConfig, VESSEL_FIELD_DISPLAY_CONFIG_KEY } from './VesselFieldDisplayConfig'

export type IPreferences
  = IPreferencesFeatureFlags
  | IPreferencesTidalRateConfig
  | IPreferencesVesselFieldDisplayConfig

function extendPreferencesWithDefaults(preferences: IPreferences[], portUuid: string): IPreferences[] {

  const featureFlags = preferences.find(
    ({ key }) => key === FEATURE_FLAG_KEY
  ) as IPreferencesFeatureFlags | undefined

  const tidalRateConfig = preferences.find(
    ({ key }) => key === TIDAL_RATE_CONFIG_KEY
  ) as IPreferencesTidalRateConfig | undefined

  const vesselFieldDisplayConfig = preferences.find(
    ({ key }) => key === VESSEL_FIELD_DISPLAY_CONFIG_KEY
  ) as IPreferencesVesselFieldDisplayConfig | undefined

  return [
    featureFlags || defaultFeatureFlags,
    tidalRateConfig || defaultTidalRateConfig,
    vesselFieldDisplayConfig || defaultVesselFieldDisplayConfig,
  ].map(preference =>
    ({
      ...preference,
      port: { ...preference.port, uuid: preference.port ? preference.port.uuid : portUuid }
    })
  )
}

export async function getPreferences(portUuid: string) {
  try {
    const res = await api.get<ApiResponse<IPreferences[]>>(
      `/preferences`, { params: { portUuid }}
    )
    const preferences = res.data && res.data.data
    return preferences && extendPreferencesWithDefaults(preferences, portUuid)

  } catch (error) {
    console.error(error)
    return extendPreferencesWithDefaults([], portUuid)
  }
}

export async function createPreferences(preferences: IPreferences) {
  try {
    const res = await api.post<ApiResponse<IPreferences[]>>(
      '/preferences',
      preferences
    )
    return res.data && res.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function updatePreferences(
  uuid: string,
  preferences: IPreferences
) {
  try {
    const res = await api.put<ApiResponse<IPreferences[] | undefined>>(
      `/preferences/${uuid}`,
      preferences
    )
    return res.data && res.data.data
  } catch (error) {
    return Promise.reject(error)
  }
}
