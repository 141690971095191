import React from 'react'
import PropTypes from 'prop-types'
import { PortShape } from 'src/utils/types'
import PortExtras from 'src/components/organisms/PortExtras'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import SignaturesToggle from 'src/components/organisms/SignaturesToggle'

export const GuidelinesAndTerms = ({ port }) => {
  const portUuid = port && port.uuid
  return (
    <SettingsTemplate title="Guidelines &amp; Terms" port={port}>
      <PortExtras disabled={!portUuid} port={port} />
      {
        !process.env.REACT_APP_ESIGN_DISABLE &&
        <SignaturesToggle />
      }
    </SettingsTemplate>
  )
}

GuidelinesAndTerms.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default GuidelinesAndTerms
