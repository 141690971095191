import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { actions as routerActions } from 'redux-router5'
import { useRoute } from 'react-router5'

import { PortShape } from 'src/utils/types'
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import {
  SETTINGS_PORT,
  SETTINGS_PILOTAGE,
  HOME,
  SETTINGS_SCOPED,
  SETTINGS_CREATE,
  SETTINGS_PORT_IDENTIFICATION,
  SETTINGS_PILOTAGES,
  SETTINGS_VESSELS,
  SETTINGS_LOCATIONS,
  SETTINGS_ENVIRONMENT,
  SETTINGS_FLOW,
  SETTINGS_PASSAGE_PLANNER,
  SETTINGS_CHECKLISTS,
  SETTINGS_GUIDELINES_TERMS,
  SETTINGS_INTEGRATIONS,
} from 'src/router/routes'

import ChecklistsIcon from 'src/components/atoms/Icons/ChecklistsIcon'
import EnvironmentIcon from 'src/components/atoms/Icons/EnvironmentIcon'
import GuideLinesIcon from 'src/components/atoms/Icons/GuideLinesIcon'
import LocationsIcon from 'src/components/atoms/Icons/LocationsIcon'
import PassagePlannerIcon from 'src/components/atoms/Icons/PassagePlannerIcon'
import PilotagesIcon from 'src/components/atoms/Icons/PilotagesIcon'
import VesselsIcon from 'src/components/atoms/Icons/VesselsIcon'
import PortIdentificationIcon from 'src/components/atoms/Icons/PortIdentificationIcon'
import IntegrationsIcon from 'src/components/atoms/Icons/IntegrationsIcon'
import FlowIcon from './../../components/atoms/Icons/FlowIcon'

const navItems = [
  {
    label: 'Port Identification & Tugs',
    routeName: SETTINGS_PORT_IDENTIFICATION,
    adornment: <PortIdentificationIcon />,
  },
  {
    label: 'Locations & Charts',
    routeName: SETTINGS_LOCATIONS,
    adornment: <LocationsIcon />,
  },
  {
    label: 'Environment',
    routeName: SETTINGS_ENVIRONMENT,
    adornment: <EnvironmentIcon />,
  },
  {
    label: 'Flow',
    routeName: SETTINGS_FLOW,
    adornment: <FlowIcon />,
  },
  {
    label: 'Passage Planner',
    routeName: SETTINGS_PASSAGE_PLANNER,
    adornment: <PassagePlannerIcon />,
  },
  {
    label: 'Checklists & Attachments',
    routeName: SETTINGS_CHECKLISTS,
    adornment: <ChecklistsIcon />,
  },
  {
    label: 'Guidelines and Terms',
    routeName: SETTINGS_GUIDELINES_TERMS,
    adornment: <GuideLinesIcon />,
  },
  {
    label: 'Pilotages',
    routeName: SETTINGS_PILOTAGES,
    adornment: <PilotagesIcon />,
  },
  {
    label: 'Vessels',
    routeName: SETTINGS_VESSELS,
    adornment: <VesselsIcon />,
  },
  {
    label: 'Integrations',
    routeName: SETTINGS_INTEGRATIONS,
    adornment: <IntegrationsIcon />,
  },
]

const StyledListItemText = styled(ListItemText)(
  ({ selected, theme }) => `
    && {
        > span {
          color: ${
            selected ? theme.palette.primary.main : theme.palette.text.secondary
          };
          font-weight: ${selected ? '800' : '600'};
        }
      &:hover {
        cursor: pointer;
      }
    }
  `
)

const StyledListItemIcon = styled(ListItemIcon)(
  ({ selected, theme, label }) => `
      && {
        display: flex;
        justify-content: center;
          svg path {
            fill: ${
              selected && label !== 'Passage Planner' && label !== 'Environment'
                ? theme.palette.primary.main
                : label === 'Passage Planner' || label === 'Environment'
                ? null
                : theme.palette.text.secondary
            };
            stroke: ${
              label === 'Environment' || label === 'Passage Planner'
                ? selected
                  ? theme.palette.primary.main
                  : null
                : null
            };
          }
            svg circle {
              fill: ${
                selected
                  ? label === 'Locations & Charts' ||
                    label === 'Passage Planner'
                    ? theme.palette.primary.xlight
                    : theme.palette.primary.main
                  : label === 'Locations & Charts' ||
                    label === 'Passage Planner'
                  ? theme.palette.primary.xlight
                  : theme.palette.text.secondary
              };
          }
          svg line {
            stroke: ${
              selected
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            };
          }
          svg rect {
            stroke: ${
              selected
                ? theme.palette.primary.main
                : theme.palette.text.secondary
            };
          }
      }
  `
)

export const Navigation = ({ port }) => {
  const dispatch = useDispatch()
  const routeInfo = useRoute()
  const { route: { name: routeName } = {} } = routeInfo

  const handleClick = (routeName) => {
    if (port) {
      dispatch(routerActions.navigateTo(routeName, { portUuid: port.uuid }))
    }
  }

  const isSelected = (route) => {
    switch (routeName) {
      case SETTINGS_CREATE:
      case SETTINGS_PORT:
      case HOME:
      case SETTINGS_SCOPED:
      case SETTINGS_PILOTAGE:
      case SETTINGS_PORT_IDENTIFICATION:
        return route === SETTINGS_PORT_IDENTIFICATION
      default:
        return routeName === route
    }
  }

  return (
    <>
      <Grid item md={4} xs={4}>
        <List>
          {navItems.map((item) => (
            <ListItem
              key={item.label}
              onClick={() => handleClick(item.routeName)}
            >
              <StyledListItemIcon
                selected={isSelected(item.routeName)}
                label={item.label}
              >
                {item.adornment}
              </StyledListItemIcon>
              <StyledListItemText selected={isSelected(item.routeName)}>
                {item.label}
              </StyledListItemText>
            </ListItem>
          ))}
        </List>
      </Grid>
    </>
  )
}

Navigation.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Navigation
