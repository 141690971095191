export const UI_NOTIFICATIONS_OPEN = 'UI_NOTIFICATIONS_OPEN'
export const UI_NOTIFICATIONS_CLOSE = 'UI_NOTIFICATIONS_CLOSE'

export const openNotifications = () => ({ type: UI_NOTIFICATIONS_OPEN })
export const closeNotifications = () => ({ type: UI_NOTIFICATIONS_CLOSE })

export const UI_PROGRESS_SET = 'UI_PROGRESS_SET'
export const UI_PROGRESS_CLEAR = 'UI_PROGRESS_CLEAR'
export const setProgress = (payload) => ({ type: UI_PROGRESS_SET, payload })
export const clearProgress = () => ({
  type: UI_PROGRESS_CLEAR,
})
