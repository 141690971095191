import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getTideStations = (config) =>
  api
    .get(`/tide_stations`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getTideStation = (uuid, config) =>
  api
    .get(`/tide_stations/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createTideStation = (tideStation, config) => {
  const { file, ...body } = tideStation
  return api.post('/tide_stations', body, config).then(
    // POST the tide_station data
    getSinglePayloadFromResponse,
    (error) => Promise.reject(error)
  )
}

export const updateTideStation = (tideStation, config) => {
  const { file, ...body } = tideStation
  return api.put(`/tide_stations/${tideStation.uuid}`, body, config).then(
    // PUT the tide_station data
    getSinglePayloadFromResponse,
    (error) => Promise.reject(error)
  )
}

export const deleteTideStation = (uuid, config) =>
  api
    .delete(`/tide_stations/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  create: createTideStation,
  update: updateTideStation,
  delete: deleteTideStation,
}
