import styled from 'styled-components'

import { Button } from '@material-ui/core'

export const NarrowButton = styled(Button)`
  && {
    min-height: 42px;
  }
`

export default NarrowButton
