import api, {
  getSinglePayloadFromResponse,
  getPayloadFromResponse,
} from 'src/utils/sauce/api'

/**
 * @description function to get all users
 * @param {Object} config - configuration object
 * must include `baseUrl`, `sauceVersion` and `headers`.
 * @param {string} queryParams - can be used to apply pagination,
 * filter and order.
 * @return {Promise}
 */
export async function getUsers(queryParams) {
  try {
    return api
      .get(`/users${queryParams || ''}`)
      .then(
        (response) => getPayloadFromResponse(response),
        (error) => Promise.reject(error)
      )
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function getUserWithUUID(uuid) {
  try {
    if (!uuid) {
      throw new Error('The UUID parameter is required.')
    }
    return api.get(`/users/${uuid}`)
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * @description function to sign in user
 * @param {Object} config - configuration object
 * must include `baseUrl` and `sauceVersion`.
 * Parameter `headers` is optional.
 * @param {Object} data - request body
 * @return {Promise}
 */
export function loginUser(data, config) {
  const { email, password } = data || {}
  if (!email || !password) {
    return Promise.reject(new Error('Missing credentials.'))
  }
  return api
    .post(
      `/users/login`,
      {
        isAdmin: data.isAdmin,
        password: data.password,
        contact: {
          contactType: 'email',
          value: data.email.toLowerCase(),
        },
      },
      config
    )
    .then(getSinglePayloadFromResponse)
}

export const loginUserSso = (token, redirectUrl) => {
  return api.post(`/sso/loginsso`, {
    redirectUrl,
  },
  {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

export function acceptInvitation(data, config) {
  const { password, token } = data || {}
  if (!password) {
    return Promise.reject(new Error('The password is missing.'))
  }
  if (!token) {
    return Promise.reject(new Error('The token is missing.'))
  }
  return api
    .post(`/users/signup/${token}`, { password }, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

/**
 * @description function to sign out user
 * @param {Object} config - configuration object
 * must include `baseUrl`, `sauceVersion` and `headers`.
 * @return {Promise}
 */
export async function logoutUser(config) {
  return api
    .post(`/users/logout`, null, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

/**
 * @description function to refresh user's session
 * @param {Object} config - configuration object
 * must include `baseUrl`, `sauceVersion` and `headers`.
 * @param {string} refreshToken
 * @return {Promise}
 */
export const refreshSession = ({ token, refreshToken }, config = {}) => {
  if (!refreshToken) {
    return Promise.reject(new Error("Parameter 'refreshToken' is required"))
  }
  if (!token) {
    return Promise.reject(new Error("Parameter 'token' is required"))
  }
  return api
    .post(
      `/users/refresh`,
      { refreshToken },
      {
        ...config,
        headers: {
          ...(config.headers || {}),
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

/**
 * @description function to request new password
 * @param {Object} config - configuration object (`cancelToken` is optional).
 * @param {string} email - user email
 * @return {Promise}
 */
export async function requestNewPassword(payload, config) {
  try {
    if (!payload) {
      throw new Error("The payload with parameter 'email' is required")
    }
    if (!payload.email) {
      throw new Error("Parameter 'email' is required")
    }
    return api
      .post(`/users/password`, payload, config)
      .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * @description function to reset password
 * @param {Object} config - configuration object
 * must include `baseUrl` and `sauceVersion`.
 * Parameter `headers` is optional.
 * @param {string} data - request payload.
 * Must include `token` and `passwordHash`
 * @return {Promise}
 */
export async function resetPassword(data, config) {
  try {
    if (!data) {
      throw new Error(
        'Reset password function needs token and the new password'
      )
    }
    if (!data.token) {
      throw new Error('Reset password function needs token')
    }
    if (!data.passwordHash) {
      throw new Error('Reset password function needs the new password')
    }
    return api
      .put(`/users/password/reset`, data, config)
      .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * @description function to update password
 * @param {Object} config - configuration object
 * @param {string} data - request payload.
 * Must include `password` and `newPassword`
 * @return {Promise}
 */
export async function updatePassword(data, config) {
  try {
    if (!data) {
      return Promise.reject(
        new Error("Parameters 'password' and 'newPassword' are required")
      )
    }
    if (!data.password) {
      return Promise.reject(new Error("Parameter 'password' is required"))
    }
    if (!data.newPassword) {
      return Promise.reject(new Error("Parameter 'newPassword' is required"))
    }
    return api
      .put(`/users/password`, data, config)
      .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
  } catch (error) {
    return Promise.reject(error)
  }
}

export async function inviteUser(data, config) {
  try {
    if (!data) {
      return Promise.reject(
        new Error('Parameters email, first name and last name are required')
      )
    }
    if (!data.email) {
      return Promise.reject(new Error("Parameter 'email' is required"))
    }
    if (!data.metadata) {
      return Promise.reject(new Error("Parameter 'metadata' is required"))
    }
    if (!data.metadata.firstName) {
      return Promise.reject(new Error("Parameter 'first name' is required"))
    }
    if (!data.metadata.lastName) {
      return Promise.reject(new Error("Parameter 'last name' is required"))
    }
    return api
      .post(`/users/invites`, data, config)
      .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
  } catch (error) {
    return Promise.reject(error)
  }
}

export const updateUser = (user, config) =>
  api
    .put(`/users/${user.uuid}`, user, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const updateUserPorts = ({ uuid, ports }) =>
  api
    .put(`/users/${uuid}/ports`, { ports })
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const deleteUser = (uuid, config) =>
  api
    .delete(`/users/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  getUsers,
  acceptInvitation,
  loginUser,
  logoutUser,
  refreshSession,
  requestNewPassword,
  resetPassword,
  updatePassword,
  updateUser,
  updateUserPorts
}
