import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import { TextInput } from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import { InvitationShape } from 'src/utils/types'
import { validateValues } from 'src/utils/validation'
import Autocomplete from '@material-ui/lab/Autocomplete'

export const EMPTY_INVITATION = {
  firstName: '',
  lastName: '',
  email: '',
  isAdmin: true,
}

const transformValues = ({ uuid, email, isAdmin, ...metadata }) => ({
  uuid,
  email,
  isAdmin,
  metadata,
})

export const InvitationForm = ({
  submitting,
  disabled,
  invitation,
  onChange,
  advisory,
  emailOptions
}) => {

  const validateInvite = (values) =>
      validateValues(values, {
        firstName: ['required', 'humanName'],
        lastName: ['required', 'humanName'],
        email: ['required', 'email', () => advisoryResult.error],
        isAdmin: 'required',
      })

  const { metadata, ...values } = invitation

  values.firstName = (metadata && metadata.firstName) || ''
  values.lastName = (metadata && metadata.lastName) || ''

  const { fields } = useForm({
    initialValues: values,
    validationHandler: validateInvite,
    changeHandler: onChange,
    transformHandler: transformValues,
  })
  
  const advisoryResult = useMemo(() =>
    advisory ? advisory({ email: fields.email.value }) : {},
    [fields.email.value]
  )

  useEffect(() => {
    if (advisoryResult.message) {
      fields.firstName.onChange('firstName', advisoryResult.firstName || '')
      fields.lastName.onChange('lastName', advisoryResult.lastName || '')
    } else {
      fields.firstName.onChange('firstName', (metadata && metadata.firstName) || '')
      fields.lastName.onChange('lastName', (metadata && metadata.lastName) || '')
    }
  }, [advisoryResult])

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Autocomplete
          id="email-autocomplete"
          freeSolo
          inputValue={fields.email.value}
          onInputChange={(event, newValue) => {
            fields.email.onChange('email', newValue)
          }}
          options={emailOptions || []}
          getOptionLabel={(email) => email}
          renderInput={(params) => {
            return (
              <TextInput
                fullWidth
                label="Email"
                name="email"
                disabled={disabled || submitting}
                {...params}
                error={fields.email.error}
              />
            )
          }}
        />
      </Grid>
      {
        advisoryResult.message &&
        <Grid item xs={12}>{advisoryResult.message}</Grid>
      }
      <Grid item xs={6}>
        <TextInput
          fullWidth
          label="First name"
          name="firstName"
          disabled={disabled || submitting || !!advisoryResult.firstName}
          {...fields.firstName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          fullWidth
          label="Last name"
          name="lastName"
          disabled={disabled || submitting || !!advisoryResult.lastName}
          {...fields.lastName}
        />
      </Grid>
    </Grid>
  )
}
InvitationForm.propTypes = {
  invitation: PropTypes.shape(InvitationShape),
  onChange: PropTypes.func,
  advisory: PropTypes.func,
  emailOptions: PropTypes.arrayOf(PropTypes.string),
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
}
export default InvitationForm
