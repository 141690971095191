import { createSelector } from 'reselect'

export const ssoStateSelector = (state) => state.sso

export const ssoStatusSelector = createSelector(
  ssoStateSelector,
  (state) => state && state.status
)

export const ssoDataSelector = createSelector(
  ssoStateSelector,
  (state) => state && state.data
)
