import React from 'react'

const EnvironmentIcon = () => (
  <svg
    width="20"
    height="11"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 0.6L2.55967 1.84774C3.35976 2.48781 4.52489 2.37013 5.18083 1.583V1.583C5.88922 0.732931 7.17463 0.674625 7.95707 1.45707L8.11435 1.61435C8.88416 2.38416 10.1283 2.39685 10.9136 1.64291L12 0.6"
      stroke="#B0B0B1"
    />
    <path
      d="M1 4.6L2.55967 5.84774C3.35976 6.48781 4.52489 6.37013 5.18083 5.583V5.583C5.88922 4.73293 7.17463 4.67463 7.95707 5.45707L8.11435 5.61435C8.88416 6.38416 10.1283 6.39685 10.9136 5.64291L12 4.6"
      stroke="#B0B0B1"
    />
  </svg>
)

export default EnvironmentIcon
