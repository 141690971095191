import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { Input } from '@material-ui/core'

import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import TextInput from 'src/components/atoms/TextInput'
import Fieldset from 'src/components/organisms/Fieldset'
import { PortShape } from 'src/utils/types'
import { countDecimals } from 'src/utils/formatters'
import TimeZoneInput from 'src/components/atoms/TimeZoneInput/TimeZoneInput'
import { validateEmail } from './../../../utils/validateEmail';

const validateForm = (values) =>
  validateValues(values, {
    name: ['required', 'name'],
    locationName: 'required',
    locationLatitude: [
      'required',
      { name: 'min', params: [-90] },
      { name: 'max', params: [90] },
    ],
    locationLongitude: [
      'required',
      { name: 'min', params: [-180] },
      { name: 'max', params: [180] },
    ],
    timezone: 'required',
    commonEmails: (
      value,
    ) => {
      if (!value) {
        return null
      }
      const emailArr = value.split(',')
      const isValidEmail = (value) => validateEmail(value)
      for (let i = 0; i < emailArr.length; i += 1) {
        const email = emailArr[i]
        if (!isValidEmail(email)) {
          return 'Please enter a valid email address or list of comma separated email addresses'
        }
      }
      return null
    },
    replyToEmail: (
      value,
    ) => {
      if (!value) {
        return null
      }
      if (!validateEmail(value)) {
        return 'Please enter a valid email address'
      }
      return null
    },
  })

const transformSubmitValues = ({
  uuid,
  name,
  locationName,
  locationLatitude,
  locationLongitude,
  timezone,
  commonEmails,
  replyToEmail,
}) => ({
  uuid,
  name,
  locationName,
  location: {
    latitude: Number(locationLatitude),
    longitude: Number(locationLongitude),
  },
  timezone,
  commonEmails: commonEmails ? commonEmails.split(',') : null,
  replyToEmail,
})

const formatLatOrLong = (value) => {
  if (!value) {
    return ''
  }
  if (countDecimals(value) > 6) {
    return Number(value)
  }
  return Number(value).toFixed(6)
}

const replyToLongTooltip =
  "The email address entered here will be shown as the reply to address sent to the master in the pilotage plan email."

const latLongTooltip =
  "Enter the latitude and the longitude of the location of the port. You can find the values on Google Maps by a right-click on the location on a map, then select the menu option: 'What's here?'. The Lat. & Long. of the selected location will be displayed at the bottom of your screen."

const LatLongField = styled(TextInput)`
  && {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export const PortForm = ({ port, onChange, disabled, inModal }) => {
  const { name = '', replyToEmail = '', commonEmails = '', locationName = '', location = {}, uuid } = port || {}
  const { dirty, fields } = useForm({
    initialValues: {
      uuid,
      name,
      locationName,
      locationLatitude: formatLatOrLong(location.latitude),
      locationLongitude: formatLatOrLong(location.longitude),
      timezone: (port && port.timezone) || '',
      commonEmails: commonEmails ? commonEmails.join(',') : '',
      replyToEmail,
    },
    validationHandler: validateForm,
    transformHandler: transformSubmitValues,
    changeHandler: onChange,
  })

  const blurHandler = (event) => {
    const { name } = event.target
    const value = fields[name].value
    const formattedValue = formatLatOrLong(value)
    if (value !== formattedValue) {
      fields[name].onChange(name, formattedValue)
    }
    fields[name].onBlur(event)
  }

  return (
    <Grid container spacing={1}>
      <Fieldset
        item
        title={!inModal && "Port identification"}
        description="Set the name and the location of your port"
        dirty={dirty}
        fullWidth={inModal}
      >
        <TextInput
          id="port-name"
          name="name"
          label="Port name"
          fullWidth
          disabled={disabled}
          {...fields.name}
        />
        <TextInput
          id="port-location-name"
          name="locationName"
          label="Port location"
          fullWidth
          disabled={disabled}
          {...fields.locationName}
        />
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <LatLongField
              id="port-location-latitude"
              name="locationLatitude"
              label="Latitude"
              infoTooltip={latLongTooltip}
              disabled={disabled}
              {...fields.locationLatitude}
              onBlur={blurHandler}
              fullWidth
              type="number"
              step="0.01"
            />
          </Grid>
          <Grid item xs={6}>
            <LatLongField
              id="port-location-longitude"
              name="locationLongitude"
              label="Longitude"
              infoTooltip={latLongTooltip}
              disabled={disabled}
              {...fields.locationLongitude}
              fullWidth
              type="number"
              step="0.01"
              onBlur={blurHandler}
            />
          </Grid>
        </Grid>
        <TimeZoneInput
          disabled={disabled}
          name="timezone"
          id="port-timezone"
          {...fields.timezone}
        />
        <TextInput
          id="port-common-emails"
          name="commonEmails"
          label="Common Emails"
          fullWidth
          disabled={disabled}
          {...fields.commonEmails}
        />
        <TextInput
          id="port-replyto-email"
          name="replyToEmail"
          label="Reply-To Email"
          fullWidth
          infoTooltip={replyToLongTooltip}
          disabled={disabled}
          {...fields.replyToEmail}
        />
        <Input disableUnderline type="hidden" name="uuid" defaultValue={uuid} />
      </Fieldset>
    </Grid>
  )
}
PortForm.propTypes = {
  port: PropTypes.shape(PortShape),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  inModal: PropTypes.bool
}

export default PortForm
