import { findTimeZone, getUTCOffset } from 'timezone-support'

function formatTimezone(tz) {
  const { offset } = getUTCOffset(new Date(), findTimeZone(tz))
  const offsetHours = Math.abs(Math.trunc(-offset / 60))
  const offsetMinutes = Math.abs(offset % 60)
  const offsetHoursFormatted = String(offsetHours).padStart(2, '0')
  const offsetMinutesFormatted = String(offsetMinutes).padStart(2, '0')

  const offsetStr = `UTC${
    offset > 0 ? '-' : '+'
  }${offsetHoursFormatted}:${offsetMinutesFormatted}`
  return {
    label: `${tz} (${offsetStr})`,
    value: tz,
    offset,
  }
}

export default formatTimezone
