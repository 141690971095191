import isObject from 'src/utils/isObject'

export const TOAST_MESSAGES = {
  PORT_CREATE_SUCCESS: 'Port created: `{{name}}`',
  PORT_CREATE_ERROR: 'Error creating port: `{{name}}`',

  PORT_UPDATE_SUCCESS: 'Port updated: `{{name}}`',
  PORT_UPDATE_ERROR: 'Error updating port: `{{name}}`',

  CHART_CREATE_ERROR: 'Error creating chart: `{{name}}`.',
  CHART_CREATE_SUCCESS: 'Chart has been created: `{{name}}`.',
  CHART_UPDATE_ERROR: 'Error updating chart: `{{name}}`.',
  CHART_UPDATE_SUCCESS: 'Chart has been updated: `{{name}}`.',

  TIDE_DATA_CREATE_ERROR:
    'Error processing tide data for `{{tideStation.name}}`.',
  TIDE_DATA_SERVER_ERROR: 'Server error creating tide data',
  TIDE_DATA_CSV_ERROR: 'Error with tide data CSV file',
  TIDE_DATA_FORBIDDEN_ERROR: 'You are not permitted to create tide data',
  TIDE_DATA_CREATE_SUCCESS: 'Tide data updated for `{{name}}`.',
  TIDE_STATION_CREATE_SUCCESS: 'Tide station created: `{{name}}`',
  TIDE_STATION_CREATE_ERROR: 'Error creating tide station: `{{name}}`',
  TIDE_STATION_UPDATE_SUCCESS: 'Tide station updated: `{{name}}`',
  TIDE_STATION_UPDATE_ERROR: 'Error updating tide station: `{{name}}`',

  TIDE_RATE_DATA_CREATE_ERROR:
    'Error processing tide rate data for `{{tideStation.name}}`.',
  TIDE_RATE_DATA_SERVER_ERROR: 'Server error creating tide rate data',
  TIDE_RATE_DATA_CSV_ERROR: 'Error with tide rate data CSV file',
  TIDE_RATE_DATA_FORBIDDEN_ERROR: 'You are not permitted to create tide rate data',
  TIDE_RATE_DATA_CREATE_SUCCESS: 'Tide rate data updated for `{{name}}`.',
  TIDE_RATE_STATION_CREATE_SUCCESS: 'Tide rate station created: `{{name}}`',
  TIDE_RATE_STATION_CREATE_ERROR: 'Error creating tide rate station: `{{name}}`',
  TIDE_RATE_STATION_UPDATE_SUCCESS: 'Tide rate station updated: `{{name}}`',
  TIDE_RATE_STATION_UPDATE_ERROR: 'Error updating tide rate station: `{{name}}`',

  CONSTRAINT_CREATE_SUCCESS: 'Constraint created: `{{name}}`.',
  CONSTRAINT_CREATE_ERROR: 'Error creating constraint: `{{name}}`.',
  CONSTRAINT_UPDATE_SUCCESS: 'Constraint updated: `{{name}}`.',
  CONSTRAINT_UPDATE_ERROR: 'Error updating constraint: `{{name}}`.',

  CONSTRAINT_DELETE_SUCCESS: 'Constraint deleted: `{{name}}`.',
  CONSTRAINT_DELETE_ERROR: 'Error deleting constraint: `{{name}}`.',

  WAYPOINT_CREATE_SUCCESS: 'Waypoint created: `{{name}}`.',
  WAYPOINT_CREATE_ERROR: 'Error creating waypoint: `{{name}}`.',
  WAYPOINT_UPDATE_SUCCESS: 'Waypoint updated: `{{name}}`.',
  WAYPOINT_UPDATE_ERROR: 'Error updating waypoint: `{{name}}`.',

  WAYPOINT_DELETE_SUCCESS: 'Waypoint deleted: `{{name}}`.',
  WAYPOINT_DELETE_ERROR: 'Error deleting waypoint: `{{name}}`.',

  ROUTE_ATTACHMENT_CREATE_SUCCESS: 'Attachment created: `{{name}}`.',
  ROUTE_ATTACHMENT_CREATE_ERROR: 'Error creating attachment: `{{name}}`.',
  ROUTE_ATTACHMENT_UPDATE_SUCCESS: 'Attachment updated: `{{name}}`.',
  ROUTE_ATTACHMENT_UPDATE_ERROR: 'Error updating attachment: `{{name}}`.',

  ROUTE_ATTACHMENT_DELETE_SUCCESS: 'Attachment deleted: `{{name}}`.',
  ROUTE_ATTACHMENT_DELETE_ERROR: 'Error deleting attachment: `{{name}}`.',

  SSO_GET_ERROR: 'Error loading SSO config.',
  SSO_CREATE_SUCCESS: 'SSO config created.',
  SSO_CREATE_ERROR: 'Error creating SSO config.',
  SSO_UPDATE_SUCCESS: 'SSO config updated.',
  SSO_UPDATE_ERROR: 'Error updating SSO config.',

  COPIED_TO_CLIPBOARD: 'Copied to clipboard.',

  TUG_CREATE_SUCCESS: 'Tug created: `{{name}}`.',
  TUG_CREATE_ERROR: 'Error creating tug: `{{name}}`.',
  TUG_UPDATE_SUCCESS: 'Tug updated: `{{name}}`.',
  TUG_UPDATE_ERROR: 'Error updating tug: `{{name}}`.',
  TUG_DELETE_SUCCESS: 'Tug deleted: `{{name}}`.',
  TUG_DELETE_ERROR: 'Error deleting tug: `{{name}}`.',
  AUTH_ACCEPT_INVITATION_SUCCESS:
    'Account successfully created. Please login to continue',
  AUTH_ACCEPT_INVITATION_ERROR: 'Error creating your account.',
  AUTH_ACCEPT_INVITATION_ERROR_409:
    'The user for this token has already signed up',
  AUTH_REFRESH_SESSION_ERROR: 'Session expired.',
  INVITATION_CREATE_SUCCESS:
    'Invitation sent to {{firstName}} {{lastName}} ({{email}})',
  INVITATION_CREATE_ERROR:
    'Error sending invitation to {{firstName}} {{lastName}} ({{email}})',
  INVITATION_CREATE_ERROR_409:
    'The given pilot ID already exists: {{externalId}}',
  INVITATION_UPDATE_SUCCESS: 'Pilot details have been updated',
  INVITATION_UPDATE_ERROR: 'Error updating pilot',
  INVITATION_UPDATE_ERROR_409:
    'The given pilot ID already exists: {{externalId}}',
  INVITATION_DELETE_SUCCESS:
    'Invitation for {{metadata.firstName}} {{metadata.lastName}} ({{email}}) deleted',
  INVITATION_DELETE_ERROR:
    'Error deleting invitation: {{metadata.firstName}} {{metadata.lastName}} ({{email}})',
  USER_DELETE_SUCCESS: 'User deleted: {{firstName}} {{lastName}} ({{email}})',
  USER_DELETE_ERROR:
    'Error deleting user: {{firstName}} {{lastName}} ({{email}})',
  RESET_PASSWORD_SUCCESS: 'Your password has been reset successfully',
  RESET_PASSWORD_ERROR:
    'An error occurred while we tried to reset the password',
  RESET_PASSWORD_ERROR_401: 'This link is no longer valid',
  STATIONS_CREATE_SUCCESS: '{{type}} created',
  STATIONS_CREATE_ERROR: 'Error creating {{type}}',
  STATIONS_UPDATE_SUCCESS: '{{type}} updated',
  STATIONS_UPDATE_ERROR: 'Error updating {{type}}',
  STATIONS_DELETE_SUCCESS: '{{type}} deleted',
  STATIONS_DELETE_ERROR: 'Error deleting {{type}}',
  WEATHERCONFIG_UPDATE_SUCCESS:
    'Successfully updated Weather Config to {{type}}',
  WEATHERCONFIG_UPDATE_ERROR: `Error updating weather config for {{type}}`,
  WEATHERCONFIG_CREATE_SUCCESS: `Success creating weather config for {{type}}`,
  WEATHERCONFIG_CREATE_ERROR: `Error creating weather config for {{type}}`,

  WEATHER_LOCATION_DELETE_SUCCESS: `Weather location deleted: {{name}}`,
  WEATHER_LOCATION_DELETE_ERROR: `Error deleting weather location: {{name}}`,

  IMPORT_SUCCESS: `File {{fileName}} imported`,
  IMPORT_ERROR: `Error importing file {{fileName}}`,
  DOWNLOAD_SUCCESS: `Pilotages downloaded`,
  DOWNLOAD_ERROR: `Error downloading pilotages`,
  DOWNLOAD_PDF_SUCCESS: `Pilotage PDF downloaded`,
  DOWNLOAD_PDF_ERROR: `Error downloading pilotage PDF`,
  LOAD_SSO_LOAD_ERROR: 'Failed to initialise page',
  LOAD_SSO_LOGOUT_ERROR: 'Failed to sign out',
  LOAD_SSO_IDP_ERROR: 'Failed to sign into identity provider',
  LOAD_SSO_TOKEN_ERROR: 'Failed to get token to sign into eMPX',
}

export const getToastMessage = (str, obj, prefix = '') =>
  Object.keys(obj).reduce((msg, key) => {
    const value = obj[key]
    const canReplace = /number|string/.test(typeof value)
    return canReplace
      ? msg.replace(`{{${prefix}${key}}}`, value || '')
      : isObject(value)
      ? getToastMessage(msg, value, `${prefix}${prefix ? '.' : ''}${key}.`)
      : msg
  }, str)

export default TOAST_MESSAGES
