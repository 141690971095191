import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
  Tooltip,
  LinearProgress,
} from '@material-ui/core'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import CheckIcon from '@material-ui/icons/CheckCircle'

import { InvitationShape } from 'src/utils/types'
import RemoveButton from 'src/components/atoms/RemoveButton'

const VerificationIcon = ({ verified, ...props }) => {
  return (
    <>
      <Tooltip title={verified ? 'Registered' : 'Pending'}>
        {verified ? (
          <CheckIcon color="primary" {...props} />
        ) : (
          <HelpOutlineIcon color="disabled" {...props} />
        )}
      </Tooltip>
    </>
  )
}
VerificationIcon.propTypes = {
  verified: PropTypes.bool,
}

const StyledListItem = styled(ListItem)`
  padding: ${({ theme }) => theme.spacing(1, 1, 1, 1)};
  .MuiListItem-secondaryAction {
    padding-right: 0;
  }
`

const InvitationListItem = ({ invitation, enableResetRemove, onClick, onDelete, onReset }) => {
  const { user, verified, metadata, email, uuid } = invitation
  const { firstName, lastName } = (user && user.metadata) || metadata || {}
  return (
    <StyledListItem button key={uuid} onClick={() => onClick(invitation)}>
      <ListItemIcon>
        <VerificationIcon verified={verified} />
      </ListItemIcon>
      <ListItemText primary={`${firstName} ${lastName}`} secondary={email} />
      {enableResetRemove && (
        <ListItemSecondaryAction>
          <Tooltip title={verified ? 'Reset password' : 'Resend invitation'}>
            <ResetButton onClick={onReset} />
          </Tooltip>
          <Tooltip title={verified ? 'Remove admin' : 'Remove invitation'}>
            <RemoveButton onClick={onDelete} />
          </Tooltip>
        </ListItemSecondaryAction>
      )}
    </StyledListItem>
  )
}
InvitationListItem.propTypes = {
  invitation: PropTypes.shape(InvitationShape),
  enableResetRemove: PropTypes.bool,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onReset: PropTypes.func,
}

// eslint-disable-next-line react/display-name
const ResetButton = React.forwardRef((props, ref) => (
  <IconButton ref={ref} {...props}>
    <RotateLeftIcon />
  </IconButton>
))
ResetButton.propTypes = {}

const Loading = styled(LinearProgress)`
  && {
    height: 2px;
  }
`

export const InvitationList = ({
  list,
  enableResetRemove,
  onClick,
  onDelete,
  onReset,
  className,
  isLoading,
  sortBy = 'created',
}) => {
  if (sortBy) {
    list.sort((i1, i2) =>
      i1[sortBy] < i2[sortBy] ? -1 : i1[sortBy] > i2[sortBy] ? 1 : 0
    )
  }
  return (
    <List className={className}>
      {isLoading && <Loading variant="indeterminate" />}
      {list.map((invitation) => (
        <InvitationListItem
          key={invitation.uuid}
          invitation={invitation}
          enableResetRemove={enableResetRemove}
          onClick={() => onClick(invitation)}
          onDelete={() => onDelete(invitation)}
          onReset={() => onReset(invitation)}
        />
      ))}
    </List>
  )
}
InvitationList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape(InvitationShape)),
  enableResetRemove: PropTypes.bool,
  isLoading: PropTypes.bool,
  sortBy: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  onReset: PropTypes.func,
}

export default InvitationList
