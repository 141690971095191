import { createSelector } from 'reselect'

export const pilotagesExportStateSelector = state => state.pilotagesExport

export const pilotagesExportIsLoadingSelector = createSelector(
  pilotagesExportStateSelector,
  state => state && state.isLoading
)

export const pilotagesExportErrorSelector = createSelector(
  pilotagesExportStateSelector,
  state => state && state.error
)

export const pilotagesExportSelector = createSelector(
  pilotagesExportStateSelector,
  state => state && state.data
)
