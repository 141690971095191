import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { WeatherLocation, WeatherLocationFlat } from 'src/models/weatherLocations'
import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import { TextInput } from 'src/components/atoms/TextInput'
import { formatLatOrLong } from 'src/utils/formatters'
import { getListFilteredBySelectedPort } from 'src/store/constraint/selectors'
import SelectInput from 'src/components/atoms/SelectInput'
import InfoIcon from 'src/components/atoms/Icons/InfoIcon'
import { Tooltip } from '@material-ui/core'

const StyledInfoIcon = styled(InfoIcon)`
  && {
    display: block;
  }
`

const latLongTooltip =
  "Enter the latitude and the longitude of the weather location. You can find the values on Google Maps by a right-click on the location on a map, then select the menu option: 'What's here?'. The Lat. & Long. of the selected location will be displayed at the bottom of your screen."

const LatLongField = styled(TextInput)`
  && {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

const constraintTooltip =
    "Constraints are used to show the forecast for the time at constraint on pilot app."

export interface WeatherLocationFormProps {
    onChange?: Function
    onSubmit?: Function
    disabled?: boolean
    submitting?: boolean
    weatherLocation: WeatherLocation
}

export const WeatherLocationForm: React.FC<WeatherLocationFormProps> = ({
    weatherLocation,
    onChange,
    onSubmit,
    disabled
}) => {

    const constraints = useSelector(getListFilteredBySelectedPort)

    const validateForm = (values: any) => {
        return validateValues(values, {
            name: ['required', 'name'],
            latitude: [
                'required',
                { name: 'min', params: [-90] },
                { name: 'max', params: [90] },
            ],
            longitude: [
                'required',
                { name: 'min', params: [-180] },
                { name: 'max', params: [180] },
            ]
        })
    }

    const handleOnChange = (...args: any) => {
        if (onChange) {
            onChange(...args)
        }
    }

    const transformSubmitValues = ({
        name,
        latitude,
        longitude,
        constraintUuids,
    }: WeatherLocationFlat) => ({
        uuid: weatherLocation.uuid,
        port: weatherLocation.port,
        name,
        location: {
            latitude: Number(latitude),
            longitude: Number(longitude),
        },
        weatherLocationConstraints: constraintUuids.map((uuid: string) => {
            const existing = (weatherLocation.weatherLocationConstraints || []).find(weatherLocationConstraint => 
                weatherLocationConstraint.constraint.uuid === uuid
            )
            return existing || { constraint: { uuid } }
        })
    })

    const { fields }: { fields: { [k: string]: any }; isValid: boolean } = useForm({
        initialValues: {
            name: weatherLocation.name,
            latitude: weatherLocation.location.latitude,
            longitude: weatherLocation.location.longitude,
            constraintUuids: (weatherLocation.weatherLocationConstraints || []).map(({ constraint }) => constraint.uuid)
        },
        validationHandler: validateForm,
        changeHandler: handleOnChange,
        transformHandler: transformSubmitValues,
        submitHandler: onSubmit,
    })

    const blurHandler = (event: any) => {
        const { name } = event.target
        const value = fields[name].value
        const formattedValue = formatLatOrLong(value)
        if (value !== formattedValue) {
            fields[name].onChange(name, formattedValue)
        }
        fields[name].onBlur(event)
    }

    const constraintOptions: Array<{ label: string, value: string }> = (constraints || [])
        .map(({ name, uuid }: any) => ({ label: name, value: uuid }))
        .sort((a: any, b: any) => {
            const A = a.label.toLowerCase()
            const B = b.label.toLowerCase()
            return A < B ? -1 : A > B ? 1 : 0
        })

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TextInput
                        fullWidth
                        narrow
                        name="name"
                        label="Name"
                        {...fields.name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <LatLongField
                        name="latitude"
                        label="Latitude"
                        infoTooltip={latLongTooltip}
                        disabled={disabled}
                        {...fields.latitude}
                        onBlur={blurHandler}
                        fullWidth
                        narrow
                        type="number"
                        step="0.01"
                    />
                </Grid>
                <Grid item xs={6}>
                    <LatLongField
                        name="longitude"
                        label="Longitude"
                        infoTooltip={latLongTooltip}
                        disabled={disabled}
                        {...fields.longitude}
                        fullWidth
                        narrow
                        type="number"
                        step="0.01"
                        onBlur={blurHandler}
                    />
                </Grid>
                {
                    constraintOptions.length > 0 &&
                    <Grid item xs={12}>
                        <SelectInput
                            fullWidth
                            narrow
                            name="constraintUuids"
                            label={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ paddingRight: 6 }}>Associated Constraints</span>
                                    <Tooltip title={constraintTooltip}>
                                        <div>
                                            <StyledInfoIcon />
                                        </div>
                                    </Tooltip>
                                </div>
                            }
                            disabled={disabled}
                            infotooltip={constraintTooltip}
                            multiple
                            {...fields.constraintUuids}
                            options={constraintOptions}
                            renderValue={(selected: string[]) => {
                                return selected
                                    .map(key => {
                                        const option = constraintOptions.find(({ value }) => value === key)
                                        return option ? option.label : ''
                                    })
                                    .filter(s => s)
                                    .join(', ')
                            }}
                            MenuProps={
                                { 
                                    variant: "menu",
                                    getContentAnchorEl: null 
                                }
                            }
                        />
                        <sub>(Multiple constraints may be selected)</sub>
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default WeatherLocationForm
