import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import { Typography, Grid, TablePagination, Box } from '@material-ui/core'

import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import {
  getFilteredListByPort,
  getListFilteredBySelectedPort,
  routeListSelector,
} from 'src/store/route/selectors'
import { getRouteList, deleteRoute } from 'src/store/route/actions'
import { getChartList } from 'src/store/chart/actions'

import { RouteShape, PortShape } from 'src/utils/types'
import RouteListItem from 'src/components/molecules/RouteListItem'
import RouteFormDialog from 'src/components/organisms/RouteFormDialog'
import { RemoveButton } from 'src/components/atoms/RemoveButton'
import { CopyButton } from 'src/components/atoms/CopyButton'
import Fieldset from 'src/components/organisms/Fieldset'
import Preloader from 'src/components/atoms/Preloader'
import { compareName } from 'src/utils/sorting'
import { PAGINATION_ROWS_PER_PAGE_OPTIONS } from 'src/constants/settings'

const RouteItemContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.background.paper};
  box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(1, 3, 3, 1)};
  position: relative;
`

const RouteItemTitle = styled(Typography)`
  text-transform: none;
`

const TopRightRemoveButton = styled(RemoveButton).attrs({
  size: 'small',
})`
  && {
    position: absolute;
    top: -${({ theme: { spacing } }) => spacing(1)}px;
    right: -${({ theme: { spacing } }) => spacing(1)}px;
  }
`
const TopRightCopyButton = styled(CopyButton).attrs({
  size: 'small',
})`
  && {
    position: absolute;
    top: -${({ theme: { spacing } }) => spacing(1)}px;
    right: ${({ theme: { spacing } }) => spacing(4)}px;
  }
`
const ButtonContents = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
`

const ButtonTitle = styled(Typography).attrs({ variant: 'h5' })`
  && {
    text-transform: none;
    font-weight: 800;
  }
`
const ButtonSubText = styled(Typography).attrs({ variant: 'h6' })``

const RouteItem = ({ onEdit, onCopy, onRemove, ...route }) => {
  const { name, stages } = route
  const handleCopy = (event) => {
    event.stopPropagation()
    if (onCopy) {
      onCopy(route)
    }
  }
  const handleClicks = () => onEdit(route)
  const handleRemove = (event) => {
    event.stopPropagation()
    if (onRemove) {
      onRemove(route)
    }
  }

  return (
    <RouteItemContainer onClick={handleClicks}>
      <RouteItemTitle variant="h4" gutterBottom>
        {name}
      </RouteItemTitle>
      <Grid container spacing={1}>
        {(stages || [])
          .sort((s1, s2) =>
            s1.order > s2.order ? 1 : s1.order < s2.order ? -1 : 0
          )
          .map(({ chart, uuid, name }) => (
            <Grid key={uuid} item sm={6} md={3}>
              <RouteListItem
                showRemoveButton={false}
                uuid={chart && chart.uuid}
                name={name}
              />
            </Grid>
          ))}
      </Grid>
      <TopRightCopyButton onClick={handleCopy} />
      <TopRightRemoveButton onClick={handleRemove} />
    </RouteItemContainer>
  )
}
RouteItem.propTypes = {
  onEdit: PropTypes.func,
  ...RouteShape,
}

const NEW_ROUTE = {
  name: '',
  stages: [{ name: 'First stage', chart: null, order: 0 }],
  showBerthingStages: false,
  routeConstraints: [],
}

export const RouteList = ({ port, disableFilterByPort }) => {
  const dispatch = useDispatch()
  const routeList = useSelector(
    disableFilterByPort
      ? routeListSelector
      : port
      ? getFilteredListByPort(port.uuid)
      : getListFilteredBySelectedPort
  )
  const [selectedToRemove, setSelectedToRemove] = useState(null)
  const [selected, setSelected] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const handleCancelRemove = () => setSelectedToRemove(null)
  const handleConfirmRemove = () => {
    dispatch(deleteRoute(selectedToRemove))
    setSelectedToRemove(null)
  }

  const handleEdit = (route) =>
    setSelected({
      ...route,
      routeConstraints: route.routeConstraints.map((rc) => ({
        ...rc,
        constraint: rc.constraint.uuid,
      })),
    })

  const handleCopy = (route) => {
    const routeNoUuid = { ...route }
    delete routeNoUuid.uuid
    const routeConstraintsNoUuid = route.routeConstraints.map((rc) => {
      const routeConstraintNoUuid = { ...rc }
      delete routeConstraintNoUuid.uuid
      routeConstraintNoUuid.key = `${Date.now()}${Math.random()}`
      return routeConstraintNoUuid
    })
    routeNoUuid.routeConstraints = routeConstraintsNoUuid
    const stagesNoUuid = route.stages.map((stage) => {
      const stageNoUuid = { ...stage }
      delete stageNoUuid.uuid
      stageNoUuid.key = `${Date.now()}${Math.random()}`
      return stageNoUuid
    })
    routeNoUuid.stages = stagesNoUuid
    routeNoUuid.name = `Copy of ${routeNoUuid.name}`
    const selectedRoute = {
      ...routeNoUuid,
      routeConstraints: routeNoUuid.routeConstraints.map((rc) => ({
        ...rc,
        constraint: rc.constraint.uuid,
      })),
    }
    setSelected(selectedRoute)
  }

  const handleClickCreateNew = () =>
    setSelected({
      ...NEW_ROUTE,
      stages: NEW_ROUTE.stages.map((stage) => ({
        ...stage,
        key: `${Date.now()}${Math.random()}`,
      })),
    })
  const handleCloseRequest = () => setSelected(null)

  const handleRemove = (route) => setSelectedToRemove(route)

  useEffect(() => {
    Promise.all([dispatch(getRouteList()), dispatch(getChartList())]).then(() =>
      setLoaded(true)
    )
  }, [dispatch])

  const sortedRouteList = routeList ? routeList.sort(compareName) : []

  // Pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)

  const handlePaginationChangePage = (event, selectedPage) =>
    setPage(selectedPage)

  const handlePaginationChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(value)
    setPage(0)
  }

  const currentPageRoutes = sortedRouteList.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  return (
    <Fieldset
      title="Routes"
      subtitle="Define your passage plan routes"
      description="Define your passage plan routes"
      clickHandler={handleClickCreateNew}
      hasButton
      buttonContents={
        <ButtonContents>
          <ButtonTitle>Create New Route</ButtonTitle>
          <ButtonSubText>Select the stages, charts etc...</ButtonSubText>
        </ButtonContents>
      }
    >
      {!loaded && <Preloader />}
      {currentPageRoutes.map((route) => (
        <RouteItem
          key={route.uuid}
          onEdit={handleEdit}
          onCopy={handleCopy}
          onRemove={handleRemove}
          {...route}
        />
      ))}

      <TablePagination
        component={Box}
        count={sortedRouteList.length}
        page={page}
        rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
        rowsPerPage={rowsPerPage}
        labelRowsPerPage="Routes per page:"
        onChangePage={handlePaginationChangePage}
        onChangeRowsPerPage={handlePaginationChangeRowsPerPage}
      />

      {selected && (
        <RouteFormDialog
          open={!!selected}
          onCloseRequest={handleCloseRequest}
          route={selected}
          port={port}
        />
      )}
      {selectedToRemove && (
        <ConfirmDialog
          open={!!selectedToRemove}
          onCancel={handleCancelRemove}
          onConfirm={handleConfirmRemove}
          title={`Remove route: ${selectedToRemove.name}`}
        >
          Are you sure you want to remove this route?
        </ConfirmDialog>
      )}
    </Fieldset>
  )
}

RouteList.propTypes = {
  port: PropTypes.shape(PortShape),
  disableFilterByPort: PropTypes.bool,
}

export default RouteList
