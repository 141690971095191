import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

const ENDPOINT_PATH = `/routes_attachments`

export const getRouteAttachments = (config) =>
  api
    .get(`${ENDPOINT_PATH}`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getRouteAttachment = (uuid, config) =>
  api
    .get(`${ENDPOINT_PATH}/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createRouteAttachment = (routeAttachment, config) => {
  let cfg = config
  let body = { ...routeAttachment }
  body = getFileUploadBody(routeAttachment)
  cfg = getConfigMultipartFormDataHeaders(cfg)
  return api
    .post(`${ENDPOINT_PATH}`, body, cfg)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateRouteAttachment = (routeAttachment, config) => {
  let cfg = config
  let body = { ...routeAttachment }
  body = getFileUploadBody(routeAttachment)
  cfg = getConfigMultipartFormDataHeaders(cfg)
  return api
    .put(`${ENDPOINT_PATH}/${routeAttachment.uuid}`, body, cfg)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteRouteAttachment = (uuid, config) =>
  api
    .delete(`${ENDPOINT_PATH}/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

const getConfigMultipartFormDataHeaders = (original = {}) => ({
  ...original,
  headers: {
    ...(original.headers || {}),
    'Content-Type': 'multipart/form-data',
  },
})

const getFormDataFromJSON = (body = {}) => {
  const data = new FormData()
  const { port, routes, ...fields } = body
  Object.keys(fields).forEach((name) => {
    if (fields[name] !== undefined) {
      data.append(name, fields[name])
    }
  })
  data.append('port', JSON.stringify(port))
  data.append('routes', JSON.stringify(routes || []))
  return data
}

const getFileUploadBody = (chart) => {
  const { fileUrl, ...fields } = chart
  let data = getFormDataFromJSON(fields)
  if (fileUrl instanceof File) {
    data.append('file', fileUrl)
  }
  return data
}

export default {
  create: createRouteAttachment,
  update: updateRouteAttachment,
  delete: deleteRouteAttachment,
}
