import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Collapse, Tooltip, Switch } from '@material-ui/core'
import styled from 'styled-components'
import {
  validateValues,
  validPositiveNumber,
  required,
} from 'src/utils/validation'
import { CONSTRAINT_TYPE } from 'src/constants/settings'
import BerthStationSelect from 'src/components/organisms/BerthStationSelect'
import TextInput from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import SelectInput from 'src/components/atoms/SelectInput'
import { ConstraintShape } from 'src/utils/types'
import { TideStationSelect } from 'src/components/organisms/TideStationSelect'
import { TideRateStationSelect } from 'src/components/organisms/TideRateStationSelect'
import { formatConstraintType } from 'src/utils/formatters'
import NumberInput from 'src/components/atoms/NumberInput'
import InfoIcon from 'src/components/atoms/Icons/InfoIcon'

const SafetyMarginLabel = styled.div`
  display: flex;
  align-items: center;
`

const Label = styled.label`
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
`

const StyledInfoIcon = styled(InfoIcon)`
  && {
    display: block;
  }
`

const TooltipSummary = styled.div`
  font-size: 12px;
  font-style: italic;
  color: #A8AAB7;
  flex: 1;
  text-align: right;
`

const ValueOverridableContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  .toggle-label {
    font-size: 12px;
    color: #999;
  }
`

export const ConstraintForm = ({
  onChange,
  onSubmit,
  disabled,
  constraint,
}) => {

  const typeOptions = Object.values(CONSTRAINT_TYPE).map((value) => ({
    value,
    label: formatConstraintType(value),
  }))

  const validateForm = (values) => {

    const validSafetyMargin = (value) =>
      required(value) === null &&
      validPositiveNumber(value) === null

    const validEitherSafetyMargin = (values) =>
      validSafetyMargin(values.safetyMarginM) ||
      validSafetyMargin(values.safetyMarginPct)

    return validateValues(values, {
      name: ['required', 'name'],
      value: ['required', 'number'],
      safetyMarginM: (value, field, values) =>
        validEitherSafetyMargin(values)
          ? validPositiveNumber(value)
          : 'Please enter metres and/or % of max. draft',

      safetyMarginPct: (value, field, values) =>
        validEitherSafetyMargin(values)
          ? validPositiveNumber(value)
          : ' ',

      correctionFactor: (value, field, values) =>
        values.constraintType !== CONSTRAINT_TYPE.UKC_DYNA ||
        (required(value) === null && validPositiveNumber(value) === null)
          ? null
          : 'Please enter a valid number',

      tideStation: 'required',
      // tideRateStation is optional
    })
  }

  const beforeSubmit = (values) => {
    const safetyMarginM = values.safetyMarginM === '' ? null : Number(values.safetyMarginM)
    const safetyMarginPct = values.safetyMarginPct === '' ? null : Number(values.safetyMarginPct)
    return ({
      ...values,
      safetyMarginM,
      safetyMarginPct,
      location: {
        latitude: 0,
        longitude: 0,
      },
      tideRateStation: values.tideRateStation ? values.tideRateStation : null,
    })
  }

  const handleOnChange = (...args) => {
    if (onChange) {
      onChange(...args)
    }
  }

  const { fields } = useForm({
    initialValues: {
      uuid: null,
      name: '',
      constraintType: CONSTRAINT_TYPE.UKC,
      value: 0,
      correctionFactor: 1,
      berthStation: '',
      tideStation: '',
      tideRateStation: '',

      ...constraint,

      safetyMarginM: typeof constraint.safetyMarginM === 'number' ? constraint.safetyMarginM : '',
      safetyMarginPct: typeof constraint.safetyMarginPct === 'number' ? constraint.safetyMarginPct : '',
      valueOverridable: !!constraint.valueOverridable,

      isWaypoint: false,
    },
    validationHandler: validateForm,
    changeHandler: handleOnChange,
    transformHandler: beforeSubmit,
    submitHandler: onSubmit,
  })

  let valueLabel
  switch (fields.constraintType.value) {
    case CONSTRAINT_TYPE.UKC:
    case CONSTRAINT_TYPE.UKC_DYNA:
      valueLabel = 'Available Depth'
      break
    case CONSTRAINT_TYPE.OHC:
      valueLabel = 'Overhead Clearance'
      break
    default:
      valueLabel = 'Value'
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            narrow
            name="name"
            label="Name"
            {...fields.name}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            fullWidth
            narrow
            name="constraintType"
            label="Constraint Type"
            disabled={disabled}
            {...fields.constraintType}
            options={typeOptions}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            fullWidth
            narrow
            name="value"
            label={valueLabel}
            disabled={disabled}
            decimalCount={2}
            {...fields.value}
          />
        </Grid>
        {
          [CONSTRAINT_TYPE.UKC, CONSTRAINT_TYPE.UKC_DYNA].indexOf(fields.constraintType.value) > -1 &&
          <Grid item xs={12}>
            <ValueOverridableContainer>
              <span className="toggle-label">
                Allow pilots to override available depth on pilot application
              </span>
              <Switch
                name="toggle"
                color="primary"
                checked={fields.valueOverridable.value}
                onChange={() => fields.valueOverridable.onChange('valueOverridable', !fields.valueOverridable.value)}
              />
            </ValueOverridableContainer>
          </Grid>
        }
        <Grid item xs={12}>
          <Collapse
            in={fields.constraintType.value === CONSTRAINT_TYPE.UKC_DYNA}
          >
            <NumberInput
              fullWidth
              narrow
              infoTooltip="A tide correction factor can be entered for this constraint. Tide values from the assigned Tide Station (below) are multiplied with this (fixed) correction value for this constraint and displayed in the Constraint. Enter (value) 1 if no correction factor is needed. "
              name="correctionFactor"
              regularCase
              label="Tide Corr. Fact. (x)"
              disabled={disabled}
              decimalCount={2}
              {...fields.correctionFactor}
            />
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <SafetyMarginLabel>
            <Label>Safety Margin (+)</Label>
            <Tooltip title={
              <>
                You can define a fixed <b>metre</b> and/or a <b>% of max draft</b> value.
                If only one is defined, it will be applied in the risk calculation.
                If both are defined, then the maximum between <b>metres </b>
                and <b>% of given max draft</b> will be applied.
              </>
            }>
              <div style={{ padding: '0px 10px' }}>
                <StyledInfoIcon />
              </div>
            </Tooltip>
            <TooltipSummary>
              Unit resulting in greatest metre value will be applied in the risk calculation
            </TooltipSummary>
          </SafetyMarginLabel>
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            fullWidth
            narrow
            name="safetyMarginM"
            unit="metres"
            disabled={disabled}
            decimalCount={2}
            {...fields.safetyMarginM}
          />
        </Grid>
        <Grid item xs={6}>
          <NumberInput
            fullWidth
            narrow
            name="safetyMarginPct"
            unit="% of Max. Draft"
            disabled={disabled}
            decimalCount={2}
            {...fields.safetyMarginPct}
          />
        </Grid>
        <Grid item xs={12}>
          <BerthStationSelect
            fullWidth
            narrow
            name="berthStation"
            label="Berth/Pilot Boarding Place"
            disabled={disabled}
            port={constraint.port}
            {...fields.berthStation}
          />
        </Grid>
        <Grid item xs={6}>
          <TideStationSelect
            fullWidth
            narrow
            name="tideStation"
            label="Tide Station"
            disabled={disabled}
            {...fields.tideStation}
          />
        </Grid>
        {
          <Grid item xs={6}>
            <TideRateStationSelect
              fullWidth
              narrow
              name="tideRateStation"
              label="Tide Rate Station"
              disabled={disabled}
              disableFilterByPort={false}
              {...fields.tideRateStation}
            />
          </Grid>
        }
      </Grid>
    </>
  )
}

ConstraintForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  constraint: PropTypes.shape(ConstraintShape),
}

export default ConstraintForm
