import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { actions as routerActions } from 'redux-router5'

import PortForm from 'src/components/organisms/PortForm'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { PortShape } from 'src/utils/types'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { selectedOrganisationSelector } from 'src/store/organisation/selectors'
import { savePort } from 'src/store/port/actions'
import { SETTINGS_PORT } from 'src/router/routes'

const StyledButton = styled(PrimaryButton)`
  && {
    margin-right: 25%;
  }
`

export const Port = ({ port }) => {
  const dispatch = useDispatch()
  const selectedOrgUuid = useSelector(selectedOrganisationSelector)
  const values = useRef()
  const [submitting, setSubmitting] = useState(false)
  const [formState, setFormState] = useState({
    isValid: true,
    dirty: false,
  })
  const [confirmDialog, setConfirmDialog] = useState(false)
  const handleFormChange = (change) => {
    values.current = change.values
    if (
      formState.isValid !== change.isValid ||
      formState.dirty !== change.dirty
    ) {
      setFormState({
        isValid: change.isValid,
        dirty: change.dirty,
      })
    }
  }

  const updatePort = async () => {
    try {
      const result = await dispatch(
        savePort({
          ...values.current,
          organisation: { uuid: selectedOrgUuid },
        })
      )
      const { error, payload } = result
      if (!port && !error && payload && payload.uuid) {
        dispatch(
          routerActions.navigateTo(SETTINGS_PORT, {
            portUuid: payload.uuid,
          })
        )
      }
    } catch (error) {}
  }

  const handleSave = async () => {
    setSubmitting(true)
    port && port.timezone !== values.current.timezone
      ? setConfirmDialog(true)
      : updatePort()
    setSubmitting(false)
  }

  const handleConfirm = async () => {
    await setConfirmDialog(false)
    updatePort()
  }

  const handleCancel = () => {
    setConfirmDialog(false)
  }

  const message =
    'Are you sure you want to change the time zone? If you do so all existing pilotages as well as tide prediction data will be updated accordingly.'

  return (
    <>
      <ConfirmDialog
        open={confirmDialog}
        title="Warning"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      >
        {message}
      </ConfirmDialog>
      <PortForm port={port} onChange={handleFormChange} disabled={submitting} />
      {(!port || formState.dirty) && (
        <StyledButton
          disabled={!formState.isValid || submitting}
          onClick={handleSave}
        >
          {port ? 'Save' : 'Next'}
        </StyledButton>
      )}
    </>
  )
}
Port.propTypes = {
  port: PropTypes.shape(PortShape),
}
export default Port
