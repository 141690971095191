import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getExtras = (config) =>
  api
    .get(`/extras`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getExtra = (uuid, config) =>
  api
    .get(`/extras/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createExtra = (extra, config) => {
  const { file, ...body } = extra
  return api
    .post('/extras', body, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateExtra = (extra, config) => {
  const { file, ...body } = extra
  return api
    .put(`/extras/${extra.uuid}`, body, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteExtra = (uuid, config) =>
  api
    .delete(`/extras/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  get: getExtra,
  getList: getExtras,
  create: createExtra,
  update: updateExtra,
  delete: deleteExtra,
}
