import React from 'react'
import { useDispatch } from 'react-redux'
import { actions as routerActions } from 'redux-router5'
import styled from 'styled-components'

import { Box, Typography } from '@material-ui/core'

import { SETTINGS_CREATE } from 'src/router/routes'
import PrimaryButton from 'src/components/atoms/PrimaryButton'

const TitleText = styled(Typography).attrs({
  variant: 'h1',
})`
  font-size: ${({ theme }) => theme.typography.pxToRem(50)};
  font-weight: 600;
  line-height: ${({ theme }) => theme.typography.pxToRem(60)};
`

const SubText = styled(Box).attrs({
  py: 6,
})(
  ({ theme }) => `
    color: ${theme.palette.text.secondary};
  `
)

export const NoPortPage = () => {
  const dispatch = useDispatch()
  const handleSetup = () => dispatch(routerActions.navigateTo(SETTINGS_CREATE))
  return (
    <Box>
      <TitleText variant="h1">
        Looks like you haven&apos;t
        <br /> configured your port yet...
      </TitleText>
      <SubText>Let&apos;s fix that and get things set up</SubText>
      <Box>
        <PrimaryButton onClick={handleSetup}>Start now</PrimaryButton>
      </Box>
    </Box>
  )
}

export default NoPortPage
