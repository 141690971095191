import React from 'react'
import PropTypes from 'prop-types'

export const DashboardIcon = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-921.000000, -141.000000)" fill="#515151">
        <g transform="translate(843.000000, 48.000000)">
          <g transform="translate(35.000000, 93.000000)">
            <g>
              <path d="M52,18 L54,18 L54,2 L52,2 L52,18 Z M50,20 L56,20 L56,0 L50,0 L50,20 Z M45,18 L47,18 L47,6 L45,6 L45,18 Z M43,20 L49,20 L49,4 L43,4 L43,20 Z M59,18 L61,18 L61,12 L59,12 L59,18 Z M57,20 L63,20 L63,10 L57,10 L57,20 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

DashboardIcon.propTypes = {
  size: PropTypes.number,
}

export default React.memo(DashboardIcon)
