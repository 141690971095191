import { useDispatch, useSelector } from 'react-redux'
import {
  invitationListSelector,
  invitationListStatusSelector,
  invitationListWithUsersSelector,
  getListFilteredByPort2,
  getListFilteredBySelectedPort2,
} from 'src/store/invitation/selectors'
import {
  INVITATION_LIST_GET_SUCCESS,
  INVITATION_LIST_GET_ERROR,
  getInvitationList,
  INVITATION_LIST_GET_IN_PROGRESS,
} from 'src/store/invitation/actions'
import { useCallback } from 'react'
import { getUserList } from 'src/store/user/actions'
import { isLoggedInSelector } from 'src/store/auth/selectors'
import { getListFilteredBySelectedPortWithUsers2, getListFilteredByPortWithUsers2 } from './../store/invitation/selectors';

const useInvitationsData = (
  mergeWithUserList,
  filterByPort = true,
  portUuid
) => {
  const dispatch = useDispatch()
  const data = useSelector(
    mergeWithUserList
      ? filterByPort
        ? portUuid
          ? getListFilteredByPortWithUsers2(portUuid)
          : getListFilteredBySelectedPortWithUsers2
        : invitationListWithUsersSelector

      : filterByPort
      ? portUuid
        ? getListFilteredByPort2(portUuid)
        : getListFilteredBySelectedPort2
      : invitationListSelector
  )
  const status = useSelector(invitationListStatusSelector)

  const reFetch = useCallback(() => {
    dispatch(getInvitationList())
    if (mergeWithUserList) {
      dispatch(getUserList())
    }
  }, [dispatch, mergeWithUserList])

  const isLoggedIn = useSelector(isLoggedInSelector)
  if (status === null && isLoggedIn) {
    reFetch()
  }
  return {
    data,
    reFetch,
    status,
    isLoading: INVITATION_LIST_GET_IN_PROGRESS === status,
    isLoaded: [INVITATION_LIST_GET_SUCCESS, INVITATION_LIST_GET_ERROR].includes(
      status
    ),
    isFailed: INVITATION_LIST_GET_ERROR === status,
  }
}

export default useInvitationsData
