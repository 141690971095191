import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from '@material-ui/core'

import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryDialogButton from 'src/components/atoms/PrimaryDialogButton'

export const ConfirmDialog = ({
  open,
  onCancel,
  onConfirm,
  title,
  children,
  cancelButtonLabel = 'No',
  confirmButtonLabel = 'Yes',
  cancelPrimary = true,
}) => {
  return (
    <Dialog open={open} onClose={onCancel} onEscapeKeyDown={onCancel}>
      {title && (
        <DialogTitle disableTypography>
          <Typography variant="h2">{title}</Typography>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <SecondaryButton onClick={cancelPrimary ? onConfirm : onCancel}>
          {cancelPrimary ? confirmButtonLabel : cancelButtonLabel}
        </SecondaryButton>
        <PrimaryDialogButton onClick={cancelPrimary ? onCancel : onConfirm}>
          {cancelPrimary ? cancelButtonLabel : confirmButtonLabel}
        </PrimaryDialogButton>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  cancelButtonLabel: PropTypes.string,
  confirmButtonLabel: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  cancelPrimary: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  children: PropTypes.node,
}

export default ConfirmDialog
