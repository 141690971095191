import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FormControl } from '@material-ui/core'

import PrimaryButton from 'src/components/atoms/PrimaryButton'
import TextInput, { Label } from 'src/components/atoms/TextInput/TextInput'
import ErrorMessage from 'src/components/atoms/ErrorMessage'

import validatePassword, {
  PASSWORD_CRITERIA_MESSAGE,
} from 'src/utils/validatePassword'

const SubmitButton = styled(PrimaryButton)`
  && {
    margin-top: 16px;
    width: 100%;
  }
`

const StyledForm = styled.form`
  width: 100%;
`

const EmailFormControl = styled(FormControl)`
  width: 100%;
`
const EmailField = styled.div`
  background-color: ${({ theme }) => theme.palette.background.grey};
  color: ${({ theme }) => theme.palette.text.secondary}
  margin-bottom: 1em;
  padding: 0.5em;
  width: 100%;
`
export const SetPasswordForm = ({
  buttonLabel = 'Set my password',
  email,
  error,
  inProgress,
  onSubmit,
  token,
}) => {
  const [password, setPassword] = useState('')
  const [passwordConfirmed, setPasswordConfirmed] = useState('')
  const [formErrors, setFormErrors] = useState({})

  const isValidPassword = (value) => validatePassword(value)
  const arePasswordsMatch = (password, password2) => password === password2
  const isValidToken = (value) => value && value.length
  const isFormValid = () =>
    isValidToken(token) &&
    isValidPassword(password) &&
    arePasswordsMatch(password, passwordConfirmed)

  const handlePasswordChange = (event) => {
    setPassword(event.target.value)
  }
  const handlePasswordConfirmedChange = (event) =>
    setPasswordConfirmed(event.target.value)
  const handleFieldBlur = () => {
    validateForm()
  }
  const handleFieldFocus = ({ target: { name } } = {}) => {
    const errors = { ...formErrors }
    delete errors[name]
    setFormErrors(errors)
  }
  const validateForm = () => {
    let errors
    if (password && !isValidPassword(password)) {
      errors = {
        password: PASSWORD_CRITERIA_MESSAGE,
      }
    }
    if (passwordConfirmed && !arePasswordsMatch(password, passwordConfirmed)) {
      errors = {
        ...errors,
        passwordConfirmed: `The passwords are not matching.`,
      }
    }
    setFormErrors(errors || {})
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    if (isFormValid()) {
      onSubmit({ password, passwordConfirmed, token })
    }
  }

  return (
    <StyledForm onSubmit={handleSubmit}>
      {email && (
        <EmailFormControl>
          <Label>Your email:</Label>
          <EmailField>{email}</EmailField>
        </EmailFormControl>
      )}
      <TextInput
        error={formErrors.password}
        fullWidth
        id="password"
        label="Password"
        name="password"
        onBlur={handleFieldBlur}
        onChange={handlePasswordChange}
        onFocus={handleFieldFocus}
        placeholder="Your password"
        type="password"
      />
      <TextInput
        error={formErrors.passwordConfirmed}
        fullWidth
        id="passwordConfirmed"
        label="Confirm password"
        name="passwordConfirmed"
        onBlur={handleFieldBlur}
        onChange={handlePasswordConfirmedChange}
        onFocus={handleFieldFocus}
        placeholder="Confirm password"
        type="password"
      />
      <ErrorMessage message={error} active={error !== undefined} />
      <SubmitButton type="submit" disabled={!isFormValid() || inProgress}>
        {buttonLabel}
      </SubmitButton>
      <ErrorMessage
        message="Invalid token. Please check the link in your email."
        active={!isValidToken(token)}
      />
    </StyledForm>
  )
}

SetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  buttonLabel: PropTypes.string,
  email: PropTypes.string,
  error: PropTypes.string,
  token: PropTypes.string,
  inProgress: PropTypes.bool,
}

export default SetPasswordForm
