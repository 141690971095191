import React, { useState, useEffect, ChangeEvent } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Slider } from '@material-ui/core'
import { TidalRateConfig, TidalRanges, TidalRange, TidalRate } from 'src/utils/sauce/sauce-api-preferences/TidalRateConfig'
import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Fieldset from 'src/components/organisms/Fieldset'
import DataTable from 'src/components/molecules/DataTable'
import { Switch, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import deepEqual from 'dequal'
import { updateTideRateStation } from '../../store/tideRateStations/actions'
import { tideRateStationUpdateInProgressSelector } from 'src/store/tideRateStations/selectors'

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
    display: inline-block;
    padding: 1px 5px;
  }
`

const ActionsBox = styled.div`
  display: flex;
  margin-top: 10px;
  & button {
    margin-right: 0px;
  }
`

const RangesContainer = styled.div`
    .range-selector {
        display: flex;
        align-items: flex-start;
        margin-bottom: 5px;
    }
    .range-selector-term {
        flex: 2;
    }
    .range-selector-term > div {
        padding-bottom: 0px;
        margin: bottom: 0px;
        width: 100%;
    }
    .range-selector-slider {
        flex: 4;
        margin: 0px 20px;
        padding-top: 5px;
    }
    .range-selector-label {
        flex: 1;
        white-space: nowrap;
        padding-top: 9px;
    }
    .arrow-tip {
        color: #a0a0a0;
        font-size: 12px;
        text-align: right;
    }
`

const ThresholdContainer = styled.div`
    padding: 20px 0px;
    > div {
        margin: 0px 10px;
    }
    span {
        display: inline-block;
        color: #a0a0a0;
        padding-top: 8px;
    }
`

interface TideRateConfigProps {
    tideRateStation: any
    config: TidalRateConfig
    disabled: boolean
}

const TideRateConfig: React.FC<TideRateConfigProps> = ({ tideRateStation, config, disabled, children }) => {

    const dispatch = useDispatch()
    const [editing, setEditing] = useState<TidalRateConfig | undefined>(undefined)
    const [showTip, setShowTip] = useState(false)
    const updating = useSelector(tideRateStationUpdateInProgressSelector)
    const dirty = editing && !deepEqual(editing, config)

    useEffect(() => {
        if (config) {
            setEditing(config)
        }
    }, [config])

    const ranges = !editing ? [] :
        Object
            .keys(editing.ranges)
            .map((uuid) => ({ uuid, range: editing.ranges[uuid] }))
            .sort((a, b) => a.range.knotsLower < b.range.knotsLower ? -1 : 1)

    const rates = !editing ? [] :
        Object
            .keys(editing.rates)
            .map((uuid) => ({ uuid, rate: editing.rates[uuid] }))
            .sort((a, b) => a.rate.hoursOffset < b.rate.hoursOffset ? -1 : 1)

    const handleTermChange = (uuid: string) => (event: ChangeEvent<HTMLInputElement>) => {

        const term = event.target.value

        if (editing) {
            setEditing({
                ...editing,
                ranges: {
                    ...editing.ranges,
                    [uuid]: {
                        ...editing.ranges[uuid],
                        term
                    }
                }
            })
        }
    }

    const handleRangeChange = (index: number) => (event: any, value: number | number[]) => {

        const [lower, upper] = (value as number[])
            .sort()
            .map(n =>
                parseFloat(Math.min(n, 15).toFixed(3)) // limit max kn to 15
            )

        const prev = ranges[index - 1]
        const current = ranges[index]
        const next = ranges[index + 1]

        // can't move beyond range of prev/next
        if ((prev && lower < prev.range.knotsLower) || (next && upper > next.range.knotsUpper)) { return }

        const rangesPatch: TidalRanges = {
            [current.uuid]: {
                ...current.range,
                knotsLower: lower,
                knotsUpper: upper
            }
        }

        // pin prev upper to current lower
        if (prev) {
            rangesPatch[prev.uuid] = { ...prev.range, knotsUpper: lower }
        }

        // pin next lower to current upper
        if (next) {
            rangesPatch[next.uuid] = { ...next.range, knotsLower: upper }
        }

        if (editing) {
            setEditing({
                ...editing,
                ranges: {
                    ...editing.ranges,
                    ...rangesPatch
                }
            })
        }
    }

    const handleRateChange = (uuid: string, rate: TidalRate) => {
        if (editing) {
            setEditing({
                ...editing,
                rates: {
                    ...editing.rates,
                    [uuid]: rate
                }
            })
        }
    }

    const handleSave = async () => {
      if (editing) {
        const amendedValues = {
          uuid: tideRateStation.uuid,
          metadata: editing,
        }
        await dispatch(updateTideRateStation(amendedValues))
      }
    }

    return (
        <Fieldset
            disabled={disabled}
            title={`Tidal Rate for ${tideRateStation.name}`}
            subtitle="eMPX uses HW/LW range to decide whether or not to show Springs vs Neaps tidal rate. Please configure your tidal range below."
            description="Manage your tidal rate configuration"
            clickHandler={() => null}
            buttonContents={null}
            hasButton={false}
        >
            {children}

            {
            editing &&
            <>
                <RangesContainer>
                {
                    ranges.map(({ uuid, range }, index) => {

                        const error = !/^[\w\-\s]+$/.test(range.term)

                        return (
                        <div key={uuid} className="range-selector">
                            <div className="range-selector-term">
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={range.term}
                                    onChange={handleTermChange(uuid)}
                                    error={error}
                                    disabled={updating}
                                    helperText={error && "No special characters"}
                                />
                            </div>
                            <div className="range-selector-slider">
                                <Slider
                                    value={[range.knotsLower, range.knotsUpper]}
                                    onChange={handleRangeChange(index)}
                                    onFocus={() => setShowTip(true)}
                                    onBlur={() => setShowTip(false)}
                                    step={0.05}
                                    min={0}
                                    max={ranges[ranges.length - 1].range.knotsUpper + 0.1}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                    getAriaValueText={(value: number) => `${value}kn`}
                                    disabled={updating}
                                />
                            </div>
                            <div className="range-selector-label">
                                {
                                range.knotsLower === range.knotsUpper ?
                                    <>{range.knotsLower} kn </> :
                                    <>{range.knotsLower} - {range.knotsUpper} kn</>
                                }
                            </div>
                        </div>
                        )
                    })
                }
                {
                    showTip &&
                    <div className="arrow-tip">
                        <b>Tip: </b>
                        <span> use &#8592; / &#8594; keys for fine-grained control</span>
                    </div>
                }
                </RangesContainer>

                <ThresholdContainer>
                    <span>Use Springs rate if HW/LW range is greater or equal than</span>
                    <TextField
                        style={{width: 80}}
                        size="small"
                        variant="outlined"
                        type="number"
                        label="metres"
                        disabled={updating}
                        inputProps={{
                            min: 0,
                            max: 100,
                            step: 0.01
                        }}
                        value={editing.tideTypeRangeThreshold}
                        onBlur={() => {
                            editing && setEditing({
                                ...editing,
                                tideTypeRangeThreshold: Number(editing.tideTypeRangeThreshold.toFixed(2))
                            })
                        }}
                        onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            editing && setEditing({
                                ...editing,
                                tideTypeRangeThreshold: Number(event.target.value)
                            })
                        }
                    />
                    <span>or else apply Neaps rate.</span>
                </ThresholdContainer>

                <DataTable
                    columns={[
                        {
                            field: 'rate',
                            title: 'Time (Hours)',
                            width: 50,
                            labelFunction: (rate) => (
                                <div>HW {rate.hoursOffset < 0 ? '-' : '+'} {Math.abs(rate.hoursOffset)}</div>
                            )
                        },
                        {
                            field: 'rate',
                            title: 'Neap Rate (Knots)',
                            labelFunction: (rate, { uuid }) => (
                                <RateRangeSelector
                                    value={rate.neapRangeUuid}
                                    options={ranges}
                                    disabled={updating}
                                    onChange={(neapRangeUuid) => handleRateChange(uuid, { ...rate, neapRangeUuid })}
                                />
                            )
                        },
                        {
                            field: 'rate',
                            title: 'Spring Rate (Knots)',
                            labelFunction: (rate, { uuid }) => (
                                <RateRangeSelector
                                    value={rate.springRangeUuid}
                                    options={ranges}
                                    disabled={updating}
                                    onChange={(springRangeUuid) => handleRateChange(uuid, { ...rate, springRangeUuid })}
                                />
                            )
                        },
                        {
                            field: 'rate',
                            title: 'Direction (Degrees)',
                            width: 70,
                            labelFunction: (rate, { uuid }) => (
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    type="number"
                                    disabled={updating}
                                    inputProps={{ step: 1, min: 0, max: 360 }}
                                    value={rate.directionDegrees || ''}
                                    onBlur={() => {
                                        handleRateChange(uuid, {
                                            ...rate,
                                            directionDegrees: rate.directionDegrees ?
                                                Math.round(rate.directionDegrees) :
                                                undefined
                                        })
                                    }}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        handleRateChange(uuid, {
                                            ...rate,
                                            directionDegrees: !isNaN(parseInt(value, 10)) ?
                                                Number(value) :
                                                undefined
                                        })
                                    }}
                                />
                            )
                        }
                    ]}
                    dataProvider={rates}
                />

                <Box style={{ float: 'left' }}>
                    <h5>Show in Pilot App?</h5>
                    <Box display="flex">
                        <Switch
                        name="toggle"
                        color="primary"
                        checked={!!editing.enabled}
                        disabled={updating}
                        onChange={() => editing && setEditing({
                            ...editing,
                            enabled: !editing.enabled
                        })}
                        />
                        <ToggleText>
                        {updating
                            ? '...'
                            : editing.enabled
                            ? 'Enabled'
                            : 'Disabled'}
                        </ToggleText>
                    </Box>
                </Box>

                <ActionsBox>
                    <PrimaryButton
                        disabled={updating || !dirty || disabled}
                        onClick={handleSave}
                    >
                        Save
                    </PrimaryButton>
                </ActionsBox>
            </>
        }
        </Fieldset>
    )
}

interface RateRangeSelectorProps {
    value: string
    options: Array<{ uuid: string, range: TidalRange }>
    onChange(value: string): void
    disabled: boolean
}

const RateRangeSelector: React.FC<RateRangeSelectorProps> = ({ value, disabled, options, onChange }) => {
    return (
        <FormControl size="small" style={{width: '100%'}}>
            <Select
                variant="outlined"
                value={value}
                disabled={disabled}
                onChange={(event: ChangeEvent<any>) => onChange(event.target.value)}
            >
                {
                    options.map(({ uuid, range }) =>
                        <MenuItem key={uuid} value={uuid}>
                            {range.knotsLower} - {range.knotsUpper} ({range.term})
                        </MenuItem>
                    )
                }
            </Select>
        </FormControl>
    )
}

export default TideRateConfig
