export const FEATURE_FLAG_KEY = 'feature_flags'

export interface FeatureFlags {
    pilot_create_pilotage_disabled?: boolean
    show_checklist_in_master_view?: boolean
    show_passfail_in_master_view?: boolean
    show_timestamps_in_master_view?: boolean
    active_default_enabled?: boolean
    hide_waypoint_location_enabled?: boolean
    use_e_signatures?: boolean
    use_berthing_conditions?: boolean
    use_chart_tools_grid?: boolean
    omc_config?: {
        system_name: string
        show_graph_in_master_view?: boolean
        show_table_in_master_view?: boolean
    }
    tide_rate_source?: 'config' | 'csv'
}

export interface IPreferencesFeatureFlags {
    id?: number
    key: 'feature_flags'
    createdAt?: string
    updatedAt?: string
    deletedAt?: string
    port?: any
    uuid?: string
    metadata: FeatureFlags
}

export const defaultFeatureFlags: IPreferencesFeatureFlags  = {
    uuid: '',
    key: FEATURE_FLAG_KEY,
    metadata: {
      pilot_create_pilotage_disabled: false,
      show_checklist_in_master_view: true,
      show_passfail_in_master_view: true,
      show_timestamps_in_master_view: false,
      active_default_enabled: false,
      hide_waypoint_location_enabled: false,
      use_e_signatures: false,
      use_berthing_conditions: false,
      use_chart_tools_grid: true,
      omc_config: {
        system_name: '',
        show_graph_in_master_view: true,
        show_table_in_master_view: true
      },
      tide_rate_source: 'config'
    }
}