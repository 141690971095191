import styled from 'styled-components'

export const VerticalDivider = styled.div`
  width: 0;
  height: ${({ height }) => height || 'inherit'};
  border-left: 2px solid ${({ theme }) => theme.palette.divider};
  margin: 0 ${({ theme }) => theme.spacing(1)}px;
`

export default VerticalDivider
