import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-organisation'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import {
  organisationListSelector,
  selectedOrganisationSelector,
} from 'src/store/organisation/selectors'
import { userOrganisationUUIDSelector } from 'src/store/user/selectors'

// GET ORGANISATION
const ACTION_GET = 'ORGANISATION_GET'
export const [
  ORGANISATION_GET_REQUEST,
  ORGANISATION_GET_RESULT,
  ORGANISATION_GET_SUCCESS,
  ORGANISATION_GET_ERROR,
  ORGANISATION_GET_CANCEL,
  ORGANISATION_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getOrganisation = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getOrganisationRequest(uuid))
}
export const getOrganisationRequest = (uuid) => async (dispatch) => {
  dispatch(getOrganisationInProgress(uuid))
  try {
    const organisationResult = await api.getOrganisation(uuid)
    return dispatch(getOrganisationSuccess(organisationResult))
  } catch (error) {
    return dispatch(getOrganisationError(error))
  }
}
export const getOrganisationInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getOrganisationSuccess = (organisation) =>
  createSuccessAction(ACTION_GET, organisation)
export const getOrganisationError = (error) =>
  createErrorAction(ACTION_GET, error)

// GET ORGANISATION_LIST
const ACTION_GET_LIST = 'ORGANISATION_LIST_GET'
export const [
  ORGANISATION_LIST_GET_REQUEST,
  ORGANISATION_LIST_GET_RESULT,
  ORGANISATION_LIST_GET_SUCCESS,
  ORGANISATION_LIST_GET_ERROR,
  ORGANISATION_LIST_GET_CANCEL,
  ORGANISATION_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getOrganisationList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getOrganisationListRequest(uuid))
}
export const getOrganisationListRequest = (uuid) => async (dispatch) => {
  dispatch(getOrganisationListInProgress(uuid))
  try {
    const organisationListResult = await api.getOrganisations(uuid)
    return dispatch(getOrganisationListSuccess(organisationListResult))
  } catch (error) {
    return dispatch(getOrganisationListError(error))
  }
}
export const getOrganisationListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getOrganisationListSuccess = (organisation) => (
  dispatch,
  getState
) => {
  const result = dispatch(createSuccessAction(ACTION_GET_LIST, organisation))
  const userOrgUuid = userOrganisationUUIDSelector(getState())
  const selectedOrgUuid = selectedOrganisationSelector(getState())
  if (!selectedOrgUuid) {
    dispatch(selectOrganisation(userOrgUuid))
  }
  return result
}
export const getOrganisationListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE ORGANISATION
const ACTION_CREATE = 'ORGANISATION_CREATE'
export const [
  ORGANISATION_CREATE_REQUEST,
  ORGANISATION_CREATE_RESULT,
  ORGANISATION_CREATE_SUCCESS,
  ORGANISATION_CREATE_ERROR,
  ORGANISATION_CREATE_CANCEL,
  ORGANISATION_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createOrganisation = (organisation) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: organisation })
  return dispatch(createOrganisationRequest(organisation))
}
export const createOrganisationRequest = (payload) => async (dispatch) => {
  dispatch(createOrganisationInProgress(payload))
  try {
    const organisationResult = await api.createOrganisation(payload)
    return dispatch(createOrganisationSuccess(organisationResult))
  } catch (error) {
    return dispatch(createOrganisationError(error))
  }
}
export const createOrganisationInProgress = (organisation) =>
  createInProgressAction(ACTION_CREATE, organisation)
export const createOrganisationSuccess = (organisation) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: `You successfully created the organisation: '${organisation.name}'`,
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, organisation))
}
export const createOrganisationError = (error) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: `An error occurred while we tried to create the organisation`,
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE organisation
const ACTION_UPDATE = 'ORGANISATION_UPDATE'
export const [
  ORGANISATION_UPDATE_REQUEST,
  ORGANISATION_UPDATE_RESULT,
  ORGANISATION_UPDATE_SUCCESS,
  ORGANISATION_UPDATE_ERROR,
  ORGANISATION_UPDATE_CANCEL,
  ORGANISATION_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateOrganisation = (organisation) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: organisation })
  return dispatch(updateOrganisationRequest(organisation))
}
export const updateOrganisationRequest = (payload) => async (dispatch) => {
  dispatch(updateOrganisationInProgress(payload))
  try {
    const organisationResult = await api.updateOrganisation(payload)
    return dispatch(updateOrganisationSuccess(organisationResult))
  } catch (error) {
    return dispatch(updateOrganisationError(error))
  }
}
export const updateOrganisationInProgress = (organisation) =>
  createInProgressAction(ACTION_UPDATE, organisation)
export const updateOrganisationSuccess = (organisation) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: `You successfully updated the organisation: '${organisation.name}'`,
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, organisation))
}
export const updateOrganisationError = (error) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: `An error occurred while we tried to update the organisation`,
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE organisation (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'ORGANISATION_SAVE'
export const saveOrganisation = (organisation) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: organisation })
  const result = await dispatch(
    organisation.uuid
      ? updateOrganisation(organisation)
      : createOrganisation(organisation)
  )
  return result
}

// DELETE organisation
const ACTION_DELETE = 'ORGANISATION_DELETE'
export const [
  ORGANISATION_DELETE_REQUEST,
  ORGANISATION_DELETE_RESULT,
  ORGANISATION_DELETE_SUCCESS,
  ORGANISATION_DELETE_ERROR,
  ORGANISATION_DELETE_CANCEL,
  ORGANISATION_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteOrganisation = (organisation) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: organisation })
  return dispatch(deleteOrganisationRequest(organisation))
}
export const deleteOrganisationRequest = (organisation) => async (dispatch) => {
  dispatch(deleteOrganisationInProgress(organisation))
  try {
    await api.deleteOrganisation(organisation.uuid)
    return dispatch(deleteOrganisationSuccess(organisation))
  } catch (error) {
    dispatch(deleteOrganisationError(error))
    return error
  }
}
export const deleteOrganisationInProgress = (organisation) =>
  createInProgressAction(ACTION_DELETE, organisation)
export const deleteOrganisationSuccess = (organisation) =>
  createSuccessAction(ACTION_DELETE, organisation)
export const deleteOrganisationError = (error) =>
  createErrorAction(ACTION_DELETE, error)

// SELECT organisation
const ACTION_SELECT = 'ORGANISATION_SELECT'
export const [
  ORGANISATION_SELECT_REQUEST,
  ORGANISATION_SELECT_RESULT,
  ORGANISATION_SELECT_SUCCESS,
  ORGANISATION_SELECT_ERROR,
  ORGANISATION_SELECT_CANCEL,
  ORGANISATION_SELECT_IN_PROGRESS,
] = createBasicActionTypes(ACTION_SELECT)
export const selectOrganisation = (organisation) => (dispatch) => {
  dispatch({ type: ACTION_SELECT, payload: organisation })
  return dispatch(selectOrganisationRequest(organisation))
}
export const selectOrganisationRequest = (uuid) => async (
  dispatch,
  getState
) => {
  const organisations = organisationListSelector(getState())
  if (organisations.find((organisation) => organisation.uuid === uuid)) {
    return dispatch(selectOrganisationSuccess(uuid))
  }
  dispatch(
    selectOrganisationError(new Error(`No organisation with UUID ${uuid}`))
  )
}
export const selectOrganisationSuccess = (organisation) =>
  createSuccessAction(ACTION_SELECT, organisation)
export const selectOrganisationError = (error) =>
  createErrorAction(ACTION_SELECT, error)
