import { useCallback, useEffect, useReducer, useRef } from 'react'

import deepEqual from 'dequal'
import { useDeepCompareEffectNoCheck } from 'use-deep-compare-effect'

const getInitialState = (initialValues, validationHandler) => {
  const errors = validationHandler ? validationHandler(initialValues) : {}
  const isValid = !Object.values(errors).some((error) => !!error)
  return {
    initialValues: initialValues || {},
    values: initialValues || {},
    errors,
    touched: false,
    dirty: false,
    isValid,
    valuesToSubmit: null,
    submitting: false,
    submitError: null,
    lastSubmit: null,
  }
}

function reducer(state, action) {
  switch (action.type) {
    case 'reset':
      return getInitialState(action.initialValues, action.validationHandler)
    case 'validate':
      const validationErrors = action.validationHandler(state.values)
      const errorsHasChanged = !deepEqual(state.errors, validationErrors)
      return errorsHasChanged
        ? {
            ...state,
            errors: validationErrors,
          }
        : state
    case 'change': {
      const values = {
        ...state.values,
        [action.property]: action.value,
      }
      const dirty = !deepEqual(values, state.initialValues)
      const errors = action.validationHandler(values)
      const isValid = !Object.values(errors).some((error) =>
        Array.isArray(error) ? error.some((e) => !!e) : !!error
      )
      return {
        ...state,
        values,
        dirty,
        errors,
        isValid,
      }
    }
    case 'startSubmitting':
      return {
        ...state,
        valuesToSubmit: { ...state.values },
        submitting: true,
        submitError: null,
        touched: true,
      }
    case 'finishSubmitting':
      return {
        ...state,
        initialValues: action.error
          ? state.initialValues
          : action.values || state.values,
        submitError: action.error,
        valuesToSubmit: null,
        submitting: false,
        touched: !!action.error,
        dirty: !!action.error,
        lastSubmit: action.error ? state.lastSubmit : Date.now(),
      }
    case 'invalidForm':
      return {
        ...state,
        submitError: null,
        touched: true,
      }
    default:
      throw new Error()
  }
}

const defaultTransformHandler = (values) => values

const useForm = ({
  initialValues,
  submitHandler,
  validationHandler,
  transformHandler = defaultTransformHandler,
  changeHandler,
  autoSave = false,
  isCopy = false,
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    getInitialState(initialValues, validationHandler)
  )
  let handlers = useRef({})
  handlers.current.submitHandler = submitHandler
  handlers.current.validationHandler = validationHandler
  handlers.current.transformHandler = transformHandler
  handlers.current.changeHandler = changeHandler

  // EMPX-484: if form is a copy from an existing entity,
  // we set dirty to true.
  const defaultFieldProps = useRef({
    pristine: true,
    touched: false,
    dirty: isCopy,
  })

  const autoSaveTimer = useRef(null)
  const clearAutoSaveTimer = () => {
    clearTimeout(autoSaveTimer.current)
    autoSaveTimer.current = null
  }

  useEffect(() => {
    let canceled = false
    const submitForm = async () => {
      const { submitHandler, transformHandler } = handlers.current
      if (state.valuesToSubmit && submitHandler) {
        try {
          const values = await submitHandler(
            transformHandler
              ? transformHandler(state.valuesToSubmit)
              : state.valuesToSubmit
          )
          if (!canceled) {
            fields.current = Object.keys(fields.current).reduce(
              (currentFields, fieldName) => ({
                ...currentFields,
                [fieldName]: {
                  ...fields.current[fieldName],
                  ...defaultFieldProps.current,
                },
              }),
              {}
            )
            dispatch({ type: 'finishSubmitting', values })
          }
        } catch (e) {
          if (!canceled) {
            console.warn(e)
            dispatch({
              type: 'finishSubmitting',
              error: (e && e.message) || 'Oops... something went wrong',
            })
          }
        }
      }
    }
    submitForm()
    return () => {
      canceled = true
    }
  }, [state.valuesToSubmit])

  const handleSubmit = useCallback(
    (event, skipValidation) => {
      if (event) {
        event.preventDefault()
      }
      clearAutoSaveTimer()
      if (skipValidation || state.isValid) {
        dispatch({ type: 'startSubmitting' })
      } else {
        dispatch({ type: 'invalidForm' })
      }
    },
    [state.isValid]
  )

  const resetForm = useCallback(
    (values) => {
      dispatch({
        type: 'reset',
        initialValues: values || state.initialValues,
      })
    },
    [state.initialValues]
  )

  const onChange = useCallback(
    (property, value) => {
      clearAutoSaveTimer()
      if (!state.submitting) {
        if (typeof property !== 'string' && property.target) {
          value = property.target.value
          if (property.target.type === 'number' && !isNaN(Number(value))) {
            value = value ? Number(value) : value
          }
          if (property.target.type === 'checkbox') {
            value = property.target.checked
          }
          property = property.target.name
        }
        const field = {
          ...fields.current[property],
        }
        // This is a quick no-too-bad fix for unnecessary onChange calls
        // TODO: figure out why the onChange gets called when the input has been rendered
        if (value === field.value || deepEqual(value, field.value)) {
          return
        }
        fields.current[property] = {
          ...field,
          pristine: false,
          dirty: !deepEqual(value, state.initialValues[property]),
        }

        dispatch({
          type: 'change',
          property,
          value,
          initialValue: state.initialValues[property],
          validationHandler,
        })
      }
    },
    [state.initialValues, state.submitting, validationHandler]
  )

  useEffect(() => {
    const { changeHandler } = handlers.current
    if (changeHandler) {
      changeHandler({
        values: handlers.current.transformHandler(state.values),
        errors: state.errors,
        dirty: state.dirty,
        isValid: state.isValid,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.values])

  const onBlur = useCallback(
    (property) => {
      const startAutoSaveTimer = () => {
        clearTimeout(autoSaveTimer.current)
        autoSaveTimer.current = setTimeout(handleSubmit, autoSave)
      }
      if (!state.submitting) {
        if (typeof property !== 'string' && property.target) {
          property = property.target.name
        }
        const field = fields.current[property]
        if (!field.touched) {
          fields.current[property] = {
            ...field,
            touched: true,
          }
        }
        dispatch({
          type: 'validate',
          validationHandler,
        })
        if (autoSave && state.dirty) {
          startAutoSaveTimer()
        }
      }
    },
    [autoSave, handleSubmit, state.dirty, state.submitting, validationHandler]
  )

  useDeepCompareEffectNoCheck(() => {
    dispatch({
      type: 'reset',
      initialValues,
      validationHandler: handlers.current.validationHandler,
    })
  }, [initialValues])

  useEffect(() => {
    dispatch({
      type: 'validate',
      validationHandler,
    })
  }, [validationHandler])

  const fields = useRef({})
  Object.keys(state.values).forEach((key) => {
    let current = fields.current[key] || {
      ...defaultFieldProps.current,
    }
    current = {
      ...current,
      value: state.values[key],
      error: current.dirty ? state.errors[key] : '',
      onChange,
      onBlur,
    }

    fields.current[key] = current
  })

  return {
    handleSubmit,
    fields: fields.current,
    pristine: deepEqual(state.values, state.initialValues),
    dirty: state.dirty,
    submitting: state.submitting,
    submitError: state.submitError,
    resetForm,
    isValid: state.isValid,
  }
}

export default useForm
