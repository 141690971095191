import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Box from '@material-ui/core/Box'

import { PortShape } from 'src/utils/types'
import Navigation from 'src/containers/Navigation'

const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const SettingsWrapper = ({ port, children }) => {
  return (
    <Box display="flex">
      <Navigation port={port} />
      <ComponentWrapper>{children}</ComponentWrapper>
    </Box>
  )
}

SettingsWrapper.propTypes = {
  port: PropTypes.shape(PortShape),
  children: PropTypes.node,
}

export default SettingsWrapper
