import React from 'react'
import Box from '@material-ui/core/Box'
import Fieldset from 'src/components/organisms/Fieldset'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { Switch, Grid } from '@material-ui/core'
import useFeatureFlags from 'src/hooks/useFeatureFlags'

const SubheadingText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-bottom: 20px;
  }
`
const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
  }
`

export const SignaturesToggle = () => {

  const { getFlag, setFlag, error, loading } = useFeatureFlags()

  const flagName = 'use_e_signatures'
  const useESignatures = getFlag(flagName)

  if (loading) { return null }

  return (
    <Fieldset
      disabled={false}
      title='MPX Acceptance'
      description={''}
    >
      <Box>
        <SubheadingText>
          Would you like to use e-signature component for MPX Acceptance between Master and Pilot?
        </SubheadingText>
      </Box>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <Switch
            name="toggle"
            color="primary"
            checked={useESignatures === true}
            onChange={() => setFlag(flagName, !useESignatures)}
          />
        </Grid>
        <Grid item>
          <ToggleText>
            {typeof useESignatures === 'undefined' || useESignatures === false
              ? 'Disabled'
              : 'Enabled'}
          </ToggleText>
        </Grid>
      </Grid>
      {error && error.message}
    </Fieldset>
  )
}

SignaturesToggle.propTypes = {}

export default SignaturesToggle
