import React from 'react'
import PropTypes from 'prop-types'

export const CrossIcon = ({ size, ...props }) => (
  <svg
    viewBox="0 0 26 26"
    width={size}
    height={size}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle fill="#CA4545" cx="13" cy="13" r="13" />
    <path
      transform="translate(4 4)"
      fill="#fff"
      d="M11.308 13.205l-2.306-2.304-2.31 2.308a.99.99 0 11-1.401-1.4l2.31-2.31-2.31-2.308a.99.99 0 111.401-1.4l2.31 2.308 2.306-2.304a.99.99 0 111.401 1.4L10.404 9.5l2.305 2.305a.99.99 0 010 1.4.992.992 0 01-1.401 0z"
    />
  </svg>
)

CrossIcon.propTypes = {
  size: PropTypes.number,
}

CrossIcon.defaultProps = {
  size: 26,
}

export default CrossIcon
