import React from 'react'
import PropTypes from 'prop-types'

export const EditIcon = ({ size = 16 }) => (
  <svg
    viewBox={`0 0 ${size} ${size}`}
    height={size}
    width={size}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M0 16h15.708v-1.566H0V16zm5.498-5.482V8.41l4.479-4.136 1.564 1.72-3.996 4.523H5.498zm6.879-8.402l1.382 1.375-1.076 1.14-1.44-1.436 1.134-1.079zM16 3.716L12.291 0 3.927 7.75v4.334h4.291L16 3.716z"
        id="ea"
      />
    </defs>
    <g fill="none" fillRule="evenodd">
      <mask id="eb" fill="none">
        <use xlinkHref="#ea" />
      </mask>
      <use fill="rgba(0, 0, 0, 0.54)" xlinkHref="#ea" />
      <g mask="url(#eb)" fill="rgba(0, 0, 0, 0.54)">
        <path d="M0 0h16v16H0z" />
      </g>
    </g>
  </svg>
)

EditIcon.propTypes = {
  size: PropTypes.number,
}

export default React.memo(EditIcon)
