import React, { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Box, LinearProgress, Tooltip } from '@material-ui/core'

import { LabelNarrow } from 'src/components/atoms/TextInput'
import useConstraintsData from 'src/hooks/useConstraints'
import SelectInput from 'src/components/atoms/SelectInput'
import Fieldset from 'src/components/organisms/Fieldset'
import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import { RemoveIconButton } from 'src/components/atoms/RemoveButton'
import TimeInput from 'src/components/atoms/TimeInput/TimeInput'
import InfoIcon from 'src/components/atoms/Icons/InfoIcon'

const validateRouteConstraintForm = (values) =>
  validateValues(values, {
    constraint: ['required'],
    // WILL BE USED IN A LATER VERSION
    // distance: ['required', 'positiveNumber'],
    // speed: ['required', 'positiveNumber'],
    time: ['required', 'positiveNumber'],
  })

const StyledInfoIcon = styled(InfoIcon)`
  && {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`
const RouteConstraintForm = ({
  routeConstraint,
  onChange,
  onRemove,
  constraints,
}) => {
  const [values, setValues] = useState(routeConstraint)

  useEffect(() => {
    if (onChange) {
      onChange(values)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])
  const handleFormChange = (change) => {
    setValues(change.values)
  }
  const { fields } = useForm({
    initialValues: routeConstraint,
    validationHandler: validateRouteConstraintForm,
    changeHandler: handleFormChange,
  })
  const handleRemoveButtonClick = () => {
    if (onRemove) {
      onRemove(values)
    }
  }

  const constraintsOrderedByLabel = (constraints || []).sort((a, b) => {
    const A = a.label.toLowerCase()
    const B = b.label.toLowerCase()
    return A < B ? -1 : A > B ? 1 : 0
  })

  return (
    <Box position="relative">
      <Grid container key={routeConstraint.uuid} spacing={1}>
        <Grid item xs={6}>
          <SelectInput
            fullWidth
            name="constraint"
            narrow
            options={constraintsOrderedByLabel}
            {...fields.constraint}
          />
        </Grid>
        {/* LEAVE THIS HERE TO USE IT IN A LATER VERSION */}
        {/* <Grid item xs={2} sm={2}>
          <TextInput
            name="distance"
            narrow
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
            {...fields.distance}
          />
        </Grid>
        <Grid item xs={2} sm={2}>
          <TextInput
            name="speed"
            narrow
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">kn</InputAdornment>,
            }}
            {...fields.speed}
          />
        </Grid> */}
        <Grid item xs={5}>
          <TimeInput fullWidth name="time" narrow {...fields.time} />
        </Grid>
        <Grid item xs={1}>
          <RemoveIconButton onClick={handleRemoveButtonClick} />
        </Grid>
      </Grid>
    </Box>
  )
}

RouteConstraintForm.propTypes = {
  routeConstraint: PropTypes.shape({
    active: PropTypes.bool,
    constraint: PropTypes.string,
    distance: PropTypes.number,
    order: PropTypes.number,
    speed: PropTypes.number,
    time: PropTypes.number,
  }),
  constraints: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
}

const durationTooltip =
  'This is the duration (e.g. 15 minutes) for the constraint to become relevant after the start time of the pilotage (e.g. 12:30) to calculate the time (12:45) and tide at the constraint. Please note that for multiple constraints, it adds the duration of constraint to the last constraint calculated time, and not to the pilotage start time.'

export const RouteConstraintList = ({ routeConstraints, onChange }) => {
  const { data: constraintsList, isLoading } = useConstraintsData()
  const selectOptions = useMemo(
    () =>
      (constraintsList || []).map((constraint) => ({
        label: constraint.name,
        value: constraint.uuid,
      })),
    [constraintsList]
  )
  const [list, setList] = useState(
    (routeConstraints || []).sort((c1, c2) =>
      c1.order > c2.order ? 1 : c1.order < c2.order ? -1 : 0
    )
  )

  const EMPTY_ROUTE_CONSTRAINT = {
    constraint: {},
    distance: 0,
    speed: 0,
    time: 0,
  }
  const handleAddNewRouteConstraint = () =>
    setList([
      ...list,
      {
        ...EMPTY_ROUTE_CONSTRAINT,
        key: `${Date.now()}${Math.random()}`,
        order: 1 + Math.max(...list.map((item) => item.order)),
      },
    ])

  const handleRouteConstraintChange = useCallback(
    (routeConstraint) => {
      const routeConstraintsList = list.map((item) =>
        (item.uuid
        ? item.uuid === routeConstraint.uuid
        : item.key === routeConstraint.key)
          ? routeConstraint
          : item
      )
      setList(routeConstraintsList)
    },
    [list]
  )
  const handleRouteConstraintRemove = (routeConstraint) => {
    setList(
      list.filter((rc) =>
        routeConstraint.uuid
          ? rc.uuid !== routeConstraint.uuid
          : routeConstraint.key !== rc.key
      )
    )
  }

  useEffect(() => {
    onChange(
      list.map((rc) => {
        const { key, ...routeConstraint } = rc
        return routeConstraint
      })
    )
  }, [list, onChange])

  return (
    <>
      <Fieldset
        title="Constraints / Waypoints on Route"
        fullWidth
        hasButton
        clickHandler={handleAddNewRouteConstraint}
        buttonDisabled={isLoading}
        buttonContents="Add To Route"
      >
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <LabelNarrow>Constraint / Waypoint</LabelNarrow>
          </Grid>
          {/* <Grid xs={2}>
            <LabelNarrow>Distance</LabelNarrow>
          </Grid>
          <Grid xs={2}>
            <LabelNarrow>Speed</LabelNarrow>
          </Grid> */}
          <Grid container item xs={6}>
            <LabelNarrow>Duration till constraint / waypoint</LabelNarrow>
            <Tooltip title={durationTooltip}>
              <Box ml={1}>
                <StyledInfoIcon />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
        {list.map((routeConstraint, index) => (
          <RouteConstraintForm
            key={`${routeConstraint.uuid} + ${index}`}
            routeConstraint={routeConstraint}
            constraints={selectOptions}
            onChange={handleRouteConstraintChange}
            onRemove={handleRouteConstraintRemove}
          />
        ))}
        {isLoading && <LinearProgress variant="indeterminate" />}
      </Fieldset>
    </>
  )
}

RouteConstraintList.propTypes = {
  routeConstraints: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      constraint: PropTypes.string,
      distance: PropTypes.number,
      order: PropTypes.number,
      speed: PropTypes.number,
      time: PropTypes.number,
    })
  ),
  onChange: PropTypes.func,
}

export default React.memo(RouteConstraintList)
