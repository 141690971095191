import PropTypes from 'prop-types'
import React from 'react'
import Typography from '@material-ui/core/Typography'

const ErrorMessage = ({ active, message }) =>
  active ? (
    <Typography variant="body1" color="error" gutterBottom>
      {message}
    </Typography>
  ) : (
    false
  )

ErrorMessage.propTypes = {
  message: PropTypes.string,
  active: PropTypes.bool,
}
export default React.memo(ErrorMessage)
