import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { formatNumber } from 'src/utils/formatters'
import { TextInput } from 'src/components/atoms/TextInput'

export const formatRegex = /^\d{0,2}:?\d{0,2}$/

const strToMs = (str) => {
  if (!formatRegex.test(str)) {
    throw new Error('Invalid time string format')
  }
  let [hours, mins] = str.split(':')
  hours = Number(hours)
  mins = Number(mins)
  return hours * 60 + mins
}

const msToStr = (minutes) => {
  const hours = Math.trunc(minutes / 60)
  const mins = minutes % 60
  return `${formatNumber(hours, 2)}:${formatNumber(mins, 2)}`
}

export const TimeInput = ({ value, onChange, onBlur, maxHours, ...props }) => {
  if (typeof value === 'string' && !formatRegex.test(value)) {
    throw new Error('Invalid time string format')
  }
  const [timeStr, setTimeStr] = useState(
    typeof value === 'string' ? value : msToStr(value)
  )
  const handleChange = (event) => {
    const {
      target: { value },
      nativeEvent: { inputType },
    } = event
    if (formatRegex.test(value)) {
      let nextValue = value
      // auto insert colon when the user enters a second digit in the field
      if (/^\d{2}$/.test(value) && inputType !== 'deleteContentBackward') {
        nextValue += ':'
      }
      setTimeStr(nextValue)
    }
  }

  const handleBlur = (event) => {
    const { value } = event.target

    const formatMinutes = (n) => {
      const string = String(n)
      const int = parseInt(string, 10)
      const result = string.length === 2 && int < 10
        ? String(int).padStart(2, '0') 
        : String(int).padEnd(2, '0')

      return parseInt(result, 10) > 59 ? "59" : result
    }

    const formatHours = (n, limit) => {
      const string = String(n)
      const int = parseInt(string, 10)
      const result = String(int).padStart(2, '0')
      
      return typeof limit === 'number' && parseInt(result, 10) > limit 
        ? String(limit) 
        : result
    }

    const [hours = 0, minutes = 0] = value.split(':')
    const nextValue = `${formatHours(hours)}:${formatMinutes(minutes)}`

    setTimeStr(nextValue)
    if (onBlur) {
      onBlur(event)
    }
  }

  useEffect(
    () =>
      onChange &&
      onChange(
        props.name,
        typeof value === 'string' ? timeStr : strToMs(timeStr)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeStr]
  )
  return (
    <TextInput
      value={timeStr}
      onChange={handleChange}
      onBlur={handleBlur}
      {...props}
    />
  )
}
TimeInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxHours: PropTypes.number,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

export default TimeInput
