import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import { TextInput } from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import { UserShape } from 'src/utils/types'
import { validateValues } from 'src/utils/validation'
import useInvitationsData from 'src/hooks/useInvitations'

const transformUserToForm = ({ metadata, uuid, contacts }) => ({
  uuid,
  firstName: metadata ? metadata.firstName : '',
  lastName: metadata ? metadata.lastName : '',
  email:
    ((contacts || []).find((contact) => contact.contactType === 'email') || {})
      .value || '',
})

const transformFormValuesToUser = (
  { uuid, firstName, lastName, email },
  originalUser
) => ({
  ...originalUser,
  uuid,
  metadata: {
    firstName,
    lastName,
  },
  contacts: (originalUser.contacts || [{ contactType: 'email' }]).map(
    (contact) =>
      contact.type === 'email' ? { contactType: 'email', email } : contact
  ),
})

export const UserForm = ({ submitting, disabled, user, onChange }) => {
  const { data: invitations } = useInvitationsData()
  const [registeredEmails, setRegisteredEmails] = useState([])
  const initialValues = transformUserToForm(user)
  useEffect(() => {
    setRegisteredEmails(
      (invitations || []).map((invitation) => invitation.email)
    )
  }, [invitations])
  const validateUser = useCallback(
    (values) =>
      validateValues(values, {
        firstName: ['required', 'humanName'],
        lastName: ['required', 'humanName'],
        email: [
          'required',
          'email',
          (email) => {
            if (
              registeredEmails
                .filter((e) => e !== initialValues.email)
                .includes(email)
            ) {
              return 'This email is already registered or invited'
            }
          },
        ],
        isAdmin: 'required',
      }),
    [initialValues.email, registeredEmails]
  )
  const { fields } = useForm({
    initialValues,
    validationHandler: validateUser,
    changeHandler: onChange,
    transformHandler: (values) => transformFormValuesToUser(values, user),
  })
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <TextInput
          fullWidth
          label="First name"
          name="firstName"
          disabled={disabled || submitting}
          {...fields.firstName}
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          fullWidth
          label="Last name"
          name="lastName"
          disabled={disabled || submitting}
          {...fields.lastName}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          fullWidth
          label="Email"
          name="email"
          InputProps={{
            inputProps: {
              readOnly: true,
            },
          }}
          disabled={disabled || submitting}
          {...fields.email}
        />
      </Grid>
    </Grid>
  )
}
UserForm.propTypes = {
  user: PropTypes.shape(UserShape),
  onChange: PropTypes.func,
  submitting: PropTypes.bool,
  disabled: PropTypes.bool,
}
export default UserForm
