import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { getExtraList } from 'src/store/extra/actions'
import { PortShape } from 'src/utils/types'
import PortExtraForm from 'src/components/organisms/PortExtraForm'
import { extraByNameSelector } from 'src/store/extra/selectors'

const GUIDELINE_EXTRA_DEFAULT = {
  name: 'Guidelines',
  extraType: 'guidelines',
  metadata: { value: '' },
}
const TERMS_EXTRA_DEFAULT = {
  name: 'Terms',
  extraType: 'terms',
  metadata: { value: '' },
}

export const PortExtras = ({ port, disabled }) => {
  const dispatch = useDispatch()
  const portUuid = port && port.uuid
  useEffect(() => {
    dispatch(getExtraList())
  }, [dispatch])
  // the port extras is always filtered by port
  const guidelineExtra = useSelector(
    // the second parameter is filterByPort
    extraByNameSelector('Guidelines', !!port, portUuid)
  )
  const termsExtra = useSelector(
    // the second parameter is filterByPort
    extraByNameSelector('Terms', !!port, portUuid)
  )
  return (
    <>
      <PortExtraForm
        disabled={disabled || !port || !portUuid}
        port={port}
        label="Procedures &amp; Guidelines"
        subtitle="Enter relevant information from your port for the master to review."
        extra={guidelineExtra || GUIDELINE_EXTRA_DEFAULT}
        infoTooltip="Add important text for visiting vessels to your port. Any words starting with 'http' will be converted to clickable links which will redirect to that website."
      />
      <PortExtraForm
        disabled={disabled || !port || !portUuid}
        port={port}
        label="Port Terms &amp; Conditions"
        subtitle="Enter relevant information from your port for the master to review."
        infoTooltip="Add important text for visiting vessels to your port. Any words starting with 'http' will be converted to clickable links which will redirect to that website."
        extra={termsExtra || TERMS_EXTRA_DEFAULT}
      />
    </>
  )
}

PortExtras.propTypes = {
  disabled: PropTypes.bool,
  port: PropTypes.shape(PortShape),
}

export default PortExtras
