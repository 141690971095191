import React from 'react'
import { Switch, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useFeatureFlags from 'src/hooks/useFeatureFlags'
import styled from 'styled-components'

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
    display: inline-block;
    padding: 1px 5px;
  }
`

export const EmptyTugsPdfExportToggle = () => {
  const featureFlags = useFeatureFlags()
  const flagName = 'no_tugs_exclude_in_pdf_enabled'
  const active = featureFlags.getFlag(flagName)

  return (
    <Box>
      <h5>Exclude tug diagram from PDF output, if none are assigned?</h5>
      <Box display="flex">
        <Switch
          name="toggle"
          color="primary"
          checked={!!active}
          disabled={featureFlags.updating || featureFlags.loading}
          onChange={() => featureFlags.setFlag(flagName, !active)}
        />
        <ToggleText>
          {featureFlags.loading || featureFlags.updating
            ? '...'
            : active
              ? 'Enabled'
              : 'Disabled'}
        </ToggleText>
        {featureFlags.error && <span>{featureFlags.error.message}</span>}
      </Box>
    </Box>
  )
}

export default React.memo(EmptyTugsPdfExportToggle)
