import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import useForm from 'src/hooks/useForm'
import { validateValues, validUrl } from 'src/utils/validation'
import Fieldset from 'src/components/organisms/Fieldset'
import { TextInput } from 'src/components/atoms/TextInput'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { PortShape } from 'src/utils/types'
import { saveExtra, deleteExtra } from 'src/store/extra/actions'
import { extraByTypeSelector } from 'src/store/extra/selectors'
import { EXTRA_TYPE, EXTRA_NAME } from 'src/constants/settings'
import useExtrasData from 'src/hooks/useExtras'

const MAX_CHARACTERS = 25

const ActionsBox = styled.div`
  display: flex;
  & button {
    margin-right: ${({ theme }) => theme.spacing(1)}px;
  }
`

const validateForm = (values) =>
  validateValues(values, {
    name: [
      (value, field, fields) => {
        if (fields.url && !value) {
          return 'Please also set a name/title'
        }
        return null
      },
      'name',
      {
        name: 'maxLength',
        params: [MAX_CHARACTERS],
      },
    ],
    url: (value, field, values) => {
      if (values.name && !value) {
        return 'Please also set a valid URL'
      }
      return values.name ? validUrl(value) : value ? validUrl(value) : null
    },
  })

export const ExternalLinkConfig = ({ port, index, disabled }) => {

  const extraType = `${EXTRA_TYPE.EXTERNAL_LINK}${index > 1 ? `_${index}` : ''}`

  const dispatch = useDispatch()
  const { isLoading } = useExtrasData()
  const externalLink = useSelector(
    extraByTypeSelector(extraType)
  )
  const [submitting, setSubmitting] = useState(false)
  const [inputsDisabled, setInputsDisabled] = useState(disabled || submitting)

  const { fields, resetForm, dirty, isValid } = useForm({
    initialValues: (externalLink && externalLink.metadata) || {
      uuid: '',
      name: '',
      url: '',
    },
    validationHandler: validateForm,
  })

  useEffect(() => {
    setInputsDisabled(disabled || !port || !port.uuid || isLoading)
  }, [disabled, isLoading, port])

  const handleSave = async () => {
    if (isValid && dirty) {
      setSubmitting(true)
      const {
        name: { value: name },
        url: { value: url },
      } = fields
      try {
        if (!name && !url) {
          handleHemoveExternalLink()
        } else {
          const result = await dispatch(
            saveExtra({
              name: EXTRA_NAME[extraType],
              extraType,
              port: { uuid: port.uuid },
              uuid: (externalLink && externalLink.uuid) || undefined,
              metadata: JSON.stringify({
                name,
                url,
              }),
            })
          )
          if (result.payload) {
            resetForm({
              ...result.payload.metadata,
              uuid: result.payload.uuid,
            })
          }
        }
      } catch (error) {
      } finally {
        setSubmitting(false)
      }
    }
  }

  const handleHemoveExternalLink = () => {
    if (externalLink && externalLink.uuid) {
      dispatch(deleteExtra(externalLink))
    }
  }

  return (
    <>
      <Fieldset
        title={`External Hyperlink Button ${index}`}
        dirty={dirty}
        disabled={inputsDisabled}
        infoTooltip="Here a button can be configured to directly open any website, for example with tide, weather and/or other (real-time) information"
      >
        <TextInput
          label={`Title/Text on Button (max. ${MAX_CHARACTERS} char.)`}
          name="name"
          fullWidth
          disabled={inputsDisabled}
          {...fields.name}
        />
        <TextInput
          label="URL"
          name="url"
          fullWidth
          disabled={inputsDisabled}
          placeholder="Please start with protocol. Eg.: https://, http://, ftp://"
          {...fields.url}
        />
        <ActionsBox>
          {dirty && (
            <PrimaryButton
              disabled={submitting || !isValid || !dirty || disabled}
              onClick={handleSave}
            >
              Save
            </PrimaryButton>
          )}
        </ActionsBox>
      </Fieldset>
    </>
  )
}

ExternalLinkConfig.propTypes = {
  port: PropTypes.shape(PortShape),
  index: PropTypes.number,
  disabled: PropTypes.bool,
}

export default React.memo(ExternalLinkConfig)
