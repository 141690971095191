import {
  USER_LOAD_CURRENT_FROM_STORAGE_SUCCESS,
  USER_SET_CURRENT_REQUEST,
  USER_UPDATE_CURRENT_REQUEST,
  USER_REMOVE_CURRENT_REQUEST,
  USER_LIST_GET_REQUEST,
  USER_LIST_GET_IN_PROGRESS,
  USER_LIST_GET_ERROR,
  USER_LIST_GET_SUCCESS,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_DELETE_SUCCESS,
} from 'src/store/user/actions'
import {
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_IN_PROGRESS,
  AUTH_LOGIN_ERROR,
  AUTH_SESSION_REFRESH_ERROR,
} from 'src/store/auth/actions'
import { ORGANISATION_LIST_GET_SUCCESS } from 'src/store/organisation/actions'
import idx from 'idx'
import { USER_ROLES } from 'src/constants/user'
import { getIdsFromList, getByIdFromList } from 'src/utils/store'

export const getUserStateFromUserDataResult = (result) => {
  if (!result) {
    return null
  }
  const { uuid, organisation, roles } = result
  return {
    uuid,
    organisation,
    roles,
  }
}
export const initialState = {
  current: null,
  data: null,
  status: null,
}

export const reducer = (state = initialState, action) => {
  const roles = idx(state, (_) => _.current.roles)
  const isSuperAdmin = roles && roles.includes(USER_ROLES.SUPER_ADMIN)
  const { type, payload, error } = action
  switch (type) {
    case AUTH_LOGIN_IN_PROGRESS:
      return {
        ...state,
        current: {
          email: payload.email,
        },
      }
    case AUTH_LOGIN_SUCCESS:
      const {
        payload: {
          user: { metadata },
          roles,
        },
      } = action
      return {
        ...state,
        current: {
          ...(state.current || {}),
          roles,
          ...metadata,
        },
      }
    case USER_SET_CURRENT_REQUEST:
    case USER_LOAD_CURRENT_FROM_STORAGE_SUCCESS:
      return {
        ...state,
        current: payload,
      }
    case USER_UPDATE_CURRENT_REQUEST:
      return {
        ...state,
        current: {
          ...(state.current || {}),
          ...payload,
        },
      }
    case AUTH_LOGIN_ERROR:
    case AUTH_SESSION_REFRESH_ERROR:
    case USER_REMOVE_CURRENT_REQUEST:
      return {
        ...state,
        current: null,
      }
    case USER_LIST_GET_REQUEST:
    case USER_LIST_GET_IN_PROGRESS:
    case USER_LIST_GET_ERROR:
      return {
        ...state,
        status: type,
      }
    case USER_LIST_GET_SUCCESS:
      return {
        ...state,
        status: type,
        data: getIdsFromList(payload),
        byId: getByIdFromList(payload),
        statusById: getIdsFromList(payload).reduce(
          (status, id) => ({
            ...status,
            [id]: type,
          }),
          {}
        ),
        errorById: {},
      }
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [payload.uuid]: {
            ...state.byId[payload.uuid],
            ...payload,
          },
        },
        statusById: {
          ...state.statusById,
          [payload.uuid]: type,
        },
        errorById: {
          ...(state.errorById || {}),
          [payload.uuid]: undefined,
        },
      }
    case USER_UPDATE_ERROR:
      return {
        ...state,
        statusById: {
          ...state.statusById,
          [payload.uuid]: type,
        },
        errorById: {
          ...(state.errorById || {}),
          [payload.uuid]: error,
        },
      }
    case USER_DELETE_SUCCESS: {
      const { data, byId, statusById, errorById, ...restState } = state
      const { uuid } = payload
      return {
        ...restState,
        data: data.filter((id) => id !== uuid),
        byId: {
          ...byId,
          [uuid]: undefined,
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        errorById: {
          ...errorById,
          [uuid]: undefined,
        },
      }
    }
    case ORGANISATION_LIST_GET_SUCCESS:
      return {
        ...state,
        current: state.current
          ? {
              ...state.current,
              organisation: isSuperAdmin
                ? null
                : action.payload && action.payload[0],
            }
          : state.current,
      }
    case AUTH_LOGOUT_SUCCESS:
      return initialState
    default:
      return state
  }
}

export default reducer
