import React from 'react'

const ChecklistsIcon = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="5.5" cy="5.5" r="5" fill="#B0B0B1" />
    <path
      d="M3 5.99999L4.50001 7.49999L8 4"
      stroke="white"
      strokeLinejoin="round"
    />
  </svg>
)

export default ChecklistsIcon
