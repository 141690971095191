import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-constraint'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'

// GET CONSTRAINT
const ACTION_GET = 'CONSTRAINT_GET'
export const [
  CONSTRAINT_GET_REQUEST,
  CONSTRAINT_GET_RESULT,
  CONSTRAINT_GET_SUCCESS,
  CONSTRAINT_GET_ERROR,
  CONSTRAINT_GET_CANCEL,
  CONSTRAINT_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getConstraint = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getConstraintRequest(uuid))
}
export const getConstraintRequest = (uuid) => async (dispatch) => {
  dispatch(getConstraintInProgress(uuid))
  try {
    const constraintResult = await api.getConstraint(uuid)
    return dispatch(getConstraintSuccess(constraintResult))
  } catch (error) {
    return dispatch(getConstraintError(error))
  }
}
export const getConstraintInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getConstraintSuccess = (constraint) =>
  createSuccessAction(ACTION_GET, constraint)
export const getConstraintError = (error) =>
  createErrorAction(ACTION_GET, error)

// GET CONSTRAINT_LIST
const ACTION_GET_LIST = 'CONSTRAINT_LIST_GET'
export const [
  CONSTRAINT_LIST_GET_REQUEST,
  CONSTRAINT_LIST_GET_RESULT,
  CONSTRAINT_LIST_GET_SUCCESS,
  CONSTRAINT_LIST_GET_ERROR,
  CONSTRAINT_LIST_GET_CANCEL,
  CONSTRAINT_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getConstraintList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getConstraintListRequest(uuid))
}
export const getConstraintListRequest = (uuid) => async (dispatch) => {
  dispatch(getConstraintListInProgress(uuid))
  try {
    const constraintListResult = await api.getConstraints(uuid)
    return dispatch(getConstraintListSuccess(constraintListResult))
  } catch (error) {
    return dispatch(getConstraintListError(error))
  }
}
export const getConstraintListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getConstraintListSuccess = (constraint) =>
  createSuccessAction(ACTION_GET_LIST, constraint)
export const getConstraintListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE CONSTRAINT
const ACTION_CREATE = 'CONSTRAINT_CREATE'
export const [
  CONSTRAINT_CREATE_REQUEST,
  CONSTRAINT_CREATE_RESULT,
  CONSTRAINT_CREATE_SUCCESS,
  CONSTRAINT_CREATE_ERROR,
  CONSTRAINT_CREATE_CANCEL,
  CONSTRAINT_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createConstraint = (constraint) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: constraint })
  return dispatch(createConstraintRequest(constraint))
}
export const createConstraintRequest = (payload) => async (dispatch) => {
  dispatch(createConstraintInProgress(payload))
  try {
    const constraintResult = await api.createConstraint(payload)
    return dispatch(createConstraintSuccess(constraintResult))
  } catch (error) {
    return dispatch(createConstraintError(error, payload))
  }
}
export const createConstraintInProgress = (constraint) =>
  createInProgressAction(ACTION_CREATE, constraint)
export const createConstraintSuccess = (constraint) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        constraint.isWaypoint ? TOAST_MESSAGES.WAYPOINT_CREATE_SUCCESS : TOAST_MESSAGES.CONSTRAINT_CREATE_SUCCESS,
        constraint
      ),
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, constraint))
}
export const createConstraintError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(payload.isWaypoint ? TOAST_MESSAGES.WAYPOINT_CREATE_ERROR : TOAST_MESSAGES.CONSTRAINT_CREATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE constraint
const ACTION_UPDATE = 'CONSTRAINT_UPDATE'
export const [
  CONSTRAINT_UPDATE_REQUEST,
  CONSTRAINT_UPDATE_RESULT,
  CONSTRAINT_UPDATE_SUCCESS,
  CONSTRAINT_UPDATE_ERROR,
  CONSTRAINT_UPDATE_CANCEL,
  CONSTRAINT_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateConstraint = (constraint) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: constraint })
  return dispatch(updateConstraintRequest(constraint))
}
export const updateConstraintRequest = (payload) => async (dispatch) => {
  dispatch(updateConstraintInProgress(payload))
  try {
    const constraintResult = await api.updateConstraint(payload)
    return dispatch(updateConstraintSuccess(constraintResult))
  } catch (error) {
    return dispatch(updateConstraintError(error, payload))
  }
}
export const updateConstraintInProgress = (constraint) =>
  createInProgressAction(ACTION_UPDATE, constraint)
export const updateConstraintSuccess = (constraint) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        constraint.isWaypoint ? TOAST_MESSAGES.WAYPOINT_UPDATE_SUCCESS : TOAST_MESSAGES.CONSTRAINT_UPDATE_SUCCESS,
        constraint
      ),
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, constraint))
}
export const updateConstraintError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(payload.isWaypoint ? TOAST_MESSAGES.WAYPOINT_UPDATE_ERROR : TOAST_MESSAGES.CONSTRAINT_UPDATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE constraint (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'CONSTRAINT_SAVE'
export const saveConstraint = (constraint) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: constraint })
  const { uuid, ...constraintNoUuuid } = constraint
  const result = await dispatch(
    uuid ? updateConstraint(constraint) : createConstraint(constraintNoUuuid)
  )
  return result
}

// DELETE constraint
const ACTION_DELETE = 'CONSTRAINT_DELETE'
export const [
  CONSTRAINT_DELETE_REQUEST,
  CONSTRAINT_DELETE_RESULT,
  CONSTRAINT_DELETE_SUCCESS,
  CONSTRAINT_DELETE_ERROR,
  CONSTRAINT_DELETE_CANCEL,
  CONSTRAINT_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteConstraint = (constraint) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: constraint })
  return dispatch(deleteConstraintRequest(constraint))
}
export const deleteConstraintRequest = (constraint) => async (dispatch) => {
  dispatch(deleteConstraintInProgress(constraint))
  try {
    await api.deleteConstraint(constraint.uuid)
    return dispatch(deleteConstraintSuccess(constraint))
  } catch (error) {
    dispatch(deleteConstraintError(error))
    return error
  }
}
export const deleteConstraintInProgress = (constraint) =>
  createInProgressAction(ACTION_DELETE, constraint)
export const deleteConstraintSuccess = (constraint) =>
  createSuccessAction(ACTION_DELETE, constraint)
export const deleteConstraintError = (error) =>
  createErrorAction(ACTION_DELETE, error)
