import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import InputAdornment from '@material-ui/core/InputAdornment'
import MovementTypeSelector from 'src/components/molecules/MovementTypeSelector'
import ListInput from 'src/components/organisms/ListInput'
import Fieldset from 'src/components/organisms/Fieldset'
import { PortShape } from 'src/utils/types'
import Preloader from 'src/components/atoms/Preloader'
import { EXTRA_TYPE } from 'src/constants/settings'
import { AddButton } from 'src/components/organisms/Checklist/common'
import { CautionIcon, CheckIcon } from 'src/components/atoms/Icons'
import useChecklist from 'src/hooks/useChecklist'
import ChecklistPdfExportToggle from './ChecklistPdfExportToggle'
import uuidv1 from 'uuid'

export const StyledCautionIcon = styled(CautionIcon)`
  margin-left: ${({ theme }) => theme.spacing(0.5)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const PassFailChecklist = ({ port }) => {
  const { fields, loaded, dirty, handleSave } = useChecklist({
    extraType: EXTRA_TYPE.PASS_FAIL_CHECKLIST,
    defaultItems: [],
  })

  const saveChecklist = () => handleSave(port)
  const handleAddClick = () => {
    fields.checklist.onChange('checklist', [
      ...fields.checklist.value,
      { label: undefined, uuid: uuidv1() },
    ])
  }

  const limit = 100
  const hasReachedLimit = fields.checklist.value.length >= limit

  return (
    <Fieldset
      title="Pass/Caution Checklist"
      description="Pass/Caution Checklist for the pilot app"
      hasButton
      buttonContents="Add Item"
      clickHandler={handleAddClick}
      buttonDisabled={hasReachedLimit}
    >
      {!loaded && <Preloader />}
      {loaded && (
        <ListInput
          sortable
          name="checklist"
          fullWidth
          AddButton={AddButton}
          onSave={saveChecklist}
          valueProp="label"
          limit={100}
          setHeaderColor
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <CheckIcon isSuccessColor size={22} />
                <StyledCautionIcon size={22} />
              </InputAdornment>
            ),
          }}
          ExtraControlsComponent={MovementTypeSelector}
          emptyPlaceholder="No items added yet."
          {...fields.checklist}
          dirty={dirty}
        >
          <ChecklistPdfExportToggle flagName="show_passfail_in_master_view" />
        </ListInput>
      )}
    </Fieldset>
  )
}

PassFailChecklist.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default React.memo(PassFailChecklist)
