import React, { useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { routeNode, useRoute } from 'react-router5'
import { actions as routerActions } from 'redux-router5'
import idx from 'idx'
import api from 'src/utils/sauce/api'
import { ForbiddenError } from 'src/utils/sauce/api-error'

import {
  AUTH,
  AUTH_LOGIN,
  PILOTS,
  HOME,
  SETTINGS,
  isPrivateRoute,
  ORGANISATIONS,
  SETTINGS_CREATE,
  AUTH_REFRESH_SESSION,
  LOGOUT,
  PILOTAGES_PDF,
} from 'src/router/routes'
import { isLoggedInSelector } from 'src/store/auth/selectors'
import {
  getTokenFromStorage,
  getRefreshTokenFromStorage,
} from 'src/utils/storage'

import { DefaultLayout, UnauthenticatedLayout } from 'src/components/layouts'

import Pilots from 'src/containers/Pilots'
import Auth from 'src/containers/Auth'
import LoginSso from 'src/containers/LoginSso'
import PilotagePdfDownload from 'src/components/organisms/PilotagePdfDownload'
import Toast from 'src/containers/Toast'
import OfflineWarning from 'src/components/molecules/OfflineWarning'
import Settings from 'src/containers/Settings'
import PageNotFound from 'src/components/molecules/PageNotFound'
import Organisations from 'src/containers/Organisations'
import { isSuperAdminSelector } from 'src/store/user/selectors'
import RefreshSession from 'src/components/organisms/RefreshSession'
import usePortsData from 'src/hooks/usePorts'
import useAnalytics from 'src/hooks/useAnalytics'

import {
  selectedPortSelector,
  dataSelector as portsDataSelector,
} from 'src/store/port/selectors'
import ViewportLoader from 'src/components/atoms/ViewportLoader'
import { selectPort } from 'src/store/port/actions'
import NoPortPage from 'src/components/molecules/NoPortPage'
import { LOGINSSO } from './../../router/routes';
import { isHostSsoDomain } from 'src/utils/ssoHelper'
import { requestLogout } from 'src/store/auth/actions'

const App: React.FC = () => {
  const { initGA, initHJ, recordPageView } = useAnalytics()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(isLoggedInSelector)
  const { route } = useRoute()
  const routeName = route.name
  const routeParamPortUuid = idx(route, (_) => _.params.portUuid)

  const parentRouteName = routeName.split('.').shift()
  const isSuperAdmin = useSelector(isSuperAdminSelector)
  const { data: ports, isLoaded: portsLoaded } = usePortsData()
  const selectedPortUuid = useSelector(selectedPortSelector)
  const portsUuid = useSelector(portsDataSelector)


  useEffect(() => {
    const interceptorId = api.interceptors.response.use(
      (response) => response,
      (error: Error) => {
        if (error instanceof ForbiddenError && isPrivateRoute(routeName)) {
          window.location.reload()
        }
        return Promise.reject(error)
      }
    )

    return () => api.interceptors.response.eject(interceptorId)
  }, [routeName])

  const portUuidIsValid = useCallback(
    (uuid) => {
      return !portsUuid || portsUuid.includes(uuid)
    },
    [portsUuid]
  )

  useEffect(() => {
    initGA()
    initHJ()
  }, [initGA, initHJ])

  useEffect(() => {
    recordPageView(route.name)
  }, [recordPageView, route])

  if (routeName === LOGOUT) {
    dispatch(requestLogout())
    return null
  }

  if (!isLoggedIn) {
    const token = getTokenFromStorage()
    const refreshToken = getRefreshTokenFromStorage()
    if (token && refreshToken) {
      return <RefreshSession />
    } else if (isPrivateRoute(routeName)) {
      if (isHostSsoDomain()) {
        dispatch(routerActions.navigateTo(LOGINSSO))
      } else {
        dispatch(routerActions.navigateTo(AUTH_LOGIN))
      }
      return null
    }
  }

  if (isLoggedIn) {
    if (!portsLoaded) {
      return <ViewportLoader>App is loading...</ViewportLoader>
    }

    if (!selectedPortUuid && routeParamPortUuid) {
      dispatch(selectPort(routeParamPortUuid))
    }
  }
  const showPortNotFoundPage =
    routeParamPortUuid && !portUuidIsValid(routeParamPortUuid)

  if (selectedPortUuid && showPortNotFoundPage) {
    dispatch(
      routerActions.navigateTo(routeName || HOME, {
        portUuid: selectedPortUuid,
      })
    )
  }

  const AppLayout = isLoggedIn ? DefaultLayout : UnauthenticatedLayout
  let Component
  if (
    isLoggedIn &&
    ((!ports || !ports.length) && routeName !== SETTINGS_CREATE) &&
    ![SETTINGS_CREATE, AUTH_REFRESH_SESSION].includes(routeName)
  ) {
    dispatch(routerActions.navigateTo(HOME))
    Component = NoPortPage
  } else {
    switch (parentRouteName) {
      case AUTH:
        Component = Auth
        break
      case LOGINSSO:
          Component = LoginSso
          break
      case PILOTAGES_PDF:
        Component = PilotagePdfDownload
        break
      case PILOTS:
        Component = Pilots
        break
      case ORGANISATIONS:
        Component = Organisations
        break
      case SETTINGS:
        Component = Settings
        break
      case HOME:
        Component = isLoggedIn
          ? isSuperAdmin
            ? Organisations
            : Settings
          : isHostSsoDomain() ? LoginSso : Auth
        break
      default:
        // should be 404
        Component = isLoggedIn ? PageNotFound : isHostSsoDomain() ? LoginSso : Auth
    }
  }
  return (
    <>
      <AppLayout>
        <>
          {!showPortNotFoundPage && <Component />}
          {showPortNotFoundPage && (
            <PageNotFound>Port not found. Please check the URL.</PageNotFound>
          )}
          <Toast />
          <OfflineWarning />
        </>
      </AppLayout>
    </>
  )
}

export default routeNode('')(App)
