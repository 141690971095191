import { createSelector } from 'reselect'
import {
  createGetListFilteredBySelectedPortSelector,
  createGetListFilteredByPortSelector,
} from 'src/utils/store'

import { imageTokenSelector } from 'src/store/auth/selectors'
import { defaultBaseUrl } from 'src/utils/sauce/api'

export const routeAttachmentStateSelector = (state) => {
  return state.routeAttachment
}

export const routeAttachmentListSelector = createSelector(
  routeAttachmentStateSelector,
  ({ data, byId }) =>
    data && byId && data
      .map((uuid) => byId[uuid])
      .filter(c => c)
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  routeAttachmentListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  routeAttachmentListSelector
)

export const routeAttachmentListStatusSelector = createSelector(
  routeAttachmentStateSelector,
  (state) => state.status
)

export const routeAttachmentByIndexSelector = (index) =>
  createSelector(
    routeAttachmentStateSelector,
    (state) => {
      const routeAttachment = state.data && state.data[index] && state.byId[state.data[index]]
      return routeAttachment
    }
  )

export const routeAttachmentByIdSelector = (uuid) =>
  createSelector(
    routeAttachmentStateSelector,
    (state) => {
      const routeAttachment = state.byId && state.byId[uuid]
      return routeAttachment
    }
  )

export const totalConstraintSelector = createSelector(
  routeAttachmentStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    routeAttachmentStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    routeAttachmentStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )

// Used to prview attachment from api
export const routeAttachmentApiUrlSelector = (uuid) =>
  createSelector(
    routeAttachmentByIdSelector(uuid),
    imageTokenSelector,
    (chart, imageToken) =>
      chart &&
      `${defaultBaseUrl}/routes_attachments/${uuid}/attachment?imageToken=${imageToken}`
  )
