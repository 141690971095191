import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'react-router5'

import { userPortSelector } from 'src/store/port/selectors'
import { SETTINGS_PORT } from 'src/router/routes'
import { addToast } from 'src/store/toast/actions'

export const useRedirectToPort = (cfg) => {
  const { message, routeName } = cfg || {}
  const router = useRouter()
  const dispatch = useDispatch()
  const port = useSelector(userPortSelector)
  if (!port || !port.uuid) {
    dispatch(
      addToast({
        message: message || 'Please set up your port first.',
      })
    )
    router.navigate(routeName || SETTINGS_PORT)
  }
}

export default useRedirectToPort
