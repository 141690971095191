import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addErrorToast } from 'src/store/toast/actions'
import { LOGINSSO } from './../router/routes'
import { SSO_LOAD_CONFIG_DELAY_ENABLED } from './../utils/ssoConstants'
import { sleep } from './../utils/ssoHelper'
import TOAST_MESSAGES from './../utils/toastMessages'
import { getSinglePayloadFromResponse } from 'src/utils/sauce/api'
import { getSso } from 'src/utils/sauce/sso'

// Simple non-redux hook to interface with BE SSO api to retrive SSO config.
const useSso = () => {
  const dispatch = useDispatch()

  const [ssoState, setSsoState] = useState({
    data: null,
    error: null,
    isLoading: true,
  })

  useEffect(() => {
    handleSsoRequest()
  }, [])

  const handleSsoRequest = useCallback(() => {
    const host = `${window.location.origin}/${LOGINSSO}`
    const redirectUrl = encodeURIComponent(host)
    ssoRequest(redirectUrl)
  }, [])

  const ssoRequest = async (redirectUrl) => {
    setSsoState((prevState) => ({...prevState, isLoading: true}))
    try {
      if (SSO_LOAD_CONFIG_DELAY_ENABLED) {
        await sleep(1000)
      }
      const response = await getSso(redirectUrl)
      const data = getSinglePayloadFromResponse(response)
      setSsoState((prevState) => ({...prevState, data, isLoading: false}))
      return data
    } catch (error) {
      setSsoState((prevState) => ({...prevState, error, isLoading: false}))
      dispatch(addErrorToast({message: TOAST_MESSAGES.LOAD_SSO_LOAD_ERROR}))
    }
  }

  return {
    ssoState,
    handleSsoRequest,
  }
}

export default useSso
