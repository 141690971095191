import React from 'react'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'

import { Box } from '@material-ui/core'
import UploadIcon from 'src/components/atoms/Icons/UploadIcon'
import PropTypes from 'prop-types'

const UploadContainer = styled.div`
  && {
    align-items: center;
    background: none transparent;
    border: 1px dashed ${({ theme }) => theme.palette.divider};
    border-radius: 10px;
    color: rgb(81, 81, 81);
    cursor: pointer;
    display: flex;
    padding: ${({ theme }) => theme.spacing(1.5, 4)};
    white-space: nowrap;
    ${({ leftAlign }) => leftAlign && 'margin-right: auto;'}
    ${({ rightAlign }) => rightAlign && 'margin-left: auto;'}
  }
`

const ButtonLabel = styled.div`
  font-size: ${({ theme }) => theme.typography.h4.fontSize};
  font-weight: ${({ theme }) => theme.weights.fontWeightSemiBold};
`

const ExtensionLabel = styled.div(
  ({ theme }) => css`
    color: ${theme.palette.text.secondary};
    font-size: ${theme.typography.pxToRem(16)};
    font-weight: ${theme.weights.fontWeightSemiBold};
  `
)

const UploadButtonBase = ({
  label,
  extensionsLabel,
  onSelect,
  className,
  forwardRef,
  leftAlign,
  rightAlign,
  children,
  Container = UploadContainer,
  ...dropZoneProps
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: 'image/*',
    onDropAccepted: onSelect,
    ...dropZoneProps,
  })

  return (
    <Container
      leftAlign={leftAlign}
      rightAlign={rightAlign}
      className={className}
      {...getRootProps()}
      ref={forwardRef}
    >
      <input {...getInputProps()} />
      {children || (
        <>
          <UploadIcon />
          <Box ml={2}>
            {label && <ButtonLabel>{label}</ButtonLabel>}
            {extensionsLabel && (
              <ExtensionLabel>{extensionsLabel}</ExtensionLabel>
            )}
          </Box>
        </>
      )}
    </Container>
  )
}

// eslint-disable-next-line react/display-name
export const UploadButton = React.forwardRef(
  (
    {
      label = '',
      extensionsLabel = '',
      onSelect = null,
      className = '',
      children = null,
      Container = UploadContainer,
      ...dropZoneProps
    },
    ref
  ) => (
    <UploadButtonBase
      label={label}
      extensionsLabel={extensionsLabel}
      onSelect={onSelect}
      className={className}
      Container={Container}
      forwardRef={ref}
      {...dropZoneProps}
    >
      {children}
    </UploadButtonBase>
  )
)

UploadButtonBase.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  extensionsLabel: PropTypes.string,
  onSelect: PropTypes.func,
  forwardRef: PropTypes.any,
  leftAlign: PropTypes.bool,
  rightAlign: PropTypes.bool,
  children: PropTypes.node,
  Container: PropTypes.elementType,
}
UploadButton.propTypes = UploadButtonBase.propTypes

export default UploadButton
