import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-tide-rate-stations'
import { TIDE_RATE_DATA_CREATE_ERROR } from 'src/store/tideRateData/actions'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import idx from 'idx'

const getErrorMessage = (errorPayload) => {
  const { type, error } = errorPayload
  const { status } = error || {}
  let message
  if (type === TIDE_RATE_DATA_CREATE_ERROR) {
    const responseMessage = idx(error, (_) => _.response.data.message)
    message =
      'Tide rate data error: ' +
      (status > 500
        ? TOAST_MESSAGES.TIDE_RATE_DATA_SERVER_ERROR
        : status === 400
        ? TOAST_MESSAGES.TIDE_RATE_DATA_CSV_ERROR
        : status === 403
        ? TOAST_MESSAGES.TIDE_RATE_DATA_FORBIDDEN_ERROR
        : status === 422
        ? responseMessage || TOAST_MESSAGES.TIDE_RATE_DATA_CSV_ERROR
        : TOAST_MESSAGES.TIDE_RATE_STATION_CREATE_ERROR)
  } else {
    message = getToastMessage(
      TOAST_MESSAGES.TIDE_RATE_STATION_CREATE_ERROR,
      errorPayload
    )
  }
  return message
}

// GET TIDE_RATE_STATION
const ACTION_GET = 'TIDE_RATE_STATION_GET'
export const [
  TIDE_RATE_STATION_GET_REQUEST,
  TIDE_RATE_STATION_GET_RESULT,
  TIDE_RATE_STATION_GET_SUCCESS,
  TIDE_RATE_STATION_GET_ERROR,
  TIDE_RATE_STATION_GET_CANCEL,
  TIDE_RATE_STATION_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getTideRateStation = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getTideRateStationRequest(uuid))
}
export const getTideRateStationRequest = (uuid) => async (dispatch) => {
  dispatch(getTideRateStationInProgress(uuid))
  try {
    const tideRateStationResult = await api.getTideRateStation(uuid)
    return dispatch(getTideRateStationSuccess(tideRateStationResult))
  } catch (error) {
    return dispatch(getTideRateStationError(error))
  }
}
export const getTideRateStationInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getTideRateStationSuccess = (tideRateStation) =>
  createSuccessAction(ACTION_GET, tideRateStation)
export const getTideRateStationError = (error) =>
  createErrorAction(ACTION_GET, error)

// GET TIDE_RATE_STATION_LIST
const ACTION_GET_LIST = 'TIDE_RATE_STATION_LIST_GET'
export const [
  TIDE_RATE_STATION_LIST_GET_REQUEST,
  TIDE_RATE_STATION_LIST_GET_RESULT,
  TIDE_RATE_STATION_LIST_GET_SUCCESS,
  TIDE_RATE_STATION_LIST_GET_ERROR,
  TIDE_RATE_STATION_LIST_GET_CANCEL,
  TIDE_RATE_STATION_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getTideRateStationList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getTideRateStationListRequest(uuid))
}
export const getTideRateStationListRequest = (uuid) => async (dispatch) => {
  dispatch(getTideRateStationListInProgress(uuid))
  try {
    const tideRateStationListResult = await api.getTideRateStations(uuid)
    return dispatch(getTideRateStationListSuccess(tideRateStationListResult))
  } catch (error) {
    return dispatch(getTideRateStationListError(error))
  }
}
export const getTideRateStationListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getTideRateStationListSuccess = (tideRateStation) =>
  createSuccessAction(ACTION_GET_LIST, tideRateStation)
export const getTideRateStationListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE TIDE_RATE_STATION
const ACTION_CREATE = 'TIDE_RATE_STATION_CREATE'
export const [
  TIDE_RATE_STATION_CREATE_REQUEST,
  TIDE_RATE_STATION_CREATE_RESULT,
  TIDE_RATE_STATION_CREATE_SUCCESS,
  TIDE_RATE_STATION_CREATE_ERROR,
  TIDE_RATE_STATION_CREATE_CANCEL,
  TIDE_RATE_STATION_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createTideRateStation = (tideRateStation) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: tideRateStation })
  return dispatch(createTideRateStationRequest(tideRateStation))
}
export const createTideRateStationRequest = (payload) => async (dispatch) => {
  dispatch(createTideRateStationInProgress(payload))
  try {
    const tideRateStationResult = await api.createTideRateStation(payload)
    dispatch(
      addSuccessToast({
        message: getToastMessage(
          TOAST_MESSAGES.TIDE_RATE_STATION_CREATE_SUCCESS,
          tideRateStationResult
        ),
      })
    )
    return dispatch(createTideRateStationSuccess(tideRateStationResult))
  } catch (error) {
    dispatch(addErrorToast({ message: getErrorMessage(error) }))
    return error.type === TIDE_RATE_DATA_CREATE_ERROR
  }
}
export const createTideRateStationInProgress = (tideRateStation) =>
  createInProgressAction(ACTION_CREATE, tideRateStation)
export const createTideRateStationSuccess = (payload) =>
  createSuccessAction(ACTION_CREATE, payload)
export const createTideRateStationError = (error) =>
  createErrorAction(ACTION_CREATE, error)

// UPDATE tideRateStation
const ACTION_UPDATE = 'TIDE_RATE_STATION_UPDATE'
export const [
  TIDE_RATE_STATION_UPDATE_REQUEST,
  TIDE_RATE_STATION_UPDATE_RESULT,
  TIDE_RATE_STATION_UPDATE_SUCCESS,
  TIDE_RATE_STATION_UPDATE_ERROR,
  TIDE_RATE_STATION_UPDATE_CANCEL,
  TIDE_RATE_STATION_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateTideRateStation = (tideRateStation) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: tideRateStation })
  return dispatch(updateTideRateStationRequest(tideRateStation))
}
export const updateTideRateStationRequest = (data) => async (dispatch) => {
  dispatch(updateTideRateStationInProgress(data))
  try {
    const tideRateStationResult = await api.updateTideRateStation(data)
    dispatch(
      addSuccessToast({
        message: getToastMessage(
          TOAST_MESSAGES.TIDE_RATE_STATION_UPDATE_SUCCESS,
          tideRateStationResult
        ),
      })
    )
    return dispatch(updateTideRateStationSuccess(tideRateStationResult))
  } catch (error) {
    dispatch(
      addErrorToast({
        message: getErrorMessage(error),
      })
    )
    return dispatch(updateTideRateStationError(error))
  }
}
export const updateTideRateStationInProgress = (tideRateStation) =>
  createInProgressAction(ACTION_UPDATE, tideRateStation)
export const updateTideRateStationSuccess = (tideRateStation) =>
  createSuccessAction(ACTION_UPDATE, tideRateStation)
export const updateTideRateStationError = (error) =>
  createErrorAction(ACTION_UPDATE, error)

// SAVE tideRateStation (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'TIDE_RATE_STATION_SAVE'
export const saveTideRateStation = (tideRateStation) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: tideRateStation })
  return dispatch(
    tideRateStation.uuid
      ? updateTideRateStation(tideRateStation)
      : createTideRateStation(tideRateStation)
  )
}

// DELETE tideRateStation
const ACTION_DELETE = 'TIDE_RATE_STATION_DELETE'
export const [
  TIDE_RATE_STATION_DELETE_REQUEST,
  TIDE_RATE_STATION_DELETE_RESULT,
  TIDE_RATE_STATION_DELETE_SUCCESS,
  TIDE_RATE_STATION_DELETE_ERROR,
  TIDE_RATE_STATION_DELETE_CANCEL,
  TIDE_RATE_STATION_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteTideRateStation = (tideRateStation) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: tideRateStation })
  return dispatch(deleteTideRateStationRequest(tideRateStation))
}
export const deleteTideRateStationRequest = (tideRateStation) => async (dispatch) => {
  dispatch(deleteTideRateStationInProgress(tideRateStation))
  try {
    await api.deleteTideRateStation(tideRateStation.uuid)
    return dispatch(deleteTideRateStationSuccess(tideRateStation))
  } catch (error) {
    dispatch(deleteTideRateStationError(error))
    return error
  }
}
export const deleteTideRateStationInProgress = (tideRateStation) =>
  createInProgressAction(ACTION_DELETE, tideRateStation)
export const deleteTideRateStationSuccess = (tideRateStation) =>
  createSuccessAction(ACTION_DELETE, tideRateStation)
export const deleteTideRateStationError = (error) =>
  createErrorAction(ACTION_DELETE, error)
