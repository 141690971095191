import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import classNames from 'classnames'

export const StyledLabel = styled.label`
  display: block;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: uppercase;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  margin-bottom: 5px;
`

export const StyledLabelNarrow = styled(StyledLabel)`
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  text-transform: none;
`

export const StyledErrorMessage = styled.div`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 0.85em;
  position: absolute;
  right: 0;
`

export const FieldWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(1)}px;
  position: relative;
`

export const Input = ({
  id,
  label,
  error,
  disabled,
  pristine,
  touched,
  dirty,
  className,
  narrow,
  Component,
  children,
  forwardLabel,
  ...inputProps
}) => {
  const inputId = id || `input-${Date.now()}`
  const clsName = classNames(className, { dirty, touched, pristine })
  const Label = narrow ? StyledLabelNarrow : StyledLabel
  return (
    <FieldWrapper className={clsName}>
      {!forwardLabel && label && (
        <Label disabled={disabled} htmlFor={inputId}>
          {label}
        </Label>
      )}
      <Component
        className={className}
        variant="outlined"
        disabled={disabled}
        error={!!error}
        id={inputId}
        label={forwardLabel && label}
        {...inputProps}
      >
        {children}
      </Component>
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </FieldWrapper>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  error: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  pristine: PropTypes.bool,
  touched: PropTypes.bool,
  dirty: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  narrow: PropTypes.bool,
  Component: PropTypes.elementType,
  children: PropTypes.node,
  forwardLabel: PropTypes.bool,
}

export default Input
