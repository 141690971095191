import React from 'react'
import PropTypes from 'prop-types'
import InputAdornment from '@material-ui/core/InputAdornment'
import styled from 'styled-components'
import ListInput from 'src/components/organisms/ListInput'
import Fieldset from 'src/components/organisms/Fieldset'
import { PortShape } from 'src/utils/types'
import Preloader from 'src/components/atoms/Preloader'
import { EXTRA_TYPE } from 'src/constants/settings'
import { CheckIcon } from 'src/components/atoms/Icons'
import useChecklist from 'src/hooks/useChecklist'
import { Box, Switch } from '@material-ui/core'
import uuidv1 from 'uuid'

const StyledCheckIcon = styled(CheckIcon)`
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

const DEFAULT_FLOWLIST = [
  { label: 'Vessel', isHeader: true, },
  { label: 'Tides', isHeader: true, },
  { label: 'Weather', isHeader: true, },
  { label: 'Pilotage Plan', isHeader: true, },
  { label: 'Checklist', isHeader: true, },
  { label: 'Acceptance', isHeader: true, },
  { label: 'Tugs', isHeader: true, },
  { label: 'Admin', isHeader: true, },
]

const Flowlist = ({ port }) => {
  const { fields, loaded, dirty, handleSave } = useChecklist({
    extraType: EXTRA_TYPE.FLOWLIST,
    defaultItems: DEFAULT_FLOWLIST,
  })

  const saveChecklist = () => handleSave(port)

  const handleResetClick = () => {
    fields.checklist.onChange('checklist', DEFAULT_FLOWLIST.map((item) => item.uuid ? item : { ...item, uuid: uuidv1() }))
  }

  const limit = 100
  const hasReachedLimit =
    typeof limit === 'number' && fields.checklist.value.length >= limit

  return (
    <Fieldset
      title="Flowlist"
      description="Flowlist for the pilot app"
      hasButton
      isButtonReset
      buttonContents="Reset"
      clickHandler={handleResetClick}
      buttonDisabled={hasReachedLimit}
    >
      {!loaded && <Preloader />}
      {loaded && (
        <ListInput
          sortable
          name="checklist"
          fullWidth
          onSave={saveChecklist}
          valueProp="label"
          limit={limit}
          readOnly
          setHeaderColor
          hideDeleteButton
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <StyledCheckIcon isSuccessColor size={22} />
              </InputAdornment>
            ),
          }}
          ExtraControlsComponent={({ item, onChange }) => {
            const isHeader = item.isHeader || false
            const handleToggleIsHeader = (isHeader) => {
              onChange({
                ...item,
                isHeader,
              })
            }
            return (
              <Box mr={1}>
                <Switch
                  name="toggle"
                  color="primary"
                  checked={isHeader}
                  onChange={(_event, checked) => handleToggleIsHeader(checked)}
                />
              </Box>
            )
          }}
          {...fields.checklist}
          dirty={dirty}
        >
        </ListInput>
      )}
    </Fieldset>
  )
}

Flowlist.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default React.memo(Flowlist)
