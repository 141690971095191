import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import Fieldset from 'src/components/organisms/Fieldset'
import DialogHeader from 'src/components/molecules/DialogHeader/DialogHeader'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import SaveProgress from 'src/components/atoms/SaveProgress'

export const FormDialog = ({
  FormComponent,
  formComponentProps,
  onSave,
  onCancel,
  onChange,
  title,
  subTitle,
  saveButtonLabel,
  cancelButtonLabel,
  disabled,
  submitting,
  entity,
  open,
  formEntityProp = 'entity',
  alwaysEnableSaveBtn = false,
  ...dialogProps
}) => {
  const [formState, setFormState] = useState({
    dirty: false,
    isValid: true,
  })
  const [confirmCancel, setConfirmCancel] = useState(false)
  const formValues = useRef(entity)
  const handleFormChange = (change) => {
    const { isValid, dirty } = change
    if (isValid !== formState.isValid || dirty !== formState.dirty) {
      setFormState({ isValid, dirty })
    }
    if (onChange) {
      onChange(change)
    }
    formValues.current = change.values
  }
  const handleSave = useCallback(() => {
    if (onSave) {
      onSave(formValues.current)
    }
  }, [onSave])

  const handleCloseRequest = () => {
    if (formState.dirty) {
      setConfirmCancel(true)
    } else {
      onCancel()
    }
  }
  const handleCancelChangesAndClose = () => {
    setConfirmCancel(false)
    onCancel()
  }

  const handleRejectCloseRequest = () => setConfirmCancel(false)

  return (
    <Dialog open={open} onClose={handleCloseRequest} {...dialogProps}>
      {submitting && <SaveProgress />}
      {title && <DialogHeader disableTypography>{title}</DialogHeader>}
      <DialogContent>
        <Fieldset fullWidth title={subTitle} dirty={formState.dirty}>
          <FormComponent
            {...{
              [formEntityProp]: entity,
            }}
            onChange={handleFormChange}
            disabled={disabled || submitting}
            {...formComponentProps}
          />
        </Fieldset>
      </DialogContent>
      <DialogActions>
        <SecondaryButton onClick={handleCloseRequest} disabled={submitting}>
          {cancelButtonLabel || 'Cancel'}
        </SecondaryButton>
        <PrimaryButton
          disabled={
            !formState.isValid ||
            submitting ||
            (!alwaysEnableSaveBtn && !formState.dirty)
          }
          onClick={formState.dirty ? handleSave : handleCloseRequest}
        >
          {saveButtonLabel || (entity.uuid ? 'Update' : 'Create')}
        </PrimaryButton>
      </DialogActions>
      <ConfirmDialog
        open={confirmCancel}
        title="Unsaved changes"
        onCancel={handleRejectCloseRequest}
        onConfirm={handleCancelChangesAndClose}
      >
        You have unsaved changes. Are you sure you want to close?
      </ConfirmDialog>
    </Dialog>
  )
}

FormDialog.propTypes = {
  FormComponent: PropTypes.elementType,
  formComponentProps: PropTypes.object,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  saveButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  entity: PropTypes.object,
  open: PropTypes.bool,
  formEntityProp: PropTypes.string,
  alwaysEnableSaveBtn: PropTypes.bool,
}

export default FormDialog
