import PropTypes from 'prop-types'
import React from 'react'
import IconButton from '@material-ui/core/IconButton'

const ValuedIconButton = ({ children, onClick, value }) => {
  const handleClick = (event) => {
    if (onClick) {
      onClick(event, value)
    }
  }

  return <IconButton onClick={handleClick}>{children}</IconButton>
}

ValuedIconButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.any,
}

export default ValuedIconButton
