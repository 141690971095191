import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Fab, IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const CircleIconButton = styled(Fab).attrs({
  size: 'small',
})`
  && {
    background-color: ${({ theme }) => theme.palette.background.paper};
    height: 27px;
    min-height: 27px;
    min-width: 27px;
    width: 27px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`

// eslint-disable-next-line react/display-name
export const RemoveIconButton = React.forwardRef(
  ({ children, Icon = HighlightOffIcon, ...props }, ref) => (
    <IconButton ref={ref} {...props}>
      <Icon color="action" />
      {children}
    </IconButton>
  )
)
RemoveIconButton.propTypes = {
  size: PropTypes.string,
  Icon: PropTypes.elementType,
  children: PropTypes.node,
  onClick: PropTypes.any,
}

// eslint-disable-next-line react/display-name
export const RemoveButton = React.forwardRef(
  ({ children, Icon = HighlightOffIcon, ...props }, ref) => (
    <CircleIconButton ref={ref} {...props}>
      <Icon color="action" />
      {children}
    </CircleIconButton>
  )
)

RemoveButton.propTypes = {
  size: PropTypes.string,
  Icon: PropTypes.elementType,
  children: PropTypes.node,
  onClick: PropTypes.any,
}

export default React.memo(RemoveIconButton)
