import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { DateTime, Interval } from 'luxon'
import PropTypes from 'prop-types'
import React, { useState, Component } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import Fieldset from 'src/components/organisms/Fieldset'
import { pilotagesExportRequest } from 'src/store/pilotagesExport/actions'
import { pilotagesExportIsLoadingSelector } from 'src/store/pilotagesExport/selectors'
import { addSuccessToast } from 'src/store/toast/actions'
import { userNameSelector } from 'src/store/user/selectors'
import { TOAST_MESSAGES } from 'src/utils/toastMessages'
import { PortShape } from 'src/utils/types'
import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import TextInput from 'src/components/atoms/TextInput/TextInput'
import { DATE_FORMAT, PILOTAGE_EXPORT_MAX_DAYS } from 'src/constants/settings'
class CustomDatePickerInput extends Component {
  render () {
    return (
      <div className="react-datepicker-ignore-onclickoutside">
        <div style={{ position: 'relative' }}>
          <TextInput
            label={this.props.labelname}
            InputProps={{
              autoComplete: 'off',
              placeholder: DATE_FORMAT,
              readOnly: true,
            }}
            {...this.props}
          />
          <div />
        </div>
      </div>
    )
  }
}

CustomDatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  labelname: PropTypes.string,
}

const SubheadingText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-left: 10px;
    margin-bottom: 20px;
  }
`

const LeftAlignedPrimaryButton = styled(PrimaryButton)`
  & {
    margin-left: inherit !important;
  }
`

export const PilotageDownload = ({ disabled, port }) => {
  const dispatch = useDispatch()
  const name = useSelector(userNameSelector) || ''
  const submitting = useSelector(pilotagesExportIsLoadingSelector)

  const dateLuxonNow = DateTime.now()
  let dateLuxonYesterday = dateLuxonNow.plus({ days: -1 })
  dateLuxonYesterday = dateLuxonYesterday.set({ hours: 23, minutes: 59, seconds: 59, milliseconds: 999 })
  const [toDate, setToDate] = useState(dateLuxonYesterday.toJSDate())
  let dateLuxon2WeeksAgo = dateLuxonNow.plus({ days: -PILOTAGE_EXPORT_MAX_DAYS })
  dateLuxon2WeeksAgo = dateLuxon2WeeksAgo.set({ hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
  const [fromDate, setFromDate] = useState(dateLuxon2WeeksAgo.toJSDate())

  let dateRangeError
  const luxonDateFrom = DateTime.fromJSDate(fromDate)
  const luxonDateTo = DateTime.fromJSDate(toDate)
  if (luxonDateFrom.valueOf() >= luxonDateTo.valueOf()) {
    dateRangeError = 'From must be earlier than To date'
  } else {
    const daysDiff = Interval.fromDateTimes(luxonDateFrom, luxonDateTo).length('days')
    if (daysDiff > PILOTAGE_EXPORT_MAX_DAYS) {
      dateRangeError = `Invalid date range, max ${PILOTAGE_EXPORT_MAX_DAYS} days`
    }
  }

  const handleStartProcess = async () => {
    const luxonDateFrom = DateTime.fromJSDate(fromDate)
    const luxonDateTo = DateTime.fromJSDate(toDate)
    const data = await dispatch(pilotagesExportRequest({
      portUuid: port.uuid,
      fromDate: luxonDateFrom.toISO(),
      toDate: luxonDateTo.toISO(),
    }))
    if (data) {
      const url = window.URL.createObjectURL(
        new Blob(data, {
          type: 'application/octet-stream',
        })
      )
      const link = document.createElement('a')
      link.href = url
      const dateLuxon = DateTime.now()
      const timesampStr = dateLuxon.toFormat('yyyyLLddHHmmss')
      const filename = `pilotage_export_${timesampStr}_${name}.csv`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      dispatch(addSuccessToast({ message: TOAST_MESSAGES.DOWNLOAD_SUCCESS }))
    }
  }

  const description = `Here you can download previous pilotages (up to 14 days) for your organisation.
  Note the export file only includes pilotages with a status of Done.`

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Pilotage Download"
        description={description}
      >
        <Box>
          <SubheadingText>{description}</SubheadingText>
        </Box>
        <Box>
          <DatePicker
            selected={fromDate}
            highlightDates={[dateLuxonNow.toJSDate()]}
            dateFormat="d MMM yyyy"
            onChange={date => setFromDate(date)}
            disabled={submitting}
            customInput={
              <CustomDatePickerInput labelname={'From Date'} error={dateRangeError} />
            }
            readOnly={submitting}
          />
        </Box>
        <Box>
          <DatePicker
            selected={toDate}
            highlightDates={[dateLuxonNow.toJSDate()]}
            dateFormat="d MMM yyyy"
            onChange={date => setToDate(date)}
            disabled={submitting}
            customInput={
              <CustomDatePickerInput labelname={'To Date'} error={dateRangeError} />
            }
            readOnly={submitting}
          />
        </Box>
        <Box>
          <LeftAlignedPrimaryButton
            disabled={submitting || !!dateRangeError}
            onClick={handleStartProcess}
          >Download</LeftAlignedPrimaryButton>
        </Box>
      </Fieldset>
    </>
  )
}

PilotageDownload.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
}

export default PilotageDownload
