import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useRouter } from 'react-router5'
import { requestLoginSso } from 'src/store/auth/actions'
import { addErrorToast } from 'src/store/toast/actions'
import { SSO_EMPX_LOGIN_DELAY_ENABLED, SSO_ENABLE_CONSOLE_LOG, SSO_SIM_ERROR_ACCOUNT_FOR_TOKEN, SSO_SIM_ERROR_SEND_NULL_TOKEN } from './../utils/ssoConstants'
import { sleep } from './../utils/ssoHelper'
import TOAST_MESSAGES from './../utils/toastMessages'
import { getLoginRequest } from './useMsal'
import { USER_ROLES } from './../constants/user'

// Non-redux hook to acquire a silent token from MSAL lib and subsequently call BE to login SSO user.
const useSsoLogin = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  let mounted = useRef(true)

  useEffect(() => {
    return () => {
      mounted.current = false
    }
  }, [])

  const [loginState, setLoginState] = useState({
    account: null, // Set account used to log into BE, useful if we need to trace which account from MSAL's point of view was used to log into BE
    data: null, // Data from BE upon successful login
    tokenError: null, // Error if cannot retrive token from MSAL
    roleError: null, // Error if not logged in as admin
    error: null, // Error from BE if login failed, or if failed to retrieve to token (so UI can just check this state if either flow fails)
    isLoading: false, // This state covers both getting token and logging into BE
  })

  const getAccessToken = async (instance, ssoData, account) => {
    let accessToken
    try {
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`getAccessToken`)
      }
      if (SSO_EMPX_LOGIN_DELAY_ENABLED) {
        await sleep(1000)
      }
      const loginRequest = getLoginRequest(ssoData.metadata.clientId)
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: SSO_SIM_ERROR_ACCOUNT_FOR_TOKEN ? null : account,
      })
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`response [${JSON.stringify(response)}]`)
      }
      accessToken = response.accessToken
    } catch (err) {
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`failed to get token for logging in: [${JSON.stringify(err)}]`)
      }
    }
    return accessToken
  }

  const loginToEmpx = useCallback(async (accessToken, ssoData, account) => {
    try {
      const loggedIn = await dispatch(requestLoginSso(accessToken, ssoData.redirectUrl))
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`result [${JSON.stringify(loggedIn)}]`)
      }
      if (loggedIn) {
        const { roles } = loggedIn
        if (roles.some((role) => role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN)) {
          if (SSO_ENABLE_CONSOLE_LOG) {
            console.log(`loginToEmpx mounted.current [${mounted.current}]`)
          }
          if (mounted.current) {
            setLoginState((prevState) => ({ ...prevState, data: loggedIn, account: account }))
          }
          router.navigateToDefault()
        } else {
          setLoginState((prevState) => ({ ...prevState, error: true, roleError: true }))
        }
      } else {
        if (SSO_ENABLE_CONSOLE_LOG) {
          console.log(`failed to log into eMPX`)
        }
        setLoginState((prevState) => ({ ...prevState, error: true }))
      }
    } catch (err) {
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`failed to log into eMPX: ${JSON.stringify(err)}`)
      }
      setLoginState((prevState) => ({ ...prevState, error: true }))
    }
  }, [dispatch, router])

  const getAccessTokenAndLoginToEmpx = useCallback(async (instance, ssoData, account) => {
    setLoginState((prevState) => ({ ...prevState, isLoading: true, error: null, tokenError: null, roleError: null }))
    let accessToken = await getAccessToken(instance, ssoData, account)
    if (accessToken) {
      if (SSO_SIM_ERROR_SEND_NULL_TOKEN) {
        accessToken = null
      }
      await loginToEmpx(accessToken, ssoData, account)
    } else {
      setLoginState((prevState) => ({ ...prevState, error: true, tokenError: true }))
      dispatch(addErrorToast({ message: TOAST_MESSAGES.LOAD_SSO_TOKEN_ERROR }))
    }
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`getAccessTokenAndLoginToEmpx mounted.current [${mounted.current}]`)
    }
    if (mounted.current) {
      setLoginState((prevState) => ({ ...prevState, isLoading: false }))
    }
  }, [dispatch, loginToEmpx])

  return {
    loginState,
    getAccessTokenAndLoginToEmpx,
  }
}

export default useSsoLogin
