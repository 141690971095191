import { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  chartListSelector,
  getListFilteredBySelectedPort,
  chartListStatusSelector,
  getListFilteredByPort,
} from 'src/store/chart/selectors'
import {
  CHART_LIST_GET_SUCCESS,
  CHART_LIST_GET_ERROR,
  CHART_LIST_GET_IN_PROGRESS,
} from 'src/store/chart/actions'

export const useChartsData = (filterByPort = true, portUuid) => {
  const data = useSelector(
    filterByPort
      ? portUuid
        ? getListFilteredByPort(portUuid)
        : getListFilteredBySelectedPort
      : chartListSelector
  )
  const status = useSelector(chartListStatusSelector)
  const timeoutRef = useRef(null)

  useEffect(() => () => clearTimeout(timeoutRef.current), [])
  return {
    data,
    isLoading: CHART_LIST_GET_IN_PROGRESS === status,
    isLoaded: [CHART_LIST_GET_SUCCESS, CHART_LIST_GET_ERROR].includes(status),
    hasError: CHART_LIST_GET_ERROR === status,
  }
}

export default useChartsData
