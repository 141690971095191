import { createSelector } from 'reselect'

import { selectedPortSelector } from 'src/store/port/selectors'

export const createFilterBySelectedPortSelector = (listSelector) =>
  createSelector(
    selectedPortSelector,
    listSelector,
    (portUuid, list) =>
      list && list.filter((item) => item.port && item.port.uuid === portUuid)
  )
