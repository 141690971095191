import React from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import { ImportResult } from './types'
import { ImportResultStatus } from 'src/components/organisms/Upload/enums/ImportResultStatus'
import { ImportResultItem } from './ImportResultItem'

interface Props {
  results: ImportResult[]
}

const Summary = styled(Typography).attrs({
  variant: 'subtitle1',
})`
  && {
    color: #515151;
  }
`

const VesselNumber = styled.span`
  font-weight: 600;
`

interface NumberOfVesselsProps {
  number: number
}

const NumberOfVessels = ({ number }: NumberOfVesselsProps) => {
  return (
    <VesselNumber>
      {number} vessel{number > 1 ? 's' : ''}
    </VesselNumber>
  )
}

const SubTitle = styled(Typography).attrs({
  variant: 'subtitle2',
})`
  && {
    margin-top: ${({ theme }) => theme.spacing(1)}px;
  }
`

export const ImportResults = ({ results }: Props) => {
  const totalVessels: number = results.length
  const rejected = results.filter(
    (result) => result.status === ImportResultStatus.REJECTED
  )
  const partial = results.filter(
    (result) => result.status === ImportResultStatus.PARTIAL
  )
  const totalRejects: number = rejected.length
  const totalSuccess: number = totalVessels - totalRejects

  return (
    <>
      <Summary>
        <NumberOfVessels number={totalVessels} /> in the list
        <br />
        {totalSuccess > 0 && (
          <>
            <NumberOfVessels number={totalSuccess} /> successfully uploaded
            <br />
          </>
        )}
        {totalRejects > 0 && (
          <>
            <NumberOfVessels number={totalRejects} /> failed to upload
          </>
        )}
      </Summary>
      <Box>
        {rejected && rejected.length > 0 && (
          <>
            <SubTitle color="error">Rejected vessels ({rejected.length})</SubTitle>
            {rejected.map((result) => (
              <ImportResultItem key={result.index} result={result} />
            ))}
          </>
        )}
        {partial && partial.length > 0 && (
          <>
            <SubTitle color="primary">
              Imported vessels with ignored fields ({partial.length})
            </SubTitle>
            {partial.map((result) => (
              <ImportResultItem key={result.index} result={result} />
            ))}
          </>
        )}
      </Box>
    </>
  )
}
