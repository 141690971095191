import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { Grid, Typography, TablePagination, Box } from '@material-ui/core'
import Clear from '@material-ui/icons/Clear'
import { compareName } from 'src/utils/sorting'

import Fieldset from 'src/components/organisms/Fieldset'
import { addToast } from 'src/store/toast/actions'
import {
  TOAST_VARIANT_ERROR,
  TOAST_VARIANT_SUCCESS,
} from 'src/containers/Toast'
import {
  deleteChart,
  getChartList,
  saveChart,
  CHART_UPDATE_SUCCESS,
  CHART_CREATE_SUCCESS,
} from 'src/store/chart/actions'
import {
  CHART_UNITS,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
  PAGINATION_DEFAULT_ROWS_PER_PAGE,
} from 'src/constants/settings'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { PortShape } from 'src/utils/types'
import {
  chartByIdSelector,
  chartStateSelector,
  isChartListLoadingSelector,
} from 'src/store/chart/selectors'
import { RemoveButton } from 'src/components/atoms/RemoveButton'
import ChartImage from 'src/components/organisms/ChartImage/ChartImage'
import Preloader from 'src/components/atoms/Preloader'
import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import ChartForm from 'src/components/organisms/ChartForm'
import { ChartToolsToggle } from './../ChartToolsToggle/ChartToolsToggle';

const ChartItemContainer = styled.div`
  background-color: ${({ theme: { palette } }) => palette.background.paper};
  border-radius: 2px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 126px;
  padding: 4px;
  position: relative;
`
const ChartItemName = styled.div`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  padding: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const ChartRemoveIcon = styled(RemoveButton)`
  && {
    position: absolute;
    right: -${({ theme }) => theme.spacing(1)}px;
    top: -${({ theme }) => theme.spacing(1)}px;
    z-index: 1;
  }
`

const StyledClear = styled(Clear)`
  && {
    width: 50%;
    height: 50%;
  }
`

export const ChartListItem = ({
  onClick,
  onRemoveClick,
  showRemoveButton = true,
  uuid,
}) => {
  const chart = useSelector(chartByIdSelector(uuid))
  const listIsLoading = useSelector(isChartListLoadingSelector)
  const { name } = chart || {}
  const handleRemoveClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    if (onRemoveClick) {
      onRemoveClick(event)
    }
  }
  const handleClick = () => {
    if (onClick) {
      onClick(chart)
    }
  }
  return (
    <ChartItemContainer onClick={handleClick}>
      {chart && (
        <>
          <ChartImage uuid={uuid} imageUrl={chart.imageUrl} />
          <ChartItemName>{name}</ChartItemName>
        </>
      )}
      {!listIsLoading && !chart && (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          Missing chart
        </Box>
      )}
      {listIsLoading && (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Preloader />
        </Box>
      )}
      {showRemoveButton && (
        <ChartRemoveIcon onClick={handleRemoveClick} Icon={StyledClear} />
      )}
    </ChartItemContainer>
  )
}
ChartListItem.propTypes = {
  onClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  showRemoveButton: PropTypes.bool,
  uuid: PropTypes.string,
  stageName: PropTypes.string,
}

const StyledBox = styled(Box)`
  && {
    .MuiTypography-caption {
      font-weight: 800;
      font-size: ${({ theme }) => theme.typography.pxToRem(14)};
    }
    .MuiTablePagination-selectRoot {
      margin: ${({ theme }) => theme.spacing(0, 1)};
    }
    .MuiIconButton-root {
      padding: ${({ theme }) => theme.spacing(0)}px;
    }
  }
`

const PaginationWrapper = ({ children }) => (
  <StyledBox py={4}>{children}</StyledBox>
)

PaginationWrapper.propTypes = {
  children: PropTypes.node,
}
export const ChartList = ({ port, disabled }) => {
  const [selectedChart, setSelectedChart] = useState(null)
  const [selectedChartToRemove, setSelectedChartToRemove] = useState(null)
  const dispatch = useDispatch()
  const charts = useSelector(chartStateSelector)
  const chartsLoading = useSelector(isChartListLoadingSelector)
  const [submitting, setSubmitting] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(
    PAGINATION_DEFAULT_ROWS_PER_PAGE
  )
  const [page, setPage] = useState(0)
  const [dataToDisplay, setDataToDisplay] = useState([])
  const [data, setData] = useState([])

  useEffect(() => {
    dispatch(getChartList())
  }, [dispatch])
  const handleChartFileSelect = ([file]) => {
    var reader = new FileReader()
    reader.addEventListener('load', ({ target: { result } }) => {
      // open the form modal
      setSelectedChart({
        unit: CHART_UNITS.METERS,
        src: result,
        imageUrl: file,
        pixels: 0,
        meters: 0,
      })
    })
    reader.readAsDataURL(file)
  }
  const handleChartFormDialogRequestClose = () => {
    setSelectedChart(null)
  }
  const handleRejectRemove = () => setSelectedChartToRemove(null)
  const handleConfirmRemove = async () => {
    try {
      await dispatch(deleteChart(selectedChartToRemove))
      dispatch(
        addToast({
          variant: TOAST_VARIANT_SUCCESS,
          message: `The chart has been removed: ${selectedChartToRemove.name}`,
        })
      )
    } catch (error) {
      dispatch(
        addToast({
          variant: TOAST_VARIANT_ERROR,
          message: `Error deleting chart (${selectedChartToRemove.name}): ${error}`,
        })
      )
    } finally {
      setSelectedChartToRemove(null)
    }
  }
  const handlePaginationChangePage = (event, selectedPage) =>
    setPage(selectedPage)
  const handlePaginationChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(value)
    setPage(0)
  }

  useEffect(() => {
    const { data: uuids, byId } = charts
    setData(
      uuids
        ? uuids
            .map((uuid) => byId[uuid])
            .filter((item) => item.port && item.port.uuid === port.uuid)
        : []
    )
  }, [charts, port])
  useEffect(() => {
    setDataToDisplay(
      data
        ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : []
    )
  }, [page, rowsPerPage, data])

  const handleSave = useCallback(
    (values) => {
      setSubmitting(true)

      const amendedValues = {
        ...values,
        port: { uuid: port.uuid },
        berthsStations: values.berthsStations.map(({ uuid }) => ({
          uuid,
        })),
      }
      if (!(values.imageUrl instanceof File)) {
        delete amendedValues.imageUrlThumbnail
      }

      dispatch(saveChart(amendedValues)).then((action) => {
        setSubmitting(false)
        const { type } = typeof action === 'boolean' ? {} : action
        if (
          action === true ||
          [CHART_CREATE_SUCCESS, CHART_UPDATE_SUCCESS].indexOf(type) > -1
        ) {
          setSelectedChart(null)
        }
      })
    },
    [dispatch, port]
  )

  const sortedData = data && data.sort(compareName)
  const sortedDataToDisplay = dataToDisplay && dataToDisplay.sort(compareName)

  return (
    <>
      <Fieldset
        title="Charts"
        subtitle="What charts do you need to use?"
        description="Upload and set the chart that you need to use"
        disabled={disabled}
        selectHandler={handleChartFileSelect}
      >
        {chartsLoading && <Preloader />}
        {!chartsLoading &&
          (!sortedDataToDisplay || !sortedDataToDisplay.length) && (
            <Typography variant="body2" color="secondary">
              No charts yet
            </Typography>
          )}
        <Grid container alignItems="flex-start" spacing={2}>
          {(sortedDataToDisplay || []).map((chart, index) => {
            const { uuid } = chart
            return (
              <Grid key={uuid || `chart-${index}`} item xs={4}>
                <ChartListItem
                  uuid={uuid}
                  onClick={() => setSelectedChart(chart)}
                  onRemoveClick={() => setSelectedChartToRemove(chart)}
                />
              </Grid>
            )
          })}
        </Grid>
        {sortedData &&
          sortedData.length > PAGINATION_ROWS_PER_PAGE_OPTIONS[0] && (
            <TablePagination
              component={PaginationWrapper}
              count={sortedData ? sortedData.length : 0}
              page={page}
              rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Charts per page:"
              onChangePage={handlePaginationChangePage}
              onChangeRowsPerPage={handlePaginationChangeRowsPerPage}
            />
          )}
      </Fieldset>

      <ChartToolsToggle />

      {selectedChart && (
        <FormDialog
          open
          title={selectedChart.uuid ? 'Edit chart' : 'Add new chart'}
          FormComponent={ChartForm}
          formEntityProp="chart"
          entity={selectedChart}
          onSave={handleSave}
          onCancel={handleChartFormDialogRequestClose}
          submitting={submitting}
        />
      )}
      {selectedChartToRemove && (
        <ConfirmDialog
          open={!!selectedChartToRemove}
          onConfirm={handleConfirmRemove}
          onCancel={handleRejectRemove}
          title={`Delete chart: ${selectedChartToRemove.name}`}
        >
          Are you sure that you want to remove the selected chart?
        </ConfirmDialog>
      )}
    </>
  )
}
ChartList.propTypes = {
  charts: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      name: PropTypes.string,
      imageUrl: PropTypes.string,
      scale: PropTypes.number,
      unit: PropTypes.string,
    })
  ),
  port: PropTypes.shape(PortShape),
  disableFilterByPort: PropTypes.bool,
  disabled: PropTypes.bool,
}
export default React.memo(ChartList)
