import { createSelector } from 'reselect'
import {
  createGetListFilteredBySelectedPortSelector,
  createGetListFilteredByPortSelector,
} from 'src/utils/store'

export const tideStationStateSelector = (state) => state.tideStation

export const tideStationListSelector = createSelector(
  tideStationStateSelector,
  (state) => state.data && state.data.map((uuid) => state.byId[uuid])
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  tideStationListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  tideStationListSelector
)

export const tideStationByIndexSelector = (index) =>
  createSelector(
    tideStationStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const tideStationByIdSelector = (uuid) =>
  createSelector(
    tideStationStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalTideStationSelector = createSelector(
  tideStationStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    tideStationStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    tideStationStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
