import { Box, TablePagination, Tooltip, Typography } from '@material-ui/core'
import idx from 'idx'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import EditButton from 'src/components/atoms/EditButton'
import { RemoveIconButton } from 'src/components/atoms/RemoveButton'
import EmptyState from 'src/components/atoms/EmptyState'
import DataTable from 'src/components/molecules/DataTable'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import Fieldset from 'src/components/organisms/Fieldset'
import RouteAttachmentFormDialog from 'src/components/organisms/RouteAttachmentFormDialog'
import {
  PAGINATION_ROWS_PER_PAGE_OPTIONS
} from 'src/constants/settings'
import {
  TOAST_VARIANT_ERROR, TOAST_VARIANT_SUCCESS
} from 'src/containers/Toast'
import {
  deleteRouteAttachment, getRouteAttachmentList
} from 'src/store/routeAttachment/actions'
import {
  getListFilteredByPort, getListFilteredBySelectedPort,

  routeAttachmentListSelector
} from 'src/store/routeAttachment/selectors'
import { addErrorToast, addToast } from 'src/store/toast/actions'
import { compareName } from 'src/utils/sorting'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import { PortShape } from 'src/utils/types'
import fileSize from 'filesize'
import { ATTACHMENT_SIZE_LIMIT } from 'src/constants/settings'

const ActionWrapper = styled.div`
  && {
    align-items: center;
    display: flex;
    > button {
      padding: ${({ theme }) => theme.spacing(0.5)}px;
    }
  }
`
const EntityName = ({ uuid, selector }) => {
  const entity = useSelector(selector(uuid))
  return (
    <span>
      {entity ? entity.name : ''}
      {!entity && uuid && (
        <Tooltip title="Deleted">
          <Typography variant="caption" color="error">
            N/A
          </Typography>
        </Tooltip>
      )}
    </span>
  )
}
EntityName.propTypes = {
  uuid: PropTypes.string,
  selector: PropTypes.func,
}

const getColumnDefinitions = ({ onRemove, onEdit },) => [
  {
    field: 'name',
    title: 'Name',
  },
  {
    field: 'attachment',
    title: 'type',
    labelFunction: (attachment) => (
      <div>
        {attachment.mimeType.split('/').pop()}
      </div>
    ),
  },
  {
    field: 'attachment',
    title: 'size',
    labelFunction: (attachment) => (
      <div>
        {fileSize(attachment.size, { base: 10 })}
      </div>
    ),
  },
  {
    field: 'routes',
    title: 'Routes',
    // eslint-disable-next-line react/display-name
    labelFunction: (routes, item) => {
      if (item.allRoutes) {
        return (
          <div>-- Applies to all routes --</div>
        )
      } else {
        return (
        <>
          {
            routes
              .sort((a, b) => {
                const A = a.name.toLowerCase()
                const B = b.name.toLowerCase()
                return A < B ? -1 : A > B ? 1 : 0
              })
              .map(c =>
                <div
                  key={c.uuid}
                  style={{
                    fontSize: routes.length > 1 ? 12 : undefined,
                    whiteSpace: 'nowrap'
                  }}
                >{c.name}</div>
              )
          }
        </>
        )
      }
    },
  },
  {
    field: 'uuid',
    title: '',
    sortable: false,
    align: 'right',
    justify: 'flex-end',
    width: 40,
    // eslint-disable-next-line react/display-name
    labelFunction: (uuid, item) => (
      <ActionWrapper display="flex" alignItems="center">
        <Tooltip title={`Edit ${item.name} attachment`}>
          <EditButton onClick={() => onEdit(item)} />
        </Tooltip>
        <Tooltip title={`Remove ${item.name} attachment`}>
          <RemoveIconButton onClick={() => onRemove(uuid)} />
        </Tooltip>
      </ActionWrapper>
    ),
  },
]

export const RouteAttachmentList = ({ port, disabled, disableFilterByPort }) => {

  const dispatch = useDispatch()

  const [selectedRouteAttachment, setSelectedRouteAttachment] = useState(null)
  const [selectedRouteAttachmentToRemove, setSelectedRouteAttachmentToRemove] = useState(null)

  const showErrorMessage = useCallback(
    (error) => {
      let message = error
      if (typeof error === 'object') {
        // Unprocessable entry
        if (error.status === 422) {
          message = idx(error, (_) => _.response.data.message) || message
        } else {
          message = 'Error processing route attachment'
        }
      }
      dispatch(
        addErrorToast({
          message,
        })
      )
    },
    [dispatch]
  )

  const handleRouteAttachmentFileSelect = useCallback(
    async ([file]) => {
      if (file === null) {
        // rejected file
        showErrorMessage('Invalid file type selected')
        return
      }
      if (file instanceof File) {
        var reader = new FileReader()
        reader.addEventListener('load', ({ target: { result } }) => {
          // open the form modal
          setSelectedRouteAttachment({
            name: '',
            routes: [],
            fileUrl: file,
            fileSrc: result,
            allRoutes: true,
          })
        })
        reader.readAsDataURL(file)
      }
    },
    [showErrorMessage]
  )

  const onRouteAttachmentFileReject = useCallback(
    (files) => {
      const [file] = files
      if (file && file.size > ATTACHMENT_SIZE_LIMIT) {
        dispatch(
          addErrorToast({
            message: `The file size limit is ${fileSize(ATTACHMENT_SIZE_LIMIT, {
              base: 10,
            })}`,
          })
        )
      }
    },
    [dispatch]
  )

  // the routeAttachment list is always filtered by port
  // defined in the props or by the global selected port
  const routeAttachments = useSelector(
    disableFilterByPort
      ? routeAttachmentListSelector
      : port
        ? getListFilteredByPort(port.uuid)
        : getListFilteredBySelectedPort
  )

  useEffect(() => {
    dispatch(getRouteAttachmentList())
  }, [dispatch])

  const handleOnEdit = (item) => setSelectedRouteAttachment(item)
  const handleOnRemove = useCallback(
    (uuid) =>
      setSelectedRouteAttachmentToRemove(
        routeAttachments && routeAttachments.find((item) => item.uuid === uuid)
      ),
    [routeAttachments]
  )
  const handleCancelRemove = () => setSelectedRouteAttachmentToRemove(null)
  const handleConfirmRemove = () => {
    dispatch(deleteRouteAttachment(selectedRouteAttachmentToRemove)).then(
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_SUCCESS,
            message: getToastMessage(
              TOAST_MESSAGES.ROUTE_ATTACHMENT_DELETE_SUCCESS,
              selectedRouteAttachmentToRemove
            ),
          })
        )
        setSelectedRouteAttachmentToRemove(null)
      },
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_ERROR,
            message: getToastMessage(
              TOAST_MESSAGES.ROUTE_ATTACHMENT_DELETE_ERROR,
              selectedRouteAttachmentToRemove
            ),
          })
        )
        setSelectedRouteAttachmentToRemove(null)
      }
    )
  }

  const handleFormDialogCloseRequest = () => setSelectedRouteAttachment(null)

  // Pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(3)

  const handlePaginationChangePage = (event, selectedPage) =>
    setPage(selectedPage)

  const handlePaginationChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(value)
    setPage(0)
  }

  const sortedRouteAttachments = routeAttachments ? routeAttachments.sort(compareName) : []
  const currentPageRouteAttachments = sortedRouteAttachments.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Attachments"
        subtitle="Upload important documents such as Waypoints, Health and Safety information, Mooring plan etc. that is required to be sent to master with almost every pilotage. Keep file size to a minimum - less than 500KB."
        description="Define your attachments"
        buttonContents="Add Attachment"
        routeAttachmentSelectHandler={handleRouteAttachmentFileSelect}
        routeAttachmentRejectHandler={onRouteAttachmentFileReject}
      >
        {sortedRouteAttachments && sortedRouteAttachments.length > 0 && (
          <>
            <Box mb={1}>
              <DataTable
                columns={getColumnDefinitions({
                  onRemove: handleOnRemove,
                  onEdit: handleOnEdit,
                })}
                dataProvider={currentPageRouteAttachments}
              />
            </Box>

            <TablePagination
              component={Box}
              count={sortedRouteAttachments.length}
              page={page}
              rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Attachments per page:"
              onChangePage={handlePaginationChangePage}
              onChangeRowsPerPage={handlePaginationChangeRowsPerPage}
            />
          </>
        )}

        {(!sortedRouteAttachments || !sortedRouteAttachments.length) && (
          <EmptyState message="No attachments yet." />
        )}

      </Fieldset>
      {selectedRouteAttachment && (
        <RouteAttachmentFormDialog
          port={port}
          routeAttachment={selectedRouteAttachment}
          onCloseRequest={handleFormDialogCloseRequest}
          open={!!selectedRouteAttachment}
        />
      )}
      {selectedRouteAttachmentToRemove && (
        <ConfirmDialog
          open={!!selectedRouteAttachmentToRemove}
          onCancel={handleCancelRemove}
          onConfirm={handleConfirmRemove}
          title={`Remove attachment: ${selectedRouteAttachmentToRemove.name}`}
        >
          Are you sure you want to remove this attachment?
          <br />
          {selectedRouteAttachmentToRemove.name}
        </ConfirmDialog>
      )}
    </>
  )
}

RouteAttachmentList.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
}

export default RouteAttachmentList
