import {
  TIDE_RATE_STATION_LIST_GET_SUCCESS,
  TIDE_RATE_STATION_LIST_GET_ERROR,
  TIDE_RATE_STATION_GET_SUCCESS,
  TIDE_RATE_STATION_CREATE_SUCCESS,
  TIDE_RATE_STATION_UPDATE_SUCCESS,
  TIDE_RATE_STATION_UPDATE_ERROR,
  TIDE_RATE_STATION_DELETE_SUCCESS,
  TIDE_RATE_STATION_DELETE_ERROR,
  TIDE_RATE_STATION_UPDATE_IN_PROGRESS,
} from 'src/store/tideRateStations/actions'
import { getIdsFromList, getByIdFromList } from 'src/utils/store'

export const getInitialState = {
  status: null,
  data: null,
  error: null,
  byId: null,
  statusById: null,
  errorById: null,
  updating: false,
}

export const reducer = (state = getInitialState, action) => {
  const { type, payload } = action
  const { uuid } = typeof payload === 'string' ? payload : payload || {}

  switch (type) {
    case TIDE_RATE_STATION_UPDATE_IN_PROGRESS:
      return {
        ...state,
        updating: true,
      }
    case TIDE_RATE_STATION_LIST_GET_SUCCESS:
      return {
        ...state,
        data: getIdsFromList(payload),
        byId: getByIdFromList(payload),
        statusById: getIdsFromList(payload).reduce(
          (status, id) => ({
            ...status,
            [id]: type,
          }),
          {}
        ),
        errorById: {},
        error: null
      }
    case TIDE_RATE_STATION_LIST_GET_ERROR:
      return {
        ...state,
        error: action.error
      }
    case TIDE_RATE_STATION_GET_SUCCESS: {
      const { data, byId, statusById, ...restState } = state
      return {
        ...restState,
        data: data.indexOf(uuid) > -1 ? data : [...data, uuid],
        byId: {
          ...byId,
          [uuid]: payload,
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case TIDE_RATE_STATION_CREATE_SUCCESS: {
      const { data, byId, statusById, ...restState } = state
      return {
        ...restState,
        // because of timing issues it can happen that
        // the get tide station success already added
        // the uuid to the list
        data: data.indexOf(uuid) > -1 ? data : [...data, uuid],
        byId: {
          ...byId,
          [uuid]: payload,
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case TIDE_RATE_STATION_UPDATE_SUCCESS: {
      const { byId, statusById, ...restState } = state

      // PUT response doesn't include the dateRange, so keep the existing
      // date range if it is there already
      const existing = byId[uuid] || {}
      const { dateRange } = existing

      return {
        ...restState,
        byId: {
          ...byId,
          [uuid]: { dateRange, ...payload },
        },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        updating: false,
      }
    }
    case TIDE_RATE_STATION_UPDATE_ERROR: {
      const { statusById, errorById, ...restState } = state
      return {
        ...restState,
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        errorById: {
          ...errorById,
          [uuid]: payload,
        },
        updating: false,
      }
    }
    case TIDE_RATE_STATION_DELETE_SUCCESS: {
      const { data, byId, statusById, ...restState } = state
      delete byId[uuid]
      return {
        ...restState,
        data: data.filter((id) => id !== uuid),
        byId: { ...byId },
        statusById: {
          ...statusById,
          [uuid]: type,
        },
      }
    }
    case TIDE_RATE_STATION_DELETE_ERROR: {
      const { statusById, errorById, ...restState } = state
      return {
        ...restState,
        statusById: {
          ...statusById,
          [uuid]: type,
        },
        errorById: {
          ...errorById,
          [uuid]: payload,
        },
      }
    }
    default:
      return state
  }
}

export default reducer
