import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
// import styled from 'styled-components'

import { Grid } from '@material-ui/core'

import { OrganisationShape, InvitationShape } from 'src/utils/types'
import { TextInput } from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import InvitationForm from 'src/components/organisms/InvitationForm'
import Fieldset from 'src/components/organisms/Fieldset'
// TODO finish the organisation logo support
// import ImageInput from 'src/components/atoms/ImageInput/ImageInput'
import { SwitchInput } from './../../atoms/SwitchInput/SwitchInput';

// const LogoPreview = styled.img`
//   max-height: 150px;
//   max-width: 150px;
// `

const validateForm = (values) =>
  validateValues(values, {
    name: ['required', 'name'],
    ssoEnabled: 'required',
  })

export const EMPTY_ORGANISATION = {
  name: '',
  ssoEnabled: false,
  trialMode: false,
  trialStartAt: null,
  trialEndAt: null,
}

export const OrganisationForm = ({ organisation, onChange, onSubmit }) => {
  const invitationFormState = useRef(
    organisation.invitation ? { values: organisation.invitation } : null
  )
  const organisationFormState = useRef(organisation)
  const [change, setChange] = useState()

  const handleFormChange = (change) => {
    const { values, dirty, isValid, ...props } = change
    const { dirty: inviteDirty, isValid: inviteIsValid, values: invitation } =
      invitationFormState.current || {}
    organisationFormState.current = change
    let isValidFinal
    let dirtyFinal
    if (!fields.ssoEnabled.value) {
      isValidFinal = invitation === undefined ? isValid : isValid && inviteIsValid
      dirtyFinal = invitation === undefined ? dirty : dirty && inviteDirty
    } else {
      isValidFinal = isValid
      dirtyFinal = dirty
    }
    setChange({
      isValid: isValidFinal,
      dirty: dirtyFinal,
      values: invitation
        ? {
          ...values,
          invitation,
        }
        : values,
      ...props,
    })
  }

  const { fields } = useForm({
    initialValues: organisation,
    validationHandler: validateForm,
    changeHandler: handleFormChange,
    submitHandler: onSubmit,
  })

  const handleInvitationFormChange = (formChange) => {
    const { values, dirty, isValid } = formChange
    const { dirty: organisationDirty, isValid: organisationIsValid } =
      organisationFormState.current || {}
    invitationFormState.current = formChange
    let isValidFinal
    let dirtyFinal
    if (!fields.ssoEnabled.value) {
      isValidFinal = isValid && organisationIsValid
      dirtyFinal = dirty && organisationDirty
    } else {
      isValidFinal = organisationIsValid
      dirtyFinal = organisationDirty
    }
    setChange({
      isValid: isValidFinal,
      dirty: dirtyFinal,
      values: {
        ...((change && change.values) || {}),
        invitation: values,
      },
    })
  }

  useEffect(() => {
    if (change && onChange) {
      onChange(change)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change])

  return (
    <Grid container spacing={1}>
      {/* <Grid item xs={12}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          maxHeight="150px"
        >
          <ImageInput
            name="logo"
            PreviewComponent={LogoPreview}
            {...fields.logo}
          />
        </Box>
      </Grid> */}
      <Grid item xs={12}>
        <TextInput
          fullWidth
          label="Organisation name"
          name="name"
          {...fields.name}
        />
        <SwitchInput
          label="SSO Enabled"
          name="ssoEnabled"
          color="primary"
          checked={fields.ssoEnabled.value}
          {...fields.ssoEnabled}
        />
      </Grid>
      {!fields.ssoEnabled.value && invitationFormState.current && (
        <Grid item xs={12}>
          <Fieldset title="Organisation admin" fullWidth>
            <InvitationForm
              invitation={organisation.invitation}
              onChange={handleInvitationFormChange}
            />
          </Fieldset>
        </Grid>
      )}
    </Grid>
  )
}

OrganisationForm.propTypes = {
  organisation: PropTypes.shape(OrganisationShape),
  invitation: PropTypes.shape(InvitationShape),
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default OrganisationForm
