export const TIDAL_RATE_CONFIG_KEY = 'tidal_rate_config'

export interface TidalRange {
    term: string
    knotsLower: number
    knotsUpper: number
}

export interface TidalRanges {
    [uuid: string]: TidalRange
}

export interface TidalRate {
    hoursOffset: number // -6 .. 6
    neapRangeUuid: string
    springRangeUuid: string
    directionDegrees?: number
}

export interface TidalRates {
    [uuid: string]: TidalRate
}

export interface TidalRateConfig {
    enabled: boolean
    tideTypeRangeThreshold: number
    ranges: TidalRanges
    rates: TidalRates
}

export interface IPreferencesTidalRateConfig {
    id?: number
    key: 'tidal_rate_config'
    createdAt?: string
    updatedAt?: string
    deletedAt?: string
    port?: any
    uuid?: string
    metadata: TidalRateConfig
}

export const defaultTidalRateConfig: IPreferencesTidalRateConfig = {
    uuid: '', // no uuid means it doesn't exist in db yet
    key: TIDAL_RATE_CONFIG_KEY,
    metadata: {
        enabled: false,
        
        tideTypeRangeThreshold: 1,

        ranges: {
            slack: {
                term: 'Slack',
                knotsLower: 0,
                knotsUpper: 0.25
            },
            verySlight: {
                term: 'Very Slight',
                knotsLower: 0.25,
                knotsUpper: 0.5
            },
            slight: {
                term: 'Slight',
                knotsLower: 0.5,
                knotsUpper: 0.75
            },
            slightToModerate: {
                term: 'Slight to Moderate',
                knotsLower: 0.75,
                knotsUpper: 1
            },
            moderate: {
                term: 'Moderate',
                knotsLower: 1,
                knotsUpper: 1
            },
            moderateToStrong: {
                term: 'Moderate to Strong',
                knotsLower: 1,
                knotsUpper: 1.5
            },
            strong: {
                term: 'Strong',
                knotsLower: 1.5,
                knotsUpper: 1.75
            },
            veryStrong: {
                term: 'Very Strong',
                knotsLower: 1.75,
                knotsUpper: 2
            }
        },
        rates: {
            hwM6: {
                hoursOffset: -6,
                neapRangeUuid: 'slack',
                springRangeUuid: 'verySlight',
                
            },
            hwM5: {
                hoursOffset: -5,
                neapRangeUuid: 'slight',
                springRangeUuid: 'moderate',
                
            },
            hwM4: {
                hoursOffset: -4,
                neapRangeUuid: 'slight',
                springRangeUuid: 'moderateToStrong',
                
            },
            hwM3: {
                hoursOffset: -3,
                neapRangeUuid: 'slightToModerate',
                springRangeUuid: 'strong',
                
            },
            hwM2: {
                hoursOffset: -2,
                neapRangeUuid: 'moderate',
                springRangeUuid: 'veryStrong',
                
            },
            hwM1: {
                hoursOffset: -1,
                neapRangeUuid: 'slight',
                springRangeUuid: 'moderateToStrong',
                
            },
            hw: {
                hoursOffset: 0,
                neapRangeUuid: 'slack',
                springRangeUuid: 'slight',
                
            },
            hwP1: {
                hoursOffset: 1,
                neapRangeUuid: 'slight',
                springRangeUuid: 'moderate',
                
            },
            hwP2: {
                hoursOffset: 2,
                neapRangeUuid: 'slightToModerate',
                springRangeUuid: 'strong',
                
            },
            hwP3: {
                hoursOffset: 3,
                neapRangeUuid: 'moderate',
                springRangeUuid: 'veryStrong',
                
            },
            hwP4: {
                hoursOffset: 4,
                neapRangeUuid: 'moderate',
                springRangeUuid: 'strong',
                
            },
            hwP5: {
                hoursOffset: 5,
                neapRangeUuid: 'slight',
                springRangeUuid: 'moderate',
                
            },
            hwP6: {
                hoursOffset: 6,
                neapRangeUuid: 'slack',
                springRangeUuid: 'verySlight',
                
            }
        }
    }
}