import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  portListSelector,
  selectedPortSelector,
} from 'src/store/port/selectors'
import { selectPort } from 'src/store/port/actions'
import { FormControl, Select, MenuItem } from '@material-ui/core'

export const PortSelector = () => {
  const dispatch = useDispatch()
  const ports = useSelector(portListSelector)
  const selected = useSelector(selectedPortSelector)
  const [isEmpty, setIsEmpty] = useState(true)

  const handleChange = (event) => {
    if (event.target.value !== selected) {
      dispatch(selectPort(event.target.value))
    }
  }

  useEffect(() => {
    setIsEmpty(!ports || ports.length === 0)
  }, [ports])

  const renderValue = (value) => {
    const selectedPort = ports.find((port) => port.uuid === value)
    return selectedPort
      ? selectedPort.name
      : isEmpty
      ? 'Please set up a port'
      : 'Please select a port'
  }

  return (
    <>
      <FormControl>
        <Select
          value={selected || ''}
          onChange={handleChange}
          inputProps={{
            name: 'selected-port',
          }}
          disableUnderline
          displayEmpty
          disabled={isEmpty}
          renderValue={renderValue}
        >
          {!isEmpty &&
            ports.map((port) => (
              <MenuItem key={port.uuid} value={port.uuid}>
                {port.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  )
}

export default PortSelector
