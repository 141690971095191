import { EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { LogLevel } from '@azure/msal-common'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { addErrorToast } from 'src/store/toast/actions'
import { SSO_AAD_LOGIN_DELAY_ENABLED, SSO_AAD_LOGOUT_DELAY_ENABLED, SSO_ENABLE_CONSOLE_LOG, SSO_SIM_ERROR_APP_URI, SSO_SIM_ERROR_CLIENT_ID, SSO_SIM_ERROR_REDIRECT_URL, SSO_SIM_ERROR_TENANT_ID } from './../utils/ssoConstants'
import TOAST_MESSAGES from './../utils/toastMessages'

const InteractionStatus = {
  Startupc: 'startup',
  Login: 'login',
  Logout: 'logout',
  AcquireToken: 'acquireToken',
  SsoSilent: 'ssoSilent',
  HandleRedirect: 'handleRedirect',
  None: 'none'
}

// The MSAL code is based on Microsoft's alpha repo:
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-react
// It has kept it largely intact (so we can leverage their updates in future), except for:
// - porting it from global static context provider into a dynamic routine for SAAS
// - bug fixes (eg unhandled promise rejections)
// - error flags on failure
// - error code passing error handling on our end
const useMsal = (ssoData) => {
  const dispatch = useDispatch()
  const msalRef = useRef(null)
  const msalCallbackIdRef = useRef(null)
  const [inProgress, setInProgress] = useState(InteractionStatus.Startup)
  const [accounts, setAccounts] = useState([])
  const [redirectError, setRedirectError] = useState(null)
  const [logoutError, setLogoutError] = useState(null)
  // These 2 flags can be used as a UI barrier for the MSAL login and logout calls
  const [msalLoginIsLoading, setMsalLoginIsLoading] = useState(false)
  const [msalLogoutIsLoading, setMsalLogoutIsLoading] = useState(false)

  const msalInProgress = inProgress !== InteractionStatus.None
  const hasInteractionRequiredError = checkHasInteractionRequiredError(redirectError)

  useEffect(() => {
    if (!ssoData) {
      return
    }
    const msalConfig = {
      auth: {
        clientId: `${ssoData.metadata.clientId}${SSO_SIM_ERROR_CLIENT_ID ? 'xxx' : ''}`,
        authority: `https://login.microsoftonline.com/${ssoData.metadata.tenantId}${SSO_SIM_ERROR_TENANT_ID ? 'xxx' : ''}`,
        redirectUri: `${ssoData.redirectUrl}${SSO_SIM_ERROR_REDIRECT_URL ? 'xxx' : ''}`,
        postLogoutRedirectUri: ssoData.redirectUrl,
        navigateToLoginRequestUrl: true,
      },
      system: {
        loggerOptions: {
          loggerCallback: (_level, message) => {
            if (SSO_ENABLE_CONSOLE_LOG) {
              console.log(`Message: ${message}`)
            }
          },
          piiLoggingEnabled: true,
          logLevel: LogLevel.Info,
        }
      },
    }
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`msalConfig [${JSON.stringify(msalConfig)}]`)
    }
    msalRef.current = new PublicClientApplication(msalConfig)
    msalCallbackIdRef.current = msalRef.current.addEventCallback((message) => {
      switch (message.eventType) {
        case EventType.LOGIN_START:
          setInProgress(InteractionStatus.Login)
          setRedirectError(null)
          break
        case EventType.SSO_SILENT_START:
          setInProgress(InteractionStatus.SsoSilent)
          break
        case EventType.ACQUIRE_TOKEN_START:
          if (message.interactionType === InteractionType.Redirect || message.interactionType === InteractionType.Popup) {
            setInProgress(InteractionStatus.AcquireToken)
          }
          break
        case EventType.HANDLE_REDIRECT_START:
          setRedirectError(null)
          setInProgress(InteractionStatus.HandleRedirect)
          break
        case EventType.LOGOUT_START:
          setLogoutError(null)
          setInProgress(InteractionStatus.Logout)
          break
        case EventType.LOGIN_FAILURE:
          setRedirectError(message.error)
          setAccounts(msalRef.current.getAllAccounts())
          setInProgress(InteractionStatus.None)
          break
        case EventType.LOGIN_SUCCESS:
        case EventType.SSO_SILENT_SUCCESS:
        case EventType.HANDLE_REDIRECT_END:
        case EventType.SSO_SILENT_FAILURE:
        case EventType.LOGOUT_FAILURE:
          setLogoutError(message.error)
          setAccounts(msalRef.current.getAllAccounts())
          setInProgress(InteractionStatus.None)
          break
        case EventType.ACQUIRE_TOKEN_SUCCESS:
        case EventType.ACQUIRE_TOKEN_FAILURE:
          setAccounts(msalRef.current.getAllAccounts())
          if (message.interactionType === InteractionType.Redirect || message.interactionType === InteractionType.Popup) {
            setInProgress(InteractionStatus.None)
          }
          break
        default:
          // noop
      }
    })
    msalRef.current.handleRedirectPromise().catch(() => {})
    return () => {
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`unmount removing callback`)
      }
      if (msalRef.current && msalCallbackIdRef.current) {
        msalRef.current.removeEventCallback(msalCallbackIdRef.current)
        msalRef.current = null
        msalCallbackIdRef.current = null
      }
    }
  }, [ssoData])

  const handleLogin = useCallback(() => {
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`handleLogin ssoData [${JSON.stringify(ssoData)}]`)
    }
    if (!ssoData) {
      return
    }
    setMsalLoginIsLoading(true)
    const loginRequest = getLoginRequest(ssoData.metadata.clientId)
    if (SSO_AAD_LOGIN_DELAY_ENABLED) {
      setTimeout(() => msalRef.current.loginRedirect(loginRequest).catch(() => {}), 2000)
    } else {
      msalRef.current.loginRedirect(loginRequest).catch(() => {})
    }
  }, [ssoData])

  const handleLogout = useCallback(() => {
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`handleLogout ssoData [${JSON.stringify(ssoData)}]`)
    }
    if (!ssoData) {
      return
    }
    setMsalLogoutIsLoading(true)
    if (SSO_AAD_LOGOUT_DELAY_ENABLED) {
      setTimeout(() => msalRef.current.logout().catch(() => {}), 2000)
    } else {
      msalRef.current.logout().catch(() => {})
    }
  }, [ssoData])

  // Error handling: display login/logout error on ErrorToast
  useEffect(() => {
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`redirectError [${JSON.stringify(redirectError)}] logoutError [${JSON.stringify(logoutError)}]`)
    }
    if (!redirectError && !logoutError) {
      return
    }
    if (redirectError) {
      dispatch(addErrorToast({ message: TOAST_MESSAGES.LOAD_SSO_IDP_ERROR, }))
      setMsalLoginIsLoading(false)
    }
    if (logoutError) {
      dispatch(addErrorToast({ message: TOAST_MESSAGES.LOAD_SSO_LOGOUT_ERROR, }))
      setMsalLogoutIsLoading(false)
    }
  }, [redirectError, logoutError, dispatch])

  return {
    instance: msalRef.current,
    msalInProgress,
    accounts,
    redirectError,
    logoutError,
    hasInteractionRequiredError,
    handleLogin,
    handleLogout,
    msalLoginIsLoading,
    msalLogoutIsLoading,
  }
}

const checkHasInteractionRequiredError = (redirectError) => {
  let hasInteractionRequiredError = false
  if (redirectError) {
    if (redirectError.errorCode && redirectError.errorCode === 'interaction_required' &&
    redirectError.errorMessage && redirectError.errorMessage.startsWith('AADSTS50105')
    ) {
      hasInteractionRequiredError = true
    }
  }
  return hasInteractionRequiredError
}

export const getLoginRequest = (clientId) => ({
  scopes: [`api://${clientId}${SSO_SIM_ERROR_APP_URI ? 'xxx' : ''}/empx`]
})

export default useMsal
