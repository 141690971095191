import React from 'react'

const PassagePlannerIcon = () => (
  <svg
    width="21"
    height="18"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 6.83333C14 7.70511 12.8519 9.50429 12.2954 10.3251C12.152 10.5365 11.848 10.5365 11.7046 10.3251C11.1481 9.50429 10 7.70511 10 6.83333C10 5.68274 10.8954 4.75 12 4.75C13.1046 4.75 14 5.68274 14 6.83333Z"
      fill="#B0B0B1"
    />
    <circle cx="12" cy="6.75" r="1" fill="white" />
    <path
      d="M3 1.5625C3 2.18037 2.23102 3.41937 1.79424 4.07329C1.65233 4.28574 1.34767 4.28574 1.20576 4.07329C0.768981 3.41937 0 2.18037 0 1.5625C0 0.699555 0.671573 0 1.5 0C2.32843 0 3 0.699555 3 1.5625Z"
      fill="#B0B0B1"
    />
    <circle cx="1.5" cy="1.5" r="0.75" fill="white" />
    <path
      d="M9 9H5.5C4.67157 9 4 8.32843 4 7.5V7.5C4 6.67157 4.67157 6 5.5 6H7.5C8.32843 6 9 5.32843 9 4.5V4.5C9 3.67157 8.32843 3 7.5 3H4"
      stroke="#B0B0B1"
      strokeDasharray="2 1"
    />
  </svg>
)

export default PassagePlannerIcon
