import { parse } from 'libphonenumber-js'

export const HUMAN_NAME_VALIDATION_REGEX = /^[^~!@#$%^&*()_+=\\|\][{}";:/?.>,<0-9]*$/
export const NAME_VALIDATION_REGEX   = /^[^~!@#$%^&*()_+=\\|\][{}";:/?.>,<]*$/
export const NAME_WITH_SPECIAL_CHARACTERS_VALIDATION_REGEX = /^[^~!@#$%^*+=\\|\][{}";:?>,<]*$/
export const CHART_NAME_REGEX = /^[^~!@#$%^*+=\\|\][{}";:/?>,<]*$/
export const URL_REGEX = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i

export function validChartName(value) {
  return CHART_NAME_REGEX.test(value)
    ? null
    : 'Chart name cannot contain certain special characters'
}

export function required(value) {
  return value === undefined || value === null || value === ''
    ? 'Oops, you forgot to fill out this field'
    : null
}

export function validEmail(value) {
  return value && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? null
    : 'Please enter a valid email'
}

export function validUrl(value) {
  return value && URL_REGEX.test(value) ? null : 'Please enter a valid URL'
}

export function validMinLength(value, min) {
  if (value && value.length < min) {
    return `This field needs at least ${min} characters.`
  }
  return ''
}

export function validMaxLength(value, max) {
  if (value && value.length > max) {
    return `This field can be maximum ${max} characters.`
  }
  return ''
}

export function validPhoneWithPrefix(phone, prefix) {
  const parsedNumber = parse(phone, prefix)
  return parsedNumber.phone ? '' : 'This field must be a valid phone number.'
}

export function validPassword(value) {
  return /(?=.*[a-z]+)(?=.*[A-Z]+)(?=.*[0-9]+)/.test(value)
    ? ''
    : 'Password must contain lowercase character, uppercase character and number.'
}

export function validPhone(value) {
  const parsedNumber = parse(value)
  return parsedNumber.phone
    ? null
    : 'This field must be a valid phone number. E.g. +64 12345 6789'
}

export function validMatchPassword(passwordNew, passwordConfirm) {
  return passwordConfirm && passwordNew && passwordNew !== passwordConfirm
    ? 'Passwords are not matching.'
    : null
}

export function validPastYear(value) {
  if (!/^\d{4}$/.test(value) || value > new Date().getFullYear()) {
    return 'Please enter a valid year'
  }
  return null
}

export function validNumber(value) {
  return isNaN(Number(value)) ? 'Please enter a valid number' : null
}

export function validPositiveNumber(value) {
  return !validNumber(value) && Number(value) >= 0
    ? null
    : 'Please enter a valid positive number'
}

export function validHumanName(value) {
  return HUMAN_NAME_VALIDATION_REGEX.test(value)
    ? null
    : 'Please no special characters or numbers.'
}

export function validName(value) {
  return NAME_VALIDATION_REGEX.test(value)
    ? null
    : 'Please no special characters.'
}

export function validNameWithSpecialCharacters(value) {
  return NAME_WITH_SPECIAL_CHARACTERS_VALIDATION_REGEX.test(value)
    ? null
    : 'Please use only letters, numbers, spaces, or the special characters ( ) . _ - / ’'
}

export function oneOf(value, list) {
  if (list.indexOf(value) === -1) {
    return `Please choose from one of these: ${list.join(', ')}`
  }
}

export function minNum(value, num) {
  if (value < num) {
    return `The value must be greater than ${num}`
  }
}

export function maxNum(value, num) {
  if (value > num) {
    return `The value must be less than ${num}`
  }
}

export const validate = {
  required,
  email: validEmail,
  url: validUrl,
  minLength: validMinLength,
  maxLength: validMaxLength,
  phoneWithPrefix: validPhoneWithPrefix,
  password: validPassword,
  phone: validPhone,
  matchPassword: validMatchPassword,
  pastYear: validPastYear,
  oneOf,
  number: validNumber,
  positiveNumber: validPositiveNumber,
  name: validName,
  nameWithSpecialCharacters: validNameWithSpecialCharacters,
  humanName: validHumanName,
  chartName: validChartName,
  min: minNum,
  max: maxNum,
}

export const validateValues = (values, rules) => {
  const fields = Object.keys(values)
  return fields.reduce((errors, field) => {
    let validators = rules[field]
    if (validators) {
      // single validator
      if (!Array.isArray(validators)) {
        validators = [validators]
      }
      for (let validator of validators) {
        // custom validator
        if (typeof validator === 'function') {
          errors[field] = validator(values[field], field, values)
        } else if (typeof validator === 'string' && validate[validator]) {
          // can have arguments (separated by `:` and/or `|`)
          const [validatorName, ...args] = validator.split(/:|\|/)
          // simple validator
          errors[field] = validate[validatorName](...[values[field], ...args])
        } else {
          // validator with parameters (eg: minLenght)
          let { name, params, validator: validatorFn } = validator
          if (!validatorFn) {
            // using built in validator from validate
            validatorFn = validate[name]
          }
          errors[field] =
            validatorFn && validatorFn(...[values[field], ...(params || [])])
        }
        if (errors[field]) {
          // we return with the first error, ignoring the following ones
          return errors
        }
      }
    }
    return errors
  }, {})
}
