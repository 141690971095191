export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_REGEX = new RegExp(
  `^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{${PASSWORD_MIN_LENGTH},})`
)
export const PASSWORD_CRITERIA_MESSAGE = `The password has to be min ${PASSWORD_MIN_LENGTH} character long with lowercase, uppercase character and number.`

export const validatePassword = (password) =>
  password.length >= PASSWORD_MIN_LENGTH && PASSWORD_REGEX.test(password)

export default validatePassword
