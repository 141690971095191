import uuidv1 from 'uuid'

import {
  TOAST_VARIANT_INFO,
  TOAST_VARIANT_ERROR,
  TOAST_VARIANT_SUCCESS,
} from 'src/containers/Toast'

export const TOAST_ADD = 'TOAST_ADD'
export const TOAST_SEEN = 'TOAST_SEEN'

export const addToast = (toast) => ({
  type: TOAST_ADD,
  payload: {
    title: '',
    message: '',
    variant: TOAST_VARIANT_INFO,
    id: uuidv1(),
    created: new Date(),
    seen: false,
    ...toast,
  },
})

export const addErrorToast = (toast) =>
  addToast({
    variant: TOAST_VARIANT_ERROR,
    ...toast,
  })

export const addSuccessToast = (toast) =>
  addToast({
    variant: TOAST_VARIANT_SUCCESS,
    ...toast,
  })

export const markToastAsSeen = (payload) => ({
  type: TOAST_SEEN,
  payload,
})
