import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import {
  validateValues,
} from 'src/utils/validation'
import TextInput from 'src/components/atoms/TextInput'
import useForm from 'src/hooks/useForm'
import { ConstraintShape } from 'src/utils/types'
import { countDecimals } from './../../../utils/formatters'
import { CONSTRAINT_TYPE } from 'src/constants/settings'
import { TideRateStationSelect } from 'src/components/organisms/TideRateStationSelect';

const formatLatOrLong = (value) => {
  if (!value) {
    return ''
  }
  if (countDecimals(value) > 6) {
    return Number(value)
  }
  return Number(value).toFixed(6)
}

const latLongTooltip =
"Enter the latitude and the longitude of the location of the port. You can find the values on Google Maps by a right-click on the location on a map, then select the menu option: 'What's here?'. The Lat. & Long. of the selected location will be displayed at the bottom of your screen."

const LatLongField = styled(TextInput)`
  && {
    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export const WaypointForm = ({
  onChange,
  onSubmit,
  disabled,
  constraint,
}) => {

  const validateForm = (values) => {
    return validateValues(values, {
      name: ['required', 'name'],
      locationLatitude: [
        'required',
        { name: 'min', params: [-90] },
        { name: 'max', params: [90] },
      ],
      locationLongitude: [
        'required',
        { name: 'min', params: [-180] },
        { name: 'max', params: [180] },
      ],
    })
  }

  const beforeSubmit = (values) => {
    return ({
      ...values,
      location: {
        latitude: Number(values.locationLatitude),
        longitude: Number(values.locationLongitude),
      },
      tideRateStation: values.tideRateStation ? values.tideRateStation : null,
    })
  }

  const handleOnChange = (...args) => {
    if (onChange) {
      onChange(...args)
    }
  }

  const { fields } = useForm({
    initialValues: {
      uuid: null,
      name: '',
      constraintType: CONSTRAINT_TYPE.UKC,
      value: 0,
      tideRateStation: '',

      ...constraint,

      isWaypoint: true,
      locationLatitude: formatLatOrLong(constraint.location ? constraint.location.latitude : null),
      locationLongitude: formatLatOrLong(constraint.location ? constraint.location.longitude : null),
    },
    validationHandler: validateForm,
    changeHandler: handleOnChange,
    transformHandler: beforeSubmit,
    submitHandler: onSubmit,
  })

  const blurHandler = (event) => {
    const { name } = event.target
    const value = fields[name].value
    const formattedValue = formatLatOrLong(value)
    if (value !== formattedValue) {
      fields[name].onChange(name, formattedValue)
    }
    fields[name].onBlur(event)
  }

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextInput
            fullWidth
            narrow
            name="name"
            label="Name"
            {...fields.name}
          />
        </Grid>
        <Grid item xs={6}>
          <LatLongField
            id="port-location-latitude"
            name="locationLatitude"
            label="Latitude"
            infoTooltip={latLongTooltip}
            disabled={disabled}
            {...fields.locationLatitude}
            onBlur={blurHandler}
            fullWidth
            type="number"
            step="0.01"
          />
        </Grid>
        <Grid item xs={6}>
          <LatLongField
            id="port-location-longitude"
            name="locationLongitude"
            label="Longitude"
            infoTooltip={latLongTooltip}
            disabled={disabled}
            {...fields.locationLongitude}
            fullWidth
            type="number"
            step="0.01"
            onBlur={blurHandler}
          />
        </Grid>
        <Grid item xs={6}>
          <TideRateStationSelect
            fullWidth
            narrow
            name="tideRateStation"
            label="Tide Rate Station"
            disabled={disabled}
            disableFilterByPort={false}
            {...fields.tideRateStation}
          />
        </Grid>
      </Grid>
    </>
  )
}

WaypointForm.propTypes = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
  constraint: PropTypes.shape(ConstraintShape),
}

export default WaypointForm
