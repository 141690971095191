import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'

import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDown from '@material-ui/icons/KeyboardArrowDown'
import { Box, Button, Typography } from '@material-ui/core'

import { requestLogout } from 'src/store/auth/actions'
import {
  userNameSelector,
  userEmailSelector,
  userOrganisationNameSelector,
} from 'src/store/user/selectors'
import { UserIcon } from 'src/components/atoms/Icons'

const UserMenuButton = styled(Button)`
  && {
    .MuiButton-label {
      align-items: flex-start;
      > * {
        margin: 0 0.15em;
      }
    }
  }
`

const UserMenuStyled = styled(Menu)`
  && {
    .MuiListItem-root {
      min-width: 200px;
    }
  }
`

export const UserMenu = () => {
  const defaultAnchorState = null
  const [anchorElState, setAnchorElState] = useState(defaultAnchorState)
  const dispatch = useDispatch()
  const name = useSelector(userNameSelector) || ''
  const email = useSelector(userEmailSelector) || ''
  const orgName = useSelector(userOrganisationNameSelector) || ''
  const handleClick = (event) => setAnchorElState(event.currentTarget)
  const handleClose = () => setAnchorElState(defaultAnchorState)
  const handleLogout = (event) => {
    event.preventDefault()
    dispatch(requestLogout())
  }

  return (
    <Box>
      <UserMenuButton onClick={handleClick} disableRipple>
        <UserIcon />
        <Box textAlign="left">
          <div>{name || email}</div>
          <Typography variant="caption">{orgName}</Typography>
        </Box>
        <ArrowDropDown />
      </UserMenuButton>
      <UserMenuStyled
        id="user-menu"
        anchorEl={anchorElState}
        open={!!anchorElState}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </UserMenuStyled>
    </Box>
  )
}

export default UserMenu
