import React from 'react'
import PropTypes from 'prop-types'

export const FileIcon = ({ size = 18, ext }) => (
  <svg
    width={size}
    height={(size * 23) / 18}
    viewBox="0 0 18 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="#4DAAEF" fillRule="evenodd">
      <path
        d="M17 6.728L11.02 1H2a1 1 0 0 0-1 1v19a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V6.728zm-6.5-7.49l9.045 8.406H10.5V-.76z"
        stroke="#4DAAEF"
        strokeWidth="2"
        fillOpacity=".4"
      />
      <text fontFamily="Helvetica" fontSize="6">
        <tspan x="3" y="15">
          {ext && ext.toUpperCase()}
        </tspan>
      </text>
    </g>
  </svg>
)

FileIcon.propTypes = {
  size: PropTypes.number,
  ext: PropTypes.string,
}

export default FileIcon
