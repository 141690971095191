import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { List, ListItemText, ListItemIcon, Checkbox } from '@material-ui/core'
import WavesIcon from '@material-ui/icons/Waves'
import LocationOnIcon from '@material-ui/icons/LocationOn'

import ListItem from 'src/components/molecules/ListItem'
import ListItemSecondaryAction from 'src/components/molecules/ListItemSecondaryAction'
import { StationShape } from 'src/utils/types'
import { RemoveIconButton } from 'src/components/atoms/RemoveButton'
import EmptyState from 'src/components/atoms/EmptyState'
import { BERTH_STATION_TYPE } from 'src/constants/settings'
import Preloader from 'src/components/atoms/Preloader'
import { compareName } from 'src/utils/sorting'

const TwoColumnsList = styled(List)(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > * {
    max-width: 40%;
    min-width: 40%;
    ${theme.breakpoints.down('xs')} {
      max-width: 100%;
      min-width: 100%;
    }
  }
`
)

const StyledListItemText = styled(ListItemText)`
  && {
    overflow-wrap: break-word;
  }
`

const StationListItem = ({
  selectable,
  selected,
  station,
  onClick,
  onDelete,
}) => {
  const { name } = station
  const handleDelete = (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    if (onDelete) {
      onDelete(station)
    }
  }
  const handleClick = () => {
    if (onClick) {
      onClick(station)
    }
  }
  const Icon =
    station.berthStationType === BERTH_STATION_TYPE.STATION
      ? WavesIcon
      : LocationOnIcon
  return (
    <ListItem onClick={handleClick}>
      <ListItemIcon>
        {selectable ? <Checkbox checked={selected} /> : <Icon />}
      </ListItemIcon>
      <StyledListItemText primary={name} />
      {onDelete && (
        <ListItemSecondaryAction>
          <RemoveIconButton onClick={handleDelete} />
        </ListItemSecondaryAction>
      )}
    </ListItem>
  )
}

StationListItem.propTypes = {
  station: PropTypes.shape(StationShape).isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  selected: PropTypes.bool,
  selectable: PropTypes.bool,
}

export const StationsList = ({
  stations,
  onClick,
  onDelete,
  selectable,
  selected,
  useColumns,
  isLoading,
  ...props
}) => {
  const ListContainer = useColumns ? TwoColumnsList : List
  const sorted = stations && stations.sort(compareName)

  return (
    <>
      {isLoading && <Preloader />}
      <ListContainer {...props}>
        {!isLoading && !sorted && <EmptyState>No data</EmptyState>}
        {sorted &&
          sorted.map((station) => (
            <StationListItem
              key={station.uuid}
              station={station}
              onClick={onClick}
              onDelete={onDelete}
              selectable={selectable}
              selected={selectable && (selected || []).includes(station.uuid)}
            />
          ))}
      </ListContainer>
    </>
  )
}
StationsList.propTypes = {
  stations: PropTypes.arrayOf(PropTypes.shape(StationShape)),
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  useColumns: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string),
}

export default StationsList
