import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { countDecimals } from 'src/utils/formatters'
import TextInput from 'src/components/atoms/TextInput'

const formatValue = (value, isInteger, decimalCount) => {
  if (typeof value !== 'number') {
    return value
  }
  if (isInteger) {
    return countDecimals(value) > 0 ? Math.round(value) : value
  }
  return decimalCount && countDecimals(value) > decimalCount
    ? Number(value.toFixed(decimalCount))
    : value
}

const StyledTextInput = styled(TextInput)`
  && {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export const NumberInput = ({
  decimalCount,
  isInteger,
  onChange,
  value: rawValue,
  defaultValue: rawDefaultValue,
  regularCase,
  unit = '',
  ...inputProps
}) => {
  const handleNumberChange = (e) => {
    const inputValue = e.target.value
    const value = Number(e.target.value)
    if (
      (inputValue !== '-' && isNaN(value)) ||
      (isInteger &&
        (inputValue.indexOf('.') > -1 || countDecimals(value) > 0)) ||
      (!isInteger && decimalCount && countDecimals(value) > decimalCount)
    ) {
      e.preventDefault()
      return
    }
    onChange(e)
  }

  const value = formatValue(rawValue, false, decimalCount)
  const defaultValue = formatValue(rawDefaultValue, false, decimalCount)

  return (
    <StyledTextInput
      regularCase={regularCase}
      type="number"
      value={value}
      defaultValue={defaultValue}
      onChange={handleNumberChange}
      unit={unit}
      {...inputProps}
    />
  )
}

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  decimalCount: PropTypes.number,
  isInteger: PropTypes.bool,
  regularCase: PropTypes.bool,
  unit: PropTypes.string
}

export default React.memo(NumberInput)
