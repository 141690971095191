import React from 'react'
import {
    IconButton,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
  

// eslint-disable-next-line react/display-name
const EditButton = React.forwardRef((props, ref) => (
    <IconButton ref={ref} {...props}>
      <EditIcon />
    </IconButton>
))
EditButton.propTypes = {}

export default EditButton
