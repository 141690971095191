import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-sso'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'

// GET SSO
const ACTION_GET = 'SSO_GET'
export const [
  SSO_GET_REQUEST,
  SSO_GET_RESULT,
  SSO_GET_SUCCESS,
  SSO_GET_ERROR,
  SSO_GET_CANCEL,
  SSO_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getSso = (sso) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: sso })
  return dispatch(getSsoRequest(sso))
}
export const getSsoRequest = (payload) => async (dispatch) => {
  dispatch(getSsoInProgress(payload))
  try {
    const ssoResult = await api.getSso(payload)
    return dispatch(getSsoSuccess(ssoResult))
  } catch (error) {
    return dispatch(getSsoError(error))
  }
}
export const getSsoInProgress = (payload) =>
  createInProgressAction(ACTION_GET, payload)
export const getSsoSuccess = (sso) =>
  createSuccessAction(ACTION_GET, sso)
export const getSsoError = (error) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.SSO_GET_ERROR
    })
  )
  return dispatch(createErrorAction(ACTION_GET, error))
}

// CREATE SSO
const ACTION_CREATE = 'SSO_CREATE'
export const [
  SSO_CREATE_REQUEST,
  SSO_CREATE_RESULT,
  SSO_CREATE_SUCCESS,
  SSO_CREATE_ERROR,
  SSO_CREATE_CANCEL,
  SSO_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createSso = (sso) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: sso })
  return dispatch(createSsoRequest(sso))
}
export const createSsoRequest = (payload) => async (dispatch) => {
  dispatch(createSsoInProgress(payload))
  try {
    const ssoResult = await api.createSso(payload)
    return dispatch(createSsoSuccess(ssoResult))
  } catch (error) {
    return dispatch(createSsoError(error, payload))
  }
}
export const createSsoInProgress = (sso) =>
  createInProgressAction(ACTION_CREATE, sso)
export const createSsoSuccess = (sso) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        TOAST_MESSAGES.SSO_CREATE_SUCCESS,
        sso
      ),
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, sso))
}
export const createSsoError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(TOAST_MESSAGES.SSO_CREATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE sso
const ACTION_UPDATE = 'SSO_UPDATE'
export const [
  SSO_UPDATE_REQUEST,
  SSO_UPDATE_RESULT,
  SSO_UPDATE_SUCCESS,
  SSO_UPDATE_ERROR,
  SSO_UPDATE_CANCEL,
  SSO_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateSso = (sso) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: sso })
  return dispatch(updateSsoRequest(sso))
}
export const updateSsoRequest = (payload) => async (dispatch) => {
  dispatch(updateSsoInProgress(payload))
  try {
    const ssoResult = await api.updateSso(payload)
    return dispatch(updateSsoSuccess(ssoResult))
  } catch (error) {
    return dispatch(updateSsoError(error, payload))
  }
}
export const updateSsoInProgress = (sso) =>
  createInProgressAction(ACTION_UPDATE, sso)
export const updateSsoSuccess = (sso) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        TOAST_MESSAGES.SSO_UPDATE_SUCCESS,
        sso
      ),
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, sso))
}
export const updateSsoError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(TOAST_MESSAGES.SSO_UPDATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE sso (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'SSO_SAVE'
export const saveSso = (sso) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: sso })
  const { uuid, ...ssoNoUuuid } = sso
  const result = await dispatch(
    uuid ? updateSso(sso) : createSso(ssoNoUuuid)
  )
  return result
}

// DELETE sso
const ACTION_DELETE = 'SSO_DELETE'
export const [
  SSO_DELETE_REQUEST,
  SSO_DELETE_RESULT,
  SSO_DELETE_SUCCESS,
  SSO_DELETE_ERROR,
  SSO_DELETE_CANCEL,
  SSO_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteSso = (sso) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: sso })
  return dispatch(deleteSsoRequest(sso))
}
export const deleteSsoRequest = (sso) => async (dispatch) => {
  dispatch(deleteSsoInProgress(sso))
  try {
    await api.deleteSso(sso.uuid)
    return dispatch(deleteSsoSuccess(sso))
  } catch (error) {
    dispatch(deleteSsoError(error))
    return error
  }
}
export const deleteSsoInProgress = (sso) =>
  createInProgressAction(ACTION_DELETE, sso)
export const deleteSsoSuccess = (sso) =>
  createSuccessAction(ACTION_DELETE, sso)
export const deleteSsoError = (error) =>
  createErrorAction(ACTION_DELETE, error)
