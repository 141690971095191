import { addErrorToast } from 'src/store/toast/actions'
import { getPilotagesPdfDownload } from 'src/utils/sauce/sauce-api-pilotages'
import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction
} from 'src/utils/createAction'
import TOAST_MESSAGES from 'src/utils/toastMessages'

const PILOTAGE_PDF_DOWNLOAD_BASE = 'PILOTAGE_PDF_DOWNLOAD'

export const [
  PILOTAGE_PDF_DOWNLOAD_REQUEST,
  PILOTAGE_PDF_DOWNLOAD_RESULT,
  PILOTAGE_PDF_DOWNLOAD_SUCCESS,
  PILOTAGE_PDF_DOWNLOAD_ERROR,
  PILOTAGE_PDF_DOWNLOAD_CANCEL,
  PILOTAGE_PDF_DOWNLOAD_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_PDF_DOWNLOAD_BASE)

export const pilotagesPdfDownloadRequest = (params) => async (
  dispatch
) => {
  dispatch(pilotagesPdfDownloadInProgress())
  try {
    const response = await getPilotagesPdfDownload(params)
    dispatch(pilotagesPdfDownloadLoaded(null, params))
    return response.data
  } catch (error) {
    dispatch(pilotagesPdfDownloadError(error))
    return false
  }
}

export const pilotagesPdfDownloadInProgress = () =>
  createInProgressAction(PILOTAGE_PDF_DOWNLOAD_BASE)

export const pilotagesPdfDownloadError = (error) => dispatch => {
  dispatch(createErrorAction(PILOTAGE_PDF_DOWNLOAD_BASE, { error }))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.DOWNLOAD_PDF_ERROR,
    })
  )
}

export const pilotagesPdfDownloadLoaded = (data, params) =>
  createSuccessAction(PILOTAGE_PDF_DOWNLOAD_BASE, { data, params })
