import React, { useCallback, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { IconButton, Grid, InputAdornment, Box } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'

import { TugShape } from 'src/utils/types'
import { validateValues } from 'src/utils/validation'
import { TUG_TYPES } from 'src/constants/settings'
import { TextInput } from 'src/components/atoms/TextInput'
import SelectInput from 'src/components/atoms/SelectInput'
import { saveTug } from 'src/store/tug/actions'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import useForm from 'src/hooks/useForm'
import { RemoveIconButton } from 'src/components/atoms/RemoveButton'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'

const StyledIconButton = styled(IconButton)`
  && {
    padding: 0;
  }
`

const DEFAULT_VALUES = {
  name: '',
  tonnage: 0,
  tugType: '',
}

const tugTypesList = Object.values(TUG_TYPES)

const validateForm = (values) =>
  validateValues(values, {
    name: ['required', 'name'],
    tonnage: ['required', 'positiveNumber'],
    tugType: ['required', { name: 'oneOf', params: [tugTypesList] }],
  })

export const TugForm = ({
  onSaved,
  onSaveError,
  onChange,
  onDelete,
  wrapperProps = {},
  autoSave = false,
  tug,
}) => {
  const uuid = useRef(tug.uuid)
  const dispatch = useDispatch()
  const [confirmCancel, setConfirmCancel] = useState(false)

  const values = useRef(tug)
  const [submitting, setSubmitting] = useState(false)

  const handleChange = useCallback(
    (change) => {
      values.current = change.values
      if (onChange) {
        onChange(change)
      }
    },
    [onChange]
  )
  const handleFormSubmit = useCallback(() => {
    setSubmitting(true)
    dispatch(saveTug(values.current)).then(
      (result) => {
        uuid.current = result.uuid
        dispatch(
          addSuccessToast({
            message: getToastMessage(
              TOAST_MESSAGES[
                uuid.current ? 'TUG_CREATE_SUCCESS' : 'TUG_UPDATE_SUCCESS'
              ],
              values.current
            ),
          })
        )
        setSubmitting(false)
        if (onSaved) {
          onSaved(result)
        }
      },
      (error) => {
        dispatch(
          addErrorToast({
            message: getToastMessage(
              TOAST_MESSAGES[
                uuid.current ? 'TUG_CREATE_ERROR' : 'TUG_UPDATE_ERROR'
              ],
              values.current
            ),
          })
        )
        setSubmitting(false)
        if (onSaveError) {
          onSaveError(error)
        }
      }
    )
  }, [dispatch, onSaveError, onSaved])

  const handleDeleteRequest = useCallback(() => onDelete(tug), [onDelete, tug])

  const { dirty, isValid, handleSubmit, fields } = useForm({
    initialValues: {
      ...DEFAULT_VALUES,
      ...tug,
    },
    validationHandler: validateForm,
    changeHandler: handleChange,
    submitHandler: handleFormSubmit,
    autoSave,
  })

  const handleCloseRequest = () => {
    if (dirty) {
      setConfirmCancel(true)
    } else {
      handleDeleteRequest()
    }
  }
  const handleCancelChangesAndClose = () => {
    setConfirmCancel(false)
    handleDeleteRequest()
  }

  const handleRejectCloseRequest = () => setConfirmCancel(false)

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1} {...wrapperProps}>
        <Grid item xs={12} md={7}>
          <TextInput fullWidth name="name" {...fields.name} />
        </Grid>
        <Grid item xs={6} md={2}>
          <TextInput
            fullWidth
            name="tonnage"
            {...fields.tonnage}
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">t</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={5} md={2}>
          <SelectInput
            fullWidth
            name="tugType"
            {...fields.tugType}
            options={tugTypesList}
          />
        </Grid>
        <Grid item xs={1} md={1}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            height="100%"
          >
            {(!tug.uuid || dirty) && (
              <StyledIconButton disabled={!isValid} onClick={handleFormSubmit}>
                <CheckCircleOutlineIcon />
              </StyledIconButton>
            )}
            <RemoveIconButton
              disabled={submitting}
              onClick={handleCloseRequest}
            />
          </Box>
        </Grid>
      </Grid>
      <ConfirmDialog
        open={confirmCancel}
        title="Unsaved changes"
        onCancel={handleRejectCloseRequest}
        onConfirm={handleCancelChangesAndClose}
      >
        You have unsaved changes. Are you sure you want to remove this tug?
      </ConfirmDialog>
    </form>
  )
}

TugForm.propTypes = {
  tug: PropTypes.shape(TugShape),
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  onSaved: PropTypes.func,
  onSaveError: PropTypes.func,
  autoSave: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  wrapperProps: PropTypes.object,
}

export default TugForm
