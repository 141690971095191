import { CREATOR_STATUS_NAMES } from '@weareroam/sauce-web-store'
import {
  TOAST_VARIANT_ERROR,
  TOAST_VARIANT_SUCCESS,
} from 'src/containers/Toast'
import { addToast } from 'src/store/toast/actions'
import { TOAST_MESSAGES, getToastMessage } from 'src/utils/toastMessages'

const createMiddleware = ({ transformPayload, getMessage } = {}) => (
  name,
  actionName,
  status
) => (payload, dispatch, getState) => {
  const messageKey = `${name}_${actionName}_${status}`.toUpperCase()
  let message = getMessage
    ? getMessage(name, actionName, status, payload)
    : TOAST_MESSAGES[messageKey]
  if (message) {
    message = getToastMessage(
      message,
      transformPayload
        ? transformPayload(
            name,
            actionName,
            status,
            payload,
            dispatch,
            getState
          )
        : payload
    )
    dispatch(
      addToast({
        variant:
          status === CREATOR_STATUS_NAMES.SUCCESS
            ? TOAST_VARIANT_SUCCESS
            : TOAST_VARIANT_ERROR,
        message,
      })
    )
  }
  return payload
}

export const actionToastMessage = (cfg) => {
  return {
    actions: {
      '*': {
        success: createMiddleware(cfg),
        error: createMiddleware(cfg),
      },
    },
  }
}

export default actionToastMessage
