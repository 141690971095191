import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { DialogTitle, Typography } from '@material-ui/core'

const StyledDialogTitle = styled(DialogTitle)(
  ({ theme }) => `
  && {
    border-left: ${theme.spacing(0.5)}px solid;
    margin-bottom: ${theme.spacing(3)}px;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: ${theme.spacing(1.75)}px;
  }
`
)

export const DialogHeader = ({ children, ...props }) => (
  <StyledDialogTitle {...props} disableTypography>
    <Typography variant="h5">{children}</Typography>
  </StyledDialogTitle>
)
DialogHeader.propTypes = {
  children: PropTypes.node,
}
export default DialogHeader
