import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Fieldset from 'src/components/organisms/Fieldset'
import TugForm from 'src/components/organisms/TugForm'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { PortShape, TugShape } from 'src/utils/types'
import { deleteTug, getTugList } from 'src/store/tug/actions'
import {
  getListFilteredByPort,
  getListFilteredBySelectedPort,
  tugListSelector,
} from 'src/store/tug/selectors'

export const TugList = ({ disabled, port, disableFilterByPort }) => {
  const dispatch = useDispatch()

  const tugs = useSelector(
    disableFilterByPort
      ? tugListSelector
      : port
      ? getListFilteredByPort(port.uuid)
      : getListFilteredBySelectedPort
  )
  useEffect(() => {
    dispatch(getTugList())
  }, [dispatch])

  const [selectedToDelete, setSelectedToDelete] = useState(null)
  const handleDeleteRequest = (tug) => setSelectedToDelete(tug)
  const handleDeleteCancel = () => setSelectedToDelete(null)
  const handleDeleteConfirm = () => {
    dispatch(deleteTug(selectedToDelete)).then(
      () => {
        dispatch(
          addSuccessToast({
            message: getToastMessage(
              TOAST_MESSAGES.TUG_DELETE_SUCCESS,
              selectedToDelete
            ),
          })
        )
        setSelectedToDelete(null)
      },
      () => {
        dispatch(
          addErrorToast({
            message: getToastMessage(
              TOAST_MESSAGES.TUG_DELETE_ERROR,
              selectedToDelete
            ),
          })
        )
        setSelectedToDelete(null)
      }
    )
  }
  const [newTug, setNewTug] = useState(null)
  const handleNewTugAdd = useCallback(
    () => setNewTug({ port, name: '', tugType: '', tonnage: 0 }),
    [port]
  )
  const handleNewTugDelete = () => setNewTug(null)
  const handleNewTugSaved = () => setNewTug(null)

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Tugs"
        description="Set all the available tugs in your port"
        subtitle="Create a list of available tugs for your pilotages at your location.
        Also maintain the max. bollard pull and the propulsion system for
        each."
        clickHandler={handleNewTugAdd}
        hasButton
        buttonContents="Add Tug"
      >
        {tugs &&
          tugs.map((tug) => (
            <TugForm
              autoSave={4000}
              key={tug.uuid}
              onDelete={handleDeleteRequest}
              tug={{ ...tug, port }}
            />
          ))}
        {newTug && (
          <TugForm
            onDelete={handleNewTugDelete}
            onSaved={handleNewTugSaved}
            tug={newTug}
          />
        )}
      </Fieldset>
      {selectedToDelete && (
        <ConfirmDialog
          open={!!selectedToDelete}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          title={`Remove tug: ${selectedToDelete.name}`}
        >
          Are you sure you want to remove this tug?
        </ConfirmDialog>
      )}
    </>
  )
}

TugList.propTypes = {
  port: PropTypes.shape(PortShape),
  tugs: PropTypes.arrayOf(PropTypes.shape(TugShape)),
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
}

export default TugList
