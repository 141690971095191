import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

import ViewportLoader from 'src/components/atoms/ViewportLoader/ViewportLoader'
import { isPortLoadingSelector } from 'src/store/port/selectors'

const SubText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin: 20px 0px;
  }
`

const StyledGrid = styled(Grid)`
  && {
    flex-basis: 0;
  }
`

export const SettingsTemplate = ({ children, title }) => {
  const portIsLoading = useSelector(isPortLoadingSelector)

  return (
    <>
      {portIsLoading && <ViewportLoader>Loading port data...</ViewportLoader>}
      <StyledGrid item xs={10}>
        <Typography variant="h1" color="textPrimary">
          Settings
        </Typography>
        <SubText>
          Here you can maintain the configuration of your eMPX application.
        </SubText>
        <Typography variant="h2" color="textPrimary">
          {title}
        </Typography>
      </StyledGrid>
      <StyledGrid item xs={12}>
        {children}
      </StyledGrid>
    </>
  )
}

SettingsTemplate.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
}

export default SettingsTemplate
