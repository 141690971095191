import React from 'react'
import PropTypes from 'prop-types'

import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import Flowlist from 'src/components/organisms/Checklist/Flowlist'

export const Flow = ({ port }) => {
  return (
    <SettingsTemplate title="Flow" port={port}>
      <Flowlist port={port} />
    </SettingsTemplate>
  )
}

Flow.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Flow
