import styled from 'styled-components'

import { Divider as MuiDivider } from '@material-ui/core'

export const Divider = styled(MuiDivider)`
  && {
    margin: ${({ theme }) => theme.spacing(4, 0)};
    width: 75%;
  }
`

export default Divider
