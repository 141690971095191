import { HmacSHA256, AES, enc } from 'crypto-js'

export const getSecret = () => {
  return process.env.REACT_APP_SECRET
}

export const getSalt = () => {
  return process.env.REACT_APP_SALT
}

export const encrypt = (key) => {
  const encryptedKey = AES.encrypt(key, getSecret())
  return String(encryptedKey)
}

export const decrypt = (key) => {
  const decryptedKey = AES.decrypt(key, getSecret())
  return decryptedKey.toString(enc.Utf8)
}

export const hash = (text) => HmacSHA256(text, getSalt()).toString()
