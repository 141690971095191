import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Grid, Typography } from '@material-ui/core'

import TextInput from 'src/components/atoms/TextInput'
import ChartSelector from 'src/components/organisms/ChartSelector'
import { StageShape, ChartShape } from 'src/utils/types'
import { validateValues } from 'src/utils/validation'
import {
  replaceSpecialCharacters,
  removeFileExtensionFromName,
} from 'src/utils/formatters'

const StyledSubtitle = styled(Typography)`
  && {
    margin: ${({ theme }) => theme.spacing(1, 1)};
  }
`
const StyledAddButtonWrapper = styled(Grid)`
  && > button {
    padding: ${({ theme }) => theme.spacing(6, 4)};
  }
`

const validateStage = (values) =>
  validateValues(values, {
    name: ['required', 'name'],
    chart: [
      'required',
      (value) =>
        value && value.name && value.uuid && value.imageUrl
          ? null
          : 'Please select a chart',
    ],
  })

export const StageForm = ({ charts, value, onChange, subTitle = '' }) => {
  const [edits, setEdits] = useState({ ...value })
  const [errors, setErrors] = useState({})

  const handleChange = (next) => {
    setEdits(next)
    onChange(next)
  }

  const handleNameChange = (event) => {
    handleChange({ ...edits, name: event.target.value })
  }

  const handleChartChange = (name, chart) => {
    handleChange({
      ...edits,
      chart,
      name:
        chart && chart.name
          ? replaceSpecialCharacters(removeFileExtensionFromName(chart.name))
          : edits.name,
    })
  }

  return (
    <>
      <Grid
        container
        spacing={2}
        onBlur={() => setErrors(validateStage(edits))}
        onFocus={() => setErrors({})}
      >
        <Grid item xs={7}>
          <TextInput
            fullWidth
            name="name"
            narrow
            onChange={handleNameChange}
            value={edits.name}
            error={errors.name}
          />
          <StyledSubtitle variant="subtitle2" color="textSecondary">
            {subTitle}
          </StyledSubtitle>
        </Grid>
        <StyledAddButtonWrapper item xs={5}>
          <ChartSelector
            name="chart"
            charts={charts}
            onChange={handleChartChange}
            value={edits.chart}
            error={errors.chart}
          />
        </StyledAddButtonWrapper>
      </Grid>
    </>
  )
}
StageForm.propTypes = {
  value: PropTypes.shape(StageShape),
  charts: PropTypes.arrayOf(PropTypes.shape(ChartShape)),
  onChange: PropTypes.func,
  subTitle: PropTypes.string,
}

export default React.memo(StageForm)
