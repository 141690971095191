import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'
import { createTideData as createTideDataApi } from 'src/utils/sauce/sauce-api-tide-data'
import { addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import { getTideStation } from 'src/store/tideStations/actions'

export const TIDE_DATA_ACTION = 'TIDE_DATA'
export const TIDE_DATA_CREATE = `${TIDE_DATA_ACTION}_CREATE`
export const [
  TIDE_DATA_CREATE_REQUEST,
  TIDE_DATA_CREATE_RESULT,
  TIDE_DATA_CREATE_SUCCESS,
  TIDE_DATA_CREATE_ERROR,
  TIDE_DATA_CREATE_CANCEL,
  TIDE_DATA_CREATE_IN_PROGRESS,
] = createBasicActionTypes(TIDE_DATA_CREATE)

export const createTideData = (payload) => (dispatch) => {
  dispatch({ type: TIDE_DATA_CREATE, payload })
  return dispatch(createTideDataRequest(payload))
}
export const createTideDataRequest = (payload) => async (dispatch) => {
  dispatch(createTideDataInProgress(payload))
  try {
    const tideData = await createTideDataApi(payload, {
      timeout: 0,
      onUploadProgress: (event) => {
        dispatch(
          createTideDataInProgress({
            ...payload,
            percent: Math.floor((event.loaded * 100) / event.total),
          })
        )
      },
    })
    return dispatch(
      createTideDataSuccess({
        ...payload,
        tideData,
      })
    )
  } catch (error) {
    return dispatch(createTideDataError({ ...error, payload }))
  }
}
export const createTideDataInProgress = (payload) =>
  createInProgressAction(TIDE_DATA_CREATE, payload)

export const createTideDataSuccess = (payload) => async (dispatch) => {
  // we use await so we display success toast only
  // when the tide station is updated with the new
  // tide data range information
  const { payload: tideStation } = await dispatch(
    getTideStation(payload.tideStation.uuid)
  )
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        TOAST_MESSAGES.TIDE_DATA_CREATE_SUCCESS,
        tideStation
      ),
    })
  )
  return dispatch(
    createSuccessAction(TIDE_DATA_CREATE, { ...payload, tideStation })
  )
}

export const createTideDataError = (error) => (dispatch) => {
  return dispatch(createErrorAction(TIDE_DATA_CREATE, error))
}
