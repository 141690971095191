import React from 'react'
import PropTypes from 'prop-types'

import TideStations from 'src/containers/TideStations'
import TideRate from 'src/containers/TideRate'
import Divider from 'src/components/atoms/Divider/Divider'
import ConstraintList from 'src/components/organisms/ConstraintList'
import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import Weather from 'src/components/organisms/Weather'
import WeatherLocations from 'src/components/organisms/WeatherLocations'
import { WaypointList } from './../../components/organisms/WaypointList/WaypointList'
import { ResidualDiffToggle } from './../../components/organisms/ConstraintList/ResidualDiffToggle'
import { ResidualDiffIgnoreTimeToggle } from '../../components/organisms/ConstraintList/ResidualDiffIgnoreTimeToggle'
import { ShowActiveDefaultToggle } from './../../components/organisms/ConstraintList/ShowActiveDefaultToggle';
import { HideWaypointLocationToggle } from './../../components/organisms/ConstraintList/HideWaypointLocationToggle';

export const Environment = ({ port }) => {
  const portUuid = port && port.uuid

  return (
    <SettingsTemplate title="Environment" port={port}>
      <TideStations disabled={!portUuid} port={port} />
      <Divider />
      <TideRate disabled={!portUuid} port={port} />
      <Divider />
      <WaypointList disabled={!portUuid} port={port} />
      <HideWaypointLocationToggle/>
      <Divider />
      <ConstraintList disabled={!portUuid} port={port} />
      <ResidualDiffToggle />
      <ResidualDiffIgnoreTimeToggle />
      <ShowActiveDefaultToggle/>
      <Divider />
      <WeatherLocations disabled={!portUuid} port={port} />
      <Weather disabled={!portUuid} port={port} />
    </SettingsTemplate>
  )
}

Environment.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Environment
