import PropTypes from 'prop-types'
import React from 'react'
import { routeNode } from 'react-router5'
import styled from 'styled-components'

import { Divider } from '@material-ui/core'

import DefaultLayoutHeader from './DefaultLayoutHeader'
import NotificationDrawer from 'src/containers/NotificationDrawer'
import { MainContainer } from 'src/components/layouts/DefaultLayout'

const LayoutContainer = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(10)}px;
`

const MainContentContainer = styled(MainContainer)`
  padding: ${({ theme }) => theme.spacing(6, 0)};
`

const StickyHeader = styled.div`
  background: #fdfdfd;
  position: sticky;
  top: 0;
  z-index: 10;
`

export const DefaultLayout = ({ children }) => {
  return (
    <LayoutContainer>
      <StickyHeader>
        <DefaultLayoutHeader />
        <Divider />
      </StickyHeader>
      <MainContentContainer>{children}</MainContentContainer>
      <NotificationDrawer />
    </LayoutContainer>
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.object,
}

export default routeNode('')(DefaultLayout)
