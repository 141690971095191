import React from 'react'
import PropTypes from 'prop-types'

import Divider from 'src/components/atoms/Divider/Divider'
import PassFailChecklist from 'src/components/organisms/Checklist/PassFailChecklist'
import TimestampChecklist from 'src/components/organisms/TimestampChecklist'
import Checklist from 'src/components/organisms/Checklist'
import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import { RouteAttachmentList } from './../../components/organisms/RouteAttachmentList/RouteAttachmentList'

export const Checklists = ({ port }) => {
  return (
    <SettingsTemplate title="Checklists & Attachments" port={port}>
      <Checklist port={port} />
      <Divider />
      <PassFailChecklist port={port} />
      <Divider />
      <TimestampChecklist port={port} />
      <Divider />
      <RouteAttachmentList port={port} />
    </SettingsTemplate>
  )
}

Checklists.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Checklists
