export const ACTION_TYPE_REQUEST = 'REQUEST'
export const ACTION_TYPE_ERROR = 'ERROR'
export const ACTION_TYPE_RESULT = 'RESULT'
export const ACTION_TYPE_SUCCESS = 'SUCCESS'
export const ACTION_TYPE_CANCEL = 'CANCEL'
export const ACTION_TYPE_IN_PROGRESS = 'IN_PROGRESS'

export const DEFAULT_PAYLOAD_KEY = 'payload'
export const payloadKeyByActionType = {
  [ACTION_TYPE_REQUEST]: DEFAULT_PAYLOAD_KEY,
  [ACTION_TYPE_ERROR]: 'error',
  [ACTION_TYPE_RESULT]: 'result',
  [ACTION_TYPE_SUCCESS]: DEFAULT_PAYLOAD_KEY,
  [ACTION_TYPE_CANCEL]: DEFAULT_PAYLOAD_KEY,
  [ACTION_TYPE_IN_PROGRESS]: DEFAULT_PAYLOAD_KEY,
}

// helper, low level functions
export const getActionType = (baseAction, actionType = ACTION_TYPE_REQUEST) =>
  `${baseAction}_${actionType}`

export const getPayloadKeyByActionType = (actionType) =>
  payloadKeyByActionType[actionType] || DEFAULT_PAYLOAD_KEY

export const createAction = (type, payload, payloadKey = 'payload') => {
  const action = { type }
  if (payload !== undefined) {
    action[payloadKey] = payload
  }
  return action
}

// to create API/HTTP requests
export const createRequestAction = (baseAction, payload) =>
  createAction(
    getActionType(baseAction, ACTION_TYPE_REQUEST),
    payload,
    getPayloadKeyByActionType(ACTION_TYPE_REQUEST)
  )
// to create error result, mainly AJAX errors
export const createErrorAction = (baseAction, payload) =>
  createAction(
    getActionType(baseAction, ACTION_TYPE_ERROR),
    payload,
    getPayloadKeyByActionType(ACTION_TYPE_ERROR)
  )
// mainly request actions results
export const createResultAction = (baseAction, payload) =>
  createAction(
    getActionType(baseAction, ACTION_TYPE_RESULT),
    payload,
    getPayloadKeyByActionType(ACTION_TYPE_RESULT)
  )
// mainly kind of boolean request results eg.: login
export const createSuccessAction = (baseAction, payload) =>
  createAction(
    getActionType(baseAction, ACTION_TYPE_SUCCESS),
    payload,
    getPayloadKeyByActionType(ACTION_TYPE_SUCCESS)
  )
// mainly to cancel requests
export const createCancelAction = (baseAction, payload) =>
  createAction(
    getActionType(baseAction, ACTION_TYPE_CANCEL),
    payload,
    getPayloadKeyByActionType(ACTION_TYPE_CANCEL)
  )
// mainly to indicate when a request is in progress eg: AUTH_LOGIN_IN_PROGRESS
export const createInProgressAction = (baseAction, payload) =>
  createAction(
    getActionType(baseAction, ACTION_TYPE_IN_PROGRESS),
    payload,
    getPayloadKeyByActionType(ACTION_TYPE_IN_PROGRESS)
  )

// to create action types' constans values
export const createBasicActionTypes = (baseAction) => [
  getActionType(baseAction, ACTION_TYPE_REQUEST), // request action, eg: AUTH_LOGIN_REQUEST
  getActionType(baseAction, ACTION_TYPE_RESULT), // request action, eg: PILOT_LIST_RESULT
  getActionType(baseAction, ACTION_TYPE_SUCCESS), // request action, eg: AUTH_LOGIN_SUCCESS
  getActionType(baseAction, ACTION_TYPE_ERROR), // request action, eg: AUTH_LOGIN_ERROR
  getActionType(baseAction, ACTION_TYPE_CANCEL), // request action, eg: AUTH_LOGIN_CANCEL
  getActionType(baseAction, ACTION_TYPE_IN_PROGRESS), // request action, eg: AUTH_LOGIN_CANCEL
]

export default createAction
