import React from 'react'
import {
    IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
  

// eslint-disable-next-line react/display-name
const CreateButton = React.forwardRef((props, ref) => (
    <IconButton ref={ref} {...props}>
      <AddIcon />
    </IconButton>
))
CreateButton.propTypes = {}

export default CreateButton
