import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Box } from '@material-ui/core'

import ChartImage from 'src/components/organisms/ChartImage/ChartImage'
import { RemoveButton } from 'src/components/atoms/RemoveButton'
import Preloader from 'src/components/atoms/Preloader'
import {
  chartByIdSelector,
  isChartListLoadingSelector,
} from 'src/store/chart/selectors'

const RouteItemContainer = styled.div`
  background-color: ${({ theme: { palette } }) => palette.background.paper};
  border-radius: 2px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  height: 126px;
  padding: 4px;
  position: relative;
`

const RouteItemName = styled.div`
  font-size: ${({ theme }) => theme.typography.pxToRem(16)};
  padding: 11px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`

const ChartRemoveIcon = styled<any>(RemoveButton)`
  && {
    position: absolute;
    right: -${({ theme }) => theme.spacing(1)}px;
    top: -${({ theme }) => theme.spacing(1)}px;
    z-index: 1;
  }
`

interface Props {
  onClick: any
  onRemoveClick: any
  showRemoveButton: boolean
  name: string
  uuid: string
}

const RouteListItem: React.FC<Props> = ({
  onClick,
  onRemoveClick,
  showRemoveButton = true,
  name,
  uuid,
}) => {
  const chart = useSelector(chartByIdSelector(uuid))
  const listIsLoading = useSelector(isChartListLoadingSelector)

  const handleRemoveClick = (event: any) => {
    event.preventDefault()
    event.stopPropagation()
    if (onRemoveClick) {
      onRemoveClick(event)
    }
  }

  const handleClick = () => {
    if (onClick) {
      onClick(chart)
    }
  }

  return (
    <RouteItemContainer onClick={handleClick}>
      {chart && (
        <>
          <ChartImage uuid={uuid} imageUrl={chart.imageUrl} />
          <RouteItemName>{name}</RouteItemName>
        </>
      )}
      {!listIsLoading && !chart && (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          Missing chart
        </Box>
      )}
      {listIsLoading && (
        <Box
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Preloader />
        </Box>
      )}
      {showRemoveButton && <ChartRemoveIcon onClick={handleRemoveClick} />}
    </RouteItemContainer>
  )
}

export default RouteListItem
