import { formatToTimeZone } from 'date-fns-timezone'

import { CONSTRAINT_TYPE, DATE_FORMAT } from 'src/constants/settings'

export const formatNumber = (number, digit = 2) => {
  let str = number.toString()
  const repeat = digit - str.length
  if (repeat > 0) {
    str = `${'0'.repeat(repeat)}${str}`
  }
  return str
}

export const minutesToTime = (minutes) => {
  const hours = Math.trunc(minutes / 60)
  const mins = minutes % 60
  return `${formatNumber(hours, 2)}:${formatNumber(mins, 2)}`
}

export const formatConstraintType = (type) => {
  switch (type) {
    case CONSTRAINT_TYPE.UKC:
      return 'UKC'
    case CONSTRAINT_TYPE.UKC_DYNA:
      return 'UKC DYNA'
    case CONSTRAINT_TYPE.OHC:
      return 'OHC'
    default:
      return 'Unknown'
  }
}

export const formatTideDataRange = (from, to, dateFormat = DATE_FORMAT) => {
  if (from && to) {
    // we use Etc/UTC time zone because the tide data datetime is not zoned
    return `${formatToTimeZone(new Date(from), dateFormat, {
      timeZone: 'Etc/UTC',
    })} - ${formatToTimeZone(new Date(to), dateFormat, {
      timeZone: 'Etc/UTC',
    })}`
  }
  return 'not available'
}

export const formatTideRateDataRange = formatTideDataRange

export const formatLatOrLong = (value) => {
  if (!value) {
    return ''
  }
  if (countDecimals(value) > 6) {
    return Number(value)
  }
  return Number(value).toFixed(6)
}

export const replaceSpecialCharacters = (str) =>
  str.replace(/[~!@#$%^*+=\\|\][{}";:/?>,<]/g, ' ')

export const removeFileExtensionFromName = (fileName) =>
  fileName.replace(/\..*$/, '')

export const countDecimals = (value) => {
  const parts = value.toString().split('.')
  return (parts[1] && parts[1].length) || 0
}
