import { combineReducers } from 'redux'
import { router5Reducer as router } from 'redux-router5'

import auth from './auth/reducers'
import user from './user/reducers'
import toast from './toast/reducers'
import ui from './ui/reducers'
import port from './port/reducers'
import tideStation from './tideStations/reducers'
import tideRateStation from './tideRateStations/reducers'
import tideRateConfig from './tideRateConfig/reducers'
import tideData from './tideData/reducers'
import tideRateData from './tideRateData/reducers'
import constraint from './constraint/reducers'
import routeAttachment from './routeAttachment/reducers'
import tug from './tug/reducers'
import chart from './chart/reducers'
import organisation from './organisation/reducers'
import invitation from './invitation/reducers'
import route from './route/reducers'
import extra from './extra/reducers'
import stations from './stations'
import weather from './weatherConfig'
import weatherLocations from './weatherLocations/reducers'
import pilotages from './pilotages'
import keyManager from './keyManager'
import sso from './sso/reducers'
import featureFlags from './featureFlags/reducers'
import vesselFieldDisplay from './vesselFieldDisplay/reducers'
import pilotagesExport from 'src/store/pilotagesExport/reducer'

import { AUTH_LOGOUT_SUCCESS } from 'src/store/auth/actions'
import pilotagesPdfDownload from './pilotagesPdfDownload/reducer'

export const appReducers = combineReducers({
  auth,
  user,
  ui,
  toast,
  router,
  port,
  tideStation,
  tideRateStation,
  tideRateConfig,
  tideData,
  tideRateData,
  constraint,
  tug,
  chart,
  organisation,
  invitation,
  route,
  extra,
  featureFlags,
  sso,
  vesselFieldDisplay,
  weatherLocations,
  routeAttachment,
  pilotagesExport,
  pilotagesPdfDownload,
  weatherConfig: weather.reducer,
  stations: stations.reducer,
  pilotages: pilotages.reducer,
  keyManager: keyManager.reducer,
})
export const rootReducers = (state, action) => {
  if (action.type === AUTH_LOGOUT_SUCCESS) {
    return appReducers({}, action)
  }
  return appReducers(state, action)
}
export default rootReducers
