import React from 'react'
import PropTypes from 'prop-types'

import CircularProgress from '@material-ui/core/CircularProgress'
import DirectionsBoat from '@material-ui/icons/DirectionsBoat'
import { Box } from '@material-ui/core'

const Preloader = ({ size, className }) => (
  <Box
    display="flex"
    alignContent="center"
    justifyContent="center"
    alignItems="center"
    className={className}
  >
    <CircularProgress size={size} color="secondary" />
    <Box
      width={size}
      height={size}
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginLeft={`-${size}px`}
    >
      <DirectionsBoat size={size} color="secondary" />
    </Box>
  </Box>
)

Preloader.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
}

Preloader.defaultProps = {
  size: 50,
}

export default Preloader
