import React from 'react'
import Box from '@material-ui/core/Box'
import Fieldset from 'src/components/organisms/Fieldset'
import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import { Switch, Grid } from '@material-ui/core'
import useFeatureFlags from 'src/hooks/useFeatureFlags'

const SubheadingText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-left: 10px;
    margin-bottom: 20px;
  }
`
const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
  }
`

export const PilotageCreateToggle = () => {
  const description = `
      Here you can enable or disable the ‘New Pilotage’ button,
       found on the first page of the eMPX Pilot app. 
       When disabled, pilots are unable to create their own pilotage in eMPX, 
       and must rely on the list generated by the connected Harbour Planning System.
       When enabled, pilots are able to create a new pilotage in eMPX 
       separate from the Harbour Planning System.`

  const { getFlag, setFlag, error, loading } = useFeatureFlags()

  const flagName = 'pilot_create_pilotage_disabled'
  const pilotCreatePilotageDisabled = getFlag(flagName)

  return (
    <Fieldset
      disabled={false}
      title='"New Pilotage" Button'
      description={description}
    >
      <Box>
        <SubheadingText>{description}</SubheadingText>
      </Box>
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>
          <Switch
            name="toggle"
            color="primary"
            checked={!pilotCreatePilotageDisabled}
            onChange={() => setFlag(flagName, !pilotCreatePilotageDisabled)}
          />
        </Grid>
        <Grid item>
          <ToggleText>
            {loading
              ? '...'
              : pilotCreatePilotageDisabled
              ? 'Disabled'
              : 'Enabled'}
          </ToggleText>
        </Grid>
      </Grid>
      {error && error.message}
    </Fieldset>
  )
}

PilotageCreateToggle.propTypes = {}

export default PilotageCreateToggle
