import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import ApiError from 'src/utils/sauce/api-error'

export let online = Boolean(navigator.onLine)
window.addEventListener('online', () => (online = true))
window.addEventListener('offline', () => (online = false))

export interface ApiResponse<Data> {
  data: Data
  duration: number
  end_time: string
  request_id: string
  start_time: string
  status: number
}

export const defaultBaseUrl = process.env.REACT_APP_API_URL
const defaultSauceVersion = process.env.REACT_APP_SAUCE_VERSION
const defaultAuthHeaders = {
  'X-Sauce-Key': process.env.REACT_APP_SAUCE_KEY,
}

export const defaultConfig: AxiosRequestConfig & { sauceVersion?: string } = {
  baseURL: `${defaultBaseUrl}${
    defaultSauceVersion ? `/${defaultSauceVersion}` : ''
  }`,
  headers: defaultAuthHeaders,
  responseType: 'json',
  sauceVersion: defaultSauceVersion,
}

export const getSource = () => axios.CancelToken.source()

export const api = axios.create(defaultConfig)

export const addDefaultHeader = (key: string, value: string) => {
  api.defaults.headers[key] = value
}

export const setAuthToken = (token: string) => {
  addDefaultHeader('Authorization', token && `Bearer ${token}`)
}

export const getPayloadFromResponse = <Data>(
  response: AxiosResponse<ApiResponse<Data>>
) => response.data && response.data.data

export const getSinglePayloadFromResponse = <Data extends Array<any>>(
  response: AxiosResponse<ApiResponse<Data>>
) => {
  const [payload] = getPayloadFromResponse(response) || []
  return payload
}

export const getPayloadFromErrorResponse = (errorResponse: any) =>
  (errorResponse.response &&
    errorResponse.response.data &&
    errorResponse.response.data.message) ||
  errorResponse.message

api.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      !online ? new Error('You are offline') : ApiError.createError(error)
    )
)

export default api
