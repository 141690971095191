import React from 'react'

export const IntegrationsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.6667 5.33366L12 2.66699V4.66699H2V6.00033H12V8.00033L14.6667 5.33366Z" />
    <path d="M1.33301 10.6667L3.99967 13.3333V11.3333H13.9997V10H3.99967V8L1.33301 10.6667Z" />
  </svg>
)

export default IntegrationsIcon
