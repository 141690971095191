import styled from 'styled-components'
import { LinearProgress } from '@material-ui/core'

export const SaveProgress = styled(LinearProgress).attrs({
  variant: 'indeterminate',
})`
  && {
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`

export default SaveProgress
