import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import store from 'src/store/stations'
import StationsList from 'src/components/organisms/StationsList'
import { PortShape } from 'src/utils/types'
import Fieldset from 'src/components/organisms/Fieldset'
import Divider from 'src/components/atoms/Divider'
import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import StationForm from 'src/components/organisms/StationForm/StationForm'
import {
  BERTH_STATION_TYPE,
  BERTH_STATION_TYPE_LABEL,
} from 'src/constants/settings'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { Typography } from '@material-ui/core'
import { chartListSelector } from 'src/store/chart/selectors'
import BerthingConditionsToggle from 'src/components/organisms/BerthingConditionsToggle'

const StyledListItem = styled.li`
  && {
    margin-left: 25px;
  }
`

export const Stations = ({ port, disabled }) => {
  const dispatch = useDispatch()
  const berths = useSelector(store.selectors.berths)
  const stations = useSelector(store.selectors.stations)
  const isLoading = useSelector(store.selectors.isLoading)

  const [selectedStation, setSelectedStation] = useState()
  const [selectedStationToRemove, setSelectedStationToRemove] = useState()

  useEffect(() => {
    dispatch(store.actions.getList())
  }, [dispatch])

  const handleStationItemClick = (station) => setSelectedStation(station)
  const handleStationItemDelete = (station) =>
    setSelectedStationToRemove(station)

  const handleSaveStation = async (station) => {
    try {
      await dispatch(
        store.actions[station.uuid ? 'update' : 'create']({
          port,
          ...station,
        })
      )
      setSelectedStation(null)
    } catch (error) {
      console.warn(error)
    }
  }
  const handleCancelStationForm = () => setSelectedStation(null)
  const handleConfirmDelete = () => {
    dispatch(store.actions.delete(selectedStationToRemove.uuid))
    setSelectedStationToRemove(null)
  }
  const handleCancelDelete = () => setSelectedStationToRemove(null)

  const handleAddNewBerth = () =>
    setSelectedStation({
      name: '',
      berthStationType: BERTH_STATION_TYPE.BERTH,
    })

  const handleAddNewPilotStation = () =>
    setSelectedStation({
      name: '',
      berthStationType: BERTH_STATION_TYPE.STATION,
    })

  const stationLimit = stations && stations.length >= 500
  const berthLimit = berths && berths.length >= 500

  const allCharts = useSelector(chartListSelector)
  const [associatedCharts, setAssociatedCharts] = useState([])

  useEffect(() => {
    const stationUuid = selectedStationToRemove && selectedStationToRemove.uuid
    if (stationUuid) {
      setAssociatedCharts(
        allCharts.filter((chart) => {
          const { berthsStations } = chart
          return !!berthsStations.find(
            (station) => station.uuid === stationUuid
          )
        })
      )
    }
    // eslint-disable-next-line
  }, [allCharts, selectedStationToRemove])

  return (
    <>
      <Fieldset
        title="Berths"
        disabled={disabled}
        hasButton
        clickHandler={handleAddNewBerth}
        buttonContents="Add Berth"
        buttonDisabled={berthLimit}
      >
        <StationsList
          stations={berths}
          onClick={handleStationItemClick}
          onDelete={handleStationItemDelete}
          isLoading={isLoading}
          useColumns
        />
      </Fieldset>

      <BerthingConditionsToggle />

      <Divider />

      <Fieldset
        title="Pilot Boarding Place"
        disabled={disabled}
        hasButton
        clickHandler={handleAddNewPilotStation}
        buttonContents="Add Pilot Boarding Place"
        buttonDisabled={stationLimit}
      >
        <StationsList
          stations={stations}
          onClick={handleStationItemClick}
          onDelete={handleStationItemDelete}
          isLoading={isLoading}
          useColumns
        />
      </Fieldset>
      {selectedStation && (
        <FormDialog
          open
          title={`${selectedStation.uuid ? 'Edit' : 'Create'} ${
            BERTH_STATION_TYPE_LABEL[selectedStation.berthStationType]
          }`}
          FormComponent={StationForm}
          formEntityProp="station"
          entity={selectedStation}
          onSave={handleSaveStation}
          onCancel={handleCancelStationForm}
        />
      )}
      {selectedStationToRemove && (
        <ConfirmDialog
          open
          title={`Delete ${
            selectedStationToRemove.berthStationType ===
            BERTH_STATION_TYPE.BERTH
              ? 'berth'
              : 'pilot boarding place'
          }`}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
        >
          {associatedCharts.length > 0 && (
            <>
              <p>
                This{' '}
                {
                  BERTH_STATION_TYPE_LABEL[
                    selectedStationToRemove.berthStationType
                  ]
                }{' '}
                is connected to the following Chart
                {associatedCharts.length > 1 ? 's' : ''}:
              </p>
              <div>
                <ul>
                  {associatedCharts.map(({ name }) => (
                    <StyledListItem key={name}>{name}</StyledListItem>
                  ))}
                </ul>
              </div>
              <p>
                By deleting this{' '}
                {
                  BERTH_STATION_TYPE_LABEL[
                    selectedStationToRemove.berthStationType
                  ]
                }{' '}
                you will also delete them from{' '}
                {associatedCharts.length > 1 ? 'those ' : 'that '}
                chart{associatedCharts.length > 1 ? 's.' : '.'}
              </p>
              <div />
            </>
          )}
          <p>
            Are you sure you want to delete this{' '}
            {BERTH_STATION_TYPE_LABEL[selectedStationToRemove.berthStationType]}
            ?
          </p>
          <Typography variant="subtitle1">
            {selectedStationToRemove.name}
          </Typography>
        </ConfirmDialog>
      )}
    </>
  )
}
Stations.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
}

export default Stations
