import React, { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import idx from 'idx'

import { ListItemText, List } from '@material-ui/core'

import TideStationForm from 'src/components/organisms/TideStationForm/TideStationForm'
import {
  getListFilteredByPort,
  getListFilteredBySelectedPort,
  tideStationListSelector,
} from 'src/store/tideStations/selectors'
import {
  getListFilteredByPort as getConstraintListFilteredByPort,
  constraintListSelector,
} from 'src/store/constraint/selectors'
import {
  getTideStationList,
  saveTideStation,
  deleteTideStation,
  TIDE_STATION_DELETE_SUCCESS,
  TIDE_STATION_CREATE_ERROR,
} from 'src/store/tideStations/actions'
import { addToast, addErrorToast } from 'src/store/toast/actions'
import {
  TOAST_VARIANT_SUCCESS,
  TOAST_VARIANT_ERROR,
} from 'src/containers/Toast'
import TideStationList from 'src/components/organisms/TideStationList'
import Fieldset from 'src/components/organisms/Fieldset'
import EmptyState from 'src/components/atoms/EmptyState'
import { PortShape } from 'src/utils/types'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import Preloader from 'src/components/atoms/Preloader'

import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import { FILE_STATUS, TIDE_TYPE } from 'src/constants/settings'
import { createTideData } from 'src/store/tideData/actions'
import { compareName } from 'src/utils/sorting'

export const TideStations = ({ disabled, port, disableFilterByPort }) => {
  const dispatch = useDispatch()

  const tideStationList = useSelector(
    disableFilterByPort
      ? tideStationListSelector
      : port
      ? getListFilteredByPort(port.uuid)
      : getListFilteredBySelectedPort
  )

  const constraints =
    useSelector(
      disableFilterByPort
        ? constraintListSelector
        : port
        ? getConstraintListFilteredByPort(port.uuid)
        : getListFilteredBySelectedPort
    ) || []

  const [allDataFile, setAllDataFile] = useState(null)
  const [extremesDataFile, setExtremesDataFile] = useState(null)

  useEffect(() => {
    dispatch(getTideStationList())
  }, [dispatch])

  const [selectedTideStation, setSelectedTideStation] = useState(null)
  const [
    selectedTideStationToRemove,
    setSelectedTideStationToRemove,
  ] = useState(null)
  const [submitting, setSubmitting] = useState(false)

  const handleClick = useCallback(
    (uuid) => {
      const tideStation = tideStationList.find((item) => item.uuid === uuid)
      if (tideStation) {
        setSelectedTideStation(tideStation)
      }
    },
    [tideStationList]
  )
  const handleRemove = useCallback(
    (uuid) => {
      const tideStation = tideStationList.find((item) => item.uuid === uuid)
      if (tideStation) {
        setSelectedTideStationToRemove(tideStation)
      }
    },
    [tideStationList]
  )
  const handleRejectRemove = () => setSelectedTideStationToRemove(null)

  const handleConfirmRemove = useCallback(() => {
    dispatch(deleteTideStation(selectedTideStationToRemove)).then((action) => {
      const { type, payload } = action
      if (type === TIDE_STATION_DELETE_SUCCESS) {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_SUCCESS,
            message: `Tide station has been removed '${selectedTideStationToRemove.name}'`,
          })
        )
      } else {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_ERROR,
            message: `Error deleting tide station '${selectedTideStationToRemove.name}': ${payload}`,
          })
        )
      }
      setSelectedTideStationToRemove(null)
    })
  }, [dispatch, selectedTideStationToRemove])

  const showErrorMessage = useCallback(
    (error) => {
      let message = error
      if (typeof error === 'object') {
        // Unprocessable entry
        if (error.status === 422) {
          message = idx(error, (_) => _.response.data.message) || message
        } else {
          message = 'Error processing tide data'
        }
      }
      dispatch(
        addErrorToast({
          message,
        })
      )
    },
    [dispatch]
  )

  const handleCreateNewTideStation = () => setSelectedTideStation({ name: '' })

  const handleCancel = () => {
    setSelectedTideStation(null)
    setExtremesDataFile(null)
    setAllDataFile(null)
  }

  const handleSave = useCallback(
    async (values) => {
      try {
        setSubmitting(true)
        const { location } = port
        const amendedValues = {
          location,
          port,
          name: values.name,
          uuid: values.uuid,
        }
        const action = await dispatch(saveTideStation(amendedValues))
        setSubmitting(false)
        const { type, error } = typeof action === 'boolean' ? {} : action
        if (error) {
          throw error
        }
        if (
          values.uuid &&
          (action === true || type !== TIDE_STATION_CREATE_ERROR)
        ) {
          // For existing tide stations, close the form on save
          handleCancel()
        } else if (!values.uuid) {
          // Update the selected tide station with the API response payload
          setSelectedTideStation(action.payload)
        }
      } catch (error) {
        showErrorMessage(error)
      }
    },
    [dispatch, port, showErrorMessage]
  )

  const setFileStatus = (tidesType, file, status) => {
    const setter =
      tidesType === TIDE_TYPE.ALL ? setAllDataFile : setExtremesDataFile
    setter({ file, status })
  }

  const onFileSelect = useCallback(
    async (tideStation, tidesType, file, isDataDst) => {
      if (file === null) {
        // rejected file
        showErrorMessage('Invalid file type selected')
        return
      }
      if (file instanceof File && tideStation) {
        try {
          // TODO: fails for new tide station
          setFileStatus(tidesType, file, FILE_STATUS.UPLOADING)
          setSubmitting(true)
          const action = await dispatch(
            createTideData({
              tideStation: { uuid: tideStation.uuid },
              file,
              tidesType,
              isDataDst,
            })
          )
          if (action.error) {
            throw action.error
          }
          setFileStatus(tidesType, file, FILE_STATUS.SUCCESS)
          // Returned tide station has the updated date ranges
          setSelectedTideStation(action.payload.tideStation)
        } catch (error) {
          setFileStatus(tidesType, file, FILE_STATUS.ERROR)
          showErrorMessage(error)
        } finally {
          setSubmitting(false)
        }
      }
    },
    [dispatch, showErrorMessage]
  )

  if (tideStationList === null) {
    return <Preloader>Loading Tide Stations List...</Preloader>
  }

  const constraintsLinkedToTideStation = constraints.filter(
    (constraint) =>
      idx(constraint.tideStation, (_) => _.uuid) ===
      idx(selectedTideStationToRemove, (_) => _.uuid)
  )

  const sortedTideStationList =
    tideStationList && tideStationList.sort(compareName)

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Tide Stations"
        description="Set your tide station"
        clickHandler={handleCreateNewTideStation}
        hasButton
        buttonContents="Add Tide Station"
      >
        <TideStationList
          data={sortedTideStationList}
          onRemove={handleRemove}
          onClick={handleClick}
        />
        {(!sortedTideStationList || !sortedTideStationList.length) && (
          <EmptyState message="No tide station data yet" />
        )}
      </Fieldset>
      {selectedTideStation && (
        <FormDialog
          open
          disableBackdropClick={submitting}
          title={`${selectedTideStation.uuid ? 'Edit' : 'Create'} Tide Station`}
          FormComponent={TideStationForm}
          formComponentProps={{
            allDataFile,
            extremesDataFile,
            onFileSelect,
          }}
          formEntityProp="tideStation"
          entity={selectedTideStation}
          onSave={handleSave}
          onCancel={handleCancel}
          submitting={submitting}
          saveButtonLabel={selectedTideStation.uuid ? 'Done' : 'Next'}
          alwaysEnableSaveBtn
        />
      )}
      {selectedTideStationToRemove && (
        <ConfirmDialog
          open={!!selectedTideStationToRemove}
          title={`Remove Tide Station: ${selectedTideStationToRemove.name}`}
          onCancel={handleRejectRemove}
          onConfirm={handleConfirmRemove}
        >
          {constraintsLinkedToTideStation.length >= 1 ? (
            <>
              {`This Tide Station is currently linked to the following
              constraint${
                constraintsLinkedToTideStation.length === 1 ? '' : 's'
              }:`}
              <List>
                {constraintsLinkedToTideStation.map((constraint) => (
                  <ListItemText inset key={constraint.name}>
                    {constraint.name}
                  </ListItemText>
                ))}
              </List>
              Are you sure you want to remove the selected Tide Station?
              Constraints using this Tide Station will not function anymore.
            </>
          ) : (
            'Are you sure that you remove the selected Tide Station?'
          )}
        </ConfirmDialog>
      )}
    </>
  )
}

TideStations.propTypes = {
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
  port: PropTypes.shape(PortShape),
}

export default TideStations
