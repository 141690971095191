import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-tug'

// GET TUG
const ACTION_GET = 'TUG_GET'
export const [
  TUG_GET_REQUEST,
  TUG_GET_RESULT,
  TUG_GET_SUCCESS,
  TUG_GET_ERROR,
  TUG_GET_CANCEL,
  TUG_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getTug = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getTugRequest(uuid))
}
export const getTugRequest = (uuid) => async (dispatch) => {
  dispatch(getTugInProgress(uuid))
  try {
    const tugResult = await api.getTug(uuid)
    return dispatch(getTugSuccess(tugResult))
  } catch (error) {
    return dispatch(getTugError(error))
  }
}
export const getTugInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getTugSuccess = (tug) => createSuccessAction(ACTION_GET, tug)
export const getTugError = (error) => createErrorAction(ACTION_GET, error)

// GET TUG_LIST
const ACTION_GET_LIST = 'TUG_LIST_GET'
export const [
  TUG_LIST_GET_REQUEST,
  TUG_LIST_GET_RESULT,
  TUG_LIST_GET_SUCCESS,
  TUG_LIST_GET_ERROR,
  TUG_LIST_GET_CANCEL,
  TUG_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getTugList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getTugListRequest(uuid))
}
export const getTugListRequest = (uuid) => async (dispatch) => {
  dispatch(getTugListInProgress(uuid))
  try {
    const tugListResult = await api.getTugs(uuid)
    return dispatch(getTugListSuccess(tugListResult))
  } catch (error) {
    return dispatch(getTugListError(error))
  }
}
export const getTugListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getTugListSuccess = (tug) =>
  createSuccessAction(ACTION_GET_LIST, tug)
export const getTugListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE TUG
const ACTION_CREATE = 'TUG_CREATE'
export const [
  TUG_CREATE_REQUEST,
  TUG_CREATE_RESULT,
  TUG_CREATE_SUCCESS,
  TUG_CREATE_ERROR,
  TUG_CREATE_CANCEL,
  TUG_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createTug = (tug) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: tug })
  return dispatch(createTugRequest(tug))
}
export const createTugRequest = (payload) => async (dispatch) => {
  dispatch(createTugInProgress(payload))
  try {
    const tugResult = await api.createTug(payload)
    return dispatch(createTugSuccess(tugResult))
  } catch (error) {
    return dispatch(createTugError(error))
  }
}
export const createTugInProgress = (tug) =>
  createInProgressAction(ACTION_CREATE, tug)
export const createTugSuccess = (tug) => createSuccessAction(ACTION_CREATE, tug)
export const createTugError = (error) => createErrorAction(ACTION_CREATE, error)

// UPDATE tug
const ACTION_UPDATE = 'TUG_UPDATE'
export const [
  TUG_UPDATE_REQUEST,
  TUG_UPDATE_RESULT,
  TUG_UPDATE_SUCCESS,
  TUG_UPDATE_ERROR,
  TUG_UPDATE_CANCEL,
  TUG_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateTug = (tug) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: tug })
  return dispatch(updateTugRequest(tug))
}
export const updateTugRequest = (payload) => async (dispatch) => {
  dispatch(updateTugInProgress(payload))
  try {
    const tugResult = await api.updateTug(payload)
    return dispatch(updateTugSuccess(tugResult))
  } catch (error) {
    return dispatch(updateTugError(error))
  }
}
export const updateTugInProgress = (tug) =>
  createInProgressAction(ACTION_UPDATE, tug)
export const updateTugSuccess = (tug) => createSuccessAction(ACTION_UPDATE, tug)
export const updateTugError = (error) => createErrorAction(ACTION_UPDATE, error)

// SAVE tug (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'TUG_SAVE'
export const saveTug = (tug) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: tug })
  const result = await dispatch(tug.uuid ? updateTug(tug) : createTug(tug))
  return result
}

// DELETE tug
const ACTION_DELETE = 'TUG_DELETE'
export const [
  TUG_DELETE_REQUEST,
  TUG_DELETE_RESULT,
  TUG_DELETE_SUCCESS,
  TUG_DELETE_ERROR,
  TUG_DELETE_CANCEL,
  TUG_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteTug = (tug) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: tug })
  return dispatch(deleteTugRequest(tug))
}
export const deleteTugRequest = (tug) => async (dispatch) => {
  dispatch(deleteTugInProgress(tug))
  try {
    await api.deleteTug(tug.uuid)
    return dispatch(deleteTugSuccess(tug))
  } catch (error) {
    dispatch(deleteTugError(error))
    throw error
  }
}
export const deleteTugInProgress = (tug) =>
  createInProgressAction(ACTION_DELETE, tug)
export const deleteTugSuccess = (tug) => createSuccessAction(ACTION_DELETE, tug)
export const deleteTugError = (error) => createErrorAction(ACTION_DELETE, error)
