import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Container } from '@material-ui/core'

const CenterWrapper = styled(Container)`
  align-items: center;
  display: flex !important;
  justify-content: center;
  min-height: 100vh;
`

export const UnauthenticatedLayout = ({ children }) => (
  <CenterWrapper>{children}</CenterWrapper>
)

UnauthenticatedLayout.propTypes = {
  children: PropTypes.node,
}

export default UnauthenticatedLayout
