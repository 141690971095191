import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import {
  getListFilteredByPort,
  getListFilteredBySelectedPort,
  tideRateStationListSelector,
} from 'src/store/tideRateStations/selectors'
import SelectInput from 'src/components/atoms/SelectInput'
import { getTideRateStationList } from 'src/store/tideRateStations/actions'
import { PortShape } from 'src/utils/types'

export const TideRateStationSelect = ({
  onChange,
  value: selected,
  required = false,
  port,
  disableFilterByPort,
  ...props
}) => {
  const tideRateStations = useSelector(
    disableFilterByPort
      ? tideRateStationListSelector
      : port
      ? getListFilteredByPort(port.uuid)
      : getListFilteredBySelectedPort
  )
  const dispatch = useDispatch()
  const [uuid, setUuid] = useState(selected && selected.uuid)
  const [options, setOptions] = useState([])
  useEffect(() => {
    const opts =
      tideRateStations &&
      tideRateStations
        .map((item) => ({
          label: item.name,
          value: item.uuid,
        }))
        .sort(({ label: l1 }, { label: l2 }) => {
          const l11 = l1.toLowerCase()
          const l22 = l2.toLowerCase()
          return l11 > l22 ? 1 : l11 < l22 ? -1 : 0
        })
    setOptions(opts)
  }, [tideRateStations])

  if (tideRateStations === null) {
    dispatch(getTideRateStationList())
  }
  if (options && !required) {
    if (uuid) {
      if (options.length > 0 && options[0].value !== null) {
        options.unshift({
          label: '-- None --',
          value: null,
        })
      }
    } else {
      // if not selected, placeholder will be displayed, we remove the -- None -- option
      if (options.length > 0 && options[0].value === null) {
        options.shift()
      }
    }
  }
  const handleChange = useCallback(
    (event) => {
      const {
        target: { value: uuid },
      } = event
      const tideRateStation = tideRateStations.find((item) => item.uuid === uuid)
      onChange(props.name, tideRateStation)
      setUuid(tideRateStation && tideRateStation.uuid)
    },
    [tideRateStations, onChange, props.name]
  )
  return (
    <SelectInput
      {...props}
      value={uuid || '-'}
      onChange={handleChange}
      disabled={options === null || props.disabled || tideRateStations.length === 0}
      options={options || []}
      placeholder={
        !tideRateStations || tideRateStations.length === 0 ?
        "No tide rate stations defined" :
        "Select tide rate station"
      }
    />
  )
}

TideRateStationSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
  name: PropTypes.string,
  port: PropTypes.shape(PortShape),
}

export default TideRateStationSelect
