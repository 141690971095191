import React, { useState, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import Avatar from 'react-avatar-edit'
import { Dialog, DialogActions } from '@material-ui/core'
import DialogHeader from 'src/components/molecules/DialogHeader/DialogHeader'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { SizeMe } from 'react-sizeme'
import { EditIcon } from 'src/components/atoms/Icons'
import emptyAvatar from 'src/assets/images/empty-avatar.jpg'

const EditAvatarContainer = styled.div`
    display: flex;
    align-items: center;
    height: 30px;
    img {
        display: inline-block;
        width: 30px;
        margin-right: 5px;
        border-radius: 50%;
    }
`

// assuming top of range cameras at time of writing yield photos at around 20mb
const MAX_FILE_BYTES = 20000000

interface EditAvatarProps {
    onChange(value: string): void
    value: string
}

const EditAvatar: React.FC<EditAvatarProps> = ({ onChange, value }) => {

    const [editing, setEditing] = useState(false)
    const [preview, setPreview] = useState<string>('')

    const onClose = () => setPreview('')
    const onCrop = async (data: string) => {
        setPreview(await compressImage(data))
    }
    const onBeforeFileLoad = (elem: any) => {
        if (elem.target.files[0].size > MAX_FILE_BYTES){
            alert("File is too big!")
            elem.target.value = ""
        }
    }
  
    return editing ?

        <Dialog open={editing} onClose={() => setEditing(false)}>
            <DialogHeader>Edit Pilot Photo</DialogHeader>
            <SizeMe>
                {({ size }) =>
                    size.width ?
                    <Avatar
                        width={size.width}
                        height={200}
                        imageHeight={300}
                        onCrop={onCrop}
                        onClose={onClose}
                        onBeforeFileLoad={onBeforeFileLoad}
                        label={`Choose file (max ${Math.floor(MAX_FILE_BYTES / 1000000)}mb)`}
                    /> :
                    <></>
                }
            </SizeMe>
            <DialogActions>
                { 
                value &&
                <SecondaryButton minWidth={150} onClick={() => {
                    onChange('')
                    setEditing(false)
                }}>
                    <img src={value} alt="Photo" style={{width: 30, marginRight: 10}} />
                    Remove
                </SecondaryButton>
                }
                <SecondaryButton minWidth={150} onClick={() => setEditing(false)}>Cancel</SecondaryButton>
                <PrimaryButton
                    disabled={!preview}
                    onClick={() => {
                        onChange(preview)
                        setEditing(false)
                    }}
                >
                    Update
                </PrimaryButton>
            </DialogActions>
        </Dialog> :

        <EditAvatarContainer style={{ cursor: 'pointer' }} onClick={() => {
            setEditing(true)
            setPreview('')
        }}>
            <img src={value || emptyAvatar} alt="Photo" />
            <EditIcon />
        </EditAvatarContainer>
}

export default EditAvatar

function compressImage(base64: string, width = 100, height = 100) {

    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
  
    return new Promise((resolve: (data: string) => void, reject) => {
        img.onload = function () {
            canvas.width = width
            canvas.height = height

            const ctx = canvas.getContext('2d')
            if (ctx) { ctx.drawImage(img, 0, 0, width, height) }

            resolve(canvas.toDataURL('image/png'))
        }
        img.onerror = function (err) {
            reject(err)
        }
        img.src = base64
    })
}