import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { actions as routerActions } from 'redux-router5'
import { useRoute } from 'react-router5'

import {
  Card,
  CardMedia,
  CardContent,
} from '@material-ui/core'

import { OrganisationShape, PortShape, UserShape, InvitationShape } from 'src/utils/types'

import PortCard from '../PortCard'
import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import PortForm from 'src/components/organisms/PortForm'
import { ORGANISATIONS, ORGANISATION_PORT_CREATE, ORGANISATION_PORT_EDIT } from 'src/router/routes'
import { savePort } from 'src/store/port/actions'
import {
  Tooltip,
} from '@material-ui/core'
import CreateButton from 'src/components/atoms/CreateButton'
import RemoveButton from 'src/components/atoms/RemoveButton'
import EditButton from 'src/components/atoms/EditButton'


const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  .MuiCardContent-root:last-child {
      padding-bottom: 0px;
  } 
`
const StyledCardContent = styled(CardContent)`
  flex-grow: 1;
`


const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin-top: 5px;
    text-transform: uppercase;
  }
  .org-card-actions {
    margin-right: -13px;
    margin-top: -13px;
  }
`


export const OrganisationCard = ({ 
  organisation, 
  ports, 
  invitations, 
  invitationsLoading,
  users, 
  onEditOrganisation,
  onDeleteOrganisation,
  className 
}) => {

  const dispatch = useDispatch()

  const { route } = useRoute()
  const { params, name: routeName } = route
  const { orgUuid, portUuid } = params

  const isActive = orgUuid === organisation.uuid

  const [portFormSubmitting, setPortFormSubmitting] = useState(false)
  const port = ports.find(({ uuid }) => uuid === portUuid)
  const EMPTY_PORT = { organisation }

  const { logo, name } = organisation
  const [imageSrc, setImageSrc] = useState(null)
  useEffect(() => {
    const image = new Image()
    const handleLoad = () => setImageSrc(logo)
    const handleError = () => setImageSrc(null)
    image.addEventListener('load', handleLoad)
    image.addEventListener('error', handleError)
    return () => {
      image.removeEventListener('load', handleLoad)
      image.removeEventListener('error', handleError)
    }
  }, [logo])

  const backToOrgList = () =>
    dispatch(routerActions.navigateTo(ORGANISATIONS))

  const handleCreatePort = (organisation) =>
      dispatch(
          routerActions.navigateTo(ORGANISATION_PORT_CREATE, { orgUuid: organisation.uuid })
      )

  const handleEditPort = (organisation, port) =>
      dispatch(
          routerActions.navigateTo(ORGANISATION_PORT_EDIT, { orgUuid: organisation.uuid, portUuid: port.uuid })
      ) 
  
  const handlePortSave = async (values) => {
    setPortFormSubmitting(true)
    try {
      let result = await dispatch(savePort({ ...values, organisation }))
      if (!result.error) {
        backToOrgList()
      }
    } catch (error) {}
    setPortFormSubmitting(false)
  }

  return (
    <>
      <StyledCard className={className}>
        {imageSrc && (
          <CardMedia component="img" image={imageSrc} height="140" />
        )}

        <StyledCardContent>
          <Header>
            <h3>{name}</h3>
            <div className="org-card-actions">
              <Tooltip title="Add port">
                <CreateButton 
                  disabled={portFormSubmitting} 
                  onClick={() => handleCreatePort(organisation)} />
              </Tooltip>
              <Tooltip title="Edit organisation">
                <EditButton 
                  disabled={portFormSubmitting} 
                  onClick={() => onEditOrganisation(organisation)} />
              </Tooltip>
              <Tooltip title="Delete organisation">
                <RemoveButton 
                  disabled={portFormSubmitting} 
                  onClick={() => onDeleteOrganisation(organisation)} />
              </Tooltip>
            </div>
          </Header>

          {ports.map(port => 
            // pass down all invitations/users in the org, so we can cross reference
            <PortCard 
              key={port.uuid} 
              organisation={organisation}
              port={port} 
              users={(users || []).filter(user => user.identity.organisation.uuid === organisation.uuid)}
              invitations={(invitations || []).filter(invite => invite.organisation.uuid === organisation.uuid)}
              invitationsLoading={invitationsLoading}
              onEditPort={(port) => handleEditPort(organisation, port)}
            />
          )}

        </StyledCardContent>
      </StyledCard>


      {ORGANISATION_PORT_CREATE === routeName && isActive && (
        <FormDialog
          open
          submitting={portFormSubmitting}
          title="Create Port"
          entity={EMPTY_PORT}
          FormComponent={PortForm}
          formComponentProps={{ port: EMPTY_PORT, inModal: true }}
          formEntityProp="port"
          onCancel={backToOrgList}
          onSave={handlePortSave}
        />
      )}

      {ORGANISATION_PORT_EDIT === routeName && port && isActive && (
        <FormDialog
          open
          submitting={portFormSubmitting}
          title="Update Port"
          entity={port}
          FormComponent={PortForm}
          formComponentProps={{ port, inModal: true }}
          formEntityProp="port"
          onCancel={backToOrgList}
          onSave={handlePortSave}
        />
      )}

    </>
  )
}
OrganisationCard.propTypes = {
  organisation: PropTypes.shape(OrganisationShape),
  ports: PropTypes.arrayOf(PropTypes.shape(PortShape)),
  users: PropTypes.arrayOf(PropTypes.shape(UserShape)),
  invitations: PropTypes.arrayOf(PropTypes.shape(InvitationShape)),
  invitationsLoading: PropTypes.bool,
  onEditOrganisation: PropTypes.func,
  onDeleteOrganisation: PropTypes.func,
  className: PropTypes.string,
}

export default OrganisationCard
