import React from 'react'

const GuidelinesIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="9" height="11" stroke="#B0B0B1" />
    <circle cx="10" cy="10" r="2.66667" fill="#B0B0B1" />
    <path
      d="M8.33301 10.3333L9.33301 11.3333L11.6663 9"
      stroke="white"
      strokeWidth="0.666667"
      strokeLinejoin="round"
    />
    <line x1="3" y1="2.5" x2="7" y2="2.5" stroke="#B0B0B1" />
    <line x1="2" y1="4.5" x2="8" y2="4.5" stroke="#B0B0B1" />
    <line x1="2" y1="6.5" x2="8" y2="6.5" stroke="#B0B0B1" />
    <line x1="2" y1="8.5" x2="6" y2="8.5" stroke="#B0B0B1" />
  </svg>
)

export default GuidelinesIcon
