import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-weather-locations'

// GET WEATHER_LOCATION

const ACTION_GET = 'WEATHER_LOCATION_GET'
export const [
  WEATHER_LOCATION_GET_REQUEST,
  WEATHER_LOCATION_GET_RESULT,
  WEATHER_LOCATION_GET_SUCCESS,
  WEATHER_LOCATION_GET_ERROR,
  WEATHER_LOCATION_GET_CANCEL,
  WEATHER_LOCATION_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getWeatherLocation = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getWeatherLocationRequest(uuid))
}
export const getWeatherLocationRequest = (uuid) => async (dispatch) => {
  dispatch(getWeatherLocationInProgress(uuid))
  try {
    const weatherLocationResult = await api.getWeatherLocation(uuid)
    return dispatch(getWeatherLocationSuccess(weatherLocationResult))
  } catch (error) {
    return dispatch(getWeatherLocationError(error))
  }
}
export const getWeatherLocationInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getWeatherLocationSuccess = (weatherLocation) => createSuccessAction(ACTION_GET, weatherLocation)
export const getWeatherLocationError = (error) => createErrorAction(ACTION_GET, error)


// GET WEATHER_LOCATION_LIST
const ACTION_GET_LIST = 'WEATHER_LOCATION_LIST_GET'
export const [
  WEATHER_LOCATION_LIST_GET_REQUEST,
  WEATHER_LOCATION_LIST_GET_RESULT,
  WEATHER_LOCATION_LIST_GET_SUCCESS,
  WEATHER_LOCATION_LIST_GET_ERROR,
  WEATHER_LOCATION_LIST_GET_CANCEL,
  WEATHER_LOCATION_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getWeatherLocationList = (portUuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: portUuid })
  return dispatch(getWeatherLocationListRequest(portUuid))
}
export const getWeatherLocationListRequest = (portUuid) => async (dispatch) => {
  dispatch(getWeatherLocationListInProgress(portUuid))
  try {
    const weatherLocationListResult = await api.getWeatherLocations(portUuid)
    return dispatch(getWeatherLocationListSuccess(weatherLocationListResult))
  } catch (error) {
    return dispatch(getWeatherLocationListError(error))
  }
}
export const getWeatherLocationListInProgress = (portUuid) =>
  createInProgressAction(ACTION_GET_LIST, portUuid)
export const getWeatherLocationListSuccess = (weatherLocations) =>
  createSuccessAction(ACTION_GET_LIST, weatherLocations)
export const getWeatherLocationListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE WEATHER_LOCATION
const ACTION_CREATE = 'WEATHER_LOCATION_CREATE'
export const [
  WEATHER_LOCATION_CREATE_REQUEST,
  WEATHER_LOCATION_CREATE_RESULT,
  WEATHER_LOCATION_CREATE_SUCCESS,
  WEATHER_LOCATION_CREATE_ERROR,
  WEATHER_LOCATION_CREATE_CANCEL,
  WEATHER_LOCATION_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createWeatherLocation = (weatherLocation) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: weatherLocation })
  return dispatch(createWeatherLocationRequest(weatherLocation))
}
export const createWeatherLocationRequest = (payload) => async (dispatch) => {
  dispatch(createWeatherLocationInProgress(payload))
  try {
    const weatherLocationResult = await api.createWeatherLocation(payload)
    return dispatch(createWeatherLocationSuccess(weatherLocationResult))
  } catch (error) {
    return dispatch(createWeatherLocationError(error))
  }
}
export const createWeatherLocationInProgress = (weatherLocation) =>
  createInProgressAction(ACTION_CREATE, weatherLocation)
export const createWeatherLocationSuccess = (weatherLocation) => createSuccessAction(ACTION_CREATE, weatherLocation)
export const createWeatherLocationError = (error) => createErrorAction(ACTION_CREATE, error)

// UPDATE weatherLocation
const ACTION_UPDATE = 'WEATHER_LOCATION_UPDATE'
export const [
  WEATHER_LOCATION_UPDATE_REQUEST,
  WEATHER_LOCATION_UPDATE_RESULT,
  WEATHER_LOCATION_UPDATE_SUCCESS,
  WEATHER_LOCATION_UPDATE_ERROR,
  WEATHER_LOCATION_UPDATE_CANCEL,
  WEATHER_LOCATION_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateWeatherLocation = (weatherLocation) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: weatherLocation })
  return dispatch(updateWeatherLocationRequest(weatherLocation))
}
export const updateWeatherLocationRequest = (payload) => async (dispatch) => {
  dispatch(updateWeatherLocationInProgress(payload))
  try {
    const weatherLocationResult = await api.updateWeatherLocation(payload)
    return dispatch(updateWeatherLocationSuccess(weatherLocationResult))
  } catch (error) {
    return dispatch(updateWeatherLocationError(error))
  }
}
export const updateWeatherLocationInProgress = (weatherLocation) =>
  createInProgressAction(ACTION_UPDATE, weatherLocation)
export const updateWeatherLocationSuccess = (weatherLocation) => createSuccessAction(ACTION_UPDATE, weatherLocation)
export const updateWeatherLocationError = (error) => createErrorAction(ACTION_UPDATE, error)

// SAVE weatherLocation (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'WEATHER_LOCATION_SAVE'
export const saveWeatherLocation = (weatherLocation) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: weatherLocation })
  const result = await dispatch(weatherLocation.uuid ? updateWeatherLocation(weatherLocation) : createWeatherLocation(weatherLocation))
  return result
}

// DELETE weatherLocation
const ACTION_DELETE = 'WEATHER_LOCATION_DELETE'
export const [
  WEATHER_LOCATION_DELETE_REQUEST,
  WEATHER_LOCATION_DELETE_RESULT,
  WEATHER_LOCATION_DELETE_SUCCESS,
  WEATHER_LOCATION_DELETE_ERROR,
  WEATHER_LOCATION_DELETE_CANCEL,
  WEATHER_LOCATION_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteWeatherLocation = (uuid) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: uuid })
  return dispatch(deleteWeatherLocationRequest(uuid))
}
export const deleteWeatherLocationRequest = (uuid) => async (dispatch) => {
  dispatch(deleteWeatherLocationInProgress({ uuid }))
  try {
    await api.deleteWeatherLocation(uuid)
    return dispatch(deleteWeatherLocationSuccess({ uuid }))
  } catch (error) {
    dispatch(deleteWeatherLocationError(error))
    throw error
  }
}
export const deleteWeatherLocationInProgress = ({ uuid }) =>
  createInProgressAction(ACTION_DELETE, { uuid })
export const deleteWeatherLocationSuccess = ({ uuid }) => createSuccessAction(ACTION_DELETE, { uuid })
export const deleteWeatherLocationError = (error) => createErrorAction(ACTION_DELETE, error)
