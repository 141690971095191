import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Fieldset from 'src/components/organisms/Fieldset'
import TextInput from 'src/components/atoms/TextInput'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import store from 'src/store/keyManager'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import { isSuperAdminSelector } from 'src/store/user/selectors'

export interface PortShape {
  uuid: string
  name: string
  locationName: string
}

interface Props {
  disabled: boolean
  port: PortShape
}

const PilotageApiKeyGenerator: React.FC<Props> = ({ disabled, port }) => {
  const apiKey = useSelector(store.selectors.apiKey)
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const dispatch = useDispatch()
  const portUuid = port ? port.uuid : ''
  const isLoading = useSelector(store.selectors.isLoading)
  const isSuperAdmin = useSelector(isSuperAdminSelector)

  useEffect(() => {
    if (portUuid) {
      dispatch(store.actions.get(null, { params: { portUuid } }))
    }
  }, [dispatch, portUuid])

  const createApiKey = async () => {
    if (!isSuperAdmin) {
      return true
    }
    const result = await dispatch(store.actions.create({ port }))
    if (result.error) {
      dispatch(
        addErrorToast({
          message: 'Error creating new API key',
        })
      )
      return false
    }
    dispatch(
      addSuccessToast({
        message: 'API key successfully created',
      })
    )
    return true
  }

  const handleGenerate = async () => {
    if (!isSuperAdmin) {
      return
    }
    if (apiKey) {
      setIsDialogOpen(true)
    } else {
      await createApiKey()
    }
  }

  const handleDialogConfirm = async () => {
    if (await createApiKey()) {
      setIsDialogOpen(false)
    }
  }

  const handleDialogCancel = () => {
    setIsDialogOpen(false)
  }

  return (
    <>
      <Fieldset
        disabled={disabled || isLoading}
        title="Pilotage API Key"
        subtitle="Following API key is used for Pilotage API integration with port system."
        clickHandler={isSuperAdmin ? handleGenerate : () => null}
        hasButton={isSuperAdmin}
        buttonContents="Generate New Key"
      >
        <TextInput
          id="api-key"
          name="apiKey"
          label="API Key"
          fullWidth
          disabled={disabled}
          readOnly
          value={apiKey || ''}
        />
      </Fieldset>
      <ConfirmDialog
        title="Replace existing API key?"
        open={isDialogOpen}
        onCancel={handleDialogCancel}
        onConfirm={handleDialogConfirm}
      >
        This will generate a new API Key. Are you sure you want to discard your
        current key and create a new one?
      </ConfirmDialog>
    </>
  )
}

export default React.memo(PilotageApiKeyGenerator)
