import { addErrorToast } from 'src/store/toast/actions'
import { getPilotagesExport } from 'src/utils/sauce/sauce-api-pilotages'
import {
  createBasicActionTypes,
  createErrorAction,
  createInProgressAction,
  createSuccessAction
} from 'src/utils/createAction'
import TOAST_MESSAGES from 'src/utils/toastMessages'

const PILOTAGE_EXPORT_BASE = 'PILOTAGE_EXPORT'

export const [
  PILOTAGE_EXPORT_REQUEST,
  PILOTAGE_EXPORT_RESULT,
  PILOTAGE_EXPORT_SUCCESS,
  PILOTAGE_EXPORT_ERROR,
  PILOTAGE_EXPORT_CANCEL,
  PILOTAGE_EXPORT_IN_PROGRESS,
] = createBasicActionTypes(PILOTAGE_EXPORT_BASE)

export const pilotagesExportRequest = (params) => async (
  dispatch
) => {
  dispatch(pilotagesExportInProgress())
  try {
    const data = await getPilotagesExport(params)
    dispatch(pilotagesExportLoaded(data, params))
    return data
  } catch (error) {
    dispatch(pilotagesExportError(error))
    return false
  }
}

export const pilotagesExportInProgress = () =>
  createInProgressAction(PILOTAGE_EXPORT_BASE)

export const pilotagesExportError = (error) => dispatch => {
  dispatch(createErrorAction(PILOTAGE_EXPORT_BASE, { error }))
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.DOWNLOAD_ERROR,
    })
  )
}

export const pilotagesExportLoaded = (data, params) =>
  createSuccessAction(PILOTAGE_EXPORT_BASE, { data, params })
