import { useDispatch, useSelector } from 'react-redux'
import {
  portListSelector,
  portListStatusSelector,
} from 'src/store/port/selectors'
import {
  PORT_LIST_GET_SUCCESS,
  PORT_LIST_GET_ERROR,
  getPortList,
  PORT_LIST_GET_IN_PROGRESS,
} from 'src/store/port/actions'
import { useCallback } from 'react'
import { isLoggedInSelector } from 'src/store/auth/selectors'

const usePortsData = () => {
  const dispatch = useDispatch()
  const data = useSelector(portListSelector)
  const status = useSelector(portListStatusSelector)
  const reFetch = useCallback(() => dispatch(getPortList()), [dispatch])
  const isLoggedIn = useSelector(isLoggedInSelector)
  if (status === null && isLoggedIn) {
    reFetch()
  }
  data && data.sort((i1, i2) => i1.name.trim().toLowerCase() < i2.name.trim().toLowerCase() ? -1
    : i1.name.trim().toLowerCase() > i2.name.trim().toLowerCase() ? 1 : 0)
  return {
    data,
    reFetch,
    status,
    isLoading: PORT_LIST_GET_IN_PROGRESS === status,
    isLoaded: [PORT_LIST_GET_SUCCESS, PORT_LIST_GET_ERROR].includes(status),
    isFailed: PORT_LIST_GET_ERROR === status,
  }
}

export default usePortsData
