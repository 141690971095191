import React from 'react'
import PropTypes from 'prop-types'
import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import { PilotageUpload } from 'src/components/organisms/PilotageUpload'
import { PilotageCreateToggle } from 'src/components/organisms/PilotageCreateToggle'
import Divider from 'src/components/atoms/Divider/Divider'
import { PilotageDownload } from './../../components/organisms/PilotageDownload/PilotageDownload'
import { PilotageManualOnlineToggle } from './../../components/organisms/PilotageCreateToggle/PilotageManualOnlineToggle'
import { PilotageCreatePdfOnDoneToggle } from './../../components/organisms/PilotageCreateToggle/PilotageCreatePdfOnDoneToggle'

export const Pilotages = ({ port }) => {
  return (
    <SettingsTemplate title="Pilotages" port={port}>
      <PilotageCreateToggle />
      <PilotageManualOnlineToggle/>
      <PilotageCreatePdfOnDoneToggle/>
      <Divider />
      <PilotageUpload port={port} />
      <Divider />
      <PilotageDownload port={port} />
    </SettingsTemplate>
  )
}

Pilotages.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Pilotages
