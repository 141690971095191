import api, {
    getPayloadFromResponse,
    getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getWeatherLocations = (portUuid) =>
    api
        .get(`/weather-locations`, { params: { portUuid }})
        .then(
            (response) => getPayloadFromResponse(response),
            (error) => Promise.reject(error)
        )

export const getWeatherLocation = (uuid) =>
    api
        .get(`/weather-locations/${uuid}`)
        .then(
            (response) => getPayloadFromResponse(response),
            (error) => Promise.reject(error)
        )

export const createWeatherLocation = (weatherLocation) => {
    return api
        .post('/weather-locations', weatherLocation)
        .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateWeatherLocation = (weatherLocation) => {
    return api
        .put(`/weather-locations/${weatherLocation.uuid}`, weatherLocation)
        .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteWeatherLocation = (uuid) =>
    api
        .delete(`/weather-locations/${uuid}`)
        .then(
            (response) => getSinglePayloadFromResponse(response),
            (error) => Promise.reject(error)
        )

export default {
    create: createWeatherLocation,
    update: updateWeatherLocation,
    delete: deleteWeatherLocation,
}
