import { createSelector } from 'reselect'
import {
  createGetListFilteredBySelectedPortSelector,
  createGetListFilteredByPortSelector,
} from 'src/utils/store'

export const constraintStateSelector = (state) => state.constraint

export const constraintListSelector = createSelector(
  constraintStateSelector,
  ({ data, byId }) => 
    data && byId && data
      .map((uuid) => byId[uuid] && mapLegacySafetyMargin(byId[uuid]))
      .filter(c => c)
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  constraintListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  constraintListSelector
)

export const constraintListStatusSelector = createSelector(
  constraintStateSelector,
  (state) => state.status
)

export const constraintByIndexSelector = (index) =>
  createSelector(
    constraintStateSelector,
    (state) => {
      const constraint = state.data && state.data[index] && state.byId[state.data[index]]
      return constraint && mapLegacySafetyMargin(constraint)
    }
  )

export const constraintByIdSelector = (uuid) =>
  createSelector(
    constraintStateSelector,
    (state) => {
      const constraint = state.byId && state.byId[uuid]
      return constraint && mapLegacySafetyMargin(constraint)
    }
  )

export const totalConstraintSelector = createSelector(
  constraintStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    constraintStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    constraintStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )


// mapping is done wherever constraints are selected
// so that stale clients don't break. this is also done on backend 
function mapLegacySafetyMargin(constraint) {
    const mapped = { ...constraint }

    if (
        typeof constraint.safetyMarginM !== 'number' && 
        typeof constraint.safetyMargin === 'number' && 
        constraint.unit === 'm'
    ) {
        mapped.safetyMarginM = constraint.safetyMargin
    }

    if (
        typeof constraint.safetyMarginPct !== 'number' && 
        typeof constraint.safetyMargin === 'number' && 
        constraint.unit === '%'
    ) {
        mapped.safetyMarginPct = constraint.safetyMargin
    }   
    
    return mapped
} 