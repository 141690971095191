import React from 'react'
import PropTypes from 'prop-types'

import { Card, CardContent, CardHeader } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

import {
  TOAST_VARIANT_ERROR,
  TOAST_VARIANT_INFO,
  TOAST_VARIANT_SUCCESS,
} from 'src/containers/Toast'

export const NotificationItem = ({ createdAt, title, message, variant }) => {
  let icon
  switch (variant) {
    case TOAST_VARIANT_ERROR:
      icon = <WarningIcon color="error" />
      break
    case TOAST_VARIANT_SUCCESS:
      icon = <CheckCircleIcon color="action" />
      break
    default:
      icon = <InfoIcon color="primary" />
  }

  return (
    <Card>
      <CardHeader title={title} subheader={createdAt}>
        {icon}
      </CardHeader>
      <CardContent>{message}</CardContent>
    </Card>
  )
}

NotificationItem.propTypes = {
  createdAt: PropTypes.instanceOf(Date),
  seen: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
  variant: PropTypes.oneOf([
    TOAST_VARIANT_INFO,
    TOAST_VARIANT_ERROR,
    TOAST_VARIANT_SUCCESS,
  ]),
}

export default React.memo(NotificationItem)
