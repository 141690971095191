import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import { Box } from '@material-ui/core'

import UploadIcon from 'src/components/atoms/Icons/UploadIcon'
import NoImage from 'src/components/atoms/NoImage'

const isUrl = (value) => value && typeof value === 'string'
const isFile = (value) =>
  value && typeof value !== 'string' && value instanceof File

const DefaultPreview = styled.img`
  max-width: 100%;
`

const DefaultWrapper = styled(Box)`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-items: center;
  position: relative;
`

const DefaultUploadIcon = styled(UploadIcon)`
  bottom: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  transition: opacity 350ms;
  ${DefaultWrapper}:hover & {
    opacity: 0.7;
  }
`

export const VALUE_TYPE_FILE = 'file'
export const VALUE_TYPE_URL = 'url'
export const VALUE_TYPES = {
  FILE: VALUE_TYPE_FILE,
  URL: VALUE_TYPE_URL,
}

export const ImageInput = ({
  className,
  value,
  accept = 'image/jpg,image/jpeg,image/png',
  onChange,
  WrapperComponent = DefaultWrapper,
  PreviewComponent = DefaultPreview,
  UploadIconComponent = DefaultUploadIcon,
  NoImageComponent = NoImage,
  maxSize,
  onDropRejected,
  ...inputProps
}) => {
  const [src, setSrc] = useState(isUrl(value) ? value : '')
  const [file, setFile] = useState(null)
  const [imageError, setImageError] = useState(false)

  const clsName = classNames(className, {
    error: imageError,
  })

  useEffect(() => {
    if (isFile(value)) {
      setFile(value)
    }
    if (isUrl(value)) {
      setSrc(value)
    }
  }, [value])

  useEffect(() => {
    const loadHandler = ({ target: { result } }) => {
      setSrc(result)
    }
    if (file) {
      var reader = new FileReader()
      reader.addEventListener('load', loadHandler)
      reader.readAsDataURL(file)
      return () => {
        reader.removeEventListener('load', loadHandler)
      }
    }
  }, [file])

  useEffect(() => {
    const image = new Image()
    const loadHandler = () => setImageError(false)
    const errorHandler = () => setImageError(true)
    image.addEventListener('error', errorHandler)
    image.addEventListener('load', loadHandler)
    image.src = src
    return () => {
      image.removeEventListener('error', errorHandler)
      image.removeEventListener('load', loadHandler)
    }
  }, [src])

  const handleFileSelect = ([file]) => {
    setFile(file)
    if (onChange) {
      onChange(inputProps.name, file)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept,
    maxSize,
    onDropRejected,
    onDropAccepted: handleFileSelect,
  })

  return (
    <WrapperComponent {...getRootProps()} className={clsName}>
      {!imageError && src && <PreviewComponent src={src} />}
      {imageError && <NoImageComponent label="Failed to load image" />}
      <input {...getInputProps()} />
      <UploadIconComponent />
    </WrapperComponent>
  )
}

ImageInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(File)]),
  previewProps: PropTypes.object,
  maxSize: PropTypes.number,
  onDropRejected: PropTypes.func,
  accept: PropTypes.string,
  extensionsLabel: PropTypes.string,
  onChange: PropTypes.func,
  WrapperComponent: PropTypes.element,
  PreviewComponent: PropTypes.element,
  UploadIconComponent: PropTypes.element,
  NoImageComponent: PropTypes.element,
}

export default ImageInput
