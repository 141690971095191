import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import TableRow from '@material-ui/core/TableRow'

import theme from 'src/styles/theme'

function SelectableRow(props) {
  const { classes, children, onClick, value, ...other } = props

  const memorizedHandler = useCallback(() => {
    if (onClick) {
      onClick(value)
    }
  }, [onClick, value])

  return (
    <TableRow classes={classes} onClick={memorizedHandler} {...other}>
      {children}
    </TableRow>
  )
}

SelectableRow.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  onClick: PropTypes.func,
  value: PropTypes.any,
}

export default styled(React.memo(SelectableRow))`
  && {
    border-bottom: 1px solid ${theme.palette.divider};
  }
  .selected {
    background-color: ${theme.palette.secondary.main};
  }
  &:hover {
    background-color: ${theme.palette.primary.xlight};
  }
`
