import api, {
    getPayloadFromResponse,
    getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const createTideRateData = ({ tideRateStation, file, isDataDst }, config) => {
    const data = new FormData()
    data.append('file', file)
    data.append('tideRateStation', JSON.stringify(tideRateStation))
    data.append('fileInfo', JSON.stringify({ isDataDst }))
    return api
        .post(`/tide_rate_data`, data, {
            ...(config || {}),
            headers: {
                ...((config || {}).headers || {}),
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(
            (response) => getPayloadFromResponse(response),
            (error) => Promise.reject(error)
        )
}

export const updateTideRateData = ({ tideRateStation, file }, config) => {
    const data = new FormData()
    data.append('file', file)
    data.append('tideRateStation', JSON.stringify(tideRateStation))
    return api
        .put(`/tide_rate_data`, data, {
            ...(config || {}),
            headers: {
                ...((config || {}).headers || {}),
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(
            (response) => getPayloadFromResponse(response),
            (error) => Promise.reject(error)
        )
}

export const deleteTideRateData = (uuid, config) =>
    api
        .delete(`/tide_rate_data/${uuid}`, config)
        .then(
            (response) => getSinglePayloadFromResponse(response),
            (error) => Promise.reject(error)
        )
