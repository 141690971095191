import { useDispatch, useSelector } from 'react-redux'
import {
  organisationListSelector,
  organisationListStatusSelector,
} from 'src/store/organisation/selectors'
import {
  ORGANISATION_LIST_GET_SUCCESS,
  ORGANISATION_LIST_GET_ERROR,
  getOrganisationList,
  ORGANISATION_LIST_GET_IN_PROGRESS,
} from 'src/store/organisation/actions'
import { useCallback } from 'react'

const useOrganisationsData = () => {
  const dispatch = useDispatch()
  const data = useSelector(organisationListSelector)
  const status = useSelector(organisationListStatusSelector)
  const reFetch = useCallback(() => dispatch(getOrganisationList()), [dispatch])
  if (status === null) {
    reFetch()
  }
  return {
    data,
    reFetch,
    status,
    isLoading: ORGANISATION_LIST_GET_IN_PROGRESS === status,
    isLoaded: [
      ORGANISATION_LIST_GET_SUCCESS,
      ORGANISATION_LIST_GET_ERROR,
    ].includes(status),
    isFailed: ORGANISATION_LIST_GET_ERROR === status,
  }
}

export default useOrganisationsData
