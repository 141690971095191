import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import PlaceIcon from '@material-ui/icons/Place'

import { EditIcon } from 'src/components/atoms/Icons'
import { RemoveIconButton } from 'src/components/atoms/RemoveButton'
import { formatTideDataRange } from 'src/utils/formatters'
import { TIDE_TYPE } from 'src/constants/settings'

const StyledList = styled(List)`
  && {
    padding-bottom: 0;
    padding-top: 0;
  }
`

const StyledListItem = styled(ListItem)`
  && {
    padding: ${({ theme }) => theme.spacing(1, 0)};
  }
`

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)`
  && {
    display: flex;
  }
`

const DateRangeDescription = styled.span`
  display: inline-block;
  width: 100px;
`

const TideStationItem = ({ station, onClick, onRemove, className }) => {
  const { uuid, name, dateRange = [] } = station

  const dateRangeAll = dateRange.find(({ type }) => type === TIDE_TYPE.ALL)
  const { start: firstDateAll, end: lastDateAll } = dateRangeAll || {}

  const dateRangeExtremes = dateRange.find(
    ({ type }) => type === TIDE_TYPE.EXTREMES
  )
  const { start: firstDateExtremes, end: lastDateExtremes } =
    dateRangeExtremes || {}

  const handleRemoveClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (onRemove) {
        onRemove(uuid)
      }
    },
    [onRemove, uuid]
  )
  const handleEditClick = useCallback(
    (event) => {
      event.stopPropagation()
      if (onRemove) {
        onClick(uuid)
      }
    },
    [onClick, onRemove, uuid]
  )
  const handleOnClick = useCallback(() => {
    if (onClick) {
      onClick(uuid)
    }
  }, [onClick, uuid])

  return (
    <StyledListItem button onClick={handleOnClick} className={className}>
      <ListItemIcon>
        <PlaceIcon />
      </ListItemIcon>
      <ListItemText
        primary={name}
        secondary={
          <>
            <DateRangeDescription>Tide data: </DateRangeDescription>
            {formatTideDataRange(firstDateAll, lastDateAll)}
            <br />
            <DateRangeDescription>High/low data: </DateRangeDescription>
            {formatTideDataRange(firstDateExtremes, lastDateExtremes)}
          </>
        }
      />
      <StyledListItemSecondaryAction>
        <IconButton onClick={handleEditClick}>
          <EditIcon />
        </IconButton>
        <RemoveIconButton onClick={handleRemoveClick} />
      </StyledListItemSecondaryAction>
    </StyledListItem>
  )
}

TideStationItem.propTypes = {
  station: PropTypes.any,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  className: PropTypes.string,
}

export const TideStationList = ({
  data = [],
  onClick,
  onRemove,
  ...listProp
}) => {
  return (
    <StyledList {...listProp}>
      {data.map((item) => (
        <TideStationItem
          key={item.uuid}
          station={item}
          onRemove={onRemove}
          onClick={onClick}
        />
      ))}
    </StyledList>
  )
}

TideStationList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      uuid: PropTypes.string,
    })
  ),
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
}

export default TideStationList
