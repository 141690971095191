import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-route-attachment'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'

// GET ROUTE_ATTACHMENT
const ACTION_GET = 'ROUTE_ATTACHMENT_GET'
export const [
  ROUTE_ATTACHMENT_GET_REQUEST,
  ROUTE_ATTACHMENT_GET_RESULT,
  ROUTE_ATTACHMENT_GET_SUCCESS,
  ROUTE_ATTACHMENT_GET_ERROR,
  ROUTE_ATTACHMENT_GET_CANCEL,
  ROUTE_ATTACHMENT_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getRouteAttachment = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getRouteAttachmentRequest(uuid))
}
export const getRouteAttachmentRequest = (uuid) => async (dispatch) => {
  dispatch(getRouteAttachmentInProgress(uuid))
  try {
    const routeAttachmentResult = await api.getRouteAttachment(uuid)
    return dispatch(getRouteAttachmentSuccess(routeAttachmentResult))
  } catch (error) {
    return dispatch(getRouteAttachmentError(error))
  }
}
export const getRouteAttachmentInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getRouteAttachmentSuccess = (routeAttachment) =>
  createSuccessAction(ACTION_GET, routeAttachment)
export const getRouteAttachmentError = (error) =>
  createErrorAction(ACTION_GET, error)

// GET ROUTE_ATTACHMENT_LIST
const ACTION_GET_LIST = 'ROUTE_ATTACHMENT_LIST_GET'
export const [
  ROUTE_ATTACHMENT_LIST_GET_REQUEST,
  ROUTE_ATTACHMENT_LIST_GET_RESULT,
  ROUTE_ATTACHMENT_LIST_GET_SUCCESS,
  ROUTE_ATTACHMENT_LIST_GET_ERROR,
  ROUTE_ATTACHMENT_LIST_GET_CANCEL,
  ROUTE_ATTACHMENT_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getRouteAttachmentList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getRouteAttachmentListRequest(uuid))
}
export const getRouteAttachmentListRequest = (uuid) => async (dispatch) => {
  dispatch(getRouteAttachmentListInProgress(uuid))
  try {
    const routeAttachmentListResult = await api.getRouteAttachments(uuid)
    return dispatch(getRouteAttachmentListSuccess(routeAttachmentListResult))
  } catch (error) {
    return dispatch(getRouteAttachmentListError(error))
  }
}
export const getRouteAttachmentListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getRouteAttachmentListSuccess = (routeAttachment) =>
  createSuccessAction(ACTION_GET_LIST, routeAttachment)
export const getRouteAttachmentListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE ROUTE_ATTACHMENT
const ACTION_CREATE = 'ROUTE_ATTACHMENT_CREATE'
export const [
  ROUTE_ATTACHMENT_CREATE_REQUEST,
  ROUTE_ATTACHMENT_CREATE_RESULT,
  ROUTE_ATTACHMENT_CREATE_SUCCESS,
  ROUTE_ATTACHMENT_CREATE_ERROR,
  ROUTE_ATTACHMENT_CREATE_CANCEL,
  ROUTE_ATTACHMENT_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createRouteAttachment = (routeAttachment) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: routeAttachment })
  return dispatch(createRouteAttachmentRequest(routeAttachment))
}
export const createRouteAttachmentRequest = (payload) => async (dispatch) => {
  dispatch(createRouteAttachmentInProgress(payload))
  try {
    const routeAttachmentResult = await api.createRouteAttachment(payload)
    return dispatch(createRouteAttachmentSuccess(routeAttachmentResult))
  } catch (error) {
    return dispatch(createRouteAttachmentError(error, payload))
  }
}
export const createRouteAttachmentInProgress = (routeAttachment) =>
  createInProgressAction(ACTION_CREATE, routeAttachment)
export const createRouteAttachmentSuccess = (routeAttachment) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        TOAST_MESSAGES.ROUTE_ATTACHMENT_CREATE_SUCCESS,
        routeAttachment
      ),
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, routeAttachment))
}
export const createRouteAttachmentError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(TOAST_MESSAGES.ROUTE_ATTACHMENT_CREATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE ROUTE_ATTACHMENT
const ACTION_UPDATE = 'ROUTE_ATTACHMENT_UPDATE'
export const [
  ROUTE_ATTACHMENT_UPDATE_REQUEST,
  ROUTE_ATTACHMENT_UPDATE_RESULT,
  ROUTE_ATTACHMENT_UPDATE_SUCCESS,
  ROUTE_ATTACHMENT_UPDATE_ERROR,
  ROUTE_ATTACHMENT_UPDATE_CANCEL,
  ROUTE_ATTACHMENT_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateRouteAttachment = (routeAttachment) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: routeAttachment })
  return dispatch(updateRouteAttachmentRequest(routeAttachment))
}
export const updateRouteAttachmentRequest = (payload) => async (dispatch) => {
  dispatch(updateRouteAttachmentInProgress(payload))
  try {
    const routeAttachmentResult = await api.updateRouteAttachment(payload)
    return dispatch(updateRouteAttachmentSuccess(routeAttachmentResult))
  } catch (error) {
    return dispatch(updateRouteAttachmentError(error, payload))
  }
}
export const updateRouteAttachmentInProgress = (routeAttachment) =>
  createInProgressAction(ACTION_UPDATE, routeAttachment)
export const updateRouteAttachmentSuccess = (routeAttachment) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        TOAST_MESSAGES.ROUTE_ATTACHMENT_UPDATE_SUCCESS,
        routeAttachment
      ),
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, routeAttachment))
}
export const updateRouteAttachmentError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(TOAST_MESSAGES.ROUTE_ATTACHMENT_UPDATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE ROUTE_ATTACHMENT (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'ROUTE_ATTACHMENT_SAVE'
export const saveRouteAttachment = (routeAttachment) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: routeAttachment })
  const { uuid, ...routeAttachmentNoUuuid } = routeAttachment
  const result = await dispatch(
    uuid ? updateRouteAttachment(routeAttachment) : createRouteAttachment(routeAttachmentNoUuuid)
  )
  return result
}

// DELETE ROUTE_ATTACHMENT
const ACTION_DELETE = 'ROUTE_ATTACHMENT_DELETE'
export const [
  ROUTE_ATTACHMENT_DELETE_REQUEST,
  ROUTE_ATTACHMENT_DELETE_RESULT,
  ROUTE_ATTACHMENT_DELETE_SUCCESS,
  ROUTE_ATTACHMENT_DELETE_ERROR,
  ROUTE_ATTACHMENT_DELETE_CANCEL,
  ROUTE_ATTACHMENT_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteRouteAttachment = (routeAttachment) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: routeAttachment })
  return dispatch(deleteRouteAttachmentRequest(routeAttachment))
}
export const deleteRouteAttachmentRequest = (routeAttachment) => async (dispatch) => {
  dispatch(deleteRouteAttachmentInProgress(routeAttachment))
  try {
    await api.deleteRouteAttachment(routeAttachment.uuid)
    return dispatch(deleteRouteAttachmentSuccess(routeAttachment))
  } catch (error) {
    dispatch(deleteRouteAttachmentError(error))
    return error
  }
}
export const deleteRouteAttachmentInProgress = (routeAttachment) =>
  createInProgressAction(ACTION_DELETE, routeAttachment)
export const deleteRouteAttachmentSuccess = (routeAttachment) =>
  createSuccessAction(ACTION_DELETE, routeAttachment)
export const deleteRouteAttachmentError = (error) =>
  createErrorAction(ACTION_DELETE, error)
