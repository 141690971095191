import { FeatureFlagsState, FeatureFlagsAction } from './types'

export const initialState: FeatureFlagsState = {
  flags: undefined,
  uuid: undefined,
  loading: false,
  updating: false,
}

export function featureFlagsReducer(
  state = initialState,
  action: FeatureFlagsAction
) {
  switch (action.type) {
    case 'FEATURE_FLAGS_SET_LOADING':
      return { ...state, loading: action.loading }
    case 'FEATURE_FLAGS_SET_UPDATING':
      return { ...state, updating: action.updating }
    case 'FEATURE_FLAGS_SET_ERROR':
      return { ...state, error: action.error }
    case 'FEATURE_FLAGS_SET_FLAGS':
      return {
        ...state,
        flags: action.flags,
        uuid: action.uuid,
        error: undefined,
        loading: false,
      }
    case 'FEATURE_FLAGS_SET_FLAG':
      return {
        ...state,
        flags: {
          ...state.flags,
          [action.flagKey]: action.flagMetaData,
        },
      }
    default:
      return state
  }
}

export default featureFlagsReducer
