import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
  createRequestAction,
} from 'src/utils/createAction'
import {
  setUserToStorage,
  getUserFromStorage,
  removeUserFromStorage,
} from 'src/utils/storage'
import * as api from 'src/utils/sauce/sauce-api-user'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'

const ACTION_BASE = 'USER'

const ACTION_LOAD_CURRENT_FROM_STORAGE = `${ACTION_BASE}_LOAD_CURRENT_FROM_STORAGE`
export const [
  USER_LOAD_CURRENT_FROM_STORAGE_REQUEST,
  USER_LOAD_CURRENT_FROM_STORAGE_RESULT,
  USER_LOAD_CURRENT_FROM_STORAGE_SUCCESS,
  USER_LOAD_CURRENT_FROM_STORAGE_ERROR,
  USER_LOAD_CURRENT_FROM_STORAGE_CANCEL,
  USER_LOAD_CURRENT_FROM_STORAGE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_LOAD_CURRENT_FROM_STORAGE)
export const loadCurrentUserFromStorage = () => (dispatch) => {
  try {
    dispatch(loadCurrentUserFromStorageSuccess(getUserFromStorage()))
  } catch (error) {
    return dispatch(loadCurrentUserFromStorageError(error))
  }
}
export const loadCurrentUserFromStorageSuccess = (payload) => (dispatch) => {
  dispatch(createSuccessAction(ACTION_LOAD_CURRENT_FROM_STORAGE, payload))
}
export const loadCurrentUserFromStorageError = (error) =>
  createErrorAction(ACTION_LOAD_CURRENT_FROM_STORAGE, error)

// SET CURRENT USER
const ACTION_SET_CURRENT = `${ACTION_BASE}_SET_CURRENT`
export const [
  USER_SET_CURRENT_REQUEST,
  USER_SET_CURRENT_RESULT,
  USER_SET_CURRENT_SUCCESS,
  USER_SET_CURRENT_ERROR,
  USER_SET_CURRENT_CANCEL,
  USER_SET_CURRENT_IN_PROGRESS,
] = createBasicActionTypes(ACTION_SET_CURRENT)
export const setCurrentUser = (payload) => (dispatch) => {
  dispatch(createRequestAction(ACTION_SET_CURRENT, payload))
  try {
    setUserToStorage(payload)
    dispatch(setCurrentUserSuccess(payload))
  } catch (error) {
    return dispatch(setCurrentUserError(error))
  }
}
export const setCurrentUserSuccess = (payload) => (dispatch) => {
  dispatch(createSuccessAction(ACTION_SET_CURRENT, payload))
}
export const setCurrentUserError = (error) =>
  createErrorAction(ACTION_SET_CURRENT, error)

// UPDATE CURRENT USER
const ACTION_UPDATE_CURRENT = `${ACTION_BASE}_UPDATE_CURRENT`
export const [
  USER_UPDATE_CURRENT_REQUEST,
  USER_UPDATE_CURRENT_RESULT,
  USER_UPDATE_CURRENT_SUCCESS,
  USER_UPDATE_CURRENT_ERROR,
  USER_UPDATE_CURRENT_CANCEL,
  USER_UPDATE_CURRENT_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE_CURRENT)
export const updateCurrentUser = (payload) => (dispatch) => {
  try {
    const user = getUserFromStorage() || {}
    setUserToStorage({
      ...user,
      ...payload,
    })
    dispatch(updateCurrentUserSuccess(payload))
  } catch (error) {
    return dispatch(updateCurrentUserError(error))
  }
}
export const updateCurrentUserSuccess = (payload) => (dispatch) => {
  dispatch(createSuccessAction(ACTION_UPDATE_CURRENT, payload))
}
export const updateCurrentUserError = (error) =>
  createErrorAction(ACTION_UPDATE_CURRENT, error)

// REMOVE CURRENT USER
const ACTION_REMOVE_CURRENT = `${ACTION_BASE}_REMOVE_CURRENT`
export const [
  USER_REMOVE_CURRENT_REQUEST,
  USER_REMOVE_CURRENT_RESULT,
  USER_REMOVE_CURRENT_SUCCESS,
  USER_REMOVE_CURRENT_ERROR,
  USER_REMOVE_CURRENT_CANCEL,
  USER_REMOVE_CURRENT_IN_PROGRESS,
] = createBasicActionTypes(ACTION_REMOVE_CURRENT)
export const removeCurrentUser = (payload) => (dispatch) => {
  try {
    removeUserFromStorage()
    dispatch(removeCurrentUserSuccess(payload))
  } catch (error) {
    return dispatch(removeCurrentUserError(error))
  }
}
export const removeCurrentUserSuccess = (payload) => (dispatch) => {
  dispatch(createSuccessAction(ACTION_REMOVE_CURRENT, payload))
}
export const removeCurrentUserError = (error) =>
  createErrorAction(ACTION_REMOVE_CURRENT, error)




export const USER_PORTS_UPDATE = 'USER_PORTS_UPDATE'
export const [
  USER_PORTS_UPDATE_REQUEST,
  USER_PORTS_UPDATE_RESULT,
  USER_PORTS_UPDATE_SUCCESS,
  USER_PORTS_UPDATE_ERROR,
  USER_PORTS_UPDATE_CANCEL,
  USER_PORTS_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(USER_PORTS_UPDATE)

export const updateUserPorts = (user, message) => async (dispatch) => {
  dispatch({ type: USER_PORTS_UPDATE, payload: user })
  return dispatch(updateUserPortsRequest(user, message))
}

export const updateUserPortsRequest = (user, message) => async (dispatch) => {
  dispatch(updateUserInProgress(user))
  try {
    const ports = user.identity.portIdentities.map(({ port }) => ({ uuid: port.uuid }))
    await api.updateUserPorts({ uuid: user.uuid, ports })
    return dispatch(updateUserSuccess(user, message)) // update user ports success (add/remove)
  } catch (error) {
    return dispatch(updateUserError(error))
  }
}


export const USER_INVITE = 'USER_INVITE'
export const [
  USER_INVITE_REQUEST,
  USER_INVITE_RESULT,
  USER_INVITE_SUCCESS,
  USER_INVITE_ERROR,
  USER_INVITE_CANCEL,
  USER_INVITE_IN_PROGRESS,
] = createBasicActionTypes(USER_INVITE)
export const inviteUser = (invitation) => async (dispatch) => {
  dispatch({ type: USER_INVITE, payload: invitation })
  return dispatch(inviteUserRequest(invitation))
}
export const inviteUserRequest = (payload) => async (dispatch) => {
  dispatch(inviteUserInProgress(payload))
  try {
    const result = await api.inviteUser(payload)
    return dispatch(inviteUserSuccess(result))
  } catch (error) {
    return dispatch(inviteUserError(error))
  }
}
export const inviteUserInProgress = (payload) =>
  createInProgressAction(USER_INVITE, payload)
export const inviteUserSuccess = (result) =>
  createSuccessAction(USER_INVITE, result)
export const inviteUserError = (error) => createErrorAction(USER_INVITE, error)



// GET USER_LIST
const ACTION_GET_LIST = 'USER_LIST_GET'
export const [
  USER_LIST_GET_REQUEST,
  USER_LIST_GET_RESULT,
  USER_LIST_GET_SUCCESS,
  USER_LIST_GET_ERROR,
  USER_LIST_GET_CANCEL,
  USER_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getUserList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getUserListRequest(uuid))
}
export const getUserListRequest = (uuid) => async (dispatch) => {
  dispatch(getUserListInProgress(uuid))
  try {
    const userListResult = await api.getUsers(uuid)
    return dispatch(getUserListSuccess(userListResult))
  } catch (error) {
    return dispatch(getUserListError(error))
  }
}
export const getUserListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getUserListSuccess = (user) =>
  createSuccessAction(ACTION_GET_LIST, user)
export const getUserListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// UPDATE user
const ACTION_UPDATE = 'USER_UPDATE'
export const [
  USER_UPDATE_REQUEST,
  USER_UPDATE_RESULT,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_CANCEL,
  USER_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateUser = (user, message) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: user })
  return dispatch(updateUserRequest(user, message))
}
export const updateUserRequest = (payload) => async (dispatch) => {
  dispatch(updateUserInProgress(payload))
  try {
    const userResult = await api.updateUser(payload)
    return dispatch(updateUserSuccess(userResult))
  } catch (error) {
    return dispatch(updateUserError(error, payload))
  }
}
export const updateUserInProgress = (user) =>
  createInProgressAction(ACTION_UPDATE, user)
  
export const updateUserSuccess = (user, message) => (dispatch) => {
  const {
    metadata: { firstName, lastName },
  } = user
  dispatch(
    addSuccessToast({
      message: message || `You successfully updated the user: ${firstName} ${lastName}`,
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, user))
}
export const updateUserError = (error, payload) => (dispatch) => {
  const {
    metadata: { firstName, lastName },
  } = payload
  dispatch(
    addErrorToast({
      message: `Error updating the user: ${firstName} ${lastName}`,
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// DELETE user
const ACTION_DELETE = 'USER_DELETE'
export const [
  USER_DELETE_REQUEST,
  USER_DELETE_RESULT,
  USER_DELETE_SUCCESS,
  USER_DELETE_ERROR,
  USER_DELETE_CANCEL,
  USER_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteUser = (user, showMessage = true) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: user })
  return dispatch(deleteUserRequest(user, showMessage))
}
export const deleteUserRequest = (user, showMessage = true) => async (
  dispatch
) => {
  dispatch(deleteUserInProgress(user))
  try {
    await api.deleteUser(user.uuid)
    if (showMessage) {
      dispatch(
        addSuccessToast({
          message: getToastMessage(TOAST_MESSAGES.USER_DELETE_SUCCESS, user),
        })
      )
    }
    return dispatch(deleteUserSuccess(user))
  } catch (error) {
    if (showMessage) {
      dispatch(
        addErrorToast({
          message: getToastMessage(TOAST_MESSAGES.USER_DELETE_ERROR, user),
        })
      )
    }
    dispatch(deleteUserError(error))
    return error
  }
}
export const deleteUserInProgress = (user) =>
  createInProgressAction(ACTION_DELETE, user)
export const deleteUserSuccess = (user) =>
  createSuccessAction(ACTION_DELETE, user)
export const deleteUserError = (error) =>
  createErrorAction(ACTION_DELETE, error)
