import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Fab, IconButton } from '@material-ui/core'
import FilterNone from '@material-ui/icons/FilterNone'

const CircleIconButton = styled(Fab).attrs({
  size: 'small',
})`
  && {
    background-color: ${({ theme }) => theme.palette.background.paper};
    height: 27px;
    min-height: 27px;
    min-width: 27px;
    width: 27px;
    &:hover {
      background-color: ${({ theme }) => theme.palette.background.paper};
    }
  }
`
const buttonSizeStyle =
{
  height: '18px',
  width: '18px',
}

// eslint-disable-next-line react/display-name
export const CopyIconButton = React.forwardRef(
  ({ children, Icon = FilterNone, ...props }, ref) => (
    <IconButton ref={ref} {...props}>
      <Icon color="action" style={buttonSizeStyle} />
      {children}
    </IconButton>
  )
)
CopyIconButton.propTypes = {
  size: PropTypes.string,
  Icon: PropTypes.elementType,
  children: PropTypes.node,
  onClick: PropTypes.any,
}

// eslint-disable-next-line react/display-name
export const CopyButton = React.forwardRef(
  ({ children, Icon = FilterNone, ...props }, ref) => (
    <CircleIconButton ref={ref} {...props}>
      <Icon color="action" style={buttonSizeStyle} />
      {children}
    </CircleIconButton>
  )
)

CopyButton.propTypes = {
  size: PropTypes.string,
  Icon: PropTypes.elementType,
  children: PropTypes.node,
  onClick: PropTypes.any,
}

export default React.memo(CopyIconButton)
