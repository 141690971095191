import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getOrganisations = (config) =>
  api
    .get(`/organisations`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getOrganisation = (uuid, config) =>
  api
    .get(`/organisations/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createOrganisation = (organisation, config) => {
  return api
    .post('/organisations', organisation, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateOrganisation = (organisation, config) => {
  return api
    .put(`/organisations/${organisation.uuid}`, organisation, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteOrganisation = (uuid, config) =>
  api
    .delete(`/organisations/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  create: createOrganisation,
  update: updateOrganisation,
  delete: deleteOrganisation,
}
