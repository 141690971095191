import { createSelector } from 'reselect'

export const pilotagesPdfDownloadStateSelector = state => state.pilotagesPdfDownload

export const pilotagesPdfDownloadIsLoadingSelector = createSelector(
  pilotagesPdfDownloadStateSelector,
  state => state && state.isLoading
)

export const pilotagesPdfDownloadErrorSelector = createSelector(
  pilotagesPdfDownloadStateSelector,
  state => state && state.error
)

export const pilotagesPdfDownloadSelector = createSelector(
  pilotagesPdfDownloadStateSelector,
  state => state && state.data
)
