import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'

import { Tooltip, IconButton } from '@material-ui/core'
import RotateLeftIcon from '@material-ui/icons/RotateLeft'

import Preloader from 'src/components/atoms/Preloader'
import DataTable from 'src/components/molecules/DataTable'
import InvitationStatus from 'src/components/atoms/InvitationStatus'
import {
  deleteInvitation,
  createInvitation,
  updateInvitationPorts,
} from 'src/store/invitation/actions'
import { PortShape, OrganisationShape } from 'src/utils/types'
import EmptyState from 'src/components/atoms/EmptyState'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { requestNewPassword } from 'src/store/auth/actions'
import { deleteUser, updateUserPorts } from 'src/store/user/actions'
import { addErrorToast } from 'src/store/toast/actions'
import { RemoveIconButton } from 'src/components/atoms/RemoveButton'
import EditInline from 'src/components/molecules/EditInline'
import EditAvatar from 'src/components/molecules/EditAvatar'
import { validateValues } from 'src/utils/validation'

const ActionWrapper = styled.div`
  && {
    align-items: center;
    display: flex;
    > button {
      padding: ${({ theme }) => theme.spacing(0.5)}px;
    }
  }
`

const dataColumns = (
  organisation,
  handleResetPassword,
  handleRemovePilot,
  handleUpdateInvite
) => {
  const results = [
    {
      field: 'metadata',
      title: 'First name',
      labelFunction: (metadata, invite) =>
        (organisation && !organisation.ssoEnabled
          ? <EditInline
            onChange={(firstName) =>
              handleUpdateInvite({ ...invite, metadata: { ...metadata, firstName } })
            }
            value={metadata ? metadata.firstName : ''}
            validate={(name) => {
              const validation = validateValues({ name }, { name: ['required', 'humanName'] })
              return validation && validation.name
            }}
          /> : metadata ? metadata.firstName : '')
    },
    {
      field: 'metadata',
      title: 'Last name',
      labelFunction: (metadata, invite) =>
        (organisation && !organisation.ssoEnabled
          ? <EditInline
            onChange={(lastName) =>
              handleUpdateInvite({ ...invite, metadata: { ...metadata, lastName } })
            }
            value={metadata ? metadata.lastName : ''}
            validate={(name) => {
              const validation = validateValues({ name }, { name: ['required', 'humanName'] })
              return validation && validation.name
            }}
          /> : metadata ? metadata.lastName : '')
    },
    {
      field: 'email',
      title: 'Email',
    },
    {
      field: 'externalId',
      title: 'Pilot ID',
      labelFunction: (externalId, invite) =>
        <EditInline
          onChange={(nextExternalId) =>
            handleUpdateInvite({ ...invite, externalId: nextExternalId })
          }
          value={externalId || ''}
          validate={(externalId) => {
            const validation = validateValues({ externalId }, { externalId: ['nameWithSpecialCharacters'] })
            return validation && validation.externalId
          }}
        />
    },
    {
      field: 'metadata',
      title: 'License No.',
      labelFunction: (metadata, invite) =>
        <EditInline
          onChange={(licenseNumber) =>
            handleUpdateInvite({
              ...invite,
              metadata: {
                ...metadata, licenseNumber
              }
            })
          }
          value={metadata ? metadata.licenseNumber : ''}
        />
    },
    {
      field: 'metadata',
      title: 'Photo',
      labelFunction: (metadata, invite) =>
        <EditAvatar
          onChange={(avatar) =>
            handleUpdateInvite({
              ...invite,
              metadata: {
                ...metadata, avatar
              }
            })
          }
          value={metadata ? metadata.avatar : ''} />
    },
  ]
  if (organisation && !organisation.ssoEnabled) {
    results.push(
      {
        field: 'verified',
        title: 'Status',
        // eslint-disable-next-line react/display-name
        labelFunction: (verified) => (
          <InvitationStatus verified={verified}>
            {verified ? 'Accepted' : 'Pending'}
          </InvitationStatus>
        ),
      })

    results.push({
      field: 'uuid',
      title: 'Actions',
      sortable: false,
      align: 'right',
      justify: 'flex-end',
      width: 40,
      // eslint-disable-next-line react/display-name
      labelFunction: (uuid, item) => (
        <ActionWrapper display="flex" alignItems="center">
          <Tooltip
            title={item.verified ? 'Reset pilot password' : 'Resend invitation'}
          >
            <IconButton onClick={() => handleResetPassword(item)}>
              <RotateLeftIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={item.verified ? 'Remove pilot' : 'Remove invitation'}>
            <RemoveIconButton onClick={() => handleRemovePilot(item)} />
          </Tooltip>
        </ActionWrapper>
      ),
    })
  }
  return results
}

const UsersTable = ({ data, isLoading, port, handleUpdateInvite }) => {
  
  const dispatch = useDispatch()
  const [selectedToRemove, setSelectedToRemove] = useState(null)
  const [selectedToReset, setSelectedToReset] = useState(null)

  const portUuid = port.uuid

  const handleResetPassword = (invite) => setSelectedToReset(invite)
  const handleCancelResetPilot = () => setSelectedToReset(null)
  const handleConfirmResetPilot = async () => {

    try {
      const { email, externalId } = selectedToReset

      if (selectedToReset.user) {
        await dispatch(requestNewPassword({ email }))

      } else {
        await dispatch(deleteInvitation(selectedToReset, false))

        const newInvite = {
          email,
          metadata: selectedToReset.metadata,
          portValidationTokens: [...selectedToReset.portValidationTokens],
          externalId,
        }

        await dispatch(createInvitation(newInvite))
      }
    } catch (error) {
    } finally {
      setSelectedToReset(null)
    }
  }

  const handleRemovePilot = (invite) => setSelectedToRemove(invite)
  const handleCancelRemovePilot = () => setSelectedToRemove(null)
  const handleConfirmRemovePilot = async () => {

    const invitation = selectedToRemove

    try {
      if (invitation.user) { // update or delete user

        const portIdentities = invitation.user.identity.portIdentities || []
        const nextPortIdentities = portIdentities.filter(id => id.port.uuid !== portUuid)

        if (nextPortIdentities.length === 0) {
          await dispatch(
            deleteUser({
                uuid: invitation.user.uuid,
                email: invitation.email,
                firstName: invitation.metadata.firstName,
                lastName: invitation.metadata.lastName,
            })
          )
        } else {
          // or just remove port from list
          await dispatch(
            updateUserPorts({
              ...invitation.user, 
              identity: {
                  ...invitation.user.identity,
                  portIdentities: nextPortIdentities
              } 
            },
            `Removed ${invitation.metadata.firstName} ${invitation.metadata.lastName} from ${port.name}`
          ))
        }
      } else { // update or delete invite
        const portValidationTokens = invitation.portValidationTokens || []
        const nextPortValidationTokens = portValidationTokens.filter(token => token.port.uuid !== portUuid)

        if (portValidationTokens.length === 0) { // if no ports left, delete whole invitation
            await dispatch(deleteInvitation(invitation, false))

        } else { // or just remove port from list
            await dispatch(
              updateInvitationPorts({ 
                ...invitation,
                portValidationTokens: nextPortValidationTokens
              },
              `Removed ${invitation.metadata.firstName} ${invitation.metadata.lastName} from ${port.name}`
            ))
        }
      }

    } catch (error) {
      dispatch(
        addErrorToast({
          message: `An error while we tried to remove the ${
            selectedToRemove.user ? 'user' : 'invitation'
          }`,
        })
      )
    } finally {
      setSelectedToRemove(null)
    }
  }


  if (isLoading) {
    return <Preloader />
  }

  data.sort(
    (
      { metadata: { firstName: f1, lastName: l1 } },
      { metadata: { firstName: f2, lastName: l2 } }
    ) => {
      const n1 = `${f1}${l1}`
      const n2 = `${f2}${l2}`
      return n1 > n2 ? 1 : n1 === n2 ? 0 : -1
    }
  )

  const organisation = port.organisation
  return (
    <>
      {data && data.length > 0 && (
        <DataTable
          columns={dataColumns(port.organisation, handleResetPassword, handleRemovePilot, handleUpdateInvite)}
          dataProvider={data}
        />
      )}
      {(!data || !data.length) && (
        <>
        { (organisation && !organisation.ssoEnabled) ? (
          <EmptyState message="Invite your first pilot." />
        ) : (
          <EmptyState message="Your organisation is using Single Sign-On with existing identity provider for easier and secured authentication process. Once your organisation identity manager adds and provisions pilots as eMPX users, they will appear here for you to manage." />
        )}
        </>
      )}
      {selectedToRemove && (
        <ConfirmDialog
          open={!!selectedToRemove}
          title={`Remove ${selectedToRemove.user ? 'user' : 'invitation'}`}
          onCancel={handleCancelRemovePilot}
          onConfirm={handleConfirmRemovePilot}
        >
          Are you sure you want to remove this{' '}
          {selectedToRemove.user ? 'pilot' : 'invitation'}?
          <p>
            User email: {selectedToRemove.email}
          </p>
        </ConfirmDialog>
      )}
      {selectedToReset && (
        <ConfirmDialog
          open={!!selectedToReset}
          title={`${
            selectedToReset.user ? 'Reset password' : 'Resend invitation'
          }`}
          onCancel={handleCancelResetPilot}
          onConfirm={handleConfirmResetPilot}
        >
          Are you sure you want to{' '}
          {selectedToReset.user
            ? 'reset the password'
            : 'resend the invitation'}
          ?
          <p>
            User email: {selectedToReset.email}
          </p>
        </ConfirmDialog>
      )}
    </>
  )
}

UsersTable.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  error: PropTypes.bool,
  port: PropTypes.shape(PortShape),
  organisation: PropTypes.shape(OrganisationShape),
  handleUpdateInvite: PropTypes.func
}

export default React.memo(UsersTable)
