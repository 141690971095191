import React from 'react'
import {
    IconButton,
} from '@material-ui/core'
import AddPersonIcon from '@material-ui/icons/PersonAdd'
  

// eslint-disable-next-line react/display-name
const AddUserButton = React.forwardRef((props, ref) => (
    <IconButton ref={ref} {...props}>
      <AddPersonIcon />
    </IconButton>
))
AddUserButton.propTypes = {}

export default AddUserButton
