import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { pilotagesPdfDownloadRequest } from 'src/store/pilotagesPdfDownload/actions'
import { pilotagesPdfDownloadIsLoadingSelector } from 'src/store/pilotagesPdfDownload/selectors'
import { addSuccessToast } from 'src/store/toast/actions'
import { TOAST_MESSAGES } from 'src/utils/toastMessages'
import styled from 'styled-components'
import { useRoute } from 'react-router5'

const SubheadingText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-left: 10px;
    margin-bottom: 20px;
  }
`

const LeftAlignedPrimaryButton = styled(PrimaryButton)`
  & {
    margin-left: inherit !important;
  }

`

export const PilotagePdfDownload = () => {
  const { route } = useRoute()
  const { params } = route
  const { pilotageUuid } = params

  const dispatch = useDispatch()
  const submitting = useSelector(pilotagesPdfDownloadIsLoadingSelector)

  const handleStartProcess = async () => {
    const data = await dispatch(pilotagesPdfDownloadRequest({
      pilotageUuid,
    }))
    if (data) {
      const url = window.URL.createObjectURL(
        new Blob([data], {
          type: 'application/octet-stream',
        })
      )
      const link = document.createElement('a')
      link.href = url
      const filename = `pilotage_plan_done_${pilotageUuid}.pdf`
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      dispatch(addSuccessToast({ message: TOAST_MESSAGES.DOWNLOAD_PDF_SUCCESS }))
    }
  }

  return (
    <>
        <Box>
          <SubheadingText>
            { submitting ? 'Downloading PDF...' : `PDF download request for pilotage ${pilotageUuid}`}
          </SubheadingText>
          <Box>
            <LeftAlignedPrimaryButton
              disabled={submitting || !pilotageUuid}
              onClick={handleStartProcess}
            >Download</LeftAlignedPrimaryButton>
          </Box>
        </Box>
    </>
  )
}

PilotagePdfDownload.propTypes = {
  pilotageUuid: PropTypes.string,
}

export default PilotagePdfDownload
