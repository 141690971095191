import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { IconButton, Typography, Tooltip, Box, TablePagination } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { PortShape } from 'src/utils/types'
import Fieldset from 'src/components/organisms/Fieldset'
import {
  getListFilteredBySelectedPort,
  getListFilteredByPort,
  constraintListSelector,
} from 'src/store/constraint/selectors'
import {
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from 'src/constants/settings'
import {
  getConstraintList,
  deleteConstraint,
} from 'src/store/constraint/actions'
import { EditIcon } from 'src/components/atoms/Icons'
import EmptyState from 'src/components/atoms/EmptyState'
import DataTable from 'src/components/molecules/DataTable'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { addToast } from 'src/store/toast/actions'
import {
  TOAST_VARIANT_SUCCESS,
  TOAST_VARIANT_ERROR,
} from 'src/containers/Toast'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import { formatLatOrLong } from 'src/utils/formatters'
import store from 'src/store/stations'
import { compareName } from 'src/utils/sorting'
import { WaypointFormDialog } from './../WaypointFormDialog/WaypointFormDialog'
import { tideRateStationByIdSelector } from 'src/store/tideRateStations/selectors'
import useFeatureFlags from './../../../hooks/useFeatureFlags'

const EntityName = ({ uuid, selector }) => {
  const entity = useSelector(selector(uuid))
  return (
    <span>
      {entity ? entity.name : ''}
      {!entity && uuid && (
        <Tooltip title="Deleted">
          <Typography variant="caption" color="error">
            N/A
          </Typography>
        </Tooltip>
      )}
    </span>
  )
}
EntityName.propTypes = {
  uuid: PropTypes.string,
  selector: PropTypes.func,
}

const getColumnDefinitions = ({ onRemove, onEdit, showTideRateStation }) => {
  return [
    {
      field: 'name',
      title: 'Name',
    },
    {
      field: 'location',
      title: 'Latitude',
      labelFunction: (location) => location ? formatLatOrLong(location.latitude) : ''
    },
    {
      field: 'location',
      title: 'Longitude',
      labelFunction: (location) => location ? formatLatOrLong(location.longitude) : ''
    },
    showTideRateStation &&
    {
      field: 'tideRateStation',
      title: 'Tide Rate Station',
      // eslint-disable-next-line react/display-name
      labelFunction: (tideRateStation) => (
        <EntityName
          selector={tideRateStationByIdSelector}
          uuid={tideRateStation && tideRateStation.uuid}
        />
      ),
    },
    {
      field: 'uuid',
      title: ' ',
      // eslint-disable-next-line react/display-name
      labelFunction: (uuid, item) => (
        <div style={{float: 'right'}}>
          <Tooltip title={`Edit ${item.name} waypoint`}>
            <IconButton onClick={() => onEdit(item)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={`Remove ${item.name} waypoint`}>
            <IconButton onClick={() => onRemove(uuid)}>
              <HighlightOffIcon size={16} />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ].filter(n => n)
}

export const WaypointList = ({ port, disabled, disableFilterByPort }) => {

  const { getFlag } = useFeatureFlags()
  const flagName = 'tide_rate_source'
  const tideRateSource = getFlag(flagName) || 'config'

  const dispatch = useDispatch()
  // the constraint list is always filtered by port
  // defined in the props or by the global selected port
  const waypointsWithContraints = useSelector(
    disableFilterByPort
      ? constraintListSelector
      : port
        ? getListFilteredByPort(port.uuid)
        : getListFilteredBySelectedPort
  )

  let waypoints = []
  if (waypointsWithContraints) {
    waypoints = waypointsWithContraints.filter(constraint => constraint.isWaypoint)
  }

  useEffect(() => {
    dispatch(store.actions.getList())
  }, [dispatch])

  const [selectedWaypoint, setSelectedWaypoint] = useState(null)
  const [selectedWaypointToRemove, setSelectedWaypointToRemove] = useState(
    null
  )

  const handleAddNewWaypoint = () => {
    setSelectedWaypoint({
      name: '',
    })
  }

  const handleOnEdit = (item) => setSelectedWaypoint(item)
  const handleOnRemove = useCallback(
    (uuid) =>
      setSelectedWaypointToRemove(
        waypoints && waypoints.find((item) => item.uuid === uuid)
      ),
    [waypoints]
  )
  const handleCancelRemove = () => setSelectedWaypointToRemove(null)
  const handleConfirmRemove = () => {
    dispatch(deleteConstraint(selectedWaypointToRemove)).then(
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_SUCCESS,
            message: getToastMessage(
              TOAST_MESSAGES.WAYPOINT_DELETE_SUCCESS,
              selectedWaypointToRemove
            ),
          })
        )
        setSelectedWaypointToRemove(null)
      },
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_ERROR,
            message: getToastMessage(
              TOAST_MESSAGES.WAYPOINT_DELETE_ERROR,
              selectedWaypointToRemove
            ),
          })
        )
        setSelectedWaypointToRemove(null)
      }
    )
  }

  useEffect(() => {
    dispatch(getConstraintList())
  }, [dispatch])

  const handleFormDialogCloseRequest = () => setSelectedWaypoint(null)

  // Pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)

  const handlePaginationChangePage = (event, selectedPage) =>
    setPage(selectedPage)

  const handlePaginationChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(value)
    setPage(0)
  }

  const sortedWaypoints = waypoints ? waypoints.sort(compareName) : []
  const currentPageWaypoints = sortedWaypoints.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Waypoints"
        description="Define your waypoints"
        buttonContents="Add Waypoint"
        clickHandler={handleAddNewWaypoint}
        hasButton
      >
        {sortedWaypoints && sortedWaypoints.length > 0 && (
          <>
            <Box mb={1}>
              <DataTable
                columns={getColumnDefinitions({
                  onRemove: handleOnRemove,
                  onEdit: handleOnEdit,
                  onNew: handleAddNewWaypoint,
                  showTideRateStation:
                  tideRateSource === 'csv' &&
                  sortedWaypoints &&
                  sortedWaypoints.find(({ tideRateStation }) => !!tideRateStation)
                })}
                dataProvider={currentPageWaypoints}
              />
            </Box>

            <TablePagination
              component={Box}
              count={sortedWaypoints.length}
              page={page}
              rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Waypoints per page:"
              onChangePage={handlePaginationChangePage}
              onChangeRowsPerPage={handlePaginationChangeRowsPerPage}
            />
          </>
        )}

        {(!sortedWaypoints || !sortedWaypoints.length) && (
          <EmptyState message="No waypoints yet." />
        )}

      </Fieldset>
      {selectedWaypoint && (
        <WaypointFormDialog
          port={port}
          constraint={selectedWaypoint}
          onCloseRequest={handleFormDialogCloseRequest}
          open={!!selectedWaypoint}
        />
      )}
      {selectedWaypointToRemove && (
        <ConfirmDialog
          open={!!selectedWaypointToRemove}
          onCancel={handleCancelRemove}
          onConfirm={handleConfirmRemove}
          title={`Remove waypoint: ${selectedWaypointToRemove.name}`}
        >
          Are you sure you want to remove this waypoint?
          <br />
          {selectedWaypointToRemove.name}
        </ConfirmDialog>
      )}
    </>
  )
}

WaypointList.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
}

export default WaypointList
