import React from 'react'
import PropTypes from 'prop-types'
import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'
import Divider from 'src/components/atoms/Divider'
import PilotageApiKeyGenerator from 'src/components/organisms/PilotageApiKeyGenerator'
import OmcConfig from 'src/components/organisms/OmcConfig'
import { SsoConfig } from 'src/components/organisms/SsoConfig/SsoConfig'

export const Integrations = ({ port }) => {
  const portUuid = port && port.uuid

  return (
    <SettingsTemplate title="Integrations" port={port}>
      <PilotageApiKeyGenerator port={port} disabled={!portUuid} />
      {
        !process.env.REACT_APP_OMC_DISABLE &&
        <>
         <Divider />
          <OmcConfig port={port} disabled={!portUuid} />
        </>
      }
      <Divider />
      <SsoConfig port={port} disabled={!portUuid} />
    </SettingsTemplate>
  )
}

Integrations.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default Integrations
