import React from 'react'

const LocationsIcon = () => (
  <svg
    width="12"
    height="18"
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 4.16667C8 5.81431 5.94938 9.11832 4.78463 10.8621C4.40621 11.4287 3.59378 11.4287 3.21537 10.8621C2.05061 9.11832 0 5.81431 0 4.16667C0 1.86548 1.79086 0 4 0C6.20914 0 8 1.86548 8 4.16667Z"
      fill="#B0B0B1"
    />
    <circle cx="4" cy="4" r="2" fill="white" />
  </svg>
)

export default LocationsIcon
