import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { vesselFieldDisplayConfigSelector } from 'src/store/vesselFieldDisplay/selectors'
import { vesselFieldDisplayGet, vesselFieldDisplaySet } from 'src/store/vesselFieldDisplay/actions'
import { VesselFieldDisplayConfig } from 'src/utils/sauce/sauce-api-preferences/VesselFieldDisplayConfig'
import { Box } from '@material-ui/core'
import deepEqual from 'dequal'
import Fieldset from 'src/components/organisms/Fieldset'
import Typography from '@material-ui/core/Typography'
import { Switch, Grid } from '@material-ui/core'
import {
    vesselFieldsFixed,
    vesselFieldsEditable,
    vesselMetaDataFieldsFixed,
    vesselMetaDataFieldsEditable
} from 'src/utils/sauce/sauce-api-preferences/VesselFieldDisplayConfig'
import Preloader from 'src/components/atoms/Preloader'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import without from 'src/utils/without'
import ErrorMessage from 'src/components/atoms/ErrorMessage'

const ActionsBox = styled.div`
  display: flex;
  margin-top: 10px;
  & button {
    margin-right: 0px;
  }
`

const fieldsFixed = [...vesselFieldsFixed, ...vesselMetaDataFieldsFixed].map(field => ({ type: 'fixed', field }))
const fieldsEditable = [...vesselFieldsEditable, ...vesselMetaDataFieldsEditable].map(field => ({ type: 'editable', field}))
const fields = [...fieldsFixed, ...fieldsEditable].sort((a, b) => {
    const A = a.field.label.toLowerCase()
    const B = b.field.label.toLowerCase() 
    return (
        A < B ? -1 :
        A > B ? 1 :
        0
    )
})

interface VesselFieldDisplayProps {
    port?: { uuid: string }
    disabled: boolean
}

export const VesselFieldDisplay: React.FC<VesselFieldDisplayProps> = ({ port, disabled }) => {

    const dispatch = useDispatch()
    const { loading, updating, config } = useSelector(vesselFieldDisplayConfigSelector)
    const [editing, setEditing] = useState<VesselFieldDisplayConfig | undefined>(undefined)

    const dirty = editing && !deepEqual(editing, config)

    useEffect(() => {
        if (port && port.uuid) {
            dispatch(vesselFieldDisplayGet(port.uuid))
        }
    }, [dispatch, port && port.uuid])

    useEffect(() => {
        if (config) {
            setEditing(config)
        }
    }, [config])

    const handleSave = async () => {
        if (port && editing) {
            await dispatch(vesselFieldDisplaySet(port.uuid, editing))
        }
    }

    // either air draft or height must be defined
    const validationError = useMemo(() => {
        if (!editing) { return }
      
        if (
            (editing.disabled_master_view.air_draft && editing.disabled_master_view.height) ||
            (editing.disabled_pilot_app.air_draft && editing.disabled_pilot_app.height)
        ) {
            return 'Either Air Draft or HOA must be enabled'
        }
    }, [editing])

    const canSave = !disabled && !validationError

    const handleChange = (prop: keyof VesselFieldDisplayConfig, key: string) => (disabled: boolean) => {
        if (editing) {
            setEditing({
                ...editing,
                [prop]: disabled 
                    ? { ...editing[prop], [key]: disabled } 
                    : without(editing[prop], key)
            })
        }
    }

    return (
        <Fieldset
            disabled={disabled}
            title="Vessel Particulars"
            subtitle="Here you can manage what vessel information you would like 
                        to show and collect on pilot app, as well as what you would 
                        like to capture in passage plan for sharing with the master 
                        of the ship."
            description=""
            clickHandler={() => null}
            buttonContents={null}
            hasButton={false}
        >
            { loading && <Preloader /> }

            {
            editing &&
            <Box>
                <VesselFieldHeader>
                    <b>FIELD NAME</b>
                    <span>Show in Pilot App?</span>
                    <span>Export in PDF Output?</span>
                </VesselFieldHeader>

                {
                    fields.map(({ type, field: { key, label }}) =>
                        type === 'fixed' ?
                        <VesselFieldControls
                            key={key}
                            label={label}
                            disabledPilotApp={editing.disabled_pilot_app[key]}
                            disabledMasterView={editing.disabled_master_view[key]}
                            readOnly
                        /> :
                        <VesselFieldControls
                            key={key}
                            label={label}
                            disabledPilotApp={editing.disabled_pilot_app[key]}
                            disabledMasterView={editing.disabled_master_view[key]}
                            onChangeDisabledPilotApp={handleChange('disabled_pilot_app', key)}
                            onChangeDisabledMasterView={handleChange('disabled_master_view', key)}
                        />
                    )
                }
                {
                    validationError &&
                    <ErrorMessage message={validationError} active />
                }

                <ActionsBox>
                    <PrimaryButton
                        disabled={updating || !dirty || !canSave}
                        onClick={handleSave}
                    >
                        Save
                    </PrimaryButton>
                </ActionsBox>
            </Box>
            }
        </Fieldset>
    )
}

interface VesselFieldControlsProps {
    key: string
    label: string
    readOnly?: boolean
    disabledPilotApp?: boolean
    disabledMasterView?: boolean
    onChangeDisabledPilotApp?(disabled: boolean): void
    onChangeDisabledMasterView?(disabled: boolean): void
}

const VesselFieldHeader = styled.div`
    display: flex;
    align-items: center;
    background-color: #EFF2F5;
    border-radius: 3px;
    padding: 16px;
    margin-bottom: 5px;
    b {
        flex: 1;
        font-size: 12px;
        font-weight: bold;
    }
    span {
        width: 140px;
        font-size: 12px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        white-space: nowrap;
    }
`

const VesselFieldControlsContainer = styled.div`
    display: flex;
    align-items: center;
    border: 1px solid #EBEBEB;
    border-radius: 3px;
    padding: 8px 16px;
    margin-bottom: 5px;
    .label {
        flex: 1;
    }
    .toggle {
        width: 140px;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
`

const ToggleText = styled(Typography).attrs({
    color: 'primary',
  })`
    && {
      text-transform: uppercase;
      font-size: 11px;
    }
  `

const VesselFieldControls: React.FC<VesselFieldControlsProps> = ({
    key,
    label,
    disabledPilotApp,
    disabledMasterView,
    onChangeDisabledPilotApp = (() => null),
    onChangeDisabledMasterView = (() => null),
    readOnly
}) => {
    return (
        <VesselFieldControlsContainer 
            style={readOnly ? { opacity: 0.5, cursor: 'not-allowed' } : {}}
            title={readOnly ? "Fixed field - not editable" : ''}
        >
            <b className="label">{label}</b>

            <span className="toggle">
                <Switch
                    color="primary"
                    checked={!disabledPilotApp}
                    onChange={() => {
                        onChangeDisabledPilotApp(!disabledPilotApp)
                        // onChangeDisabledMasterView(!disabledMasterView) // master view follows pilot app by default
                    }}
                    readOnly={readOnly}
                />
                <ToggleText>{disabledPilotApp ? 'Disabled' : 'Enabled'}</ToggleText>
            </span>

            <span className="toggle">
                <Switch
                    color="primary"
                    checked={!disabledMasterView}
                    onChange={() => onChangeDisabledMasterView(!disabledMasterView)}
                    readOnly={readOnly}
                />
                <ToggleText>{disabledMasterView ? 'Disabled' : 'Enabled'}</ToggleText>
            </span>

        </VesselFieldControlsContainer>
    )
}

export default VesselFieldDisplay
