import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ active, theme }) =>
    active ? theme.palette.primary.main : theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.pxToRem(14)};
  text-transform: uppercase;
  font-weight: 600;
  cursor: ${({ sortable }) => (sortable ? 'pointer' : 'auto')};
  justify-content: ${({ justify }) => justify};
`
const StyledIcon = styled.span`
  & > svg {
    color: ${({ active, theme }) =>
      active ? theme.palette.primary.main : theme.palette.text.secondary};
    transform: scaleY(
      ${({ direction }) => (direction === 'DESC' ? '1' : '-1')}
    );
    transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    visibility: ${({ sortable, active }) =>
      sortable && active ? 'visible' : 'hidden'};
  }
`

function TableSortLabel(props) {
  const {
    children,
    onClick,
    value,
    sortable,
    active,
    direction,
    justify,
  } = props
  const memorizedHandler = useCallback(() => {
    if (sortable && onClick) {
      onClick(value)
    }
  }, [sortable, onClick, value])

  return (
    <StyledWrapper
      sortable={sortable}
      active={active}
      onClick={memorizedHandler}
      justify={justify}
    >
      <span>{children}</span>
      {sortable && (
        <StyledIcon sortable={sortable} active={active} direction={direction}>
          <KeyboardArrowDown />
        </StyledIcon>
      )}
    </StyledWrapper>
  )
}

TableSortLabel.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  justify: PropTypes.string,
  sortable: PropTypes.bool,
  value: PropTypes.any,
  direction: PropTypes.oneOf(['ASC', 'DESC']),
}

TableSortLabel.defaultProps = {
  direction: 'DESC',
}

export default React.memo(TableSortLabel)
