import { USER_ROLES } from 'src/constants/user'

export const HOME = 'home'

export const AUTH = 'auth'
export const LOGINSSO = 'sso'
export const LOGOUT = 'logout'
export const AUTH_LOGIN = 'auth.login'
export const AUTH_ACCEPT_INVITATION = 'auth.accept_invitation'
export const AUTH_FORGOT_PASSWORD = 'auth.forgot_password'
export const AUTH_REFRESH_SESSION = 'auth.refresh'
export const AUTH_RESET_PASSWORD = 'auth.reset'

export const PILOTS = 'pilot'
export const PILOTS_SCOPED = 'pilot.scoped'
export const PILOTS_CREATE = 'pilot.scoped.create'
export const PILOTS_EDIT = 'pilot.scoped.edit'

export const SETTINGS = 'settings'
export const SETTINGS_SCOPED = 'settings.scoped'
export const SETTINGS_CREATE = 'settings.create'

export const SETTINGS_PORT = 'settings.scoped.port'
export const SETTINGS_PORT_CHART = 'settings.scoped.port.chart'
export const SETTINGS_PORT_CHART_ADD = 'settings.scoped.port.chart.add'
export const SETTINGS_PORT_CHART_EDIT = 'settings.scoped.port.chart.edit'

export const SETTINGS_PILOTAGE = 'settings.scoped.pilotage'

export const ORGANISATIONS = 'organisations'
export const ORGANISATION_CREATE = 'organisations.create'
export const ORGANISATION_EDIT = 'organisations.edit'
export const ORGANISATION_DELETE = 'organisations.delete'
export const ORGANISATION_PORT = 'organisations.port'
export const ORGANISATION_PORT_CREATE = 'organisations.port.create'
export const ORGANISATION_PORT_EDIT = 'organisations.port.edit'
export const ORGANISATION_PORT_INVITE = 'organisations.port.invite'
export const ORGANISATION_PORT_INVITE_CREATE = 'organisations.port.invite.create'
export const ORGANISATION_PORT_INVITE_EDIT = 'organisations.port.invite.edit'
export const ORGANISATION_PORT_INVITE_RESET = 'organisations.port.invite.reset'
export const ORGANISATION_PORT_INVITE_DELETE = 'organisations.port.invite.delete'
export const ORGANISATION_PORT_ADMIN = 'organisations.port.admin'
export const ORGANISATION_PORT_ADMIN_CREATE = 'organisations.port.admin.create'
export const ORGANISATION_PORT_ADMIN_EDIT = 'organisations.port.admin.edit'

export const SETTINGS_PORT_IDENTIFICATION = 'settings.scoped.identification'
export const SETTINGS_PILOTAGES = 'settings.scoped.pilotages'
export const SETTINGS_VESSELS = 'settings.scoped.vessels'
export const SETTINGS_LOCATIONS = 'settings.scoped.locations'
export const SETTINGS_ENVIRONMENT = 'settings.scoped.environmentSettings'
export const SETTINGS_FLOW = 'settings.scoped.environmentFlow'
export const SETTINGS_PASSAGE_PLANNER = 'settings.scoped.passagePlanner'
export const SETTINGS_CHECKLISTS = 'settings.scoped.checklistSettings'
export const SETTINGS_GUIDELINES_TERMS = 'settings.scoped.checklists'
export const SETTINGS_INTEGRATIONS = 'settings.scoped.integrations'

export const PILOTAGES_PDF = 'pilotages_pdf'

const routes = [
  { name: HOME, path: '/' },

  {
    name: AUTH,
    forwardTo: '/login',
    path: '/auth',
  },
  { name: LOGINSSO, path: '/sso?code' },
  { name: LOGOUT, path: '/logout' },
  { name: AUTH_LOGIN, path: '~/login' },
  { name: AUTH_ACCEPT_INVITATION, path: '~/accept-invitation?token' },
  { name: AUTH_FORGOT_PASSWORD, path: '~/forgot-password' },
  { name: AUTH_RESET_PASSWORD, path: '~/reset?token' },
  { name: AUTH_REFRESH_SESSION, path: '~/refresh' },

  { name: PILOTAGES_PDF, path: '/pilotages/pdf/:pilotageUuid', roles: [USER_ROLES.ADMIN] },

  { name: PILOTS, path: '/pilots', roles: [USER_ROLES.ADMIN] },
  { name: PILOTS_SCOPED, path: '/:portUuid', roles: [USER_ROLES.ADMIN] },
  { name: PILOTS_CREATE, path: '/create', roles: [USER_ROLES.ADMIN] },
  {
    name: PILOTS_EDIT,
    path: '/edit/:invitationUuid',
    roles: [USER_ROLES.ADMIN],
  },

  { name: SETTINGS, path: '/settings', roles: [USER_ROLES.ADMIN] },
  { name: SETTINGS_CREATE, path: '/create', roles: [USER_ROLES.ADMIN] },
  { name: SETTINGS_SCOPED, path: '/:portUuid', roles: [USER_ROLES.ADMIN] },

  { name: SETTINGS_PORT, path: '/port', roles: [USER_ROLES.ADMIN] },
  {
    name: SETTINGS_PORT_IDENTIFICATION,
    path: '/port-identification',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_PILOTAGES,
    path: '/pilotages',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_VESSELS,
    path: '/vessels',
    roles: [USER_ROLES.ADMIN],
  },
  { name: SETTINGS_LOCATIONS, path: '/locations', roles: [USER_ROLES.ADMIN] },
  {
    name: SETTINGS_ENVIRONMENT,
    path: '/environment',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_FLOW,
    path: '/flow',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_PASSAGE_PLANNER,
    path: '/passage-planner',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_CHECKLISTS,
    path: '/checklist',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_GUIDELINES_TERMS,
    path: '/guidelines-terms',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_INTEGRATIONS,
    path: '/integrations',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_PORT_CHART,
    path: '/chart',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_PORT_CHART_ADD,
    path: '/chart/add',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_PORT_CHART_EDIT,
    path: '/:chartUuid',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: SETTINGS_PILOTAGE,
    path: '/pilotage',
    roles: [USER_ROLES.ADMIN],
  },
  {
    name: ORGANISATIONS,
    path: '/organisations',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_CREATE,
    path: '/create',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_DELETE,
    path: '/:orgUuid/delete',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_EDIT,
    path: '/:orgUuid',
    roles: [USER_ROLES.SUPER_ADMIN]
  },
  {
    name: ORGANISATION_PORT,
    path: '/:orgUuid/port',
    roles: [USER_ROLES.SUPER_ADMIN]
  },
  {
    name: ORGANISATION_PORT_CREATE,
    path: '/create',
    roles: [USER_ROLES.SUPER_ADMIN]
  },
  {
    name: ORGANISATION_PORT_EDIT,
    path: '/:portUuid/edit',
    roles: [USER_ROLES.SUPER_ADMIN]
  },
  {
    name: ORGANISATION_PORT_INVITE,
    path: '/:portUuid/invitation',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_PORT_INVITE_CREATE,
    path: '/create',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_PORT_INVITE_RESET,
    path: '/:invitationUuid/reset',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_PORT_INVITE_DELETE,
    path: '/:invitationUuid/delete',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_PORT_INVITE_EDIT,
    path: '/:invitationUuid',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_PORT_ADMIN,
    path: '/:portUuid/admin',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_PORT_ADMIN_CREATE,
    path: '/create',
    roles: [USER_ROLES.SUPER_ADMIN],
  },
  {
    name: ORGANISATION_PORT_ADMIN_EDIT,
    path: '/:userUuid',
    roles: [USER_ROLES.SUPER_ADMIN],
  }
]

// Public routes bypass the authentication middleware
export const publicRoutes = [
  LOGINSSO,
  LOGOUT,
  AUTH_LOGIN,
  AUTH_ACCEPT_INVITATION,
  AUTH_FORGOT_PASSWORD,
  AUTH_RESET_PASSWORD,
  AUTH_REFRESH_SESSION,
]

export const isPrivateRoute = (routeName) =>
  publicRoutes.indexOf(routeName) === -1

export default routes
