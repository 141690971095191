import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { EditIcon } from 'src/components/atoms/Icons'
import Popover from '@material-ui/core/Popover';
import { errorByIdSelector } from 'src/store/extra/selectors';

const EditInlineForm = styled.form`
    padding: 20px;
    input {
        padding: 5px 10px;
        border: 1px solid #ebebeb;
        border-radius: 2px;
    }
    input:focus {
        border: 1px solid ${({ theme }) => theme.palette.primary.main};
        outline-color: ${({ theme }) => theme.palette.primary.main};
    }
    button {
        margin-left: 10px;
        border: none;
        border-radius: 2px;
        padding: 5px 15px;
        color: #fff;
        background: ${({ theme }) => theme.palette.primary.main};
        font-weight: bold;
        cursor: pointer;
    }
    button:disabled {
        background: #999;
        cursor: not-allowed;
    }
    .validation-error {
        display: block;
        margin: 5px 0px -10px 0px;
        color: red;
        font-size: 12px;
    }
`

const FieldContainer = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
        margin-right: 5px;
        max-width: 150px;
        display: inline-block;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
`

interface EditInlineProps {
    onChange(value: string): void
    value: string
    validate?: (value: string) => string
}

const EditInline: React.FC<EditInlineProps> = ({ onChange, value, validate }) => {

    const [nextValue, setNextValue] = useState(value)
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'edit-inline-popover' : undefined
    const input = useRef<HTMLInputElement | null>(null)
    const error = validate && validate(nextValue)

    const handleChange = () => {
        if (value !== nextValue && !error) {
            onChange(nextValue)
        }
        setAnchorEl(null)
    }

    useEffect(() => { setNextValue(value) }, [value])

    return (
        <> 
            <FieldContainer 
                onClick={(event) => {
                    setAnchorEl(event.currentTarget)
                    // set caret to end
                    if (input.current) {
                        input.current.selectionStart = input.current.value.length
                    }
                }}
            >
                <span title={value}>{ value || '--' }</span> <EditIcon  />
            </FieldContainer>

            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <EditInlineForm onSubmit={(event) => {
                    event.preventDefault()
                    handleChange()
                }}>
                    <div>
                        <input 
                            autoFocus
                            ref={input}
                            value={nextValue} 
                            onChange={({ target }) => setNextValue(target.value)}
                        />
                        
                        <button type="submit" disabled={value === nextValue || !!error}>Save</button>
                    </div>
                    {error && <div className="validation-error">{error}</div>}
                
                </EditInlineForm>
            </Popover>
        </>
    )
  }

  export default React.memo(EditInline)
