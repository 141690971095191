import { VesselFieldDisplayConfigState, VesselFieldDisplayConfigAction } from './types'

export const initialState: VesselFieldDisplayConfigState = {
  config: undefined,
  uuid: undefined,
  loading: false,
  updating: false,
}

export function VesselFieldDisplayConfigReducer(
  state = initialState,
  action: VesselFieldDisplayConfigAction
) {
  switch (action.type) {
    case 'VESSEL_FIELD_DISPLAY_CONFIG_SET_LOADING':
      return { ...state, loading: action.loading }
    case 'VESSEL_FIELD_DISPLAY_CONFIG_SET_UPDATING':
      return { ...state, updating: action.updating }
    case 'VESSEL_FIELD_DISPLAY_CONFIG_SET_ERROR':
      return { ...state, error: action.error }
    case 'VESSEL_FIELD_DISPLAY_CONFIG_SET':
      return {
        ...state,
        config: action.config,
        uuid: action.uuid,
        error: undefined,
        loading: false,
      }
    default:
      return state
  }
}

export default VesselFieldDisplayConfigReducer
