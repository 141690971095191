import {
  PILOTAGE_EXPORT_IN_PROGRESS,
  PILOTAGE_EXPORT_ERROR,
  PILOTAGE_EXPORT_SUCCESS,
} from 'src/store/pilotagesExport/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function pilotagesExportReducer (state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case PILOTAGE_EXPORT_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
        status: type,
      }
    case PILOTAGE_EXPORT_ERROR:
      return {
        ...state,
        error: null,
        isLoading: false,
        status: type,
      }
    case PILOTAGE_EXPORT_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        error: null,
        status: type,
      }
    default:
      return state
  }
}

export default pilotagesExportReducer
