import React from 'react'
import styled from 'styled-components'

import Box from '@material-ui/core/Box'

import { ImportResult } from './types'

const LineLabel = styled.div`
  color: #515151;
  font-weight: bold;
`

const ErrorList = styled.ul`
  list-style-type: none;
  > li {
    display: flex;
    text-indent: 1em;
    &::before {
      content: '-';
      display: inline-block;
      margin-right: 0.5em;
    }
  }
`

interface Props {
  result: ImportResult
}

export const ImportResultItem = ({ result }: Props) => {
  const { index, errors, error, IMO } = result
  const errorFields = (errors && Object.values(errors)) || []
  const imoLabel = IMO ? `(IMO: ${IMO})` : ``
  return error || errorFields.length > 0 ? (
    <Box pb={1}>
      <LineLabel>Record #{index + 1} {imoLabel}</LineLabel>
      <ErrorList>
        {error && <li>Server error</li>}
        {errorFields.map((error, index) => {
          return <li key={index}>{error.message}</li>
        })}
      </ErrorList>
    </Box>
  ) : null
}
