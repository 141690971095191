import { Box, Paper } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import React, { useEffect, useState } from 'react'
import { useRoute } from 'react-router5'
import { AppLogoLink } from 'src/components/atoms/AppLogo'
import AppVersion from 'src/components/atoms/AppVersion'
import Preloader from 'src/components/atoms/Preloader'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import useMsal from 'src/hooks/useMsal'
import { SSO_AUTO_LOGIN_ENABLED, SSO_ENABLE_CONSOLE_LOG } from 'src/utils/ssoConstants'
import styled from 'styled-components'
import useSso from '../../hooks/useSso'
import useSsoLogin from '../../hooks/useSsoLogin'
import { SSO_ENABLE_ERROR_BORDER } from './../../utils/ssoConstants'

const LoginPaneSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
  justify-content: ${({ align }) => align};
  align-items: center;
  width: 100%;
  position: relative;

  & input {
    font-size: 16px;
  }
`

const AuthPaper = styled(Paper)`
  && {
    padding: ${({ theme }) => theme.spacing(1)}px;
    padding-bottom: ${({ theme, progress }) =>
    progress === 'true' ? theme.spacing(0.5) : theme.spacing(1)}px;
    position: relative;
    width: 600px;
    opacity: ${({ progress }) => (progress === 'true' ? 0.9 : 1)};
  }
`

const AuthHeader = styled.section`
  padding: ${({ theme }) => theme.spacing(12)}px;
  text-align: center;
`

// NOTE: we cannot use redux in this page bcos the store will be cleared upon logout
export const LoginSso = () => {
  const { route } = useRoute()
  const { code } = route.params
  const isLoggingOut = code === 'logout'

  // state to wrap progress for all 3 dependent hooks
  const [ ssoDone, setSsoDone ] = useState(false)

  // Hook to handle initial request to BE to get SSO config
  const { ssoState, handleSsoRequest } = useSso()

  // Hook to handle MSAL lib to perform SSO with Azure AD
  const {
    instance,
    msalInProgress,
    accounts,
    redirectError,
    logoutError,
    hasInteractionRequiredError,
    handleLogin,
    handleLogout,
    msalLoginIsLoading,
    msalLogoutIsLoading,
  } = useMsal(ssoState.data)

  // Hook to provide function to get access token from MSAL followed by BE SSO login
  const { loginState, getAccessTokenAndLoginToEmpx } = useSsoLogin()

  const getAccessTokenAndLoginToEmpxAsync = async (instance, ssoState, accounts) => {
    await getAccessTokenAndLoginToEmpx(instance, ssoState.data, accounts[0])
    // If we set done yet, there will be one final flicker when we transition to pilotages page
    // To prevent this, we just leave it at loading screen, then we transition smoothly to pilotages page.
    // setSsoDone(true)
  }

  // Core SSO logic here.
  useEffect(() => {
    if (SSO_ENABLE_CONSOLE_LOG) {
      console.log(`msalInProgress [${msalInProgress}] loginState.isLoading [${loginState.isLoading}] ` +
      `accounts.length [${accounts.length}] ssoState.data [${JSON.stringify(ssoState.data)}]`)
    }
    // wait for SMAL to settle
    if (msalInProgress) {
      return
    }
    if (isLoggingOut) {
      // process logout
      if (logoutError) {
        return
      }
      handleLogout()
    } else {
      // process login
      if (accounts.length === 0) {
        // ready to log in
        if (SSO_AUTO_LOGIN_ENABLED) {
          // check previous attempt has no error
          if (redirectError) {
            return
          }
          handleLogin()
        } else {
          setSsoDone(true)
        }
      } else {
        if (loginState.isLoading || loginState.error) {
          return
        }
        // If already logged in, can return
        // NOTE: each progress state update from MSAL lib will trigger a new instance of account
        // this acts as a flag to prevent re-entry. We can use a deep compare of the account
        // here, but as we are not using MSAL for the whole app (only using it to handover to eMPX),
        // just checking presence of account is enough.
        if (loginState.account) {
          return
        }
        getAccessTokenAndLoginToEmpxAsync(instance, ssoState, accounts)
      }
    }
  }, [ssoState.data,
    msalInProgress, accounts, isLoggingOut,
    getAccessTokenAndLoginToEmpxAsync,
    handleLogin,
    handleLogout,
    ssoState,
    logoutError,
    redirectError,
    loginState.account,
    loginState.isLoading, loginState.error])

  let anySsoError = false
  // Button styles and disabled states are derived from the 3 hooks: useSso, useMsal, useSsoLogin
  // We can potentially move these into their own hooks, but will keep them all in one place for now,
  // as there are certain dependencies between them.
  let ssoButtonStyle = { ...DEFAULT_BUTTON_STYLE }
  if (SSO_ENABLE_ERROR_BORDER) {
    ssoButtonStyle = getButtonStyleOnError(ssoState.error)
  }
  let ssoButtonDisabled = ssoState.isLoading
  // if we got config, wait until MSAL is initialised
  if (ssoState.data) {
    ssoButtonDisabled = ssoButtonDisabled || msalInProgress
  }
  let ssoButtonText = isLoggingOut ? 'Sign Out' : 'Sign In'
  if (ssoState.error) {
    ssoButtonText = 'Try Again'
    anySsoError = true
  }

  let loginButtonStyle = { ...DEFAULT_BUTTON_STYLE }
  if (SSO_ENABLE_ERROR_BORDER) {
    loginButtonStyle = getButtonStyleOnError(loginState.error || redirectError)
  }
  const loginButtonDisabled = loginState.isLoading || msalInProgress || msalLoginIsLoading
  let loginButtonText = 'Sign In'
  if (loginState.error || redirectError) {
    loginButtonText = 'Try Again'
    anySsoError = true
  }

  let logoutButtonStyle = { ...DEFAULT_BUTTON_STYLE }
  if (SSO_ENABLE_ERROR_BORDER) {
    logoutButtonStyle = getButtonStyleOnError(logoutError || loginState.tokenError || loginState.roleError || hasInteractionRequiredError)
  }
  const logoutButtonDisabled = msalInProgress || msalLogoutIsLoading
  let logoutButtonText = 'Sign Out'
  if (logoutError || loginState.tokenError || loginState.roleError || hasInteractionRequiredError) {
    logoutButtonText = 'Try Another Account'
    anySsoError = true
  }

  // show logout when we are logging out, or when we have errors which requires a logout to recover.
  // to simulate hasInteractionRequiredError:
  // - log out of eMPX
  // - go to http://login.microsoftonline.com/ and sign in as user belonging to tenant, BUT not assigned
  //   to eMPX
  // - for this case, attempting to login again will just show the same error, so will need to logout
  //   and login as a correct user
  const showLogoutButton = isLoggingOut || loginState.tokenError || loginState.roleError || hasInteractionRequiredError

  let hideLoading = ssoDone || anySsoError
  if (!hideLoading) {
    return <Preloader></Preloader>
  }
  return (
    <>
      <AuthPaper>
        <Box pl={8} pr={8} pb={8}>
          <AuthHeader>
            <AppLogoLink size={200} />
          </AuthHeader>
          <Box pb={1}>
            <LoginPaneSection align="center">
              {!ssoState.data ? (
                <>
                  <PrimaryButton
                    onClick={handleSsoRequest}
                    disabled={ssoButtonDisabled}
                    style={ssoButtonStyle}>
                    {ssoButtonText}
                  </PrimaryButton>
                  <div style={progressDivStyle}>
                    {ssoButtonDisabled && <LinearProgress />}
                  </div>
                </>
              ) : (
                <>
                {showLogoutButton ? (
                  <>
                  <PrimaryButton
                    onClick={handleLogout}
                    disabled={logoutButtonDisabled}
                    style={logoutButtonStyle}>
                    {logoutButtonText}
                  </PrimaryButton>
                  <div style={progressDivStyle}>
                    {logoutButtonDisabled && <LinearProgress />}
                  </div>
                  </>
                ) : (
                  <>
                  <PrimaryButton
                    onClick={handleLogin}
                    disabled={loginButtonDisabled}
                    style={loginButtonStyle}>
                    {loginButtonText}
                  </PrimaryButton>
                  <div style={progressDivStyle}>
                    {loginButtonDisabled && <LinearProgress />}
                  </div>
                  </>
                )}
                </>
              )}
              <AppVersion mt={2} />
            </LoginPaneSection>
          </Box>
        </Box>
      </AuthPaper>
    </>
  )
}

const DEFAULT_BUTTON_STYLE = { marginLeft: 0 }

const getButtonStyleOnError = (error) => {
  let buttonStyle = { ...DEFAULT_BUTTON_STYLE }
  if (error) {
    buttonStyle.border = '2px solid red'
  }
  return buttonStyle
}

const progressDivStyle = { width: '100%', marginTop: 30, minHeight: 30 }

export default LoginSso
