import React from 'react'
import PropTypes from 'prop-types'
import useTheme from '@material-ui/core/styles/useTheme'

export const CheckIcon = ({ size, isSuccessColor, ...props }) => {
  const theme = useTheme()
  return (
    <svg
      viewBox="0 0 26 26"
      width={size}
      height={size}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        fill={isSuccessColor ? theme.palette.status.success : '#1991EB'}
        cx="13"
        cy="13"
        r="13"
      />
      <path
        fill="#fff"
        d="M12.089 17.892a1.623 1.623 0 01-2.298 0l-2.496-2.495a1.626 1.626 0 012.298-2.3l1.347 1.348 5.466-5.466a1.623 1.623 0 012.298 0 1.623 1.623 0 010 2.297l-6.615 6.616z"
      />
    </svg>
  )
}

CheckIcon.propTypes = {
  size: PropTypes.number,
  isSuccessColor: PropTypes.bool,
}

CheckIcon.defaultProps = {
  size: 26,
}

export default CheckIcon
