import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'react-router5'

import src from 'src/assets/images/logo-empx.png'
import { HOME } from 'src/router/routes'

const IMG_WIDTH = 240
const IMG_HEIGHT = 47.5
const IMG_RATE = IMG_HEIGHT / IMG_WIDTH

const LogoImg = styled.img`
  width: ${IMG_WIDTH}px;
  height: ${IMG_HEIGHT}px;
  top: ${({ theme }) => theme.spacing(2)}px;
`

const LogoAnchor = styled(Link)`
  display: inline-block;
  background: url(${src}) center center no-repeat;
  background-size: contain;
`

export const AppLogoLink = ({ size = 120, routeName, ...props }) => {
  const StyledLogoLink = styled(LogoAnchor)`
    width: ${size}px;
    height: ${size * IMG_RATE}px;
  `
  return (
    <StyledLogoLink routeName={routeName || HOME} {...props}>
      {' '}
    </StyledLogoLink>
  )
}
AppLogoLink.propTypes = {
  size: PropTypes.number,
  routeName: PropTypes.string,
}

export const AppLogo = (props) => <LogoImg src={src} {...props} />

export default AppLogo
