import { createSelector } from 'reselect'
import {
  createGetListFilteredBySelectedPortSelector,
  createGetListFilteredByPortSelector,
} from 'src/utils/store'

export const routeStateSelector = (state) => state.route

export const routeListSelector = createSelector(
  routeStateSelector,
  (state) => state.data && state.data.map((uuid) => state.byId[uuid])
)

export const getFilteredListByPort = createGetListFilteredByPortSelector(
  routeListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  routeListSelector
)

export const routeByIndexSelector = (index) =>
  createSelector(
    routeStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const routeByIdSelector = (uuid) =>
  createSelector(
    routeStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalRouteSelector = createSelector(
  routeStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    routeStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    routeStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
