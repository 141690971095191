import React from 'react'
import PropTypes from 'prop-types'
import RouteList from 'src/components/organisms/RouteList'
import { PortShape } from 'src/utils/types'
import SettingsTemplate from 'src/components/organisms/SettingsTemplate'

export const PassagePlanner = ({ port }) => {
  return (
    <SettingsTemplate title="Passage Planner" port={port}>
      <RouteList port={port} />{' '}
    </SettingsTemplate>
  )
}

PassagePlanner.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default PassagePlanner
