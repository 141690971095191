import styled from 'styled-components'

export const InvitationStatus = styled.div`
  align-items: center;
  color: ${({ theme, verified }) =>
    verified ? theme.palette.success.main : theme.palette.warning.main};
  display: flex;
  &::before {
    background-color: ${({ theme, verified }) =>
      verified ? theme.palette.success.main : theme.palette.warning.main};
    border-radius: 15px;
    content: ' ';
    display: inline-block;
    height: 15px;
    margin-right: 0.5em;
    width: 15px;
  }
`

export default InvitationStatus
