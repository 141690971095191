import {
  UI_NOTIFICATIONS_OPEN,
  UI_NOTIFICATIONS_CLOSE,
  UI_PROGRESS_SET,
  UI_PROGRESS_CLEAR,
} from 'src/store/ui/actions'

const initialState = {
  notifications: {
    open: false,
  },
}

export const reducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UI_NOTIFICATIONS_OPEN:
      return {
        ...state,
        notifications: { ...state.notifications, open: true },
      }
    case UI_NOTIFICATIONS_CLOSE:
      return {
        ...state,
        notifications: { ...state.notifications, open: false },
      }
    case UI_PROGRESS_SET:
      return {
        ...state,
        progress: payload,
      }
    case UI_PROGRESS_CLEAR:
      return {
        ...state,
        progress: null,
      }
    default:
      return state
  }
}

export default reducer
