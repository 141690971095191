import { createSelector } from 'reselect'
import {
  createGetListFilteredBySelectedPortSelector,
  createGetListFilteredByPortSelector,
} from 'src/utils/store'

export const extraStateSelector = (state) => state.extra

export const extraListSelector = createSelector(
  extraStateSelector,
  ({ data, byId }) => data && byId && data.map((uuid) => byId[uuid])
)

export const getListFilteredByPort = createGetListFilteredByPortSelector(
  extraListSelector
)

export const getListFilteredBySelectedPort = createGetListFilteredBySelectedPortSelector(
  extraListSelector
)

export const extraListStatusSelector = createSelector(
  extraStateSelector,
  (state) => state.status
)

export const extraByIndexSelector = (index) =>
  createSelector(
    extraStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const extraByIdSelector = (uuid) =>
  createSelector(
    extraStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const extraByNameSelector = (name, filterByPort = true, portUuid) =>
  createSelector(
    filterByPort
      ? portUuid
        ? getListFilteredByPort(portUuid)
        : getListFilteredBySelectedPort
      : extraListSelector,
    (list) => list && list.find((extra) => extra.name === name)
  )

export const extraValueByNameSelector = (name, filterByPort = true, portUuid) =>
  createSelector(
    extraByNameSelector(name, filterByPort, portUuid),
    (extra) => extra && extra.metadata && extra.metadata.value
  )

export const extraByTypeSelector = (type, filterByPort = true, portUuid) =>
  createSelector(
    filterByPort
      ? portUuid
        ? getListFilteredByPort(portUuid)
        : getListFilteredBySelectedPort
      : extraListSelector,
    (list) => list && list.find((extra) => extra.extraType === type)
  )

export const totalExtraSelector = createSelector(
  extraStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    extraStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    extraStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
