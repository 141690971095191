import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useForm from 'src/hooks/useForm'
import { validateValues } from 'src/utils/validation'
import TextInput from 'src/components/atoms/TextInput'
import { PortShape } from 'src/utils/types'
import PrimaryButton from 'src/components/atoms/PrimaryButton'

const validateForm = (values) =>
  validateValues(values, {
    url: ['required', 'url'],
  })

const StyledTextInput = styled(TextInput)`
  && {
    .MuiInputBase-input {
      background-color: white;
    }
    label {
      text-transform: uppercase;
      color: #515151;
      font-weight: bold;
    }
  }
`

export const WeatherForm = ({
  disabled,
  submitting,
  onSubmit,
  weatherConfig,
}) => {
  const { dirty, fields, isValid } = useForm({
    initialValues: { 
      apiUrl: weatherConfig.apiUrl || '',
      apiKey: weatherConfig.apiKey || ''
    },
    validationHandler: validateForm,
  })

  const handleSubmit = () => {
    onSubmit({
      ...weatherConfig,
      apiUrl: fields.apiUrl.value || null,
      apiKey: fields.apiKey.value || null
    })
  }

  return (
    <>
      <StyledTextInput
        id="url"
        name="apiUrl"
        label="API Url"
        fullWidth
        dirty={dirty}
        disabled={disabled}
        {...fields.apiUrl}
      />
      <StyledTextInput
        id="apiKey"
        name="apiKey"
        label="API Key"
        fullWidth
        type="password"
        dirty={dirty}
        disabled={disabled}
        {...fields.apiKey}
      />
      {dirty && (
        <PrimaryButton
          disabled={!isValid || !dirty || submitting}
          onClick={handleSubmit}
        >
          {weatherConfig && weatherConfig.uuid ? 'Update' : 'Save'}
        </PrimaryButton>
      )}
    </>
  )
}

WeatherForm.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
  formState: PropTypes.object,
  onSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  weatherConfig: PropTypes.object,
}

export default WeatherForm
