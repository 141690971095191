import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing.lg}px 0;
  text-align: center;
  width: 100%;
`
const Message = styled.p`
  color: ${({ theme }) => theme.palette.text.disabled};
  font-size: ${({ theme }) => theme.typography.pxToRem(21)};
`

const EmptyState = ({ message }) => (
  <StyledWrapper>
    <Message>{message}</Message>
  </StyledWrapper>
)

EmptyState.propTypes = {
  message: PropTypes.string,
}

EmptyState.defaultProps = {
  message: 'No data',
}

export default React.memo(EmptyState)
