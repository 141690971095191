import { createSelector } from 'reselect'

export const organisationStateSelector = (state) => state.organisation

export const organisationStatusSelector = createSelector(
  organisationStateSelector,
  (state) => state && state.status
)
export const organisationListSelector = createSelector(
  organisationStateSelector,
  (state) => state.data && state.data.map((uuid) => state.byId[uuid])
)

export const organisationListStatusSelector = createSelector(
  organisationStateSelector,
  (state) => state.status
)

export const byIdSelector = createSelector(
  organisationStateSelector,
  (state) => state.byId
)

export const organisationByIndexSelector = (index) =>
  createSelector(
    organisationStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const organisationByIdSelector = (uuid) =>
  createSelector(
    organisationStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalOrganisationSelector = createSelector(
  organisationStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    organisationStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    organisationStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )

export const selectedOrganisationSelector = createSelector(
  organisationStateSelector,
  (state) => state.selected
)

export const getSelectedOrganisationSelector = createSelector(
  selectedOrganisationSelector,
  byIdSelector,
  (uuid, byId) => uuid && byId && byId[uuid]
)
