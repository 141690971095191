import React from 'react'
import PropTypes from 'prop-types'
import { listTimeZones } from 'timezone-support'
import styled from 'styled-components'
import { TextField } from '@material-ui/core'

import formatTimezone from 'src/utils/formatTimezone'
import { FieldWrapper, StyledErrorMessage } from 'src/components/atoms/Input'

export const Label = styled.label`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.text.secondary};
  text-transform: capitalize;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  margin-bottom: 5px;
`

// Style it to look like regular input
const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    & .MuiInput-underline {
      padding: 0;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 4px;
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
    & select {
      padding: ${({ theme }) => theme.spacing(2)}px;
      color: inherit;
    }
  }
`

export const TimeZoneInput = ({
  disabled,
  value,
  id,
  error,
  pristine,
  touched,
  dirty,
  ...props
}) => {
  const timeZones = listTimeZones()

  // sort primarily by timezone offset, secondarily by alphabetical A-Z
  const timeZoneOptions = timeZones
    .filter((tz) => !tz.startsWith('Etc/'))
    .map(formatTimezone)
    .sort((a, b) => a.offset - b.offset || a.value.localeCompare(b.value))

  // If it's set to something we don't recognise, treat it as empty
  const selectValue = timeZoneOptions.find((tz) => tz.value === value)
    ? value
    : ''

  return (
    <FieldWrapper>
      <Label disabled={disabled} htmlFor={id}>
        Timezone
      </Label>

      <StyledTextField
        disabled={disabled}
        value={selectValue}
        InputProps={{ id, ...props }}
        select
        SelectProps={{ native: true }}
      >
        <option value="" disabled={!!selectValue}>
          Select your port timezone
        </option>
        {timeZoneOptions.map((opt) => (
          <option key={opt.value} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </StyledTextField>
      {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
    </FieldWrapper>
  )
}

TimeZoneInput.propTypes = {
  disabled: PropTypes.bool,
  pristine: PropTypes.bool,
  touched: PropTypes.bool,
  dirty: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
}

export default TimeZoneInput
