import React from 'react'
import { Switch, Box } from '@material-ui/core'
import Fieldset from 'src/components/organisms/Fieldset'
import Typography from '@material-ui/core/Typography'
import useFeatureFlags from 'src/hooks/useFeatureFlags'
import styled from 'styled-components'

const SubheadingText = styled(Typography).attrs({
  variant: 'subtitle2',
  color: 'textSecondary',
})`
  && {
    margin-left: 10px;
    margin-bottom: 20px;
  }
`

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
    display: inline-block;
    padding: 1px 5px;
  }
`

export const PilotageCreatePdfOnDoneToggle = () => {
  const description = `
  Here you can enable or disable the feature for eMPX to store a pdf copy of a pilotage,
  when the pilotage is marked with the status of Done.`
  const featureFlags = useFeatureFlags()
  const flagName = 'pdf_on_done_enabled'
  const createPdfOnDoneEnabled = featureFlags.getFlag(flagName)

  return (
    <Fieldset
      disabled={false}
      title='Create PDF on pilotage Done'
      description={description}
    >
      <Box>
        <SubheadingText>{description}</SubheadingText>
      </Box>
      <Box display="flex">
        <Switch
          name="toggle"
          color="primary"
          checked={!!createPdfOnDoneEnabled}
          disabled={featureFlags.updating || featureFlags.loading}
          onChange={() => featureFlags.setFlag(flagName, !createPdfOnDoneEnabled)}
        />
        <ToggleText>
          { createPdfOnDoneEnabled
            ? 'Enabled'
            : 'Disabled'}
        </ToggleText>
        {featureFlags.error && <span>{featureFlags.error.message}</span>}
      </Box>
    </Fieldset>
  )
}

export default React.memo(PilotageCreatePdfOnDoneToggle)
