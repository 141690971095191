import React from 'react'
import styled from 'styled-components'
import { Switch } from '@material-ui/core';

type MovementType
    = 'Arrival'
    | 'Departure'
    | 'Shift'

interface Moveable {
    disableForMovementTypes: MovementType[]
    isHeader: boolean
}

interface Props {
    showHeaderSwitch: boolean;
    item: Moveable;
    onChange(newItem: Moveable): void;
}

const movementTypeOptions: Array<{ label: string, value: MovementType }> = [
    { label: 'Arr', value: 'Arrival' },
    { label: 'Dep', value: 'Departure' },
    { label: 'Shift', value: 'Shift' }
]

const MovementTypeContainer = styled.div`
    font-size: 10px;
    text-align: center;
    margin: 0px 10px 0px 20px;

    .movement-types {
        width: 123px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    span {
        color: ${({ theme }) => theme.palette.primary.main};
        font-size: 12px;
        cursor: pointer;
    }
    span.active {
        font-weight: bold;
        text-decoration: underline;
    }
    span.inactive {
        text-decoration: line-through;
        opacity: 0.7;
    }
    b {
        white-space: nowrap;
    }
`

const MovementTypeSelector: React.FC<Props> = ({ showHeaderSwitch, item, onChange }) => {

    const disableForMovementTypes = item.disableForMovementTypes || []
    const isHeader = item.isHeader || false

    const isDisabled = (movementType: MovementType) =>
        disableForMovementTypes.indexOf(movementType) > -1

    const handleToggleMovementType = (movementType: MovementType) => {
        onChange({
            ...item,
            disableForMovementTypes: isDisabled(movementType) ?
                disableForMovementTypes.filter(t => t !== movementType) :
                disableForMovementTypes.concat([movementType])
        })
    }

    const handleToggleIsHeader = (isHeader: boolean) => {
        onChange({
            ...item,
            isHeader,
        })
    }

    return (
        <>
        <MovementTypeContainer>
            <b>Show for movement type/s:</b>
            <div className="movement-types">
            {
                movementTypeOptions.map(option =>
                    <span
                        key={option.value}
                        onClick={() => handleToggleMovementType(option.value)}
                        className={!isDisabled(option.value) ? 'active' : 'inactive'}>
                        {option.label}
                    </span>
                )
            }
            </div>
        </MovementTypeContainer>
        { showHeaderSwitch &&
            <Switch
                name="toggle"
                color="primary"
                checked={isHeader}
                onChange={(_event, checked) => handleToggleIsHeader(checked)}
            />
        }
        </>
    )
}

export default MovementTypeSelector