import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Box } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import useFeatureFlags from 'src/hooks/useFeatureFlags'
import styled from 'styled-components'

const ToggleText = styled(Typography).attrs({
  color: 'primary',
})`
  && {
    text-transform: uppercase;
    font-size: 11px;
    display: inline-block;
    padding: 1px 5px;
  }
`

export const ChecklistPdfExportToggle = ({ flagName }) => {
  const featureFlags = useFeatureFlags()
  const active = featureFlags.getFlag(flagName)

  return (
    <Box style={{ float: 'left' }}>
      <h5>Export in the PDF output?</h5>
      <Box display="flex">
        <Switch
          name="toggle"
          color="primary"
          checked={!!active}
          disabled={featureFlags.updating || featureFlags.loading}
          onChange={() => featureFlags.setFlag(flagName, !active)}
        />
        <ToggleText>
          {featureFlags.loading || featureFlags.updating
            ? '...'
            : active
            ? 'Enabled'
            : 'Disabled'}
        </ToggleText>
        {featureFlags.error && <span>{featureFlags.error.message}</span>}
      </Box>
    </Box>
  )
}

ChecklistPdfExportToggle.propTypes = {
  flagName: PropTypes.string,
}

export default React.memo(ChecklistPdfExportToggle)
