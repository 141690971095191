import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-port'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import { portListSelector } from 'src/store/port/selectors'

// GET PORT
const ACTION_GET = 'PORT_GET'
export const [
  PORT_GET_REQUEST,
  PORT_GET_RESULT,
  PORT_GET_SUCCESS,
  PORT_GET_ERROR,
  PORT_GET_CANCEL,
  PORT_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getPort = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getPortRequest(uuid))
}
export const getPortRequest = (uuid) => async (dispatch) => {
  dispatch(getPortInProgress(uuid))
  try {
    const portResult = await api.getPort(uuid)
    return dispatch(getPortSuccess(portResult))
  } catch (error) {
    return dispatch(getPortError(error))
  }
}
export const getPortInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getPortSuccess = (port) => createSuccessAction(ACTION_GET, port)
export const getPortError = (error) => createErrorAction(ACTION_GET, error)

// GET PORT_LIST
const ACTION_GET_LIST = 'PORT_LIST_GET'
export const [
  PORT_LIST_GET_REQUEST,
  PORT_LIST_GET_RESULT,
  PORT_LIST_GET_SUCCESS,
  PORT_LIST_GET_ERROR,
  PORT_LIST_GET_CANCEL,
  PORT_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getPortList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getPortListRequest(uuid))
}
export const getPortListRequest = (uuid) => async (dispatch) => {
  dispatch(getPortListInProgress(uuid))
  try {
    const portListResult = await api.getPorts(uuid)
    return dispatch(getPortListSuccess(portListResult))
  } catch (error) {
    return dispatch(getPortListError(error))
  }
}
export const getPortListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getPortListSuccess = (port) =>
  createSuccessAction(ACTION_GET_LIST, port)
export const getPortListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE PORT
const ACTION_CREATE = 'PORT_CREATE'
export const [
  PORT_CREATE_REQUEST,
  PORT_CREATE_RESULT,
  PORT_CREATE_SUCCESS,
  PORT_CREATE_ERROR,
  PORT_CREATE_CANCEL,
  PORT_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createPort = (port) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: port })
  return dispatch(createPortRequest(port))
}
export const createPortRequest = (payload) => async (dispatch) => {
  dispatch(createPortInProgress(payload))
  try {
    const portResult = await api.createPort(payload)
    return dispatch(createPortSuccess(portResult))
  } catch (error) {
    return dispatch(createPortError(error, payload))
  }
}
export const createPortInProgress = (port) =>
  createInProgressAction(ACTION_CREATE, port)
export const createPortSuccess = (port) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(TOAST_MESSAGES.PORT_CREATE_SUCCESS, port),
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, port))
}
export const createPortError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(TOAST_MESSAGES.PORT_CREATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE port
const ACTION_UPDATE = 'PORT_UPDATE'
export const [
  PORT_UPDATE_REQUEST,
  PORT_UPDATE_RESULT,
  PORT_UPDATE_SUCCESS,
  PORT_UPDATE_ERROR,
  PORT_UPDATE_CANCEL,
  PORT_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updatePort = (port) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: port })
  return dispatch(updatePortRequest(port))
}
export const updatePortRequest = (payload) => async (dispatch) => {
  dispatch(updatePortInProgress(payload))
  try {
    const portResult = await api.updatePort(payload)
    return dispatch(updatePortSuccess(portResult))
  } catch (error) {
    return dispatch(updatePortError(error, payload))
  }
}
export const updatePortInProgress = (port) =>
  createInProgressAction(ACTION_UPDATE, port)
export const updatePortSuccess = (port) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: getToastMessage(TOAST_MESSAGES.PORT_UPDATE_SUCCESS, port),
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, port))
}
export const updatePortError = (error, payload) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: getToastMessage(TOAST_MESSAGES.PORT_UPDATE_ERROR, payload),
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE port (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'PORT_SAVE'
export const savePort = (port) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: port })
  const result = await dispatch(port.uuid ? updatePort(port) : createPort(port))
  return result
}

// DELETE port
const ACTION_DELETE = 'PORT_DELETE'
export const [
  PORT_DELETE_REQUEST,
  PORT_DELETE_RESULT,
  PORT_DELETE_SUCCESS,
  PORT_DELETE_ERROR,
  PORT_DELETE_CANCEL,
  PORT_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deletePort = (port) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: port })
  return dispatch(deletePortRequest(port))
}
export const deletePortRequest = (port) => async (dispatch) => {
  dispatch(deletePortInProgress(port))
  try {
    await api.deletePort(port.uuid)
    return dispatch(deletePortSuccess(port))
  } catch (error) {
    dispatch(deletePortError(error))
    return error
  }
}
export const deletePortInProgress = (port) =>
  createInProgressAction(ACTION_DELETE, port)
export const deletePortSuccess = (port) =>
  createSuccessAction(ACTION_DELETE, port)
export const deletePortError = (error) =>
  createErrorAction(ACTION_DELETE, error)

// SELECT port
const ACTION_SELECT = 'PORT_SELECT'
export const [
  PORT_SELECT_REQUEST,
  PORT_SELECT_RESULT,
  PORT_SELECT_SUCCESS,
  PORT_SELECT_ERROR,
  PORT_SELECT_CANCEL,
  PORT_SELECT_IN_PROGRESS,
] = createBasicActionTypes(ACTION_SELECT)
export const selectPort = (port) => (dispatch) => {
  dispatch({ type: ACTION_SELECT, payload: port })
  return dispatch(selectPortRequest(port))
}
export const selectPortRequest = (uuid) => async (dispatch, getState) => {
  const ports = portListSelector(getState())
  if (ports.find((port) => port.uuid === uuid)) {
    return dispatch(selectPortSuccess(uuid))
  }
  dispatch(selectPortError(new Error(`No port with UUID ${uuid}`)))
}
export const selectPortSuccess = (port) =>
  createSuccessAction(ACTION_SELECT, port)
export const selectPortError = (error) =>
  createErrorAction(ACTION_SELECT, error)
