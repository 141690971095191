import styled from 'styled-components'

import { ListItem as MuiListItem } from '@material-ui/core'

export const ListItem = styled(MuiListItem).attrs({ button: true })`
  && {
    padding-bottom: ${({ theme }) => theme.spacing(2)}px;
    padding-top: ${({ theme }) => theme.spacing(2)}px;
  }
`

export default ListItem
