import React from 'react'
import PropTypes from 'prop-types'

export const SettingsIcon = ({ size = 16 }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1062.000000, -250.000000)" fill="#515151">
        <g transform="translate(843.000000, 48.000000)">
          <g transform="translate(35.000000, 93.000000)">
            <g transform="translate(147.000000, 88.000000)">
              <polygon points="57 38.1752485 54.1746841 41 45.6967381 32.5247465 42.8714221 35.3504969 37 29.4802477 39.8253159 26.6544973 42.8714221 29.699995 45.6967381 26.8742446 42.6466357 23.8247515 45.4719516 21 51.348369 26.8742446 48.5230531 29.699995" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

SettingsIcon.propTypes = {
  size: PropTypes.number,
}

export default React.memo(SettingsIcon)
