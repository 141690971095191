import routes, {
  AUTH,
  AUTH_LOGIN,
  AUTH_REFRESH_SESSION,
  isPrivateRoute,
  HOME,
  LOGINSSO,
  LOGOUT,
} from 'src/router/routes'
import {
  refreshTokenSelector,
  isLoggedInSelector,
} from 'src/store/auth/selectors'
import { getRefreshTokenFromStorage } from 'src/utils/storage'
import { roleSelector, isSuperAdminSelector } from 'src/store/user/selectors'
import { isHostSsoDomain } from 'src/utils/ssoHelper'

export const isUserAuthenticated = (store) => () => (
  toState,
  fromState,
  done
) => {
  const state = store.getState()
  const isLoggedIn = isLoggedInSelector(state)
  const roles = roleSelector(state)
  const isSuperAdmin = isSuperAdminSelector(state)
  const route = routes.find((r) => r.name === toState.name)
  if (toState.name === LOGOUT) {
    // noop
  } else {
    if (!isLoggedIn) {
      const refreshToken =
        refreshTokenSelector(state) || getRefreshTokenFromStorage()
      if (refreshToken && toState.name !== AUTH_REFRESH_SESSION) {
        // eslint-disable-next-line
        return Promise.reject({
          redirect: {
            name: AUTH_REFRESH_SESSION,
            params: { redirectTo: toState },
          },
        })
      } else if (isPrivateRoute(toState.name)) {
        if (isHostSsoDomain()) {
          // eslint-disable-next-line
          return Promise.reject({ redirect: { name: LOGINSSO } })
        } else {
          // eslint-disable-next-line
          return Promise.reject({ redirect: { name: AUTH_LOGIN } })
        }
      }
    } else if (toState.name.indexOf(LOGINSSO) === 0) {
      // eslint-disable-next-line
      return Promise.reject({ redirect: { name: HOME } })
    } else if (toState.name.indexOf(AUTH) === 0) {
      // eslint-disable-next-line
      return Promise.reject({ redirect: { name: HOME } })
    } else if (!isSuperAdmin && route && route.roles) {
      const allowedRoute = route.roles.some((role) => roles.includes(role))
      if (!allowedRoute) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({ redirect: { name: HOME } })
      }
    }
  }
  done()
}
