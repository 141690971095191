import React, { useEffect } from 'react'
import { useRouter, useRoute } from 'react-router5'
import { useDispatch, useSelector } from 'react-redux'

import {
  authTokenSelector,
  refreshTokenSelector,
  authStatusSelector,
  isLoggedInSelector,
} from 'src/store/auth/selectors'
import {
  getRefreshTokenFromStorage,
  getTokenFromStorage,
} from 'src/utils/storage'
import {
  requestRefreshSession,
  AUTH_SESSION_REFRESH_IN_PROGRESS,
  AUTH_SESSION_REFRESH_SUCCESS,
  AUTH_SESSION_REFRESH_ERROR,
} from 'src/store/auth/actions'
import { HOME } from 'src/router/routes'
import ViewportLoader from 'src/components/atoms/ViewportLoader'

const shouldDispatchRefreshRequest = (currentAuthStatus) =>
  [
    AUTH_SESSION_REFRESH_IN_PROGRESS,
    AUTH_SESSION_REFRESH_SUCCESS,
    AUTH_SESSION_REFRESH_ERROR,
  ].indexOf(currentAuthStatus) === -1

export const RefreshSession = () => {
  const refreshToken =
    useSelector(refreshTokenSelector) || getRefreshTokenFromStorage()
  const token = useSelector(authTokenSelector) || getTokenFromStorage()
  const authStatus = useSelector(authStatusSelector)
  const isLoggedIn = useSelector(isLoggedInSelector)
  const dispatch = useDispatch()
  const router = useRouter()
  const route = useRoute()
  const { params: { redirectTo } = {} } = route.route
  useEffect(() => {
    if (
      refreshToken &&
      !isLoggedIn &&
      shouldDispatchRefreshRequest(authStatus)
    ) {
      dispatch(requestRefreshSession({ token, refreshToken }))
    } else if (authStatus !== AUTH_SESSION_REFRESH_IN_PROGRESS) {
      router.navigate(
        (redirectTo && redirectTo.name) || HOME,
        (redirectTo || {}).params
      )
    }
    // eslint-disable-next-line
  }, [authStatus, isLoggedIn, refreshToken])

  return <ViewportLoader>Refreshing session</ViewportLoader>
}

export default RefreshSession
