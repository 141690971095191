import PropTypes from 'prop-types'

import { CONSTRAINT_UNITS, TUG_TYPES } from 'src/constants/settings'

export const columnsShape = {
  field: PropTypes.string,
  title: PropTypes.string,
  sortable: PropTypes.bool,
  width: PropTypes.number,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  labelFunction: PropTypes.func,
}

export const paginationShape = {
  start: PropTypes.number,
  limit: PropTypes.number,
}

export const LocationShape = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
}

export const InvitationShape = {
  uuid: PropTypes.string,
  createdAt: PropTypes.string,
  verified: PropTypes.bool,
  email: PropTypes.string,
  isAdmin: PropTypes.bool,
  metadata: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  organisation: PropTypes.shape({
    uuid: PropTypes.uuid,
  }),
}

export const OrganisationShape = {
  uuid: PropTypes.string,
  name: PropTypes.string,
  logo: PropTypes.string,
  trialMode: PropTypes.bool,
  trialStartAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  invitation: PropTypes.shape(InvitationShape),
}

export const BerthShape = {
  uuid: PropTypes.string,
  name: PropTypes.string,
  berthStationType: PropTypes.oneOf(['berth', 'station']),
}
export const StationShape = BerthShape

export const ConstraintShape = {
  uuid: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.number,
  safetyMargin: PropTypes.number,
  unit: PropTypes.oneOf([CONSTRAINT_UNITS.PERCENT, CONSTRAINT_UNITS.METERS]),
  berthStation: PropTypes.oneOfType([
    PropTypes.shape(BerthShape),
    PropTypes.shape(StationShape),
  ]),
}

export const PortShape = {
  uuid: PropTypes.string,
  name: PropTypes.string,
  locationName: PropTypes.string,
  location: PropTypes.shape(LocationShape),
  organisation: PropTypes.shape(OrganisationShape),
}

export const TugShape = {
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  tonnage: PropTypes.number,
  type: PropTypes.oneOf(Object.values(TUG_TYPES)),
}

export const ChartShape = {
  name: PropTypes.string,
  scale: PropTypes.number,
  unit: PropTypes.string,
  file: PropTypes.object,
  src: PropTypes.string,
  pixels: PropTypes.number,
  meters: PropTypes.number,
  imageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export const StageShape = {
  name: PropTypes.string,
  order: PropTypes.number,
  firstOrLast: PropTypes.bool,
  chart: PropTypes.shape(ChartShape),
}
export const RouteShape = {
  name: PropTypes.string,
  stages: PropTypes.arrayOf(PropTypes.shape(StageShape)),
  port: PropTypes.shape(PortShape),
}

export const ExtraShape = {
  name: PropTypes.string,
  extraType: PropTypes.string,
  metadata: PropTypes.object,
  port: PropTypes.shape(PortShape),
}

export const RouteConstraintShape = {
  active: PropTypes.bool,
  constraint: PropTypes.shape(ConstraintShape),
  distance: PropTypes.number,
  order: PropTypes.number,
  speed: PropTypes.number,
  time: PropTypes.number,
}

export const RouteAttachmentShape = {
  route: PropTypes.shape(RouteShape),
}

export const TideStationShape = {
  name: PropTypes.string,
}

export const TideRateStationShape = {
  name: PropTypes.string,
}

export const UserShape = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
}
