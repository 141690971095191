import {
  PILOTAGE_PDF_DOWNLOAD_IN_PROGRESS,
  PILOTAGE_PDF_DOWNLOAD_ERROR,
  PILOTAGE_PDF_DOWNLOAD_SUCCESS,
} from 'src/store/pilotagesPdfDownload/actions'

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
}

function pilotagesPdfDownloadReducer (state = INITIAL_STATE, action) {
  const { type, payload } = action

  switch (type) {
    case PILOTAGE_PDF_DOWNLOAD_IN_PROGRESS:
      return {
        ...state,
        isLoading: true,
        error: null,
        status: type,
      }
    case PILOTAGE_PDF_DOWNLOAD_ERROR:
      return {
        ...state,
        error: null,
        isLoading: false,
        status: type,
      }
    case PILOTAGE_PDF_DOWNLOAD_SUCCESS:
      return {
        ...state,
        data: payload.data,
        isLoading: false,
        error: null,
        status: type,
      }
    default:
      return state
  }
}

export default pilotagesPdfDownloadReducer
