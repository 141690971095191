import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getConstraints = (config) =>
  api
    .get(`/constraints`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getConstraint = (uuid, config) =>
  api
    .get(`/constraints/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createConstraint = (tideStation, config) => {
  const { file, ...body } = tideStation
  return api
    .post('/constraints', body, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateConstraint = (tideStation, config) => {
  const { file, ...body } = tideStation
  return api
    .put(`/constraints/${tideStation.uuid}`, body, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const deleteConstraint = (uuid, config) =>
  api
    .delete(`/constraints/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  create: createConstraint,
  update: updateConstraint,
  delete: deleteConstraint,
}
