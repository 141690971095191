import {
  SSO_GET_SUCCESS,
  SSO_CREATE_SUCCESS,
  SSO_UPDATE_SUCCESS,
  SSO_UPDATE_ERROR,
  SSO_GET_REQUEST,
  SSO_GET_ERROR,
  SSO_GET_IN_PROGRESS,
  SSO_CREATE_REQUEST,
  SSO_UPDATE_REQUEST,
  SSO_CREATE_IN_PROGRESS,
  SSO_UPDATE_IN_PROGRESS,
  SSO_CREATE_ERROR,
} from 'src/store/sso/actions'
import { SSO_ENABLE_CONSOLE_LOG } from 'src/utils/ssoConstants'

export const getInitialState = {
  status: null,
  data: null,
  error: null,
}

export const reducer = (state = getInitialState, action) => {
  const { type, payload } = action

  switch (type) {
    case SSO_GET_REQUEST:
    case SSO_CREATE_REQUEST:
    case SSO_UPDATE_REQUEST:
    case SSO_GET_IN_PROGRESS:
    case SSO_CREATE_IN_PROGRESS:
    case SSO_UPDATE_IN_PROGRESS:
    case SSO_GET_ERROR:
    case SSO_CREATE_ERROR:
    case SSO_UPDATE_ERROR:
      return {
        ...state,
        status: type,
      }
    case SSO_UPDATE_SUCCESS:
    case SSO_CREATE_SUCCESS:
      const { data } = state
      // if we change port mid-flight, we want to ignore any delayed responses assigned to the previous port
      if (data && data.port.uuid !== payload.port.uuid) {
        if (SSO_ENABLE_CONSOLE_LOG) {
          console.log(`not updating state for delayed response, data.port.uuid [${data.port.uuid}] payload.port.uuid [${payload.port.uuid}]`)
        }
        return state
      }
      return {
        ...state,
        status: type,
        data: payload
      }
    case SSO_GET_SUCCESS: {
      return {
        ...state,
        status: type,
        data: payload
      }
    }
    default:
      return state
  }
}

export default reducer
