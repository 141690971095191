import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-invitation'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'

// GET INVITATION
const ACTION_GET = 'INVITATION_GET'
export const [
  INVITATION_GET_REQUEST,
  INVITATION_GET_RESULT,
  INVITATION_GET_SUCCESS,
  INVITATION_GET_ERROR,
  INVITATION_GET_CANCEL,
  INVITATION_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getInvitation = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getInvitationRequest(uuid))
}
export const getInvitationRequest = (uuid) => async (dispatch) => {
  dispatch(getInvitationInProgress(uuid))
  try {
    const invitationResult = await api.getInvitation(uuid)
    return dispatch(getInvitationSuccess(invitationResult))
  } catch (error) {
    return dispatch(getInvitationError(error))
  }
}
export const getInvitationInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getInvitationSuccess = (invitation) =>
  createSuccessAction(ACTION_GET, invitation)
export const getInvitationError = (error) =>
  createErrorAction(ACTION_GET, error)

// GET INVITATION_LIST
const ACTION_GET_LIST = 'INVITATION_LIST_GET'
export const [
  INVITATION_LIST_GET_REQUEST,
  INVITATION_LIST_GET_RESULT,
  INVITATION_LIST_GET_SUCCESS,
  INVITATION_LIST_GET_ERROR,
  INVITATION_LIST_GET_CANCEL,
  INVITATION_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET_LIST)
export const getInvitationList = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET_LIST, payload: uuid })
  return dispatch(getInvitationListRequest(uuid))
}
export const getInvitationListRequest = (uuid) => async (dispatch) => {
  dispatch(getInvitationListInProgress(uuid))
  try {
    const invitationListResult = await api.getInvitations(uuid)
    return dispatch(getInvitationListSuccess(invitationListResult))
  } catch (error) {
    return dispatch(getInvitationListError(error))
  }
}
export const getInvitationListInProgress = (uuid) =>
  createInProgressAction(ACTION_GET_LIST, uuid)
export const getInvitationListSuccess = (invitation) =>
  createSuccessAction(ACTION_GET_LIST, invitation)
export const getInvitationListError = (error) =>
  createErrorAction(ACTION_GET_LIST, error)

// CREATE INVITATION
const ACTION_CREATE = 'INVITATION_CREATE'
export const [
  INVITATION_CREATE_REQUEST,
  INVITATION_CREATE_RESULT,
  INVITATION_CREATE_SUCCESS,
  INVITATION_CREATE_ERROR,
  INVITATION_CREATE_CANCEL,
  INVITATION_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createInvitation = (invitation) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: invitation })
  return dispatch(createInvitationRequest(invitation))
}
export const createInvitationRequest = (payload) => async (dispatch) => {
  dispatch(createInvitationInProgress(payload))
  try {
    const invitationResult = await api.createInvitation(payload)
    return dispatch(createInvitationSuccess(invitationResult, payload))
  } catch (error) {
    return dispatch(createInvitationError(error, payload))
  }
}
export const createInvitationInProgress = (invitation) =>
  createInProgressAction(ACTION_CREATE, invitation)
export const createInvitationSuccess = (invitation, payload) => (dispatch) => {
  const { email, metadata: { firstName, lastName } = {} } = payload
  dispatch(
    addSuccessToast({
      message: getToastMessage(TOAST_MESSAGES.INVITATION_CREATE_SUCCESS, {
        firstName,
        lastName,
        email,
      }),
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, invitation))
}
export const createInvitationError = (error, payload) => (dispatch) => {
  const { email, metadata: { firstName, lastName } = {}, externalId } = payload
  const { response: { data: { message } = {} } = {} } = error
  const msg =
    message ||
    TOAST_MESSAGES[`INVITATION_CREATE_ERROR_${error.status}`] ||
    TOAST_MESSAGES.INVITATION_CREATE_ERROR
  dispatch(
    addErrorToast({
      message: getToastMessage(msg, {
        firstName,
        lastName,
        email,
        externalId,
      }),
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE invitation
const ACTION_UPDATE = 'INVITATION_UPDATE'
export const [
  INVITATION_UPDATE_REQUEST,
  INVITATION_UPDATE_RESULT,
  INVITATION_UPDATE_SUCCESS,
  INVITATION_UPDATE_ERROR,
  INVITATION_UPDATE_CANCEL,
  INVITATION_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateInvitation = (invitation) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: invitation })
  return dispatch(updateInvitationRequest(invitation))
}
export const updateInvitationRequest = (payload) => async (dispatch) => {
  dispatch(updateInvitationInProgress(payload))
  try {
    const invitationResult = await api.updateInvitation(payload)
    return dispatch(updateInvitationSuccess(invitationResult))
  } catch (error) {
    return dispatch(updateInvitationError(error, payload))
  }
}
export const updateInvitationInProgress = (invitation) =>
  createInProgressAction(ACTION_UPDATE, invitation)
export const updateInvitationSuccess = (result, message) => (dispatch) => {
  const { email, metadata: { firstName, lastName } = {} } = result
  dispatch(
    addSuccessToast({
      message: message || getToastMessage(TOAST_MESSAGES.INVITATION_UPDATE_SUCCESS, {
        firstName,
        lastName,
        email,
      }),
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, result))
}
export const updateInvitationError = (error, payload) => (dispatch) => {

  const { response: { data: { message } = {} } = {} } = error

  if (!payload) {
    return dispatch(createErrorAction(ACTION_UPDATE, error))
  }

  const { email, metadata: { firstName, lastName } = {}, externalId } = payload

  const msg =
    message ||
    TOAST_MESSAGES[`INVITATION_UPDATE_ERROR_${error.status}`] ||
    TOAST_MESSAGES.INVITATION_UPDATE_ERROR

  dispatch(
    addErrorToast({
      message: getToastMessage(msg, {
        firstName,
        lastName,
        email,
        externalId,
      }),
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE invitation (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'INVITATION_SAVE'
export const saveInvitation = (invitation) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: invitation })
  const result = await dispatch(
    invitation.uuid
      ? updateInvitation(invitation)
      : createInvitation(invitation)
  )
  return result
}

// DELETE invitation
const ACTION_DELETE = 'INVITATION_DELETE'
export const [
  INVITATION_DELETE_REQUEST,
  INVITATION_DELETE_RESULT,
  INVITATION_DELETE_SUCCESS,
  INVITATION_DELETE_ERROR,
  INVITATION_DELETE_CANCEL,
  INVITATION_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteInvitation = (invitation, showMessage = true) => (
  dispatch
) => {
  dispatch({ type: ACTION_DELETE, payload: invitation })
  return dispatch(deleteInvitationRequest(invitation, showMessage))
}
export const deleteInvitationRequest = (
  invitation,
  showMessage = true
) => async (dispatch) => {
  dispatch(deleteInvitationInProgress(invitation))
  try {
    await api.deleteInvitation(invitation.uuid)
    if (showMessage && !invitation.user) {
      dispatch(
        addSuccessToast({
          message: getToastMessage(
            TOAST_MESSAGES.INVITATION_DELETE_SUCCESS,
            invitation
          ),
        })
      )
    }
    return dispatch(deleteInvitationSuccess(invitation))
  } catch (error) {
    if (showMessage) {
      dispatch(
        addSuccessToast({
          message: getToastMessage(
            TOAST_MESSAGES.INVITATION_DELETE_ERROR,
            invitation
          ),
        })
      )
    }
    dispatch(deleteInvitationError(error))
    return error
  }
}
export const deleteInvitationInProgress = (invitation) =>
  createInProgressAction(ACTION_DELETE, invitation)
export const deleteInvitationSuccess = (invitation) =>
  createSuccessAction(ACTION_DELETE, invitation)
export const deleteInvitationError = (error) =>
  createErrorAction(ACTION_DELETE, error)



export const INVITATION_PORTS_UPDATE = 'INVITATION_PORTS_UPDATE'
export const [
  INVITATION_PORTS_UPDATE_REQUEST,
  INVITATION_PORTS_UPDATE_RESULT,
  INVITATION_PORTS_UPDATE_SUCCESS,
  INVITATION_PORTS_UPDATE_ERROR,
  INVITATION_PORTS_UPDATE_CANCEL,
  INVITATION_PORTS_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(INVITATION_PORTS_UPDATE)

export const updateInvitationPorts = (invitation, message) => async (dispatch) => {
  dispatch({ type: INVITATION_PORTS_UPDATE, payload: invitation })
  return dispatch(updateInvitationPortsRequest(invitation, message))
}

export const updateInvitationPortsRequest = (invitation, message) => async (dispatch) => {
  dispatch(updateInvitationInProgress(invitation))
  try {
    const ports = invitation.portValidationTokens.map(({ port }) => ({ uuid: port.uuid }))
    await api.updateInvitationPorts({ uuid: invitation.uuid, ports })
    return dispatch(updateInvitationSuccess(invitation, message))
  } catch (error) {
    return dispatch(updateInvitationError(error))
  }
}