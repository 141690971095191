import { createSelector } from 'reselect'

export const toastStateSelector = (state) => state.toast

export const toastsSelector = createSelector(
  toastStateSelector,
  (state) => state.items || []
)

export const seenToastSelector = createSelector(
  toastsSelector,
  (toast) => toast.filter((notification) => notification.seen)
)

export const unseenToastSelector = createSelector(
  toastsSelector,
  (toast) => toast.filter((notification) => !notification.seen)
)

export const nextUnseenToastSelector = createSelector(
  unseenToastSelector,
  (toast) => [...toast].shift()
)

export const toastsByIdSelector = () =>
  createSelector(
    toastStateSelector,
    (state) => state.itemsById || {}
  )

export const toastByIdSelector = (id) =>
  createSelector(
    toastsByIdSelector,
    (toasts) => toasts[id]
  )
