import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Select, MenuItem, OutlinedInput } from '@material-ui/core'

import Input from 'src/components/atoms/Input'
import isObject from 'src/utils/isObject'
import TickIcon from '../Icons/TickIcon'

const StyledSelectNarrow = styled(Select)`
  && {
    .MuiOutlinedInput-input {
      padding: ${({ theme }) => theme.spacing(1, 3, 1, 2)};
    }
  }
`

const StyledMenuItem = styled(MenuItem)`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const SelectInput = ({ options = [], placeholder, value, ...props }) => {

  let menuItems
  const Component = props.narrow ? StyledSelectNarrow : Select
  const currentValue = value

  if (Array.isArray(options)) {
    menuItems = options.map((option) => {
      const label = typeof option === 'string' ? option : option.label
      const value = typeof option === 'string' ? option : option.value
      const key = (isObject(value) && (value.uuid || value.id)) || value
      return (
        <StyledMenuItem key={key} value={key}>
          {label} 
          {
            Array.isArray(currentValue) && currentValue.includes(key) &&
            <TickIcon size={16} />
          }
        </StyledMenuItem>
      )
    })
  } else {
    menuItems = Object.keys(options).map((value) => {
      const label = options[value]
      return (
        <MenuItem key={value} value={value}>
          {label} 
        </MenuItem>
      )
    })
  }
  const propsExtra = {
    ...props,
    value,
    input: <OutlinedInput name={props.name} id={props.name} />,
  }

  if (value === '-') {
    menuItems.unshift(
      <MenuItem key="-" value="-" disabled>
        {placeholder}
      </MenuItem>
    )
  }

  return (
    <Input Component={Component} {...propsExtra}>
      {menuItems}
    </Input>
  )
}

SelectInput.propTypes = {
  narrow: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.any,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.any,
        }),
      ])
    ),
    PropTypes.object,
  ]),
}

export default SelectInput
