import { actions as routerActions } from 'redux-router5'
import { hash } from 'src/utils/crypto'

import { setBearerAuthToken, clearAuthSettings } from '@weareroam/sauce-web'
import {
  createRequestAction,
  createSuccessAction,
  createErrorAction,
  createInProgressAction,
  createBasicActionTypes,
} from 'src/utils/createAction'

import {
  loginUser,
  refreshSession,
  logoutUser,
  resetPassword,
  requestNewPassword as requestNewPasswordApi,
  updatePassword,
  acceptInvitation as acceptInvitationApi,
  loginUserSso,
} from 'src/utils/sauce/sauce-api-user'
import { setAuthToken as apiSetAuthToken, getSinglePayloadFromResponse } from 'src/utils/sauce/api'

import {
  clearAuthData,
  storeAuthData,
  getUserFromStorage,
} from 'src/utils/storage'
import { addErrorToast, addSuccessToast } from 'src/store/toast/actions'
import {
  loadCurrentUserFromStorageSuccess,
  removeCurrentUser,
  loadCurrentUserFromStorage,
} from 'src/store/user/actions'
import { getOrganisationList } from 'src/store/organisation/actions'
import {
  AuthorizationError,
  BadRequestError,
  ForbiddenError,
} from 'src/utils/sauce/api-error'
import TOAST_MESSAGES from 'src/utils/toastMessages'
import { USER_ROLES } from 'src/constants/user'
import { getPortList } from 'src/store/port/actions'
import { isLogoutInProgressSelector } from './selectors'
import idx from 'idx'
import { getPayloadFromErrorResponse } from './../../utils/sauce/api'
import { isHostSsoDomain } from 'src/utils/ssoHelper'
import { LOGINSSO } from 'src/router/routes'

// local helper constants
export const ACTION_BASE = 'AUTH'
const ACTION_LOGIN = `${ACTION_BASE}_LOGIN`
const ACTION_SESSION_REFRESH = `${ACTION_BASE}_REFRESH_SESSION`
const ACTION_LOGOUT = `${ACTION_BASE}_LOGOUT`
const ACTION_PASSWORD_REQUEST_NEW = `${ACTION_BASE}_PASSWORD_REQUEST_NEW`
const ACTION_PASSWORD_RESET = `${ACTION_BASE}_PASSWORD_RESET`
const ACTION_PASSWORD_UPDATE = `${ACTION_BASE}_PASSWORD_UPDATE`

// exported action types
export const AUTH_ERROR_STATE_RESET = 'AUTH_ERROR_STATE_RESET'

export const [
  AUTH_SESSION_REFRESH_REQUEST,
  AUTH_SESSION_REFRESH_RESULT,
  AUTH_SESSION_REFRESH_SUCCESS,
  AUTH_SESSION_REFRESH_ERROR,
  AUTH_SESSION_REFRESH_CANCEL,
  AUTH_SESSION_REFRESH_IN_PROGRESS,
] = createBasicActionTypes(ACTION_SESSION_REFRESH)
export const [
  AUTH_LOGOUT_REQUEST,
  AUTH_LOGOUT_RESULT,
  AUTH_LOGOUT_SUCCESS,
  AUTH_LOGOUT_ERROR,
  AUTH_LOGOUT_CANCEL,
  AUTH_LOGOUT_IN_PROGRESS,
] = createBasicActionTypes(ACTION_LOGOUT)
export const [
  AUTH_PASSWORD_REQUEST_NEW_REQUEST,
  AUTH_PASSWORD_REQUEST_NEW_RESULT,
  AUTH_PASSWORD_REQUEST_NEW_SUCCESS,
  AUTH_PASSWORD_REQUEST_NEW_ERROR,
  AUTH_PASSWORD_REQUEST_NEW_CANCEL,
  AUTH_PASSWORD_REQUEST_NEW_IN_PROGRESS,
] = createBasicActionTypes(ACTION_PASSWORD_REQUEST_NEW)
export const [
  AUTH_PASSWORD_RESET_REQUEST,
  AUTH_PASSWORD_RESET_RESULT,
  AUTH_PASSWORD_RESET_SUCCESS,
  AUTH_PASSWORD_RESET_ERROR,
  AUTH_PASSWORD_RESET_CANCEL,
  AUTH_PASSWORD_RESET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_PASSWORD_RESET)
export const [
  AUTH_PASSWORD_UPDATE_REQUEST,
  AUTH_PASSWORD_UPDATE_RESULT,
  AUTH_PASSWORD_UPDATE_SUCCESS,
  AUTH_PASSWORD_UPDATE_ERROR,
  AUTH_PASSWORD_UPDATE_CANCEL,
  AUTH_PASSWORD_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_PASSWORD_UPDATE)

export const resetAuthError = () => ({ type: AUTH_ERROR_STATE_RESET })

// Login (/login)
export const [
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_RESULT,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_ERROR,
  AUTH_LOGIN_CANCEL,
  AUTH_LOGIN_IN_PROGRESS,
] = createBasicActionTypes(ACTION_LOGIN)
export const requestLogin = (payload) => async (dispatch) => {
  dispatch(createRequestAction(ACTION_LOGIN, payload))
  dispatch(loginInProgress(payload))
  try {
    const responsePayload = await loginUser({
      ...payload,
      password: hash(payload.password),
      isAdmin: true,
    })
    return dispatch(loggedIn(responsePayload))
  } catch (errorResponse) {
    return dispatch(loginError(errorResponse))
  }
}

export const requestLoginSso = (
  token,
  redirectUrl
) => async dispatch => {
  const payload = { redirectUrl }
  dispatch(loginInProgress(payload))
  try {
    const response = await loginUserSso(token, redirectUrl)
    const responsePayload = getSinglePayloadFromResponse(response)
    dispatch(loggedIn(responsePayload))
    return responsePayload
  } catch (errorResponse) {
    let message
    const status = idx(errorResponse, e => e.response.status)
    if (
      errorResponse &&
      errorResponse.response &&
      [400, 401, 403].indexOf(status) !== -1
    ) {
      message = TOAST_MESSAGES.INVALID_LOGIN
    } else {
      message = TOAST_MESSAGES.GENERIC_ERROR
    }

    dispatch(loginError(getPayloadFromErrorResponse(errorResponse), message))
    return false
  }
}

export const loginInProgress = (payload) =>
  createInProgressAction(ACTION_LOGIN, payload)
export const loggedIn = (payload) => async (dispatch) => {
  const { roles } = payload
  if (
    roles.some(
      (role) => role === USER_ROLES.ADMIN || role === USER_ROLES.SUPER_ADMIN
    )
  ) {
    apiSetAuthToken(payload.token)
    setBearerAuthToken(payload.token)
    dispatch(createSuccessAction(ACTION_LOGIN, payload))
    await Promise.all([
      dispatch(getOrganisationList()),
      dispatch(getPortList()),
    ])

    const { user, ...payloadWithoutUser } = payload
    const { metadata, ...userWithoutMetaData } = user
    const { firstName, lastName } = metadata || {}

    const reformattedPayload = {
      ...payloadWithoutUser,
      user: {
        ...userWithoutMetaData,
        firstName,
        lastName,
      },
    }
    storeAuthData(reformattedPayload)
  } else {
    // dispatch(requestLogout())
    dispatch(
      addErrorToast({
        message: 'Only admins allowed to login',
      })
    )
    clearAuthData()
    clearAuthSettings()
  }
}
export const loginError = (error) => (dispatch) => {
  const invalidCredentials =
    error instanceof AuthorizationError || error instanceof BadRequestError
  const forbidden = error instanceof ForbiddenError
  dispatch(createErrorAction(ACTION_LOGIN, error))
  dispatch(
    addErrorToast({
      message: invalidCredentials
        ? 'Invalid credentials'
        : forbidden
        ? 'Only admins allowed to login'
        : 'Oops, an error occurred. Please try to login later.',
    })
  )
}

// Session Refresh
export const requestRefreshSession = (payload) => async (dispatch) => {
  dispatch(refreshSessionInProgress())
  try {
    const response = await refreshSession(payload)
    dispatch(loadCurrentUserFromStorage())
    dispatch(refreshSessionSuccess(response))
  } catch (error) {
    dispatch(refreshSessionError(error))
  }
}
export const refreshSessionInProgress = (payload) =>
  createInProgressAction(ACTION_SESSION_REFRESH, payload)
export const refreshSessionError = (error) => (dispatch) => {
  clearAuthData()
  clearAuthSettings()
  dispatch(removeCurrentUser())
  if (isHostSsoDomain()) {
    dispatch(
      routerActions.navigateTo(
        LOGINSSO,
        {
          code: 'logout',
        },
        { replace: true }
      )
    )
  }
  dispatch(
    addErrorToast({
      message: TOAST_MESSAGES.AUTH_REFRESH_SESSION_ERROR,
    })
  )
  return dispatch(createErrorAction(ACTION_SESSION_REFRESH, error))
}
export const refreshSessionSuccess = (payload) => async (dispatch) => {
  apiSetAuthToken(payload.token)
  setBearerAuthToken(payload.token)
  dispatch(createSuccessAction(ACTION_SESSION_REFRESH, payload))
  storeAuthData(payload)
  // to refresh the store with the user data from the sessionStorage/localStorage
  dispatch(loadCurrentUserFromStorageSuccess(getUserFromStorage()))
  await Promise.all([dispatch(getOrganisationList()), dispatch(getPortList())])
}

// Logout (/logout)
export const requestLogout = (payload) => async (dispatch, getState) => {
  if (isLogoutInProgressSelector(getState())) {
    return
  }

  dispatch(logoutInProgress(ACTION_LOGOUT, payload))
  try {
    await logoutUser()
  } catch (error) {
    dispatch(logoutError(error))
  } finally {
    dispatch(logoutSuccess())
    if (isHostSsoDomain()) {
      dispatch(
        routerActions.navigateTo(
          LOGINSSO,
          {
            code: 'logout',
          },
          { replace: true }
        )
      )
    }
  }
}
export const logoutError = (error) => createErrorAction(ACTION_LOGOUT, error)
export const logoutSuccess = () => (dispatch) => {
  try {
    clearAuthData()
  } catch (error) {
  } finally {
    dispatch(removeCurrentUser())
    dispatch(createSuccessAction(ACTION_LOGOUT))
  }
}
export const logoutInProgress = () => createInProgressAction(ACTION_LOGOUT)

// Request new password (/forgot-password)
export const requestNewPassword = (payload) => async (dispatch) => {
  dispatch(createRequestAction(ACTION_PASSWORD_REQUEST_NEW, payload))
  dispatch(requestNewPasswordInProgress(payload))
  try {
    const result = await requestNewPasswordApi(payload)
    dispatch(requestNewPasswordSuccess(result))
  } catch (error) {
    dispatch(requestNewPasswordError(error))
  }
}
export const requestNewPasswordSuccess = (result) => (dispatch) => {
  dispatch(createSuccessAction(ACTION_PASSWORD_REQUEST_NEW, result))
  dispatch(
    addSuccessToast({
      message: 'We have sent the instructions to reset the password.',
    })
  )
}
export const requestNewPasswordInProgress = (payload) =>
  createInProgressAction(ACTION_PASSWORD_REQUEST_NEW, payload)
export const requestNewPasswordError = (error) =>
  createErrorAction(ACTION_PASSWORD_REQUEST_NEW, error)

// Reset password (/reset)
export const requestResetPassword = (payload) => async (dispatch) => {
  dispatch(createRequestAction(ACTION_PASSWORD_RESET, payload))
  dispatch(resetPasswordInProgress(payload))
  try {
    const result = await resetPassword({
      token: payload.token,
      passwordHash: hash(payload.password),
    })
    dispatch(resetPasswordSuccess(result))
  } catch (error) {
    dispatch(resetPasswordError(error))
  }
}
export const resetPasswordSuccess = (result) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: TOAST_MESSAGES.RESET_PASSWORD_SUCCESS,
    })
  )
  return dispatch(createSuccessAction(ACTION_PASSWORD_RESET, result))
}
export const resetPasswordInProgress = (payload) =>
  createInProgressAction(ACTION_PASSWORD_RESET, payload)
export const resetPasswordError = (error) => (dispatch) => {
  const msg =
    TOAST_MESSAGES[`RESET_PASSWORD_ERROR_${error.status}`] ||
    TOAST_MESSAGES.RESET_PASSWORD_ERROR
  dispatch(
    addErrorToast({
      message: msg,
    })
  )
  return dispatch(createErrorAction(ACTION_PASSWORD_RESET, error))
}

// Reset password (/accept-invitation)
const ACTION_ACCEPT_INVITATION = `${ACTION_BASE}_ACCEPT_INVITATION`
export const [
  AUTH_ACCEPT_INVITATION_REQUEST,
  AUTH_ACCEPT_INVITATION_RESULT,
  AUTH_ACCEPT_INVITATION_SUCCESS,
  AUTH_ACCEPT_INVITATION_ERROR,
  AUTH_ACCEPT_INVITATION_CANCEL,
  AUTH_ACCEPT_INVITATION_IN_PROGRESS,
] = createBasicActionTypes(ACTION_ACCEPT_INVITATION)
export const acceptInvitation = (payload) => async (dispatch) => {
  dispatch(createRequestAction(ACTION_ACCEPT_INVITATION, payload))
  dispatch(acceptInvitationInProgress(payload))
  try {
    const result = await acceptInvitationApi({
      ...payload,
      password: hash(payload.password),
    })
    dispatch(acceptInvitationSuccess(result))
  } catch (error) {
    dispatch(acceptInvitationError(error))
  }
}
export const acceptInvitationSuccess = (result) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: TOAST_MESSAGES.AUTH_ACCEPT_INVITATION_SUCCESS,
    })
  )
  return dispatch(createSuccessAction(ACTION_ACCEPT_INVITATION, result))
}
export const acceptInvitationInProgress = (payload) =>
  createInProgressAction(ACTION_ACCEPT_INVITATION, payload)
export const acceptInvitationError = (error) => (dispatch) => {
  const msg =
    TOAST_MESSAGES[`AUTH_ACCEPT_INVITATION_ERROR_${error.status}`] ||
    TOAST_MESSAGES.AUTH_ACCEPT_INVITATION_ERROR
  dispatch(
    addErrorToast({
      message: msg,
    })
  )
  return dispatch(createErrorAction(ACTION_ACCEPT_INVITATION, error))
}

// update password (logged in user)
export const requestUpdatePassword = (payload) => async (dispatch) => {
  dispatch(createRequestAction(ACTION_PASSWORD_UPDATE, payload))
  dispatch(updatePasswordInProgress(payload))
  try {
    const result = await updatePassword({
      ...payload,
      password: hash(payload.password),
      newPassword: hash(payload.newPassword),
    })
    dispatch(updatePasswordSuccess(result))
  } catch (error) {
    dispatch(updatePasswordError(error))
  }
}
export const updatePasswordSuccess = (result) =>
  createSuccessAction(ACTION_PASSWORD_UPDATE, result)
export const updatePasswordInProgress = (payload) =>
  createInProgressAction(ACTION_PASSWORD_UPDATE, payload)
export const updatePasswordError = (error) =>
  createErrorAction(ACTION_PASSWORD_UPDATE, error)
