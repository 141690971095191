import { createSauceStore, REDUCER_TYPES } from '@weareroam/sauce-web-store'
import { api as client, getPayloadFromResponse } from '@weareroam/sauce-web'
import { createSelector } from 'reselect'

export const store = createSauceStore({
  name: 'vessels',
  type: REDUCER_TYPES.LIST,
})

const { actions, api } = store
api.import = async (payload, config = {}) => {
  const { file, portUuid } = payload
  const data = new FormData()
  data.append('file', file)
  data.append('source', 'EMPX admin csv upload')
  data.append('portUUID', portUuid)
  return client.post(`/vessels/upload`, data, {
    ...config,
    headers: {
      ...(config.headers || {}),
      'Content-Type': 'multipart/form-data',
    },
  })
}

const IMPORT = 'VESSELS_IMPORT'
const IMPORT_IN_PROGRESS = `${IMPORT}_IN_PROGRESS`
const IMPORT_SUCCESS = `${IMPORT}_SUCCESS`
const IMPORT_ERROR = `${IMPORT}_ERROR`
actions.import = ({ file, port }) => async (dispatch) => {
  try {
    const result = await api.import(
      {
        file,
        portUuid: port.uuid,
      },
      {
        onUploadProgress: (event) => {
          dispatch(
            actions.import.inProgress({
              percent: (event.loaded * 100) / event.total,
            })
          )
        },
      }
    )
    return dispatch(actions.import.success(getPayloadFromResponse(result)))
  } catch (error) {
    return dispatch(actions.import.error(error))
  }
}
actions.import.type = IMPORT
actions.import.toString = () => actions.import.type
actions.import.error = (error) => ({
  type: IMPORT_ERROR,
  error,
})
actions.import.error.type = IMPORT_ERROR
actions.import.error.toString = () => actions.import.error.type
actions.import.success = (payload) => ({
  type: IMPORT_SUCCESS,
  payload,
})
actions.import.success.type = IMPORT_SUCCESS
actions.import.success.toString = () => actions.import.success.type
actions.import.inProgress = (payload) => ({
  type: IMPORT_IN_PROGRESS,
  payload,
})
actions.import.inProgress.type = IMPORT_IN_PROGRESS
actions.import.inProgress.toString = () => actions.import.inProgress.type

store.reducer.use((state, action) => {
  const { type, payload } = action
  switch (type) {
    case store.actions.import.type:
      return {
        ...state,
        upload: payload,
      }
    default:
      return state
  }
})

store.selectors.uploadProgress = createSelector(
  store.selectors.state,
  (state) => state && state.upload && state.upload.percent
)

export default store
