import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled, { css } from 'styled-components'
import { Box, List, ListItem, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/CancelOutlined'
import { ReactSortable as Sortable } from 'react-sortablejs'
import uuidv1 from 'uuid'
import MovementTypeSelector from 'src/components/molecules/MovementTypeSelector'
import { extraByNameSelector } from 'src/store/extra/selectors'
import useChecklist from 'src/hooks/useChecklist'
import Fieldset from 'src/components/organisms/Fieldset'
import Preloader from 'src/components/atoms/Preloader'
import { EXTRA_NAME, EXTRA_TYPE } from 'src/constants/settings'
import { PortShape } from 'src/utils/types'
import ValuedIconButton from 'src/components/atoms/ValuedIconButton'
import InfoIcon from 'src/components/atoms/Icons/InfoIcon'
import FormDialog from 'src/components/molecules/FormDialog/FormDialog'

import TimestampItemForm from 'src/components/organisms/TimestampItemForm/'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import { ChecklistPdfExportToggle } from './../Checklist/ChecklistPdfExportToggle'

const StyledListItem = styled(ListItem)(
  ({ theme }) => css`
    && {
      padding: ${theme.spacing(0, 0, 0, 2)};
      margin-bottom: ${theme.spacing(2)}px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      border-radius: 5px;
      font-size: ${theme.typography.pxToRem(16)};

      .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
      }
      &:hover {
        cursor: pointer;
      }

      &.ghost {
        background-color: rgba(142, 208, 255, 0.2);
      }
    }
  `
)

const TimestampChecklist = ({ port }) => {
  const type = EXTRA_TYPE.TIMESTAMP_CHECKLIST
  const name = EXTRA_NAME[type]

  const checklistExtra = useSelector(extraByNameSelector(name, true, port.uuid))
  const [editingItem, setEditingItem] = useState(null)

  const { fields, loaded, dirty, handleSave } = useChecklist({
    extraType: EXTRA_TYPE.TIMESTAMP_CHECKLIST,
    checklistExtra,
    defaultItems: [],
  })
  const { checklist } = fields

  const saveChecklist = () => handleSave(port)

  const onAdd = () => {
    setEditingItem({
      label: '',
      refId: '',
      uuid: uuidv1(),
    })
  }

  const onRemove = (evt, value) => {
    // Stop click propagating to parent causing modal to open
    evt.stopPropagation()
    updateItems(checklist.value.filter((item) => item.uuid !== value.uuid))
  }

  const updateItems = (items) => {
    checklist.onChange('checklist', items)
  }

  const editItem = (value) => {
    setEditingItem(value)
  }

  const stopEditing = () => {
    setEditingItem(null)
  }

  const limit = 100
  const values = checklist.value || []
  const hasReachedLimit = values.length >= 100
  const isExistingItem =
    editingItem && values.find((v) => v.uuid === editingItem.uuid)

  const onDone = async (item) => {
    let items
    if (values.find((val) => val.uuid === item.uuid)) {
      items = values.map((val) => (val.uuid === item.uuid ? item : val))
    } else {
      items = [...values, item]
    }
    updateItems(items)
    stopEditing()
  }

  const onListChange = (value) => {
    // Remove the properties added by sortable off the items.
    // If we do it in an immutable way useForm will detect the form as changed
    // even if the order is left the same.
    value.forEach((item) => {
      delete item.chosen
      delete item.selected
      delete item.filtered
    })
    updateItems(value)
  }

  return (
    <Fieldset
      title="Timestamp Checklist"
      subtitle="Define which events you would like to track with timestamps"
      description="Timestamp Checklist for the pilot app"
      hasButton
      clickHandler={onAdd}
      buttonDisabled={hasReachedLimit}
      buttonContents="Add Item"
    >
      {!loaded && <Preloader />}
      {loaded && values.length === 0 && <Box mb={1}>No items added yet.</Box>}
      {loaded && values.length > 0 && (
        <>
        <Sortable
          tag={List}
          list={values}
          setList={onListChange}
          animation={150}
          ghostClass="ghost"
        >
          {values.map((value) => (
            <StyledListItem key={value.uuid}>
              <Box flex={1} onClick={() => editItem(value)}>
                <Typography variant="subtitle2" display="inline">
                  {value.label}
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  display="inline"
                >
                  {value.refId ? ` (${value.refId})` : null}
                </Typography>
              </Box>

              <MovementTypeSelector
                showHeaderSwitch={false}
                item={value}
                onChange={onDone}
              />

              <Box>
                <ValuedIconButton value={value} onClick={onRemove}>
                  <CloseIcon color="action" />
                </ValuedIconButton>
              </Box>
            </StyledListItem>
          ))}
        </Sortable>
        <ChecklistPdfExportToggle flagName="show_timestamps_in_master_view" />
        </>
      )}
      {dirty && (
        <PrimaryButton
          onClick={saveChecklist}
          variant="contained"
          color="primary"
        >
          Save
        </PrimaryButton>
      )}
      {hasReachedLimit && (
        <Box mt={2} display="flex" alignItems="center">
          <Box pr={2}>
            <InfoIcon size={24} />
          </Box>
          <Box>
            <Typography variant="body1" gutterTop>
              Limit of {limit} items reached
            </Typography>
          </Box>
        </Box>
      )}
      {editingItem && (
        <FormDialog
          open
          entity={editingItem}
          title={`${isExistingItem ? 'Edit' : 'Add'} Timestamp (Event)`}
          saveButtonLabel="Done"
          onCancel={stopEditing}
          onSave={onDone}
          FormComponent={TimestampItemForm}
        />
      )}
    </Fieldset>
  )
}

TimestampChecklist.propTypes = {
  port: PropTypes.shape(PortShape),
}

export default React.memo(TimestampChecklist)
