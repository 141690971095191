import { createSelector } from 'reselect'
import { selectedPortSelector } from 'src/store/port/selectors'

export const getByIdFromList = (list = []) =>
  list.reduce(
    (byId, item) => ({
      ...byId,
      [item.uuid]: item,
    }),
    {}
  )
export const getIdsFromList = (list = []) => list.map((item) => item.uuid)

export const createGetListFilteredByPortSelector = (listSelector) => (
  portUuid
) =>
  createSelector(
    listSelector,
    (list) =>
      list &&
      (portUuid
        ? list.filter(({ port }) => (port && port.uuid) === portUuid)
        : list)
  )

const filterByPortUuid = (invite, portUuid) => {
  if (invite.user && invite.user.identity && invite.user.identity.portIdentities) {
    return invite.user.identity.portIdentities.find((portIdentity) => portIdentity.port.uuid === portUuid)
  }
  return invite.portValidationTokens.find((portValidationToken) => portValidationToken.port.uuid === portUuid)
}

export const createGetListFilteredByPortSelector2 = (listSelector) => (
  portUuid
) => {
  return createSelector(
    listSelector,
    (list) =>
      list &&
      (portUuid
        ? list.filter((invite) => filterByPortUuid(invite, portUuid))
        : list)
  )
}

export const createGetListFilteredBySelectedPortSelector = (listSelector) =>
  createSelector(
    listSelector,
    selectedPortSelector,
    (list, portUuid) =>
      list &&
      (portUuid
        ? list && list.filter(({ port }) => (port && port.uuid) === portUuid)
        : list)
  )

  export const createGetListFilteredBySelectedPortSelector2 = (listSelector) =>
  createSelector(
    listSelector,
    selectedPortSelector,
    (list, portUuid) =>
      list &&
      (portUuid
        ? list.filter((invite) => filterByPortUuid(invite, portUuid))
        : list)
  )
