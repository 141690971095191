import { useDispatch, useSelector } from 'react-redux'
import {
  userListSelector,
  userListStatusSelector,
} from 'src/store/user/selectors'
import {
  USER_LIST_GET_SUCCESS,
  USER_LIST_GET_ERROR,
  getUserList,
  USER_LIST_GET_IN_PROGRESS,
} from 'src/store/user/actions'
import { useCallback } from 'react'
import { isLoggedInSelector } from 'src/store/auth/selectors'

const useUsersData = () => {
  const dispatch = useDispatch()
  const data = useSelector(userListSelector)
  const status = useSelector(userListStatusSelector)
  const reFetch = useCallback(() => dispatch(getUserList()), [dispatch])
  const isLoggedIn = useSelector(isLoggedInSelector)
  if (status === null && isLoggedIn) {
    reFetch()
  }
  return {
    data,
    reFetch,
    status,
    isLoading: USER_LIST_GET_IN_PROGRESS === status,
    isLoaded: [USER_LIST_GET_SUCCESS, USER_LIST_GET_ERROR].includes(status),
    isFailed: USER_LIST_GET_ERROR === status,
  }
}

export default useUsersData
