import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import SectionHeader from 'src/components/molecules/SectionHeader'
import PrimaryButton from 'src/components/atoms/PrimaryButton'

const StyledFieldset = styled(Grid)`
  position: relative;
`

const FieldsetModal = styled.div`
  background: ${({
    theme: {
      palette: { background },
    },
  }) => background.default}
  bottom: 0;
  left: 0;
  opacity: .8;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
`

export const Fieldset = ({
  children,
  title,
  disabled,
  dirty = false,
  contentProps = {},
  fullWidth = false,
  onSave = null,
  infoTooltip = null,
  subtitle,
  clickHandler,
  buttonContents,
  hasButton,
  isButtonReset = false,
  buttonDisabled = false,
  selectHandler = null,
  routeAttachmentSelectHandler = null,
  routeAttachmentRejectHandler = null,
  ...gridProps
}) => {
  return (
    <StyledFieldset item container xs={fullWidth ? 12 : 9} {...gridProps}>
      {disabled && <FieldsetModal />}
      <SectionHeader
        dirty={dirty}
        infoTooltip={infoTooltip}
        clickHandler={clickHandler}
        buttonContents={buttonContents}
        hasButton={hasButton}
        isButtonReset={isButtonReset}
        subtitle={subtitle}
        title={title}
        buttonDisabled={buttonDisabled}
        selectHandler={selectHandler}
        routeAttachmentSelectHandler={routeAttachmentSelectHandler}
        routeAttachmentRejectHandler={routeAttachmentRejectHandler}
      />
      <Grid item xs={12} md={12} {...contentProps}>
        {children}
      </Grid>
      {onSave && dirty && (
        <PrimaryButton onClick={onSave} variant="contained" color="primary">
          Save
        </PrimaryButton>
      )}
    </StyledFieldset>
  )
}

Fieldset.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  disabled: PropTypes.bool,
  showContent: PropTypes.bool,
  dirty: PropTypes.bool,
  fullWidth: PropTypes.bool,
  contentProps: PropTypes.object,
  onSave: PropTypes.func,
  subtitle: PropTypes.string,
  infoTooltip: PropTypes.string,
  clickHandler: PropTypes.func,
  buttonContents: PropTypes.node,
  hasButton: PropTypes.bool,
  isButtonReset: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  selectHandler: PropTypes.func,
  routeAttachmentSelectHandler: PropTypes.func,
  routeAttachmentRejectHandler: PropTypes.func,
}

export default Fieldset
