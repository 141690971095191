import { Dispatch, FlagName } from './types'
import { FeatureFlags } from 'src/utils/sauce/sauce-api-preferences/FeatureFlags'

export const featureFlagsSetLoading = (loading: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({ type: 'FEATURE_FLAGS_SET_LOADING', loading })
}
export const featureFlagsSetUpdating = (updating: boolean) => (
  dispatch: Dispatch
) => {
  dispatch({ type: 'FEATURE_FLAGS_SET_UPDATING', updating })
}
export const featureFlagsSetError = (error?: Error) => (dispatch: Dispatch) => {
  dispatch({ type: 'FEATURE_FLAGS_SET_ERROR', error })
}
export const featureFlagsSetFlags = (payload: {
  flags?: FeatureFlags
  uuid?: string
}) => (dispatch: Dispatch) => {
  dispatch({ type: 'FEATURE_FLAGS_SET_FLAGS', ...payload })
}
export const featureFlagsSetFlag = (payload: {
  flagKey: FlagName
  flagMetaData: FeatureFlags[FlagName]
}) => (dispatch: Dispatch) => {
  dispatch({ type: 'FEATURE_FLAGS_SET_FLAG', ...payload })
}
