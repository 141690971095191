import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { IconButton, Typography, Tooltip, Box, TablePagination } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import { PortShape } from 'src/utils/types'
import Fieldset from 'src/components/organisms/Fieldset'
import {
  getListFilteredBySelectedPort,
  getListFilteredByPort,
  constraintListSelector,
} from 'src/store/constraint/selectors'
import {
  CONSTRAINT_UNITS,
  PAGINATION_ROWS_PER_PAGE_OPTIONS,
} from 'src/constants/settings'
import ConstraintFormDialog from 'src/components/organisms/ConstraintFormDialog'
import {
  getConstraintList,
  deleteConstraint,
} from 'src/store/constraint/actions'
import { EditIcon } from 'src/components/atoms/Icons'
import EmptyState from 'src/components/atoms/EmptyState'
import DataTable from 'src/components/molecules/DataTable'
import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { addToast } from 'src/store/toast/actions'
import {
  TOAST_VARIANT_SUCCESS,
  TOAST_VARIANT_ERROR,
} from 'src/containers/Toast'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import { formatConstraintType } from 'src/utils/formatters'
import store from 'src/store/stations'
import { tideStationByIdSelector } from 'src/store/tideStations/selectors'
import { compareName } from 'src/utils/sorting'
import { tideRateStationByIdSelector } from 'src/store/tideRateStations/selectors'
import useFeatureFlags from 'src/hooks/useFeatureFlags'

const EntityName = ({ uuid, selector }) => {
  const entity = useSelector(selector(uuid))
  return (
    <span>
      {entity ? entity.name : ''}
      {!entity && uuid && (
        <Tooltip title="Deleted">
          <Typography variant="caption" color="error">
            N/A
          </Typography>
        </Tooltip>
      )}
    </span>
  )
}
EntityName.propTypes = {
  uuid: PropTypes.string,
  selector: PropTypes.func,
}

const getColumnDefinitions = ({ onRemove, onEdit, showTideRateStation }) => [
  {
    field: 'name',
    title: 'Name',
  },
  {
    field: 'value',
    title: 'Avail. depth',
    labelFunction: (value) => `${value} m`,
  },
  {
    field: 'constraintType',
    title: 'Type',
    labelFunction: (type) => formatConstraintType(type),
  },
  {
    field: 'safetyMargin',
    title: 'Safety Margin',
    labelFunction: (safetyMargin, item) => {

      const { safetyMarginM, safetyMarginPct } = item

      const metres = typeof safetyMarginM === 'number' && `${safetyMarginM}m`
      const pct = typeof safetyMarginPct === 'number' && `${safetyMarginPct}%`

      if (metres || pct) {
        return <div style={{ whiteSpace: 'nowrap' }}>{
          [metres, pct].filter(s => s).join(' or ')
        }</div>
      }

      const legacy = typeof safetyMargin === 'number' && item.unit
      return legacy ? `${safetyMargin} ${item.unit}` : '--'
    }
  },
  {
    field: 'berthStation',
    title: 'Berth / PBP',
    // eslint-disable-next-line react/display-name
    labelFunction: (berthStation) => (
      <EntityName
        selector={store.selectors.getById}
        uuid={berthStation && berthStation.uuid}
      />
    ),
  },
  {
    field: 'tideStation',
    title: 'Tide Station',
    // eslint-disable-next-line react/display-name
    labelFunction: (tideStation) => (
      <EntityName
        selector={tideStationByIdSelector}
        uuid={tideStation && tideStation.uuid}
      />
    ),
  },
  showTideRateStation &&
  {
    field: 'tideRateStation',
    title: 'Tide Rate Station',
    // eslint-disable-next-line react/display-name
    labelFunction: (tideRateStation) => (
      <EntityName
        selector={tideRateStationByIdSelector}
        uuid={tideRateStation && tideRateStation.uuid}
      />
    ),
  },
  {
    field: 'uuid',
    title: ' ',
    // eslint-disable-next-line react/display-name
    labelFunction: (uuid, item) => (
      <div>
        <Tooltip title={`Edit ${item.name} constraint`}>
          <IconButton onClick={() => onEdit(item)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={`Remove ${item.name} constraint`}>
          <IconButton onClick={() => onRemove(uuid)}>
            <HighlightOffIcon size={16} />
          </IconButton>
        </Tooltip>
      </div>
    ),
  },
].filter(n => n)

export const ConstraintList = ({ port, disabled, disableFilterByPort }) => {

  const { getFlag } = useFeatureFlags()
  const flagName = 'tide_rate_source'
  const tideRateSource = getFlag(flagName) || 'config'

  const dispatch = useDispatch()
  // the constraint list is always filtered by port
  // defined in the props or by the global selected port
  const constraintsWithWaypoints = useSelector(
    disableFilterByPort
      ? constraintListSelector
      : port
        ? getListFilteredByPort(port.uuid)
        : getListFilteredBySelectedPort
  )

  let constraints = []
  if (constraintsWithWaypoints) {
    constraints = constraintsWithWaypoints.filter(constraint => !constraint.isWaypoint)
  }

  useEffect(() => {
    dispatch(store.actions.getList())
  }, [dispatch])

  const [selectedConstraint, setSelectedConstraint] = useState(null)
  const [selectedConstraintToRemove, setSelectedConstraintToRemove] = useState(
    null
  )

  const handleAddNewConstraint = () => {
    setSelectedConstraint({
      name: '',
      value: 0,
      safetyMargin: 0,
      unit: CONSTRAINT_UNITS.METERS,
      berthStation: null,
    })
  }

  const handleOnEdit = (item) => setSelectedConstraint(item)
  const handleOnRemove = useCallback(
    (uuid) =>
      setSelectedConstraintToRemove(
        constraints && constraints.find((item) => item.uuid === uuid)
      ),
    [constraints]
  )
  const handleCancelRemove = () => setSelectedConstraintToRemove(null)
  const handleConfirmRemove = () => {
    dispatch(deleteConstraint(selectedConstraintToRemove)).then(
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_SUCCESS,
            message: getToastMessage(
              TOAST_MESSAGES.CONSTRAINT_DELETE_SUCCESS,
              selectedConstraintToRemove
            ),
          })
        )
        setSelectedConstraintToRemove(null)
      },
      () => {
        dispatch(
          addToast({
            variant: TOAST_VARIANT_ERROR,
            message: getToastMessage(
              TOAST_MESSAGES.CONSTRAINT_DELETE_ERROR,
              selectedConstraintToRemove
            ),
          })
        )
        setSelectedConstraintToRemove(null)
      }
    )
  }

  useEffect(() => {
    dispatch(getConstraintList())
  }, [dispatch])

  const handleFormDialogCloseRequest = () => setSelectedConstraint(null)

  // Pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(12)

  const handlePaginationChangePage = (event, selectedPage) =>
    setPage(selectedPage)

  const handlePaginationChangeRowsPerPage = ({ target: { value } }) => {
    setRowsPerPage(value)
    setPage(0)
  }

  const sortedConstraints = constraints ? constraints.sort(compareName) : []
  const currentPageConstraints = sortedConstraints.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  )

  return (
    <>
      <Fieldset
        disabled={disabled}
        title="Constraints"
        description="Define your constraints"
        buttonContents="Add Constraint"
        clickHandler={handleAddNewConstraint}
        hasButton
      >
        {sortedConstraints && sortedConstraints.length > 0 && (
          <>
            <Box mb={1}>
              <DataTable
                columns={getColumnDefinitions({
                  onRemove: handleOnRemove,
                  onEdit: handleOnEdit,
                  onNew: handleAddNewConstraint,
                  showTideRateStation:
                    tideRateSource === 'csv' &&
                    sortedConstraints &&
                    sortedConstraints.find(({ tideRateStation }) => !!tideRateStation)
                })}
                dataProvider={currentPageConstraints}
              />
            </Box>

            <TablePagination
              component={Box}
              count={sortedConstraints.length}
              page={page}
              rowsPerPageOptions={PAGINATION_ROWS_PER_PAGE_OPTIONS}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage="Constraints per page:"
              onChangePage={handlePaginationChangePage}
              onChangeRowsPerPage={handlePaginationChangeRowsPerPage}
            />
          </>
        )}

        {(!sortedConstraints || !sortedConstraints.length) && (
          <EmptyState message="No constraints yet." />
        )}

      </Fieldset>
      {selectedConstraint && (
        <ConstraintFormDialog
          port={port}
          constraint={selectedConstraint}
          onCloseRequest={handleFormDialogCloseRequest}
          open={!!selectedConstraint}
        />
      )}
      {selectedConstraintToRemove && (
        <ConfirmDialog
          open={!!selectedConstraintToRemove}
          onCancel={handleCancelRemove}
          onConfirm={handleConfirmRemove}
          title={`Remove constraint: ${selectedConstraintToRemove.name}`}
        >
          Are you sure you want to remove this constraint?
          <br />
          {selectedConstraintToRemove.name}
        </ConfirmDialog>
      )}
    </>
  )
}

ConstraintList.propTypes = {
  port: PropTypes.shape(PortShape),
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
}

export default ConstraintList
