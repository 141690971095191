import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { compose } from 'recompose'
import { withRouter } from 'react-router5'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'

import App from 'src/containers/App'
import GlobalStyle from 'src/styles/GlobalStyles'
import Toast from 'src/containers/Toast'

const RootLayout = ({ theme }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <React.Fragment>
          <CssBaseline />
          <GlobalStyle />
          <App />
          <Toast />
        </React.Fragment>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

RootLayout.propTypes = {
  theme: PropTypes.object,
}

export default compose(
  withRouter,
  React.memo
)(RootLayout)
