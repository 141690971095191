import { SSO_DOMAINS_WHITELIST, SSO_ENABLE_LOCALHOST, SSO_NOT_ENFORCE_HTTPS_PROTOCOL, SSO_NOT_ENFORCE_NO_PORT, SSO_ENABLE_CONSOLE_LOG } from './ssoConstants'

export const isLocalhost =
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/.test(
    window.location.hostname
  )

// This method helps provide backward compatibility with existing codebase that
// uses a single Login page for non-SSO tenants. In all the places that we need
// to route the page back to the login page, we use this method to check if the caller
// is from an SSO or non-SSO domain. If non-SSO, it routes to the existing Login page,
// else routes to the new SSO Login page.
export const isHostSsoDomain = () => {
  // non-sso domains (ie before introduction of SSO domains)
  const SSO_DOMAINS_RESERVED = [
    'https://dev.admin.empx.app',
    'https://qa.admin.empx.app',
    'https://uat.admin.empx.app',
    'https://stg.admin.empx.app',
    'https://eu.admin.empx.app',
    'https://admin.empx.app',
  ]
  if (SSO_ENABLE_CONSOLE_LOG) {
    console.log(`isHostSsoDomain origin [${window.location.origin}] hostname [${window.location.hostname}] window.location.port [${window.location.port}]`)
  }

  if (SSO_ENABLE_LOCALHOST && isLocalhost) {
    // for localhost, we don't restrict the port
    return true
  } else if (SSO_DOMAINS_RESERVED.includes(window.location.origin)) {
    return false
  } else if (SSO_DOMAINS_WHITELIST.includes(window.location.origin)) {
    return true
  } else {
    // check for subdomain here
    if (window.location.origin) {
      const protocolOkToProceed = window.location.protocol === 'https:' || SSO_NOT_ENFORCE_HTTPS_PROTOCOL
      const portOkToProceed = !window.location.port || SSO_NOT_ENFORCE_NO_PORT
      const hostNameOkToProceed = window.location.hostname.endsWith('empx.app')
      if (SSO_ENABLE_CONSOLE_LOG) {
        console.log(`protocolOkToProceed [${protocolOkToProceed}] portOkToProceed [${portOkToProceed}] hostNameOkToProceed [${hostNameOkToProceed}]`)
      }
      if (protocolOkToProceed && portOkToProceed && hostNameOkToProceed) {
        if (SSO_ENABLE_CONSOLE_LOG) {
          console.log(`empx subdomain found [${window.location.origin}]`)
        }
        return true
      }
    } else {
      return false
    }
  }
}

// sleep can be moved into a more generic util class if/when it is used by other components.
// for now, it is placed here as it is only used by SSO work.
export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
