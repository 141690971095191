import { createSelector } from 'reselect'
import {
  PORT_LIST_GET_IN_PROGRESS,
  PORT_LIST_GET_SUCCESS,
  PORT_LIST_GET_ERROR,
} from 'src/store/port/actions'

export const portStateSelector = (state) => state.port

export const portListStatusSelector = createSelector(
  portStateSelector,
  (state) => state && state.status
)

export const isPortLoadingSelector = createSelector(
  portListStatusSelector,
  (status) => status === PORT_LIST_GET_IN_PROGRESS
)

export const isPortLoadedSelector = createSelector(
  portListStatusSelector,
  (status) => status === PORT_LIST_GET_SUCCESS
)

export const portDataHasErrorSelector = createSelector(
  portListStatusSelector,
  (status) => status === PORT_LIST_GET_ERROR
)

export const byIdSelector = createSelector(
  portStateSelector,
  (state) => state.byId
)

export const dataSelector = createSelector(
  portStateSelector,
  (state) => state.data
)

export const portListSelector = createSelector(
  byIdSelector,
  dataSelector,
  (byId, data) => data && byId && data.map((uuid) => byId[uuid])
)

export const selectedPortSelector = createSelector(
  portStateSelector,
  (state) => state.selected
)

export const getSelectedPortSelector = createSelector(
  selectedPortSelector,
  byIdSelector,
  (uuid, byId) => uuid && byId && byId[uuid]
)

export const getSelectedPortTimeZoneSelector = createSelector(
  getSelectedPortSelector,
  (port) => port && port.timezone
)

export const userPortSelector = createSelector(
  portListSelector,
  (ports) => (ports && ports.length ? ports[0] : null)
)

export const userPortUuidSelector = createSelector(
  userPortSelector,
  (port) => port && port.uuid
)

export const portByIndexSelector = (index) =>
  createSelector(
    portStateSelector,
    (state) => state.data && state.data[index] && state.byId[state.data[index]]
  )

export const portByIdSelector = (uuid) =>
  createSelector(
    portStateSelector,
    (state) => state.byId && state.byId[uuid]
  )

export const totalPortSelector = createSelector(
  portStateSelector,
  (state) => state.data && state.data.length
)

export const errorByIdSelector = (uuid) =>
  createSelector(
    portStateSelector,
    (state) => state.errorById && state.errorById[uuid]
  )

export const statusByIdSelector = (uuid) =>
  createSelector(
    portStateSelector,
    (state) => state.statusById && state.statusById[uuid]
  )
