import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'
import { createTideRateData as createTideRateDataApi } from 'src/utils/sauce/sauce-api-tide-rate-data'
import { addSuccessToast } from 'src/store/toast/actions'
import TOAST_MESSAGES, { getToastMessage } from 'src/utils/toastMessages'
import { getTideRateStation } from 'src/store/tideRateStations/actions'

export const TIDE_RATE_DATA_ACTION = 'TIDE_RATE_DATA'
export const TIDE_RATE_DATA_CREATE = `${TIDE_RATE_DATA_ACTION}_CREATE`
export const [
  TIDE_RATE_DATA_CREATE_REQUEST,
  TIDE_RATE_DATA_CREATE_RESULT,
  TIDE_RATE_DATA_CREATE_SUCCESS,
  TIDE_RATE_DATA_CREATE_ERROR,
  TIDE_RATE_DATA_CREATE_CANCEL,
  TIDE_RATE_DATA_CREATE_IN_PROGRESS,
] = createBasicActionTypes(TIDE_RATE_DATA_CREATE)

export const createTideRateData = (payload) => (dispatch) => {
  dispatch({ type: TIDE_RATE_DATA_CREATE, payload })
  return dispatch(createTideRateDataRequest(payload))
}
export const createTideRateDataRequest = (payload) => async (dispatch) => {
  dispatch(createTideRateDataInProgress(payload))
  try {
    const tideRateData = await createTideRateDataApi(payload, {
      timeout: 0,
      onUploadProgress: (event) => {
        dispatch(
          createTideRateDataInProgress({
            ...payload,
            percent: Math.floor((event.loaded * 100) / event.total),
          })
        )
      },
    })
    return dispatch(
      createTideRateDataSuccess({
        ...payload,
        tideRateData,
      })
    )
  } catch (error) {
    return dispatch(createTideRateDataError({ ...error, payload }))
  }
}
export const createTideRateDataInProgress = (payload) =>
  createInProgressAction(TIDE_RATE_DATA_CREATE, payload)

export const createTideRateDataSuccess = (payload) => async (dispatch) => {
  // we use await so we display success toast only
  // when the tide station is updated with the new
  // tide data range information
  const { payload: tideRateStation } = await dispatch(
    getTideRateStation(payload.tideRateStation.uuid)
  )
  dispatch(
    addSuccessToast({
      message: getToastMessage(
        TOAST_MESSAGES.TIDE_RATE_DATA_CREATE_SUCCESS,
        tideRateStation
      ),
    })
  )
  return dispatch(
    createSuccessAction(TIDE_RATE_DATA_CREATE, { ...payload, tideRateStation })
  )
}

export const createTideRateDataError = (error) => (dispatch) => {
  return dispatch(createErrorAction(TIDE_RATE_DATA_CREATE, error))
}
