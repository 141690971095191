import React from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { Snackbar, SnackbarContent, IconButton, Box } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'

import { markToastAsSeen } from 'src/store/toast/actions'
import { nextUnseenToastSelector } from 'src/store/toast/selectors'

const StyledSnackbar = styled(Snackbar)`
  && {
    top: 0;
    padding: 0;
  }
`

const StyledSnackbarContent = styled(SnackbarContent)`
  && {
    background-color: ${({ theme }) => theme.palette.background.paper};
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    color: ${({ theme }) => theme.palette.text.secondary};
    font-size: ${({ theme }) => theme.typography.pxToRem(13)};
    font-weight: ${({ theme }) => theme.weights.fontWeightBold};
    padding: 0 1em;
    display: flex;
    align-items: flex-start;
    .MuiSnackbarContent-message {
      flex: 1;
      padding-right: 1em;
    }
    .MuiSnackbarContent-action {
      padding-left: 0;
    }
    .icon {
      opacity: 0.7;
      margin-right: ${({ theme }) => theme.spacing(1)}px;
      color: ${({ theme }) => theme.palette.toast.main};
    }
    .error-icon {
      color: ${({ theme }) => theme.palette.toast.error};
    }
    .success-icon {
      color: ${({ theme }) => theme.palette.toast.success};
    }
  }
`

export const TOAST_VARIANT_ERROR = 'error'
export const TOAST_VARIANT_SUCCESS = 'success'
export const TOAST_VARIANT_INFO = 'info'

const DEFAULT_AUTOHIDE_DURATION = 8000

const Toast = () => {
  const nextUnseenToast = useSelector(nextUnseenToastSelector)
  const dispatch = useDispatch()

  const closeToast = () =>
    nextUnseenToast && dispatch(markToastAsSeen(nextUnseenToast))
  const handleClose = (event, reason) => {
    if (reason !== 'clickaway' || variant !== TOAST_VARIANT_ERROR) {
      closeToast()
    }
  }

  const handleCloseBtnClick = () => closeToast()

  if (!nextUnseenToast) {
    return null
  }

  const { variant, message, autoHideDuration, id } = nextUnseenToast
  const actionButton = (
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      onClick={handleCloseBtnClick}
      size="small"
    >
      <CloseIcon size="small" />
    </IconButton>
  )

  return (
    <StyledSnackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open
      autoHideDuration={autoHideDuration || DEFAULT_AUTOHIDE_DURATION}
      onClose={handleClose}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down' }}
    >
      <StyledSnackbarContent
        aria-describedby={`client-snackbar-${id}`}
        message={
          <Box id={`client-snackbar-${id}`} display="flex" alignItems="center">
            {variant === TOAST_VARIANT_ERROR && (
              <WarningIcon className="icon error-icon" />
            )}
            {variant === TOAST_VARIANT_SUCCESS && (
              <CheckCircleIcon className="icon success-icon" />
            )}
            {variant === TOAST_VARIANT_INFO && (
              <InfoIcon className="icon info-icon" />
            )}
            {message}
          </Box>
        }
        action={actionButton}
      />
    </StyledSnackbar>
  )
}

Toast.propTypes = {
  classes: PropTypes.object,
  message: PropTypes.any,
  variant: PropTypes.oneOf([
    TOAST_VARIANT_SUCCESS,
    TOAST_VARIANT_ERROR,
    TOAST_VARIANT_INFO,
  ]),
  open: PropTypes.bool,
}

export default Toast
