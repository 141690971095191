import { encrypt, decrypt } from './crypto'

// REFRESH TOKEN
export const setRefreshTokenToStorage = (refreshToken) => {
  localStorage.setItem('refreshToken', encrypt(refreshToken))
}

export const getRefreshTokenFromStorage = () => {
  const encryptedToken = localStorage.getItem('refreshToken')
  if (encryptedToken) {
    return decrypt(encryptedToken)
  }
  return ''
}

export const removeRefreshTokenFromStorage = () => {
  localStorage.removeItem('refreshToken')
}

// IMAGE TOKEN
export const setImageTokenToStorage = (imageToken) => {
  localStorage.setItem('imageToken', encrypt(imageToken))
}

export const getImageTokenFromStorage = () => {
  const encryptedToken = localStorage.getItem('imageToken')
  if (encryptedToken) {
    return decrypt(encryptedToken)
  }
  return ''
}

export const removeImageTokenFromStorage = () => {
  localStorage.removeItem('imageToken')
}

// SESSION TOKEN
export const setTokenToStorage = (token) => {
  sessionStorage.setItem('token', encrypt(token))
}

export const getTokenFromStorage = () => {
  const encryptedToken = sessionStorage.getItem('token')
  if (encryptedToken) {
    return decrypt(encryptedToken)
  }
  return ''
}

export const removeTokenFromStorage = () => {
  sessionStorage.removeItem('token')
}

// USER DATA
// store user data in session storage, so we don't have to re-pull again
export const setUserToStorage = (user) => {
  sessionStorage.setItem('user', encrypt(JSON.stringify(user)))
}

export const getUserFromStorage = () => {
  try {
    const encryptedToken = sessionStorage.getItem('user')
    if (encryptedToken) {
      return JSON.parse(decrypt(encryptedToken))
    }
  } catch {}
  return null
}

export const removeUserFromStorage = () => {
  sessionStorage.removeItem('user')
}

export const storeAuthData = ({
  imageToken,
  token,
  refreshToken,
  user,
  roles,
}) => {
  setTokenToStorage(token)
  setRefreshTokenToStorage(refreshToken)
  setImageTokenToStorage(imageToken)
  if (user) {
    setUserToStorage({ ...user, roles })
  }
}

export const clearAuthData = () => {
  removeTokenFromStorage()
  removeRefreshTokenFromStorage()
  removeImageTokenFromStorage()
  removeUserFromStorage()
}
