import React from 'react'
import PropTypes from 'prop-types'

export const HelpIcon = ({ size = 16 }) => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-920.000000, -363.000000)" fill="#515151">
        <g transform="translate(843.000000, 48.000000)">
          <g transform="translate(35.000000, 93.000000)">
            <g transform="translate(0.000000, 222.000000)">
              <path d="M51.9577278,0 C45.9997278,0 41.2647278,5.127 42.0947278,11.125 C42.6237278,14.947 45.5227278,18.156 49.3197278,19.168 C50.7337278,19.545 52.1937278,19.625 53.6597278,19.372 C54.8837278,19.16 56.1397278,19.255 57.3387278,19.555 L58.7957278,19.919 L58.7957278,19.919 C60.6847278,20.392 62.4007278,18.711 61.9177278,16.859 C61.9177278,16.859 61.6477278,15.823 61.6397278,15.79 C61.3377278,14.63 61.2727278,13.405 61.5847278,12.248 C61.9707278,10.822 62.0357278,9.269 61.6887278,7.66 C60.7707278,3.415 56.8727278,0 51.9577278,0 M51.9577278,2 C55.9097278,2 59.0047278,4.71 59.7337278,8.083 C60.0007278,9.319 59.9737278,10.544 59.6547278,11.726 C58.3047278,16.718 62.2517278,18.722 57.8237278,17.614 C56.3507278,17.246 54.8157278,17.142 53.3187278,17.401 C52.1637278,17.601 50.9937278,17.544 49.8347278,17.236 C46.8077278,16.429 44.4927278,13.863 44.0757278,10.851 C43.4047278,5.997 47.2687278,2 51.9577278,2 L51.9577278,2" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

HelpIcon.propTypes = {
  size: PropTypes.number,
}

export default React.memo(HelpIcon)
