import React from 'react'
import styled, { css } from 'styled-components'
import TickIcon from 'src/components/atoms/Icons/TickIcon'

interface CheckboxIconProps {
  size?: number
}

const TickOutline = styled.div(
  ({ theme }) => css`
    background-color: ${theme.palette.status.complete};
    border: 1px solid ${theme.palette.status.complete};
    border-radius: 50%;
  `
)
const InactiveTickOutline = styled(TickOutline)(
  ({ theme }) => css`
    background-color: #fff;
    border: 1px solid ${theme.palette.text.primary};
    border-radius: 50%;
  `
)

export const CheckboxTickIcon: React.FC<CheckboxIconProps> = React.memo(
  ({ size = 28, ...props }) => (
    <TickOutline style={{height:size}} {...props}>
      <TickIcon size={size - 2} />
    </TickOutline>
  )
)

CheckboxTickIcon.displayName = 'TickIcon'

export const CheckboxInactiveTickIcon: React.FC<CheckboxIconProps> = React.memo(
  ({ size = 28, ...props }) => (
    <InactiveTickOutline style={{height:size}} {...props}>
      <TickIcon size={size - 2} inactive />
    </InactiveTickOutline>
  )
)

CheckboxInactiveTickIcon.displayName = 'InactiveTickIcon'
