import React from 'react'
import styled from 'styled-components'
import useFeatureFlags from 'src/hooks/useFeatureFlags'
import TideRateStations from 'src/containers/TideRateStations'
import Preloader from 'src/components/atoms/Preloader'

const TideRateSourceSelector = styled.div`
    display: flex;
    border: 1px solid #C5D0DE;
    border-radius: 8px;
    margin: 0px auto 30px auto;
    width: 475px;
    div {
        color: #61748D;
        font-weight: bold;
        padding: 15px 30px;
        text-transform: uppercase;
        cursor: pointer;
        text-align: center;
        flex: 1;
    }
    .active {
        color: #1991EB;
        background: rgba(25, 145, 235, 0.1);
    }
`

interface TideRateProps {
    port?: { uuid: string }
    disabled: boolean
}

const TideRate: React.FC<TideRateProps> = ({ port, disabled }) => {

    const { getFlag, setFlag, loading, flags } = useFeatureFlags()

    const flagName = 'tide_rate_source'
    const tideRateSource = getFlag(flagName) || 'config'
    const setTideRateSource = (source: 'config' | 'csv') => {
        setFlag(flagName, source)
    }

    const selector =
        loading && !flags ?
        <Preloader /> :
        <TideRateSourceSelector>
            <div
                className={tideRateSource === 'config' ? 'active' : ''}
                onClick={() => setTideRateSource('config')}>
                Use configured tidal rates
            </div>
            <div
                className={tideRateSource === 'csv' ? 'active' : ''}
                onClick={() => setTideRateSource('csv')}>
                Use uploaded tidal rates
            </div>
        </TideRateSourceSelector>

    return (
        <TideRateStations port={port} disabled={disabled}>{selector}</TideRateStations>
    )
}

export default TideRate
