import api, {
  getPayloadFromResponse,
  getSinglePayloadFromResponse,
} from 'src/utils/sauce/api'

export const getInvitations = (config) =>
  api
    .get(`/users/invites`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getInvitation = (uuid, config) =>
  api
    .get(`/users/invites/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createInvitation = (invitation, config) => {
  return api
    .post('/users/invites', invitation, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateInvitation = (invitation, config) => {
  return api
    .put(`/users/invites/${invitation.uuid}`, invitation, config)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}


export const updateInvitationPorts = ({ uuid, ports }) =>
  api
    .put(`/users/invites/${uuid}/ports`, { ports })
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const deleteInvitation = (uuid, config) =>
  api
    .delete(`/users/invites/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export default {
  create: createInvitation,
  update: updateInvitation,
  delete: deleteInvitation,
  updateInvitationPorts
}
