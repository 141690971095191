import {
  createSauceStore,
  REDUCER_TYPES,
  CREATOR_STATUS_NAMES,
  ACTION_STATUSES,
} from '@weareroam/sauce-web-store'
import { createSelector } from 'reselect'
import { selectedPortSelector } from 'src/store/port/selectors'
import actionToastMessage from 'src/utils/store/toast-middleware-creator'

export const weatherStore = createSauceStore({
  name: 'weatherConfig',
  url: '/weather_config(/:uuid)',
  type: REDUCER_TYPES.LIST,
  middlewares: [
    'statusById',
    'errorById',
    actionToastMessage({
      transformPayload: (
        name,
        actionName,
        status,
        payload,
        dispatch,
        getState
      ) => {
        const weatherConfig =
          typeof payload === 'string'
            ? weatherStore.selectors.getById(payload)(getState()) || {}
            : payload instanceof Error
            ? payload.data
            : payload
        const type = weatherConfig && weatherConfig.apiType

        return {
          ...weatherConfig,
          type:
            status === CREATOR_STATUS_NAMES[ACTION_STATUSES.SUCCESS]
              ? type[0].toUpperCase() + type.slice(1)
              : type,
        }
      },
    }),
  ],
})

weatherStore.selectors.getPortConfig = createSelector(
  selectedPortSelector,
  weatherStore.selectors.list,
  (portUuid, list) =>
    list && list.filter((item) => item.port && item.port.uuid === portUuid)
)

weatherStore.selectors.getActiveConfig = createSelector(
  weatherStore.selectors.getPortConfig,
  (config) => config && config.find((cfg) => cfg.isSelected)
)

weatherStore.reducer.use((state, action) => {
  const { type, payload } = action
  switch (type) {
    case weatherStore.actions.update.success.type:
    case weatherStore.actions.create.success.type: {
      const { byId } = state
      const newById = Object.keys(byId).reduce((byIds, uuid) => {
        const item = byId[uuid]
        return {
          ...byIds,
          [uuid]:
            payload.uuid === uuid
              ? item
              : {
                  ...item,
                  isSelected: item.uuid === payload.uuid,
                },
        }
      }, {})
      return {
        ...state,
        byId: newById,
      }
    }
    default:
      return state
  }
})

export default weatherStore
