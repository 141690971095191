import styled from 'styled-components'

import { ListItemSecondaryAction as MuiListItemSecondaryAction } from '@material-ui/core'

export const ListItemSecondaryAction = styled(MuiListItemSecondaryAction)`
  && {
    display: flex;
  }
`

export default ListItemSecondaryAction
