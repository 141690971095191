import { useDispatch, useSelector } from 'react-redux'
import {
  featureFlagsSetError,
  featureFlagsSetLoading,
  featureFlagsSetUpdating,
  featureFlagsSetFlags,
} from 'src/store/featureFlags/actions'
import { featureFlagsSelector } from 'src/store/featureFlags/selectors'
import { FlagName } from 'src/store/featureFlags/types'
import {
  getPreferences,
  createPreferences,
  updatePreferences,
  IPreferences,
} from 'src/utils/sauce/sauce-api-preferences'
import {
  IPreferencesFeatureFlags,
  FeatureFlags,
  FEATURE_FLAG_KEY
} from 'src/utils/sauce/sauce-api-preferences/FeatureFlags'
import {
  getSelectedPortSelector,
  selectedPortSelector,
} from 'src/store/port/selectors'


const useFeatureFlags = () => {
  const dispatch = useDispatch()
  const featureFlagsState = useSelector(featureFlagsSelector)
  const selectedPort = useSelector(getSelectedPortSelector)
  const selectedPortUuid = useSelector(selectedPortSelector)
  const { flags, uuid, loading, updating, error } = featureFlagsState

  async function load() {
    if (loading || !selectedPortUuid) {
      return
    }
    dispatch(featureFlagsSetFlags({ flags: undefined, uuid: undefined }))
    dispatch(featureFlagsSetLoading(true))

    try {
      const allPreferences = await getPreferences(selectedPortUuid)
      // super_admin users will have preferences for all ports in the list
      // so we'd need to only get preferences for the active port
      const featureFlags = allPreferences.find(
        (preferences) =>
          preferences.port.uuid === selectedPortUuid &&
          preferences.key === FEATURE_FLAG_KEY
      ) as IPreferencesFeatureFlags | undefined
      const flagSet = featureFlags && featureFlags.metadata
      const uuid = featureFlags && featureFlags.uuid

      dispatch(featureFlagsSetFlags({ flags: flagSet, uuid }))
    } catch (error) {
      dispatch(featureFlagsSetError(error))
    }

    dispatch(featureFlagsSetLoading(false))
  }

  function getFlag(name: FlagName) {
    return flags && flags[name]
  }

  // Update flags, or create if they don't exist
  async function setFlag(name: FlagName, value: FeatureFlags[FlagName]) {
    try {
      dispatch(featureFlagsSetUpdating(true))

      const nextFlagPreferences: IPreferences = {
        key: FEATURE_FLAG_KEY,
        metadata: { ...flags, [name]: value },
        port: selectedPort,
      }

      const [saved] = !uuid ? 
        await createPreferences(nextFlagPreferences) as IPreferencesFeatureFlags[] : 
        await updatePreferences(uuid, nextFlagPreferences) as IPreferencesFeatureFlags[]

      // also update in redux
      if (selectedPort && saved && !updating) {
        dispatch(featureFlagsSetFlags({ uuid: saved.uuid, flags: saved.metadata  }))
      }

    } catch (error) {
      dispatch(featureFlagsSetError(error))
    }

    dispatch(featureFlagsSetUpdating(false))
  }

  return {
    error,
    updating,
    loading,
    load,
    getFlag,
    setFlag,
    flags,
  }
}

export default useFeatureFlags
