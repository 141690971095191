import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRoute } from 'react-router5'
import { actions as routerActions } from 'redux-router5'

import Box from '@material-ui/core/Box'

import UsersTable from 'src/components/organisms/UsersTable'
import { saveInvitation } from 'src/store/invitation/actions'
import {
  getSelectedPortSelector,
  portListSelector,
} from 'src/store/port/selectors'
import useRedirectToPort from 'src/hooks/useRedirectToPort'
import { PILOTS_SCOPED, PILOTS_CREATE } from 'src/router/routes'
import ViewportLoader from 'src/components/atoms/ViewportLoader'
import ErrorMessage from 'src/components/atoms/ErrorMessage'
import Fieldset from 'src/components/organisms/Fieldset'
import FeatureHeader from 'src/components/atoms/FeatureHeader/FeatureHeader'
import PrimaryButton from 'src/components/atoms/PrimaryButton'
import FormDialog from 'src/components/molecules/FormDialog/FormDialog'
import { InvitePilotForm } from 'src/components/organisms/InvitePilotForm/InvitePilotForm'
import { compareName } from 'src/utils/sorting'
import useInvitationsData from 'src/hooks/useInvitations'
import {
  createInvitation,
  updateInvitationPorts
} from 'src/store/invitation/actions'
import { updateUserPorts } from 'src/store/user/actions'

export const Pilots = () => {
  const dispatch = useDispatch()
  const [submitting, setSubmitting] = useState()

  const routeInfo = useRoute()
  const { route: { name: routeName, params } = {} } = routeInfo
  const selectedPort = useSelector(getSelectedPortSelector)
  const ports = useSelector(portListSelector)

  const {
    data: invitations,
    isLoading
  } = useInvitationsData(
    true, // Do not filter by port, we need all in org
    false
  )
  
  const organisation = selectedPort && selectedPort.organisation

  // all pilots in ports that have same org as selected port
  const orgPilotInvitations = (invitations || [])
    .filter(invite => 
      !invite.isAdmin &&
      invite.organisation && 
      invite.organisation.uuid && 
      organisation && 
      invite.organisation.uuid === organisation.uuid
    ) 

  // pilots in active port
  const portPilotInvitations = orgPilotInvitations.filter(({ user, portValidationTokens = [] }) => {
    if (user && user.identity && user.identity.portIdentities) {
        return user.identity.portIdentities.find(portIdentity =>
            portIdentity.port && portIdentity.port.uuid === params.portUuid
        )
    }
    return portValidationTokens.find(validationToken =>
        validationToken.port && validationToken.port.uuid === params.portUuid
    )
  })

  useRedirectToPort()

  useEffect(() => {
    if (!params.portUuid) {
      if (selectedPort) {
        dispatch(
          routerActions.navigateTo(PILOTS_SCOPED, {
            portUuid: selectedPort.uuid,
          })
        )
      } else if (ports && ports.length) {
        dispatch(
          routerActions.navigateTo(PILOTS_SCOPED, {
            portUuid: ports[0].uuid,
          })
        )
      }
    } else if (selectedPort && params.portUuid !== selectedPort.uuid) {
      dispatch(
        routerActions.navigateTo(PILOTS_SCOPED, {
          portUuid: selectedPort.uuid,
        })
      )
    }
  }, [params.portUuid, selectedPort, ports])

  if (!params.portUuid && !selectedPort) {
    if (ports && ports.length) {
      return <ViewportLoader />
    } else {
      return <ErrorMessage>No ports available</ErrorMessage>
    }
  }

  const backToPilotList = () => {
    if (selectedPort) {
      dispatch(
        routerActions.navigateTo(PILOTS_SCOPED, {
          portUuid: selectedPort.uuid,
        })
      )
    }
  }

  const saveInvite = async (values) => {

    const { uuid, metadata, externalId, email } = values

    // don't touch ports
    const portValidationTokens = []
    if (selectedPort) {
      portValidationTokens.push({
        port: { uuid: selectedPort.uuid } // bug 
      })
    }

    const result = await dispatch(
      saveInvitation({
        uuid,
        metadata,
        externalId,
        email,
        portValidationTokens,
        isAdmin: false,
      })
    )
    if (!result.error) {
      backToPilotList()
    }
    setSubmitting(false)
  }

  const handleInviteCreate = async (values) => {
    setSubmitting(true)

    const existingInvite = orgPilotInvitations.find(({ email }) =>
      email.toLowerCase() === values.email.toLowerCase()
    )

    const existingUser = existingInvite && existingInvite.user

    try {
      let result

      if (existingUser && existingUser.identity.portIdentities) {
        result = await dispatch(
          updateUserPorts({
              ...existingUser,
              identity: {
                  ...existingUser.identity,
                  portIdentities: existingUser.identity.portIdentities.concat([{ port: selectedPort }])
              }
          }, 
          `Added ${values.firstName} ${values.lastName} to ${selectedPort.name}`
        ))

      } else if (existingInvite && existingInvite.portValidationTokens) {
        result = await dispatch(
          updateInvitationPorts({
            ...existingInvite,
            portValidationTokens: existingInvite.portValidationTokens.concat([{ port: selectedPort }])
          },
          `Invited ${values.firstName} ${values.lastName} to ${selectedPort.name}`
        ))

      } else {
        result = await dispatch(createInvitation({
          ...values,
          organisation: { uuid: selectedPort.organisation.uuid },
          portValidationTokens: [{ port: selectedPort }],
          metadata: {
            firstName: values.firstName,
            lastName: values.lastName
          }
        }))
      }
      if (!result.error) {
        backToPilotList()
      }
    } catch (err) {
      // @todo display error
    }

    setSubmitting(false)
  }

  const handleInviteUpdate = async (values) => { // dont touch ports
    const { firstName, lastName, licenseNumber, avatar } = values.metadata || {}
    setSubmitting(true)
    saveInvite({
      ...values,
      metadata: {
        firstName, lastName, licenseNumber, avatar
      }
    })
    setSubmitting(false)
  }

  const handleInviteButtonClick = () => {
    if (selectedPort) {
      dispatch(
        routerActions.navigateTo(PILOTS_CREATE, {
          portUuid: selectedPort.uuid,
        })
      )
    }
  }

  const extractName = (pilot) =>
    pilot.metadata
      ? `${pilot.metadata.firstName} ${pilot.metadata.lastName}`
      : ''

  const compareExtractedName = (a, b) => {
    return compareName({ name: extractName(a) }, { name: extractName(b) })
  }

  const handleCreateInviteAdvisory = (values) => {

    const portUuid = selectedPort.uuid

    const existingInvite = orgPilotInvitations.find(({ email }) =>
      email.toLowerCase() === values.email.toLowerCase()
    )

    const existingUser = existingInvite && existingInvite.user

    if (existingUser) {
        if ((existingUser.identity.portIdentities || []).find(identity => identity.port.uuid === portUuid)) {
            return { message: '', error: `${existingUser.metadata.firstName} ${existingUser.metadata.lastName} has already been added as a pilot` }
        }
        return {
            message: `${existingUser.metadata.firstName} ${existingUser.metadata.lastName} is already part of ${organisation.name}. Proceeding will also make them a pilot for ${selectedPort.name}.`,
            ...existingUser.metadata,
            externalId: existingUser.externalId
        }

    } else if (existingInvite) {
        if ((existingInvite.portValidationTokens || []).find(token => token.port.uuid === portUuid)) {
            return { message: '', error:`${existingInvite.metadata.firstName} ${existingInvite.metadata.lastName} has already been invited as a pilot` }
        }
        return {
            message: `${existingInvite.email} has already been invited to ${organisation.name}. Proceeding will also invite them to ${selectedPort.name}.`,
            ...existingInvite.metadata,
            externalId: existingInvite.externalId
        }
    } else {
        return {
            message: ''
        }
    }
  }

  return (
    <>
      <Fieldset title="Invitation" fullWidth>
        <FeatureHeader>Pilots</FeatureHeader>
        <UsersTable
          data={portPilotInvitations.sort(compareExtractedName)}
          isLoading={isLoading}
          port={selectedPort}
          handleUpdateInvite={handleInviteUpdate}
        />
        {!selectedPort.organisation.ssoEnabled && (
          <Box display="flex" flexDirection="row-reverse" pt={2}>
            <PrimaryButton onClick={handleInviteButtonClick}>
              Invite Pilot
            </PrimaryButton>
          </Box>
        )}
      </Fieldset>
      <FormDialog
        open={routeName === PILOTS_CREATE}
        title="Invite pilot"
        FormComponent={InvitePilotForm}
        onCancel={backToPilotList}
        onSave={handleInviteCreate}
        formEntityProp="invitation"
        formComponentProps={{
            advisory: handleCreateInviteAdvisory,
            emailOptions: orgPilotInvitations
                .filter(invite => // exclude those already in port
                    portPilotInvitations.indexOf(invite) === -1
                )
                .map(({ email }) => email)
        }}
        entity={{
          firstName: '',
          lastName: '',
          email: '',
          externalId: '',
        }}
        submitting={submitting}
      />
    </>
  )
}

Pilots.propTypes = {}

export default Pilots
