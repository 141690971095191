import { useDispatch, useSelector } from 'react-redux'
import {
  getListFilteredBySelectedPort,
  getListFilteredByPort,
  extraListStatusSelector,
  extraListSelector,
} from 'src/store/extra/selectors'
import {
  EXTRA_LIST_GET_SUCCESS,
  EXTRA_LIST_GET_ERROR,
  getExtraList,
  EXTRA_LIST_GET_IN_PROGRESS,
} from 'src/store/extra/actions'
import { useCallback } from 'react'

const useExtrasData = (filterByPort = true, portUuid) => {
  const dispatch = useDispatch()
  const data = useSelector(
    filterByPort
      ? portUuid
        ? getListFilteredByPort(portUuid)
        : getListFilteredBySelectedPort
      : extraListSelector
  )
  const status = useSelector(extraListStatusSelector)
  const reFetch = useCallback(() => dispatch(getExtraList()), [dispatch])
  if (status === null) {
    reFetch()
  }
  return {
    data,
    reFetch,
    isLoading: EXTRA_LIST_GET_IN_PROGRESS === status,
    isLoaded: [EXTRA_LIST_GET_SUCCESS, EXTRA_LIST_GET_ERROR].includes(status),
    isFailed: EXTRA_LIST_GET_ERROR === status,
  }
}

export default useExtrasData
