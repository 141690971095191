import api from 'src/utils/sauce/api'
import { getPayloadFromResponse } from './../api'

export const getPilotagesExport = (params) =>
  api
    .get('/pilotages/export/csv', { params })
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getPilotagesPdfDownload = (params) =>
  api
    .post(`/pilotages/${params.pilotageUuid}/done-pdf`, null, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf',
      },
      // some ports use 8MB charts and till we allow this size
      // we have to disable timeout
      timeout: 0,
    })

export default {
  getPilotagesExport,
  getPilotagesPdfDownload,
}
