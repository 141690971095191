import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import { validateValues } from 'src/utils/validation'
import useForm from 'src/hooks/useForm'
import { TextInput } from 'src/components/atoms/TextInput'

const TimestampItemForm = ({ entity, onChange }) => {
  const validateEntity = useCallback(
    (values) =>
      validateValues(values, {
        label: ['required'],
      }),
    []
  )
  const { fields } = useForm({
    initialValues: entity,
    validationHandler: validateEntity,
    changeHandler: onChange,
  })

  return (
    <Box display="flex">
      <Box flex={1} pr={2}>
        <TextInput
          name="label"
          narrow
          fullWidth
          label="Name of event"
          {...fields.label}
        />
      </Box>
      <Box flex={1}>
        <TextInput
          name="refId"
          narrow
          fullWidth
          label="Event Ref. ID (optional)"
          {...fields.refId}
        />
      </Box>
    </Box>
  )
}

TimestampItemForm.propTypes = {
  entity: PropTypes.shape({
    label: PropTypes.string,
    refId: PropTypes.string,
  }),
  onChange: PropTypes.func,
}

export default TimestampItemForm
