import React, { useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import {
  Dialog,
  DialogActions,
  LinearProgress,
  DialogContent,
} from '@material-ui/core'

import ConfirmDialog from 'src/components/organisms/ConfirmDialog'
import { ConstraintShape, PortShape } from 'src/utils/types'
import {
  saveConstraint,
  CONSTRAINT_CREATE_SUCCESS,
  CONSTRAINT_UPDATE_SUCCESS,
} from 'src/store/constraint/actions'
import ConstraintForm from 'src/components/organisms/ConstraintForm'
import DialogHeader from 'src/components/molecules/DialogHeader/DialogHeader'
import SecondaryButton from 'src/components/atoms/SecondaryButton'
import PrimaryDialogButton from 'src/components/atoms/PrimaryDialogButton'

export const ConstraintFormDialog = ({
  open,
  port,
  constraint,
  onCloseRequest,
  ...constraintFormProps
}) => {
  const dispatch = useDispatch()
  const [confirmOpen, setConfirmOpen] = useState(false)
  const values = useRef(constraint)
  const [dirty, setDirty] = useState(false)
  const [formIsValid, setFormIsValid] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const handleFormChange = useCallback(
    (change) => {
      const hasError = Object.values(change.errors).some(
        (item) => item && item.length
      )
      values.current = change.values
      if (dirty !== change.dirty) {
        setDirty(change.dirty)
      }
      if (hasError === formIsValid) {
        setFormIsValid(!hasError)
      }
    },
    [dirty, formIsValid]
  )
  const handleFormSubmit = async () => {
    if (dirty) {
      setSubmitting(true)
      try {
        const result = await dispatch(
          saveConstraint({
            ...values.current,
            port,
          })
        )
        if (
          [CONSTRAINT_CREATE_SUCCESS, CONSTRAINT_UPDATE_SUCCESS].includes(
            result.type
          )
        ) {
          onCloseRequest(result)
        }
      } catch (error) {}
      setSubmitting(false)
    }
  }
  const handleCloseRequest = useCallback(
    (...args) => {
      if (dirty) {
        setConfirmOpen(true)
      } else {
        onCloseRequest(...args)
      }
    },
    [dirty, onCloseRequest]
  )
  const handleLeaveUnsavedConfirmed = useCallback(
    (...args) => {
      setConfirmOpen(false)
      onCloseRequest(...args)
    },
    [onCloseRequest]
  )
  const handleLeaveUnsavedRejected = () => {
    setConfirmOpen(false)
  }
  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseRequest}
        onEscapeKeyDown={handleCloseRequest}
      >
        <DialogHeader>
          {constraint && constraint.uuid ? 'Edit' : 'Create'} constraint
        </DialogHeader>
        <DialogContent>
          {submitting && <LinearProgress variant="indeterminate" />}
          <ConstraintForm
            {...constraintFormProps}
            constraint={constraint}
            onChange={handleFormChange}
            onSubmit={handleFormSubmit}
          />
        </DialogContent>
        <DialogActions>
          <SecondaryButton onClick={handleCloseRequest} disabled={submitting}>
            Cancel
          </SecondaryButton>
          <PrimaryDialogButton
            onClick={handleFormSubmit}
            disabled={submitting || !dirty || !formIsValid}
          >
            Done
          </PrimaryDialogButton>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={confirmOpen}
        onCancel={handleLeaveUnsavedRejected}
        onConfirm={handleLeaveUnsavedConfirmed}
        title="Unsaved changes"
      >
        You have unsaved changes. Are you sure you want to close?
      </ConfirmDialog>
    </>
  )
}

ConstraintFormDialog.propTypes = {
  open: PropTypes.bool,
  onCloseRequest: PropTypes.func,
  constraint: PropTypes.shape(ConstraintShape),
  port: PropTypes.shape(PortShape),
}

export default ConstraintFormDialog
