import {
  createErrorAction,
  createBasicActionTypes,
  createSuccessAction,
  createInProgressAction,
} from 'src/utils/createAction'

import * as api from 'src/utils/sauce/sauce-api-extras'
import { addSuccessToast, addErrorToast } from 'src/store/toast/actions'

// GET EXTRA
const ACTION_GET = 'EXTRA_GET'
export const [
  EXTRA_GET_REQUEST,
  EXTRA_GET_RESULT,
  EXTRA_GET_SUCCESS,
  EXTRA_GET_ERROR,
  EXTRA_GET_CANCEL,
  EXTRA_GET_IN_PROGRESS,
] = createBasicActionTypes(ACTION_GET)
export const getExtra = (uuid) => async (dispatch) => {
  dispatch({ type: ACTION_GET, payload: uuid })
  return dispatch(getExtraRequest(uuid))
}
export const getExtraRequest = (uuid) => async (dispatch) => {
  dispatch(getExtraInProgress(uuid))
  try {
    const extraResult = await api.getExtra(uuid)
    return dispatch(getExtraSuccess(extraResult))
  } catch (error) {
    return dispatch(getExtraError(error))
  }
}
export const getExtraInProgress = (uuid) =>
  createInProgressAction(ACTION_GET, uuid)
export const getExtraSuccess = (extra) => createSuccessAction(ACTION_GET, extra)
export const getExtraError = (error) => createErrorAction(ACTION_GET, error)

// GET EXTRA_LIST
const EXTRA_LIST_GET = 'EXTRA_LIST_GET'
export const [
  EXTRA_LIST_GET_REQUEST,
  EXTRA_LIST_GET_RESULT,
  EXTRA_LIST_GET_SUCCESS,
  EXTRA_LIST_GET_ERROR,
  EXTRA_LIST_GET_CANCEL,
  EXTRA_LIST_GET_IN_PROGRESS,
] = createBasicActionTypes(EXTRA_LIST_GET)
export const getExtraList = (uuid) => async (dispatch) => {
  dispatch({ type: EXTRA_LIST_GET, payload: uuid })
  return dispatch(getExtraListRequest(uuid))
}
export const getExtraListRequest = (uuid) => async (dispatch) => {
  dispatch(getExtraListInProgress(uuid))
  try {
    const extraListResult = await api.getExtras(uuid)
    return dispatch(getExtraListSuccess(extraListResult))
  } catch (error) {
    return dispatch(getExtraListError(error))
  }
}
export const getExtraListInProgress = (uuid) =>
  createInProgressAction(EXTRA_LIST_GET, uuid)
export const getExtraListSuccess = (extra) =>
  createSuccessAction(EXTRA_LIST_GET, extra)
export const getExtraListError = (error) =>
  createErrorAction(EXTRA_LIST_GET, error)

// CREATE EXTRA
const ACTION_CREATE = 'EXTRA_CREATE'
export const [
  EXTRA_CREATE_REQUEST,
  EXTRA_CREATE_RESULT,
  EXTRA_CREATE_SUCCESS,
  EXTRA_CREATE_ERROR,
  EXTRA_CREATE_CANCEL,
  EXTRA_CREATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_CREATE)
export const createExtra = (extra) => async (dispatch) => {
  dispatch({ type: ACTION_CREATE, payload: extra })
  return dispatch(createExtraRequest(extra))
}
export const createExtraRequest = (payload) => async (dispatch) => {
  dispatch(createExtraInProgress(payload))
  try {
    const extraResult = await api.createExtra(payload)
    return dispatch(createExtraSuccess(extraResult))
  } catch (error) {
    return dispatch(createExtraError(error, payload))
  }
}
export const createExtraInProgress = (extra) =>
  createInProgressAction(ACTION_CREATE, extra)
export const createExtraSuccess = (extra) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: `'${extra.name}' saved`,
    })
  )
  return dispatch(createSuccessAction(ACTION_CREATE, extra))
}
export const createExtraError = (error, extra) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: `Error saving '${extra.name}'`,
    })
  )
  return dispatch(createErrorAction(ACTION_CREATE, error))
}

// UPDATE extra
const ACTION_UPDATE = 'EXTRA_UPDATE'
export const [
  EXTRA_UPDATE_REQUEST,
  EXTRA_UPDATE_RESULT,
  EXTRA_UPDATE_SUCCESS,
  EXTRA_UPDATE_ERROR,
  EXTRA_UPDATE_CANCEL,
  EXTRA_UPDATE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_UPDATE)
export const updateExtra = (extra) => (dispatch) => {
  dispatch({ type: ACTION_UPDATE, payload: extra })
  return dispatch(updateExtraRequest(extra))
}
export const updateExtraRequest = (payload) => async (dispatch) => {
  dispatch(updateExtraInProgress(payload))
  try {
    const extraResult = await api.updateExtra(payload)
    return dispatch(updateExtraSuccess(extraResult))
  } catch (error) {
    return dispatch(updateExtraError(error, payload))
  }
}
export const updateExtraInProgress = (extra) =>
  createInProgressAction(ACTION_UPDATE, extra)
export const updateExtraSuccess = (extra) => (dispatch) => {
  dispatch(
    addSuccessToast({
      message: `'${extra.name}' updated`,
    })
  )
  return dispatch(createSuccessAction(ACTION_UPDATE, extra))
}
export const updateExtraError = (error, extra) => (dispatch) => {
  dispatch(
    addErrorToast({
      message: `Error updating '${extra.name}'`,
    })
  )
  return dispatch(createErrorAction(ACTION_UPDATE, error))
}

// SAVE extra (just a helper function, it checks the uuid to decide if the action is create or update)
const ACTION_SAVE = 'EXTRA_SAVE'
export const saveExtra = (extra) => async (dispatch) => {
  dispatch({ type: ACTION_SAVE, payload: extra })
  const result = await dispatch(
    extra.uuid ? updateExtra(extra) : createExtra(extra)
  )
  return result
}

// DELETE extra
const ACTION_DELETE = 'EXTRA_DELETE'
export const [
  EXTRA_DELETE_REQUEST,
  EXTRA_DELETE_RESULT,
  EXTRA_DELETE_SUCCESS,
  EXTRA_DELETE_ERROR,
  EXTRA_DELETE_CANCEL,
  EXTRA_DELETE_IN_PROGRESS,
] = createBasicActionTypes(ACTION_DELETE)
export const deleteExtra = (extra) => (dispatch) => {
  dispatch({ type: ACTION_DELETE, payload: extra })
  return dispatch(deleteExtraRequest(extra))
}
export const deleteExtraRequest = (extra) => async (dispatch) => {
  dispatch(deleteExtraInProgress(extra))
  try {
    await api.deleteExtra(extra.uuid)
    dispatch(
      addSuccessToast({
        message: `'${extra.name}' removed`,
      })
    )
    return dispatch(deleteExtraSuccess(extra))
  } catch (error) {
    dispatch(
      addErrorToast({
        message: `Error removing '${extra.name}'`,
      })
    )
    dispatch(deleteExtraError(error))
    return error
  }
}
export const deleteExtraInProgress = (extra) =>
  createInProgressAction(ACTION_DELETE, extra)
export const deleteExtraSuccess = (extra) =>
  createSuccessAction(ACTION_DELETE, extra)
export const deleteExtraError = (error) =>
  createErrorAction(ACTION_DELETE, error)
