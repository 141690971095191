import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'

import {
  getListFilteredByPort,
  getListFilteredBySelectedPort,
  tideStationListSelector,
} from 'src/store/tideStations/selectors'
import SelectInput from 'src/components/atoms/SelectInput'
import { getTideStationList } from 'src/store/tideStations/actions'
import { PortShape } from 'src/utils/types'

export const TideStationSelect = ({
  onChange,
  value: selected,
  required = true,
  port,
  disableFilterByPort,
  ...props
}) => {
  const tideStations = useSelector(
    disableFilterByPort
      ? tideStationListSelector
      : port
      ? getListFilteredByPort(port.uuid)
      : getListFilteredBySelectedPort
  )
  const dispatch = useDispatch()
  const [uuid, setUuid] = useState(selected && selected.uuid)
  const [options, setOptions] = useState([])
  useEffect(() => {
    const opts =
      tideStations &&
      tideStations
        .map((item) => ({
          label: item.name,
          value: item.uuid,
        }))
        .sort(({ label: l1 }, { label: l2 }) => {
          const l11 = l1.toLowerCase()
          const l22 = l2.toLowerCase()
          return l11 > l22 ? 1 : l11 < l22 ? -1 : 0
        })
    setOptions(opts)
  }, [tideStations])

  if (tideStations === null) {
    dispatch(getTideStationList())
  }
  if (options && !required) {
    options.unshift({
      label: ' ',
      value: null,
    })
  }
  const handleChange = useCallback(
    (event) => {
      const {
        target: { value: uuid },
      } = event
      const tideStation = tideStations.find((item) => item.uuid === uuid)
      onChange(props.name, tideStation)
      setUuid(tideStation && tideStation.uuid)
    },
    [tideStations, onChange, props.name]
  )
  return (
    <SelectInput
      {...props}
      value={uuid || '-'}
      onChange={handleChange}
      disabled={options === null || props.disabled || tideStations.length === 0}
      options={options || []}
      placeholder={
        !tideStations || tideStations.length === 0 ?
        "No tide stations defined" :
        "Select tide station"
      }
    />
  )
}

TideStationSelect.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.any,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  disableFilterByPort: PropTypes.bool,
  name: PropTypes.string,
  port: PropTypes.shape(PortShape),
}

export default TideStationSelect
