import { createSelector } from 'reselect'
import { createSauceStore, REDUCER_TYPES } from '@weareroam/sauce-web-store'

const KEY_MANAGER = 'key_manager'
const KEY_MANAGER_STORE_NAME = 'keyManager'

export const store = createSauceStore({
  name: KEY_MANAGER_STORE_NAME,
  url: KEY_MANAGER,
  type: REDUCER_TYPES.OBJECT,
})

store.selectors.apiKey = createSelector(
  store.selectors.data,
  (data) => data && data.apiKey
)

export default store
