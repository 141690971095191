import { TOAST_ADD, TOAST_SEEN } from 'src/store/toast/actions'
import { AUTH_LOGIN_SUCCESS } from 'src/store/auth/actions'

const getInitialState = () => ({ items: [], itemsById: {} })

const reducer = (state = getInitialState(), action) => {
  const { type, payload: toast } = action
  switch (type) {
    case TOAST_ADD: {
      const items = [...state.items, toast]
      const itemsById = { ...state.itemsById, [toast.id]: toast }
      return {
        items,
        itemsById,
      }
    }
    case TOAST_SEEN: {
      const { id } = toast
      const items = [...state.items]
      const itemsById = { ...state.itemsById }
      const item = itemsById[id]
      if (item) {
        item.seen = true
      }
      return {
        items,
        itemsById,
      }
    }
    case AUTH_LOGIN_SUCCESS:
      return getInitialState()
    default:
      return state
  }
}

export default reducer
