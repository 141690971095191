import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import fileSize from 'filesize'

import { Typography } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'

import UploadButton from 'src/components/atoms/UploadButton'
import Input from 'src/components/atoms/Input'
import { FILE_STATUS } from 'src/constants/settings'

const DefaultWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
`

const PreviewWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    color: ${theme.palette.text.secondary};
    margin-top: ${theme.spacing(2)}px;
    word-break: break-all;
  `
)

const PreviewIconWrapper = styled.div(
  ({ theme }) => css`
    margin-right: ${theme.spacing(1)}px;
  `
)

const DefaultPreview = ({ file, status }) => {
  let icon
  switch (status) {
    case FILE_STATUS.ERROR:
      icon = <WarningIcon color="error" />
      break
    case FILE_STATUS.SUCCESS:
      icon = <CheckCircleIcon color="primary" />
      break
    case FILE_STATUS.UPLOADING:
      icon = <CloudUploadIcon color="action" />
      break
    default:
      icon = null
  }
  return (
    <PreviewWrapper>
      {icon && <PreviewIconWrapper>{icon}</PreviewIconWrapper>}
      <div>
        <Typography variant="subtitle2">{file.name}</Typography>
        <Typography variant="caption">
          {fileSize(file.size, { base: 10 })}
        </Typography>
      </div>
    </PreviewWrapper>
  )
}

DefaultPreview.propTypes = {
  file: PropTypes.any,
  status: PropTypes.string,
}

export const FileBrowser = ({
  name,
  onChange,
  WrapperComponent = DefaultWrapper,
  PreviewComponent = DefaultPreview,
  fileSelection,
  ...dropZoneOptions
}) => {
  const [selectedFiles, setSelectedFiles] = useState([])

  const handleFileSelect = (files) => {
    if (files && onChange) {
      setSelectedFiles(
        files.map((file) => ({ file, status: FILE_STATUS.SELECTED }))
      )
      onChange(name, dropZoneOptions.multiple ? files : files && files[0])
    }
  }

  useEffect(() => {
    setSelectedFiles(fileSelection)
  }, [fileSelection, setSelectedFiles])

  return (
    <WrapperComponent>
      <UploadButton {...dropZoneOptions} onSelect={handleFileSelect} />
      {PreviewComponent &&
        selectedFiles &&
        selectedFiles.map(({ file, status }, index) => (
          <PreviewComponent
            key={`${file.name}--${index}`}
            file={file}
            status={status}
          />
        ))}
    </WrapperComponent>
  )
}

FileBrowser.propTypes = {
  fileSelection: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.instanceOf(File),
      status: PropTypes.string,
    })
  ),
  name: PropTypes.string,
  onChange: PropTypes.func,
  WrapperComponent: PropTypes.element,
  PreviewComponent: PropTypes.element,
}

export const FileInput = (props) => <Input Component={FileBrowser} {...props} />

export default FileInput
