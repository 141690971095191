import api, {
  getSinglePayloadFromResponse,
  getPayloadFromResponse,
} from 'src/utils/sauce/api'

const getConfigMultipartFormDataHeaders = (original = {}) => ({
  ...original,
  headers: {
    ...(original.headers || {}),
    'Content-Type': 'multipart/form-data',
  },
})

const getFormDataFromJSON = (body = {}) => {
  const data = new FormData()
  const { port, berthsStations, ...fields } = body
  Object.keys(fields).forEach((name) => {
    if (fields[name] !== undefined) {
      data.append(name, fields[name])
    }
  })
  data.append('port', JSON.stringify(port))
  data.append('berthsStations', JSON.stringify(berthsStations || []))
  return data
}

const getFileUploadBody = (chart) => {
  const { imageUrl, ...fields } = chart
  let data = getFormDataFromJSON(fields)
  if (imageUrl instanceof File) {
    data.append('file', imageUrl)
  } else {
    data.append('imageUrl', imageUrl)
  }
  return data
}

export const getCharts = (config) =>
  api
    .get(`/charts`, config)
    .then(
      (response) => getPayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const getChart = (uuid, config) =>
  api
    .get(`/charts/${uuid}`, config)
    .then(
      (response) => getSinglePayloadFromResponse(response),
      (error) => Promise.reject(error)
    )

export const createChart = (chart, config = {}) => {
  let cfg = config
  let body = { ...chart }
  body = getFileUploadBody(chart)
  cfg = getConfigMultipartFormDataHeaders(cfg)
  return api
    .post('/charts', body, cfg)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const updateChart = (chart, config = {}) => {
  let cfg = config
  let body = { ...chart }
  body = getFileUploadBody(chart)
  cfg = getConfigMultipartFormDataHeaders(cfg)
  return api
    .put(`/charts/${chart.uuid}`, body, cfg)
    .then(getSinglePayloadFromResponse, (error) => Promise.reject(error))
}

export const saveChart = (...args) =>
  args[0].uuid ? updateChart(...args) : createChart(...args)

export const deleteChart = (uuid, config = {}) =>
  api
    .delete(`/charts/${uuid}`, config)
    .then(
      (result) => getSinglePayloadFromResponse(result),
      (error) => Promise.reject(error)
    )
